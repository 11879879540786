import React from 'react'

import { observer } from 'mobx-react'
import { Space, Popover, Select, Button, message } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { TableCard, AuthButton, } from '@components'
import store from './gamevendorsetupStore'
import { t } from '@tool'
const rowSelection = {
    onChange: (selectedRowKeys) => {
        store.setIds(selectedRowKeys)
    }, getCheckboxProps: (record) => ({
        disabled: record.id === 'Disabled Index', // Column configuration not to be checked
        name: record.id,
    }),
}

@observer
class ComTable extends React.Component {

    componentDidMount() {
        store.tableOptions = {
            ...store.tableOptions, companyType: 'OPERATOR_COMPANY', status: 1
        }
        store.fetchRecords()
    }
    columns = [
        {
            title: t('Gamebusiness.companyname'),
            width: '80px',
            key: 'companyName',
            dataIndex: 'companyName',
            ellipsis: true,
            fixed: 'left',
        },
        {
            title: t('Gamebusiness.status'),
            width: '50px',
            key: 'status',
            dataIndex: 'status',
            ellipsis: true,
            render: (text) => (text === 1) ? t('public.effective') : t('public.invain'),
        },
        {
            title: t('Gamebusiness.mailbox'), width: '80px', key: 'email', dataIndex: 'email', ellipsis: true, render: (text) => {
                return (
                    <div className="ellipsis" style={{

                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                    }} title={t('public.Clickcopy')} onClick={store.copy}>
                        {text}
                    </div>)
            }
        },
        {
            title: t('Gamebusiness.Background'), width: '100px', ellipsis: true, key: 'backOffice', dataIndex: 'backOffice', render: (text) => {
                return (
                    <div className="ellipsis" style={{
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                    }} title={t('public.Clickcopy')} onClick={store.copy}>
                        {text}
                    </div>)
            }
        },
        {
            title: t('Gamebusiness.apiaddress'), width: '100px', key: 'apiUrl', dataIndex: 'apiUrl', ellipsis: true, render: (text) => {
                return (
                    <div className="ellipsis" style={{

                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                    }} title={t('public.Clickcopy')} onClick={store.copy}>
                        {text}
                    </div>)
            }
        },
        {
            title: t('public.remark'),
            width: '100px',
            key: 'remark',
            dataIndex: 'remark',
            ellipsis: true,
            render: (text) => {
                return (
                    <div className="ellipsis" style={{
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                    }} title={t('public.Clickcopy')} onClick={store.copy}>
                        {text}
                    </div>)
            }
        },
        {
            title: t('public.Controls'), fixed: 'right', width: '140px', key: 'id', render: (_, record) => {
                return (<Space size="middle">
                    <AuthButton
                        type={"primary"}
                        ghost
                        icon={<PlusOutlined />}
                        onClick={() => store.onSetup(record)}
                        auth="operator.setup.edit">
                        {'设置'}
                    </AuthButton>
                </Space>)
            },
        },]

    handleTableChange = ({ current, pageSize }, filters, sorter) => {
        store.pageNum = current
        store.pageSize = pageSize
        store.tableOptions = {
            ...store.tableOptions, // 排序：好像只支持单个排序
            sortField: sorter.field, sortOrder: sorter.order, ...filters
        }
        store.fetchRecords()
    }

    handleTableChange = ({current, pageSize}, filters, sorter) => {
        store.pageNum = current
        store.pageSize = pageSize
        store.tableOptions = {
            ...store.tableOptions, // 排序：好像只支持单个排序
            sortField: sorter.field, sortOrder: sorter.order, ...filters
        }
        store.fetchRecords()
    }

    render() {
        return (<TableCard
            tableLayout={'fixed'}
            size={'small'}
            scroll={{
                x: 1500,
                y: '44vh'
            }}
            rowKey="id"
            title={'运营商列表'}
            loading={store.isFetching}
            // 后端的数据源
            dataSource={store.dataSource}
            onReload={store.fetchRecords}
            onChange={this.handleTableChange}
            actions={[]}
            // 分页器
            pagination={{
                showSizeChanger: true,
                showLessItems: true,
                showTotal: total => t('public.intotal', total),
                pageSizeOptions: ['10', '20', '50', '100'], // 如果不传 total，则以后端返回数据条数作为 total 的值
                total: store.total, // 如果不传，则默认是第一条，如果需要默认显示第3条，则必须传
                current: store.pageNum,
                pageSize: store.pageSize,
            }}
            columns={this.columns} />)
    }
}

export default ComTable
