import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Modal, Form, Input, message, Row, Col } from 'antd'
import store from './gamepackStore'
import { addGamePacket, modifyGamePacket } from '@api'
import { t } from '@tool'
export default observer(function () {
    // 文档中未找到这种解构使用方法
    const [form] = Form.useForm()
    // useState 函数组件中使用 state
    // loading 默认是 flase
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        form.resetFields()
        if (store.record.id) {
            form.setFieldsValue(store.record)
        }

    }, [store.record])

    const handleSubmit = () => {
        form.submit()
    }

    const onFinish = (values) => {
        setLoading(true)
        if (store.record.id) {
            values['id'] = store.record.id
            modifyGamePacket(values).then(res => {
                if (res.status === 10000) {
                    store.formVisible = false
                    store.fetchRecords()
                } else {
                    message.error(t('Gamepack.failure'))
                    
                }
            }).finally(() => setLoading(false))
        } else {
            addGamePacket(values).then(res => {
                if (res.status === 10000) {
                    store.formVisible = false
                    store.fetchRecords()
                }
            }).finally(() => setLoading(false))
        }
    }

    return (// Modal 对话框
        <Modal
            width={'40%'}
            forceRender={true}
            open={store.formVisible}
            title={store.formTitle}
            onCancel={() => store.formVisible = false}
            confirmLoading={loading}
            // ok 按钮 props
            onOk={handleSubmit}>
            <Form form={form}  onFinish={onFinish}>
                <Row gutter={{ md: 8, lg: 23, xl: 48 }}>
                    <Col span={24}>
                        <Form.Item label={t('Gamepack.name')}
                            rules={[{ required: true, message: `${t('public.input')} ${t('Gamepack.name')}` }]}
                            name={"packetName"}>
                            <Input allowClear autoComplete="off" disabled={!store.formEdit}
                                placeholder={`${t('public.input')} ${t('Gamepack.name')}`} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
                    <Col span={24}>
                        <Form.Item
                            label={t('public.remark')}
                            name="remark">
                            <Input.TextArea   placeholder={`${t('public.input')} ${t('public.remark')}`} rows={5} disabled={!store.formEdit} autoComplete="off" />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>)
})