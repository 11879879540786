
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Modal, Tree } from 'antd';
import { t } from '@tool'
import store from './RoleAuthStore'
import { getAuthListByRoleId } from '@api'
export default observer(function () {
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (store.record.id) {
            getAuthListByRole(store.record.id)
        }
    }, [store.record, store.records])

    const getAuthListByRole = (val) => {
        getAuthListByRoleId(val).then(res => {
            console.log(res);
        }).finally(() => { })
    }
    const handleSubmit = () => {
        console.log('22222');
    }
    // 默认展开
    const [expandedKeys, setExpandedKeys] = useState([1]);
    // 默认选中
    const [checkedKeys, setCheckedKeys] = useState([1, 2, 3, 4, 6, 7]);
    const [autoExpandParent, setAutoExpandParent] = useState(true);
    const onExpand = (expandedKeysValue) => {
        setExpandedKeys(expandedKeysValue);
    };
    const onCheck = (checkedKeysValue) => {
        console.log('onCheck', checkedKeysValue);
        setCheckedKeys(checkedKeysValue);
    };
    const onSelect = (selectedKeysValue, info) => {
        console.log('onSelect', info);
    };

    return (// Modal 对话框
        <Modal
            width={'54%'}
            forceRender={true}
            open={store.formVisible}
            title={store.formTitle}
            onCancel={() => store.formVisible = false}
            confirmLoading={loading}
            // ok 按钮 props
            onOk={handleSubmit}>
            <Tree
                checkable
                onExpand={onExpand}
                expandedKeys={expandedKeys}
                onCheck={onCheck}
                checkedKeys={checkedKeys}
                onSelect={onSelect}
                treeData={store.records}
            />

        </Modal>)
})
