import { observable, computed, action } from 'mobx'
import { message } from "antd"
import { getGameOperatorList, batchStatusGameOperator } from '@api'
import { history } from '@http'
import { t } from '@tool'
class GameStore {
    @observable formVisible = false

    @observable formEdit = false

    @observable formTitle = ''

    @observable record = {}

    @observable records = []

    // 默认第1页
    @observable pageNum = 1
    // 每页多少条
    @observable pageSize = 10
    // 总共多少页
    @observable total = ''

    //二次确认删除
    @observable conDelete = false

    @observable ids = ''

    // 其他参数，例如排序、过滤等等
    @observable tableOptions = {}

    @observable isFetching = false

    @computed get dataSource() {
        return this.records
    }
    _getTableParams = () => ({
        pageNum: this.pageNum, pageSize:this.pageSize, ...this.tableOptions, status: 1,
        companyId: history.location.state?.params
    })

    @action setPageNum(val) {
        this.pageNum = val
    }
    @action setPageSize(val) {
        this.setPageSize = val
    }

    @action setIds(ids) {
        this.ids = ids
    }
    //查询
    fetchRecords = () => {
        const params = this._getTableParams()
        this.isFetching = true
        getGameOperatorList(params).then(res => {
            this.total = res.totalCount
            this.records = res.list
        }).finally(() => this.isFetching = false);
    }
    //删除
    onDelete = (record) => {
        let id = []
        if (record !== -1) {
            id.push(record.id)
        } else {
            id = this.ids
        }
        this.isFetching = true
        let params = { 'ids': id, 'status': 0 }

        batchStatusGameOperator(params).then(res => {
            if (res.status === 10000) {
                this.setPageNum(1)
                this.setPageSize(10)
                this.ids = []
                this.fetchRecords()
                this.conDelete = false
            }
        }).finally(() => this.isFetching = false)
    }

    // 显示新增弹框
    // info 或许是为了编辑
    showForm = () => {
        this.formVisible = true
        this.formTitle = t('public.Newgame');
    }
    //二次确认删除
    confirmDelete = (data) => {
        if (this.ids.length === 0) {
            message.error(t('public.selectDelete'))
            return
        }
        this.conDelete = data
    }
}

export default new GameStore()