import React, { useState, useEffect } from 'react'
import { ExclamationCircleOutlined, CloseOutlined } from '@ant-design/icons'
import { Popconfirm, message } from "antd"
import { integralWarnWindow, closewindow } from '@api'
import { get, t, Debounce } from '@tool'
import './Pointnotification.less'

export default function () {
    const [dataVal, setData] = useState([]);
    useEffect(() => {
        getIntegralWarnWindow();
    }, [])
    const getIntegralWarnWindow = () => {
        const params = {}
        if (get('userType') == 'ADMIN' || get('companyId') == 1) {
            params.companyId = 0;
        } else {
            params.companyId = get('companyId')
        }
        integralWarnWindow({ ...params }).then(res => {
            if (res) {
                const newData = res.map(item => {
                    const key = Object.keys(item)[0];
                    const text = item[key];
                    return { id: parseInt(key, 10), text: text };
                });
                setData(newData);
            } else {
                setData([])
            }
        }).catch(() => { setData([]); });
        // 递归调用自身，每隔五分钟
        setTimeout(getIntegralWarnWindow, 5 * 60 * 1000);
    }

    const confirm = (val) => {
        closewindow({ id: val }).then(res => {
            if (res === 1) {
                message.success(t('public.success'))
            } else {
                message.error(t('public.error'))
            }
            getIntegralWarnWindow();
        }).catch(() => { });

    };
    return (
        <div className='beatdown'>
            {(dataVal || []).map((item, index) => (
                <div key={index} className='beatitem'>
                    <div className='topse'>
                        <ExclamationCircleOutlined className='iosre' />
                        <p>积分提醒</p>
                    </div>
                    <p className='jdura'> {item.text}</p>
                    <Popconfirm
                        placement="left"
                        title="请尽快处理以免影响正常使用！"
                        onConfirm={() => { confirm(item.id) }}
                        okText="确定"
                        cancelText="取消"
                    >
                        <CloseOutlined className='gbeds' />
                    </Popconfirm>
                </div>
            ))}




        </div>
    )
}
