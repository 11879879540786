import React, {useState, useEffect} from 'react'
import {observer} from 'mobx-react'
import {Modal, Form, Input, message, Space, Button, Radio, Row, Col} from 'antd'
import {MinusCircleOutlined, PlusOutlined} from '@ant-design/icons';
import store from './paramStore'
import {addSystemParam, modifySystemParam} from '@api'
import {t} from '@tool'

export default observer(function () {

    useEffect(() => {
        form.resetFields()
        if (store.record.id) {
            form.setFieldsValue(store.record)
        }
    }, [store.record])

    // 文档中未找到这种解构使用方法
    const [form] = Form.useForm()

    // useState 函数组件中使用 state
    // loading 默认是 false
    const [loading, setLoading] = useState(false)

    const onFinish = (values) => {
        setLoading(true)
        if (store.record.id) {
            values['id'] = store.record.id
            modifySystemParam(values).then(res => {
                if (res.status === 10000) {
                    message.success(t('public.success'))
                    store.formVisible = false
                    store.fetchRecords()
                } else {
                    message.error(t('public.error'))
                }
            }).finally(() => setLoading(false))
        } else {
            addSystemParam(values).then(res => {
                if (res.status === 10000) {
                    message.success(t('public.success'))
                    store.formVisible = false
                    store.fetchRecords()
                }
            }).finally(() => setLoading(false))
        }
    }

    const handleSubmit = () => {
        form.submit()
    }

    return (// Modal 对话框
        <Modal
            width={'70%'}
            forceRender={true}
            open={store.formVisible}
            title={store.formTitle}
            onCancel={() => store.formVisible = false}
            confirmLoading={loading}
            onOk={handleSubmit}
        >
            <div style={{overflow: 'auto', height: 430}}>
                <Form
                    form={form}
                    name="dynamic_form_nest_item"
                    onFinish={onFinish}
                >
                    <Row style={{width: '95%'}} gutter={{md: 8, lg: 24, xl: 48}}>
                        <Col span={24}>
                            <Form.Item
                                label={t('disposition.name')}
                                name={"paramKey"}
                                rules={[{required: true, message: `${t('public.input')} ${t('disposition.name')}`}]}
                            >
                                <Input
                                    allowClear
                                    name={'paramKey'}
                                    autoComplete="off"
                                    placeholder={`${t('public.input')} ${t('disposition.name')}`}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.List name="valueList" initialValue={[{}]}>
                        {(fields, {add, remove}) => (<>
                            {fields.map(({key, name, ...restField}) => (<Space style={{
                                display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '95%'
                            }} key={key}>
                                <div style={{display: 'flex', alignItems: 'center',}}>
                                    <p style={{margin: '0 10px 24px 38px'}}>{t('disposition.Key')}:</p>
                                    <Form.Item
                                        style={{width: "45vh"}}
                                        {...restField}
                                        name={[name, 'key']}
                                        rules={[{
                                            required: true, message: `${t('public.input')} ${t('disposition.Key')}`,
                                        },]}
                                    >

                                        <Input placeholder={`${t('public.input')} ${t('disposition.Key')}`}/>
                                    </Form.Item>
                                </div>
                                <div style={{display: 'flex', alignItems: 'center',}}>
                                    <p style={{margin: '0 10px 24px 0'}}>{t('disposition.Value')}:</p>
                                    <Form.Item
                                        style={{width: "45vh"}}
                                        {...restField}
                                        name={[name, 'value']}
                                        rules={[{
                                            required: true, message: `${t('public.input')} ${t('disposition.Value')}`,
                                        },]}
                                    >
                                        <Input placeholder={`${t('public.input')} ${t('disposition.Value')}`}/>
                                    </Form.Item>
                                </div>
                                {fields.length > 1 &&
                                    <MinusCircleOutlined style={{fontSize: "20px", margin: '0 0px 24px 0'}}
                                                         onClick={() => remove(name)}/>}
                            </Space>))}
                            <Form.Item>
                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined/>}>
                                    {t('disposition.add')}
                                </Button>
                            </Form.Item>
                        </>)}
                    </Form.List>
                    <Row style={{width: '95%'}} gutter={{md: 8, lg: 24, xl: 48}}>
                        <Col span={24}>
                            <Form.Item
                                label={t('public.remark')}
                                name="remark">
                                <Input.TextArea rows={4} autoComplete="off"/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{width: '95%'}} gutter={{md: 8, lg: 24, xl: 48}}>
                        <Col span={24}>
                            {store.record.id && (<Col span={24}>
                                <Form.Item
                                    label={t('disposition.status')}
                                    rules={[{
                                        required: true, message: `${t('public.Select')} ${t('disposition.status')}`
                                    }]}
                                    initialValue={store.record.status === 1}
                                    name={"status"}>
                                    <Radio.Group name={'status'} disabled={!store.formEdit}>
                                        <Radio value={1}>{t('public.effective')}</Radio>
                                        <Radio value={0}>{t('public.invain')}</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>)}
                        </Col>
                    </Row>
                </Form>
            </div>
        </Modal>)
})