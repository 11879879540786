import React from "react"

import { SearchOutlined } from '@ant-design/icons'

import { Button, Input, Select } from "antd"

import store from "./CompanyStore"
import ComTable from "./CompanyTable"
import ComForm from "./CompanyForm"

import { pulldown, t } from '@tool'

import { MyForm } from '@components'

export default function UserIndex() {

    const onFinish = (params) => {
        // 重置为第一页
        store.setPageNum(1)
        store.tableOptions = params
        store.fetchRecords()
    }

    return (<div>
        <MyForm onFinish={onFinish} initialValues={{ status: '1', companyType: '' }}>
            <MyForm.Item span={6} title={t('company.companyname')} name={"companyName"}>
                <Input allowClear name={'companyName'} autoComplete="off"
                    placeholder={`${t('public.input')}${t('company.companyname')}`} />
            </MyForm.Item>
            <MyForm.Item span={6} title={t('company.Type')} name={"companyType"}>
                <Select
                    name={'companyType'}
                    placeholder={`${t('public.Select')}${t('company.Type')}`}
                    value={'CUSTOMER'}>
                    {pulldown('companyTypeOptionsSon').map(item => {
                        return <Select.Option value={item.value} key={item.label}>{item.label}</Select.Option>
                    })}
                </Select>
            </MyForm.Item>
            <MyForm.Item span={6} title={t('company.status')} name={"status"}>
                <Select
                    name={'status'}
                    placeholder={`${t('public.Select')}${t('company.status')}`}>
                    {pulldown('statusOptionsSon').map(item => {
                        return <Select.Option value={item.value} key={item.label}>{item.label}</Select.Option>
                    })}
                </Select>
            </MyForm.Item>
            <MyForm.Item span={6}>
                <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>{t('public.inquire')}</Button>
            </MyForm.Item>
        </MyForm>
        <ComTable />
        {/* formVisible 控制表单显示 */}
        <ComForm />
    </div>)
}