import {post} from "@http"

//添加公司
export function addCompanyDetail(params) {
    return post("/manager/company_detail/add", params)
}

//修改公司
export function modifyCompanyDetail(params) {
    return post("/manager/company_detail/modify", params)
}

//获取游戏类型列表
export function getTypeList() {
    return post("/manager/company-game-type/getTypeList")
}