import React from 'react'
import { observer } from 'mobx-react'
import { Modal, Button, Input, Select, message } from 'antd'
import { TableCard } from '@components'
import store from './gameStore'
import tableStore from './tableStore'
import { gameGameName, pulldown, t, get } from '@tool'
import { SearchOutlined } from '@ant-design/icons'
import { MyForm } from '@components'

import { getHaveSetGameCompanyBrands, addGameOperator, getHaveGameCompanyBrandList } from '@api'


const rowSelection = {
    onChange: (selectedRowKeys) => {
        tableStore.setIds(selectedRowKeys)
    },
    getCheckboxProps: (record) => ({
        disabled: record.id === 'Disabled Index',
        name: record.id,
    }),
}
const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
@observer
class ComTable extends React.Component {
    state = {
        addVisible: false, // 控制下拉框显示的状态
        selectedOption: null, // 记录选择的选项
        gameCompanyBrandOptions: [],
        gameCompanyOptions: []
    }

    componentDidMount() {
        tableStore.fetchRecords()
    }

    columns = [
        { title: t('Carriergame.name'), width: '80px', key: gameGameName(), dataIndex: gameGameName() },
        { title: t('Carriergame.Affiliated'), width: '80px', key: 'companyName', dataIndex: 'companyName' },
        {
            title: t('Carriergame.type'),
            width: '60px',
            key: 'gameType',
            dataIndex: 'gameType',
            render: (text) => {
                const filtered = pulldown('gameTypeOptionsSon').filter(item => item.value === text)
                if (filtered.length > 0) {
                    return filtered[0].label
                } else {
                    return '-'
                }
            }
        },
        { title: t('Carriergame.ID'), width: '86px', key: 'gameKey', dataIndex: 'gameKey' },
        { title: t('Carriergame.Bonus'), width: '60px', key: 'gameRtp', dataIndex: 'gameRtp' }
    ]

    onSetGameCompany = async () => {
        try {
            const res = await getHaveSetGameCompanyBrands(tableStore.setID || get('companyId'));
            if (res && Array.isArray(res)) {
                // 将获取到的数据映射为下拉框的 options 格式
                const options = res.map((e) => ({
                    label: e.companyName,
                    value: e.id,
                }));
                const gameCompanyOptions = res.map((e) => ({
                    label: e.companyName,
                    value: e.brandName,
                }));
                this.setState({ gameCompanyBrandOptions: options });
                this.setState({ gameCompanyOptions: gameCompanyOptions });
            } else {
                message.error(t('操作失败'));
            }
        } catch (error) {
            message.error(t('操作失败'));
        }
    };

    onSetGameCompanyOptions = async () => {
        try {
            const res = await getHaveGameCompanyBrandList(tableStore.setID || get('companyId'));
            if (res && Array.isArray(res)) {
                // 将获取到的数据映射为下拉框的 options 格式
                const gameCompanyOptions = res.map((e) => ({
                    label: e.companyName,
                    value: e.brandName,
                }));
                this.setState({ gameCompanyOptions: gameCompanyOptions });
            } else {
                message.error(t('操作失败'));
            }
        } catch (error) {
            message.error(t('操作失败'));
        }
    };


    handleTableChange = ({ current, pageSize }, filters, sorter) => {
        tableStore.pageNum = current
        tableStore.pageSize = pageSize
        tableStore.tableOptions = {
            ...tableStore.tableOptions,
            sortField: sorter.field,
            sortOrder: sorter.order,
            ...filters,
        }
        tableStore.fetchRecords()
    }



    clicKclose = () => {
        tableStore.setPageNum(1)
        store.formVisible = false
    }

    onFinish = (params) => {
        tableStore.setPageNum(1)
        tableStore.tableOptions = params
        tableStore.fetchRecords()
    }

    onSelected = (e) => {
        tableStore.setID = e
    }

    handleAddClick = () => {
        this.setState({ addVisible: true }) // 显示下拉框
    }

    handleSelectChange = (value) => {
        this.setState({ selectedOption: value }) // 设置选中值
    }

    handleConfirmAdd = () => {
        const { selectedOption } = this.state
        if (!selectedOption) {
            message.warning(t('请选择一个选项'))
            return
        }
        // 一键添加
        let params = {
            'gameCompanyId': selectedOption,
            'ids': [],
            companyId: tableStore.setID
        }
        addGameOperator(params).then(res => {
            if (res.status === 10000) {
                message.success('添加成功')
                tableStore.fetchRecords()
                this.setState({ selectedOption: null })
                this.setState({ addVisible: false })
            } else {
                message.error(res.msg)
            }
        }).finally(() => this.setState({ addVisible: false }))
    }

    render() {
        const { addVisible, gameCompanyOptions, gameCompanyBrandOptions } = this.state

        return (
            <Modal
                width={'54%'}
                forceRender={true}
                open={store.formVisible}
                title={store.formTitle}
                footer={[]}
                onCancel={this.clicKclose}
                style={{
                    top: '3vh',
                }}
            >
                <MyForm onFinish={this.onFinish} initialValues={{ gameType: '', gameName: '' }}>
                    <MyForm.Item span={8} title={t('Carriergame.name')} name={"gameName"}>
                        <Input
                            allowClear
                            name={'gameName'}
                            autoComplete="off"
                            placeholder={`${t('public.input')} ${t('Carriergame.name')}`}
                        />
                    </MyForm.Item>
                    <MyForm.Item span={8} title={t('Carriergame.type')} name={"gameType"}>
                        <Select
                            name={'gameType'}
                            placeholder={`${t('public.Select')} ${t('Carriergame.type')}`}
                        >
                            {pulldown('gameTypeOptionsSon').map(item => (
                                <Select.Option value={item.value} key={item.value}>
                                    {item.label}
                                </Select.Option>
                            ))}
                        </Select>
                    </MyForm.Item>

                    <MyForm.Item span={8} title={t('Bet.platform')} name={"brandName"}>
                        <Select
                            onClick={() => { this.onSetGameCompanyOptions() }}
                            name={'brandName'}
                            style={{ width: '100%' }}
                            showSearch
                            placeholder={t('Carriergame.Please')}
                            options={gameCompanyOptions}
                            onChange={this.handleSelectChange}
                        />
                    </MyForm.Item>
                    <MyForm.Item span={6}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            icon={<SearchOutlined />}
                        >
                            {t('public.inquire')}
                        </Button>
                    </MyForm.Item>
                </MyForm>
                <TableCard
                    scroll={{ y: '40vh' }}
                    tableLayout={'table-layou'}
                    size={'small'}
                    rowKey="id"
                    onhide={true}
                    rowSelection={{
                        type: 'checkbox',
                        ...rowSelection,
                    }}
                    onChange={this.handleTableChange}
                    dataSource={tableStore.dataSource}
                    pagination={{
                        showSizeChanger: true,
                        showLessItems: true,
                        showTotal: total => t('public.intotal', total),
                        pageSizeOptions: ['10', '20', '50', '100'],
                        total: tableStore.total,
                        current: tableStore.pageNum,
                        pageSize: tableStore.pageSize,
                    }}
                    columns={this.columns}
                />
                <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'flex-end' }}>
                    <Button onClick={this.clicKclose}>{t('public.Cancel')}</Button>
                    <Button style={{ marginLeft: '10px' }} onClick={this.handleAddClick}>
                        {t('一键添加')}
                    </Button>
                    <Button onClick={tableStore.certainly} type="primary">
                        {t('public.OK')}
                    </Button>
                </div>

                <Modal
                    open={addVisible}
                    onCancel={() => this.setState({ addVisible: false })}
                    onOk={this.handleConfirmAdd}
                    title={t('选择游戏商')}
                >
                    <MyForm.Item span={12} title={t('Bet.platform')} name={"brandName"}>
                        <Select
                            onClick={() => { this.onSetGameCompany() }}
                            name={'platform'}
                            style={{ width: '100%' }}
                            showSearch
                            placeholder={t('请选择游戏商')}
                            filterOption={filterOption}
                            options={gameCompanyBrandOptions}
                            onChange={this.handleSelectChange}
                        />
                    </MyForm.Item>

                </Modal>
            </Modal>
        )
    }
}

export default ComTable
