import {post} from "@http"

//添加包详情
export function addGamePacketDetail(params) {
    return post("/manager/game-packet-detail/add", params)
}

//修改状态
export function batchStatusGamePacketDetail(params) {
    return post("/manager/game-packet-detail/batchStatus", params)
}

//获取游戏包详情列表信息
export function getGamePacketDetailList(params) {
    return post("/manager/game-packet-detail/getList", params)
}

//获取游戏列表信息
export function getGamePacketDetailGames(params) {
    return post("/manager/game-packet-detail/getGames", params)
}