import React, { useState, useEffect } from "react";
import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Select } from "antd";

import store from "./CurrencyStore";
import ComTable from "./CurrencyTable";
import ComForm from "./CurrencyForm";
import { pulldown, t } from '@tool';
import { MyForm } from '@components';

export default function UserIndex() {
    const [newOptions, setNewOptions] = useState([]);
    
    const onFinish = (params) => {
        // 重置为第一页
        store.setPageNum(1);
        store.tableOptions = params;
        store.fetchRecords();
    };

    const fetchNewOptions = async () => {
        store.getAllType();
        console.log(store.types);
        setNewOptions(store.types);
    };

    return (
        <div>
            <MyForm onFinish={onFinish} initialValues={{ status: '1'}}>
                <MyForm.Item span={6} title={t('Currency.currency')} name="currency">
                    <Input
                        allowClear
                        name="currency"
                        autoComplete="off"
                        placeholder={`${t('public.input')}${t('Currency.currency')}`}
                    />
                </MyForm.Item>
                <MyForm.Item span={6} title={t('Currency.address')} name="address">
                    <Input
                        allowClear
                        name="address"
                        autoComplete="off"
                        placeholder={`${t('public.input')}${t('Currency.address')}`}
                    />
                </MyForm.Item>
                <MyForm.Item span={6} title={t('company.status')} name="status">
                    <Select
                        name="status"
                        placeholder={`${t('public.Select')}${t('company.status')}`}
                    >
                        {pulldown('statusOptionsSon').map(item => (
                            <Select.Option value={item.value} key={item.label}>
                                {item.label}
                            </Select.Option>
                        ))}
                    </Select>
                </MyForm.Item>
                <MyForm.Item span={6}>
                    <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>
                        {t('public.inquire')}
                    </Button>
                </MyForm.Item>
            </MyForm>
            <ComTable />
            <ComForm />
        </div>
    );
}
