import React from 'react'
import {observer} from 'mobx-react'
import {TableCard} from '@components'
import store from "./gameCompanyStore"
import {t} from '@tool'

@observer
class ComTable extends React.Component {
    componentDidMount() {
        store.tableOptions = {
            ...store.tableOptions,
        }
        store.fetchRecords()
    }

    options = JSON.parse(localStorage.getItem('operatorList'))
    columns = [
        {
            title: t('Bet.date'),
            width: '10px',
            key: 'date',
            dataIndex: 'date',
            ellipsis: true
        },
        {
            title: t('Bet.game'),
            width: '8px',
            key: 'game',
            dataIndex: 'game',
            ellipsis: true,
        },
        {
            title: t('Bet.transactionId'),
            width: '10px',
            key: 'transactionId',
            dataIndex: 'transactionId',
            ellipsis: true,
            render: (text) => {
                return (
                    <div className="ellipsis" style={{
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                    }} title={t('public.Clickcopy')} onClick={store.copy}>
                        {text}
                    </div>)
            }
        },
        {
            title: t('Bet.betAmount'),
            width: '6px',
            key: 'betAmount',
            dataIndex: 'betAmount',
            ellipsis: true,
        },
        {
            title: t('Bet.playerName'),
            width: '8px',
            key: 'playerName',
            dataIndex: 'playerName',
            ellipsis: true,
        },
        {
            title: t('Bet.platform'),
            width: '8px',
            key: 'platform',
            dataIndex: 'platform',
            ellipsis: true,
        },
        {
            title: t('Bet.operator'),
            width: '6px',
            key: 'companyName',
            dataIndex: 'companyName',
            ellipsis: true,
        },
        {
            title: t('Bet.Currency'),
            width: '5px',
            key: 'currency',
            dataIndex: 'currency', ellipsis: true,
        },

    ]

    handleTableChange = ({current, pageSize}, filters, sorter) => {
        store.pageNum = current
        store.pageSize = pageSize
        store.tableOptions = {
            ...store.tableOptions, // 排序：好像只支持单个排序
            sortField: sorter.field, sortOrder: sorter.order, ...filters
        }
        store.fetchRecords()
    }

    render() {
        return (
            <div>
                <TableCard
                    tableLayout={'fixed'}
                    size={'small'}
                    scroll={{
                        x: 600,
                        y: '38vh'
                    }}
                    rowKey="id"
                    title={t('Bet.summaryst')}
                    loading={store.isFetching}
                    // 后端的数据源
                    dataSource={store.dataSource}
                    onReload={store.fetchRecords}
                    onChange={this.handleTableChange}
                    actions={[]}
                    // 分页器
                    pagination={{
                        showSizeChanger: true,
                        showLessItems: true,
                        showTotal: total => t('public.intotal', total),
                        pageSizeOptions: ['10', '20', '50', '100'], // 如果不传 total，则以后端返回数据条数作为 total 的值
                        total: store.total, // 如果不传，则默认是第一条，如果需要默认显示第3条，则必须传
                        current: store.pageNum,
                        pageSize: store.pageSize,
                    }}
                    columns={this.columns}
                />


            </div>)
    }
}

export default ComTable
