import { observable, computed, action } from 'mobx'
import { message } from "antd"

import copy from 'copy-to-clipboard';

import { getCompanyList, batchStatusCompany, getTypeList, apiSyncGameInfo, getCompanyById, updateGameEncryptByCompanyId } from '@api'
import { log, t, get } from '@tool'
import { post } from '@http'

class GameStore {
    @observable formVisible = false

    @observable formEdit = false

    @observable formTitle = t('Gamebusiness.View')

    @observable record = {}

    @observable records = []

    // 默认第1页
    @observable pageNum = 1
    // 每页多少条
    @observable pageSize = 10
    // 总共多少页
    @observable total = ''
    //二次确认删除
    @observable conDelete = false

    @observable ids = ''

    @observable typeList = []

    @observable currencyList = []

    // 其他参数，例如排序、过滤等等
    @observable tableOptions = {}

    @observable isFetching = false

    @computed get dataSource() {
        return this.records
    }

    _getTableParams = () => ({ pageNum: this.pageNum, pageSize: this.pageSize, ...this.tableOptions })

    @action setPageNum(val) {
        this.pageNum = val
    }

    @action setPageSize(val) {
        this.pageSize = val
    }

    @action setIds(ids) {
        this.ids = ids
    }

    fetchCardList = () => {
        this.isFetching = true
        getTypeList().then(res => {
            this.typeList = res?.map((item) => {
                return {
                    value: item.id, label: item.type,
                }
            })
        }).finally(() => this.isFetching = false);
    }

    fetchRecords = () => {
        const params = this._getTableParams()

        this.isFetching = true

        getCompanyList(params).then(res => {
            this.total = res.totalCount
            this.records = res.list
        }).finally(() => this.isFetching = false)
    }

    onDelete = (record) => {
        let id = []
        if (record !== -1) {
            id.push(record.id)
        } else {
            id = this.ids
        }
        this.isFetching = true
        let params = { 'ids': id, 'status': 0 }

        log(params)

        batchStatusCompany(params).then(res => {
            if (res.status === 10000) {
                this.setPageNum(1)
                this.setPageSize(10);
                this.fetchRecords()
                this.conDelete = false
            }
        }).finally(() => this.isFetching = false)
    }

    // 显示新增弹框
    showForm = (info = {}, formEdit) => {
        this.formVisible = true
        this.formEdit = formEdit;
        this.record = info;
        if (formEdit) {
            if (info.id) {
                this.getDetail(info.id);
            }
            this.formTitle = info.id ? t('Gamebusiness.Edit') : t('Gamebusiness.New');

        } else {
            this.formTitle = t('Gamebusiness.View')
            this.getDetail(info.id);
        }

    }

    handleClickRow = (record) => {
        return {
            onDoubleClick: () => {
                this.showForm(record, false)
            }
        }
    }

    //二次确认删除
    confirmDelete = (data) => {
        if (this.ids.length === 0) {
            message.error(t('public.selectDelete'))
            return
        }
        this.conDelete = data
    }

    copy = (e) => {
        copy(e.target.innerText)
        message.success(t('public.copy'))
    }

    syncGameInfo = (row) => {
        this.isFetching = true

        let values = "companyId=" + row.id + "&userId=" + get('id')

        post(apiSyncGameInfo, values).then(res => {
            if (res.status === 10000) {
                message.success('同步成功')

                this.setPageNum(1)
                this.setPageSize(10);
                this.fetchRecords()
            }
        }).finally(() => this.isFetching = false)
    }

    generationGameEncrypt = (companyId) => {
        updateGameEncryptByCompanyId(companyId).then(res => {
            if(res){
                message.success(res)
                this.setPageNum(1)
                this.setPageSize(10);
                this.fetchRecords()
            } else{
                message.error(res.msg)
            }
        }).finally(() => this.isFetching = false)
    }

    getDetail = (id) => {
        getCompanyById(id).then((res) => {
            this.record = res
        }).catch((err) => {
            message.error(t('public.error'))
        });
    }
}

export default new GameStore()