import React from 'react'

import { observer } from 'mobx-react'
import {message, Popconfirm, Space, Switch} from 'antd'
import { DeleteOutlined, EditOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { TableCard, AuthButton, } from '@components'
import store from './DetailStore'
import Storee from './DetailTypeStore';
import { t } from '@tool';
import {updateCompanyGameDetail} from '@api/CompanyGameDetail'

const rowSelection = {
    onChange: (selectedRowKeys) => {
        store.setIds(selectedRowKeys)
    }, getCheckboxProps: (record) => ({
        disabled: record.id === 'Disabled Index', // Column configuration not to be checked
        name: record.id,
    }),
}

const handleChange = (record,checked) => {
    const posem = JSON.parse(JSON.stringify(record))
    if (checked == 1){
        posem.status = 1;
    }else if (checked == 0){
        posem.status = 0;
    }
    updateCompanyGameDetail(posem).then(res => {
        if (res > 0) {
            store.fetchRecords()
            message.success(t('Partner.Success'))
        } else {
            message.error(t(res.msg))
        }
    }).finally()
};

@observer
class ComTable extends React.Component {

    componentDidMount() {
        store.tableOptions = {
            ...store.tableOptions, companyType: 'GAME_COMPANY', status: ''
        }
        store.fetchRecords()
    }
    columns = [
        { title: 'ID', fixed: 'left', width: '40px', key: 'id', dataIndex: 'id', ellipsis: true, },
        {
            title: t('GameCompanyDetail.type'),
            width: '80px',
            key: 'type',
            dataIndex: 'type',
            ellipsis: true,

        },
        {
            title: t('GameCompanyDetail.typeKey'),
            width: '80px',
            key: 'typeKey',
            dataIndex: 'typeKey',
            ellipsis: true,

        },
        {
            title: t('GameCompanyDetail.status'),
            width: '80px',
            key: 'status',
            dataIndex: 'status',
            ellipsis: true,
            render: (_,record) => {
                return (
                    <Switch checked={record.status === 1 ? true:false}  onChange={(checked) => handleChange(record,checked)}></Switch >
                )
            }
        },
        {
            title: t('public.Controls'), fixed: 'right', width: '200px', key: 'id', render: (_, record) => {
                return (<Space size="middle">
                    <AuthButton
                        type={"primary"}
                        ghost
                        icon={<EditOutlined />}
                        onClick={() => store.showForm(record, true)}
                        auth="game.company.edit">
                        {t('public.edit')}
                    </AuthButton>

                    <AuthButton
                        type={"primary"}
                        ghost
                        icon={<EditOutlined />}
                        onClick={() => Storee.showForm(record, true)}
                        auth="">
                        查看游戏商
                    </AuthButton>
                </Space>)
            },
        },]

    handleTableChange = ({ current, pageSize }, filters, sorter) => {
        store.pageNum = current
        store.pageSize = pageSize
        store.tableOptions = {
            ...store.tableOptions, // 排序：好像只支持单个排序
            sortField: sorter.field, sortOrder: sorter.order, ...filters
        }
        store.fetchRecords()
    }

    render() {
        return (<TableCard
            tableLayout={'fixed'}
            size={'small'}
            // scroll={{
            //     x: 1500,
            //     y: '44vh'
            // }}
            handleClickRow={store.handleClickRow}
            rowKey="id"
            rowSelection={{
                type: 'checkbox', ...rowSelection,
            }}
            title={t('Gamebusiness.vendorsList')}
            loading={store.isFetching}
            // 后端的数据源
            dataSource={store.dataSource}
            onReload={store.fetchRecords}
            onChange={this.handleTableChange}
            actions={[
                <Popconfirm
                    title={`${t('public.Are')}${store.ids.length}?`}
                    open={store.conDelete}
                    onConfirm={() => store.onDelete(-1)}
                    onCancel={() => { store.confirmDelete(false) }}
                    okText={t('public.OK')}
                    cancelText={t('public.Cancel')}
                >
                    <AuthButton
                        type="primary"
                        icon={<MinusCircleOutlined />}
                        auth="game.company.delete"
                        onClick={() => store.confirmDelete(true)}>
                        {t('public.Batchdeletion')}
                    </AuthButton>
                </Popconfirm>
                ,
                <AuthButton
                    type="primary"
                    icon={<PlusOutlined />}
                    auth="game.company.add"
                    onClick={() => store.showForm({}, true)}>
                    {t('public.new')}
                </AuthButton>
            ]}
            // 分页器
            pagination={{
                showSizeChanger: true,
                showLessItems: true,
                showTotal: total => t('public.intotal', total),
                pageSizeOptions: ['10', '20', '50', '100'], // 如果不传 total，则以后端返回数据条数作为 total 的值
                total: store.total, // 如果不传，则默认是第一条，如果需要默认显示第3条，则必须传
                current: store.pageNum,
                pageSize: store.pageSize,
            }}
            columns={this.columns} />)
    }
}

export default ComTable
