import React, { useEffect, useState } from 'react'

import { observer } from 'mobx-react'
import { Modal, Form, Input, message, Row, Col, Radio, Select } from 'antd'
import { CompanyList, t, pulldown } from '@tool'

import store from './PlatfromStore'

import { addWhiteIp, updateWhiteIp, getCompanyList } from '@api'


export default observer(function () {
    // 文档中未找到这种解构使用方法
    const [form] = Form.useForm()
    // useState 函数组件中使用 state
    // loading 默认是 flase
    const [loading, setLoading] = useState(false)
    const [join, setJoin] = useState(false)
    const [companyList, setCompanyList] = useState([]) //模糊企业搜索下拉
    const [company, setCompany] = useState('')

    useEffect(() => {
        form.resetFields();
        getList();
        if (store.record.id) {
            form.setFieldsValue(store.record)
            if(store.record.isJoin === 1){
                setJoin(true)
            } else{
                setJoin(false)
            }
        }
    }, [store.record])

    const handleSubmit = () => {
        form.submit()
    }

    const onFinish = (values) => {
        setLoading(true)
        values['companyType'] = 'PLATFROM'
        if (store.record.id) {
            values['id'] = store.record.id

            updateWhiteIp(values).then(res => {
                if (res > 0) {
                    store.formVisible = false
                    store.fetchRecords()
                    //运营商
                    CompanyList()
                } else {
                    message.error(res.msg)
                }
            }).finally(() => setLoading(false))
        } else {
            addWhiteIp(values).then(res => {
                if (res > 0) {
                    store.formVisible = false
                    store.fetchRecords()
                    //运营商
                    CompanyList()
                    setJoin(false)
                } else {
                    message.error(res.msg)
                }
            }).finally(() => setLoading(false))
        }
    }

    const onJoin = (e) => {
        if (e.target.value === 1) {
            setJoin(true);
        } else {
            setJoin(false)
        }
    }

    const onCompany = (val) => {
        setCompany(val)
    }

    // 点击查询，通过公司名获取公司信息
    const getList = () => {
        getCompanyList({
            status: 1,
            pageNum: 1,
            pageSize: 999999,
            companyType: 'OPERATOR_COMPANY'
        }).then(res => {
            setCompanyList(res.list.map((item) => {
                return {
                    label: item.companyName,
                    value: item.companyId
                }
            }))
        })
    }

    return (// Modal 对话框
        <Modal
            width={'54%'}
            forceRender={true}
            open={store.formVisible}
            title={store.formTitle}
            onCancel={() => store.formVisible = false}
            confirmLoading={loading}
            // ok 按钮 props
            onOk={handleSubmit}>
            <Form form={form} onFinish={onFinish}>
                <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
                    <Col span={12}>
                        <Form.Item label={t('WhiteIp.ip')}
                            rules={[{ required: true, message: `${t('public.input')}${t('WhiteIp.ip')}` }]}
                            name={"ip"}>
                            <Input disabled={!store.formEdit} autoComplete="off" allowClear
                                placeholder={`${t('public.input')}${t('WhiteIp.ip')}`} />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item label={t('WhiteIp.ipVersion')}
                            rules={[{ required: true, message: `${t('public.Select')}${t('WhiteIp.ipVersion')}` }]}
                            name={"ipVersion"}>
                            <Select disabled={!store.formEdit}
                                style={{ width: "100%" }}
                                allowClear
                                placeholder={`${t('public.Select')}${t('WhiteIp.ipVersion')}`}
                                value={'ipVersion'}>
                                {pulldown('ipVersion').map(item => {
                                    if (item.value != '') {
                                        return <Select.Option value={item.value}
                                            key={item.label}>{item.label}</Select.Option>
                                    }
                                })}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item label={t('WhiteIp.ipType')}
                            rules={[{ required: true, message: `${t('public.Select')}${t('WhiteIp.ipType')}` }]}
                            name={"ipType"}>
                            <Select disabled={!store.formEdit}
                                style={{ width: "100%" }}
                                allowClear
                                placeholder={`${t('public.Select')}${t('WhiteIp.ipType')}`}
                                value={'ipType'}>
                                {pulldown('ipType').map(item => {
                                    if (item.value != '') {
                                        return <Select.Option value={Number(item.value)}
                                            key={item.label}>{item.label}</Select.Option>
                                    }
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>)
})