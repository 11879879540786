import {observable, computed, action} from 'mobx'
import {getLogInfoList} from '@api'

import {log,t} from '@tool'

class GameStore {
    @observable formVisible = false

    @observable formTitle = t('Accesslog.View')

    @observable record = {}

    @observable records = []

    // 默认第1页
    @observable pageNum = 1
    // 每页多少条
    @observable pageSize = 10
    // 总共多少页
    @observable total = ''

    @observable ids = ''

    // 其他参数，例如排序、过滤等等
    @observable tableOptions = {}

    @observable isFetching = false

    @computed get dataSource() {
        return this.records
    }

    _getTableParams = () => ({pageNum: this.pageNum,pageSize: this.pageSize, ...this.tableOptions})

    @action setPageNum(val) {
        this.pageNum = val
    }
    @action setPageSize(val) {
        this.pageSize = val
    }

    @action setIds(ids) {
        this.ids = ids
    }

    fetchRecords = () => {
        const params = this._getTableParams()

        this.isFetching = true

        getLogInfoList(params).then(res => {
            this.total = res.totalCount
            this.records = res.list
        }).finally(() => this.isFetching = false)
    }

    handleClickRow = (record) => {
        return {
            onDoubleClick: () => {
                this.showForm(record)
            }
        }
    }

    // 显示新增弹框
    showForm = (info = {}) => {
        this.formVisible = true

        this.record = info
    }

}

export default new GameStore()