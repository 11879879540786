import React from "react"

import { SearchOutlined,LeftOutlined } from '@ant-design/icons'
import { Button, Input, Select, Form } from "antd"
import store from "./playerStore"
import PlayerTable from "./playerTable"

import { t, get, pulldown } from '@tool'
import { MyForm } from '@components'
import { history } from '@http'

export default function UserIndex() {
    const [form] = Form.useForm()
    const onFinish = (params) => {
        // 重置为第一页
        store.setPageNum(1)
        store.tableOptions = params
        store.fetchRecords()
    }
    const options = JSON.parse(localStorage.getItem('operatorList'))
    const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
    return (<div>

        {history.location.state?.params && <div style={{ background: '#fff' }}>
            <Button
                onClick={() => history.goBack()}
                style={{ margin: '10px' }}
                type={"primary"}
                icon={<LeftOutlined />}>
                {t('public.Back')}
            </Button>
        </div>}

        <MyForm form={form} labelCol={{ span: 7 }} onFinish={onFinish} initialValues={{ status: '1', sample: 0 }}>
            <MyForm.Item span={6} title={t('Player.nickname')} name={"nickName"}>
                <Input allowClear name={"nickName"} autoComplete="off"
                    placeholder={t('public.input')} />
            </MyForm.Item>

            <MyForm.Item span={6} title={t('Player.Account')} name={"playerName"}>
                <Input allowClear name={'playerName'} autoComplete="off"
                    placeholder={t('public.input')} />
            </MyForm.Item>
            <MyForm.Item span={6} title={t('Player.sample')} name={"sample"}>
                <Select
                    name={'sample'}
                    value={''}>
                    {pulldown('sampleType').map(item => {
                        return <Select.Option value={item.value} key={item.label}>{item.label}</Select.Option>;
                    })}
                </Select>
            </MyForm.Item>
            {get('userType') !== 'PLATFORM' &&
                <MyForm.Item span={6} title={t('Player.Owning')} name={"companyId"}>
                    <Select
                        name={'companyId'}
                        style={{ width: '100%' }}
                        showSearch
                        allowClear
                        placeholder={t('Carriergame.Please')}
                        filterOption={filterOption}
                        options={options}
                    >
                    </Select>
                </MyForm.Item>
            }
            <MyForm.Item span={6} title={t('Player.status')} name={"status"}>
                <Select
                    name={'status'}
                    value={''}>
                    {pulldown('statusOptionsSon').map(item => {
                        return <Select.Option value={item.value} key={item.label}>{item.label}</Select.Option>;
                    })}
                </Select>
            </MyForm.Item>
            <MyForm.Item span={6}>
                <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>{t('public.inquire')}</Button>
            </MyForm.Item>
        </MyForm>

        <PlayerTable />

    </div>)
}