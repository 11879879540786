import React, { useEffect, useRef, useState } from "react"
import * as echarts from 'echarts'
import { t, get } from '@tool'

export default function UserIndex(PlayerBetweenWeek) {
    const domRef = useRef();
    const [Date, setDate] = useState([]);
    const [newPlay, setnewPlay] = useState([]);
    const [oldPlay, setoldPlay] = useState([]);
    useEffect(() => {
        if (PlayerBetweenWeek.PlayerBetweenWeek.legend !== 0) {
            const result = PlayerBetweenWeek.PlayerBetweenWeek.map((item) => item.dateTime);
            const result1 = PlayerBetweenWeek.PlayerBetweenWeek.map((item) => item.newPlayerCount);
            const result2 = PlayerBetweenWeek.PlayerBetweenWeek.map((item) => item.playerCount);
            setDate(result);
            setnewPlay(result1);
            setoldPlay(result2);
        }
    }, [PlayerBetweenWeek.PlayerBetweenWeek]);

    useEffect(() => {
        chartInit();
    }, [Date, newPlay, oldPlay]);


    // 页面变化重新渲染
    const onResize = (chart) => {
        window.addEventListener("resize", () => {
            chart.resize();
        });
        const element = document.querySelector("#card");
        const resizeObserver = new ResizeObserver(() => {
            chart.resize();
        });
        // 开始观察元素
        resizeObserver.observe(element);
    }




    // 图表配置
    const option = {
        backgroundColor: "#fff",
        title: {
            text: "近七日活跃用户",
            left: '5%',
            top: '5%'
        },
        tooltip: {
            trigger: "axis",
            formatter: function (val) {

                return `   <div>
                
                <div >${val[0].axisValueLabel}</div>
       
                <div>
                    ${val[0].marker}
                      <span>${val[0].seriesName}</span>
                    ${val[0].value}
                </div>
                 <div>
                    ${val[1].marker}
                      <span>${val[1].seriesName}</span>
                    ${val[1].value}
                </div>
   
            </div>`
            }
        },
        grid: {
            left: '2%',
            right: '3%%',
            bottom: '0',
            top: '20%',
            containLabel: true
        },
        legend: {
            data: ['当日总活跃人数', '当日新增活跃人数'],
            orient: 'horizontal',
            right: '5%',
            top: '5%',
        },



        xAxis: [{
            type: "category",
            splitLine: {
                show: false
            },
            axisTick: {
                show: true
            },
            splitArea: {
                show: false
            },
            axisLabel: {
                margin: 25,
                interval: 0,
                rotate: -30,
                align: 'center',
                verticalAlign: 'top'
            },
            data: Date,
        }],
        yAxis: [{
            type: "value",
            splitLine: {
                show: true
            },

            axisTick: {
                show: false
            },
            axisLabel: {
                interval: 0,

            },
            splitArea: {
                show: false
            },

        }],

        series: [
            {
                name: "当日新增活跃人数",
                type: "bar",
                stack: "总量1",
                barMaxWidth: 35,
                itemStyle: {
                    normal: {
                        color: "#0E8FF4",
                        label: {
                            show: true,
                            position: "inside",
                            formatter: function (p) {
                                return p.value > 0 ? (p.value) : '';
                            }
                        }
                    }
                },
                data: newPlay,
            },
            {
                name: "当日总活跃人数",
                type: "bar",
                stack: "总量",
                barMaxWidth: 35,
                barGap: "10%",
                itemStyle: {
                    normal: {

                        color: "#9B32FF",
                        label: {
                            show: true,
                            textStyle: {
                                color: "#fff"
                            },
                            position: "inside",
                            formatter: function (p) {
                                return p.value > 0 ? (p.value) : '';
                            }
                        }
                    }
                },
                data: oldPlay,
            },


        ]
    }

    //渲染图表
    const chartInit = () => {
        const myChart = echarts.init(domRef.current)
        myChart.setOption(option)
        onResize(myChart);
    }


    return (
        <>
            <div id="card" ref={domRef} style={{ width: '100%', height: '100%' }}></div>
        </>
    )
}