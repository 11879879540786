import React, { useState } from 'react';

import { Button, Upload, message } from 'antd';
import AWS from 'aws-sdk';

import { t, host } from '@tool'
import { UploadOutlined } from "@ant-design/icons";

export default function UploadBtn(props) {
    const [loading, setLoading] = useState(false)
    const { uploadType, suffixType, formEdit, setLogoUrl, requiredWidth, requiredHeight } = props;

    let s3;
    if (host === 'https://admin.hotgame.bet/') {
        s3 = new AWS.S3({
            region: 'sa-east-1',
            accessKeyId: 'AKIAYMOUGR7UDYSNFSEO', // 设置你的Access Key ID
            secretAccessKey: 'mCzAKL3Qh+mQHAOUtQMB6Yi6ZNP1gmri0cavtfdZ' // 设置你的Secret Access Key
        });
    } else {
        s3 = new AWS.S3({
            region: 'ap-south-1',
            accessKeyId: 'AKIAUL7Y4CB5SIOC5E64', // 设置你的Access Key ID
            secretAccessKey: 'hqwYcEEd/Y5AHkwiX7hhjGYkEutojKj7AJO6EYK8' // 设置你的Secret Access Key
        });
    }


    const beforeUpload = (file) => {

        if (suffixType === 'img') {
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
            if (!isJpgOrPng) {
                message.error('只能上传JPG/PNG格式文件!');
                return false;
            }
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
                message.error('图片大小不能超过2MB!');
                return false;
            }
            uploadFile(file).then((res) => {
                setLogoUrl(res)
                message.success("上传成功")
            }).catch((err) => {
            })
        } else if (suffixType === 'excel') {
            const isJpgOrPng = file.type === 'xlsx' || file.type === 'xls';
            if (!isJpgOrPng) {
                message.error('只能上传excel文件!');
                return false;
            }
            uploadFile(file).then((res) => {
                setLogoUrl(res)
                message.success("上传成功")
            }).catch((err) => {
            })
        } else if (suffixType === 'game-icon-upload') {
            uploadFile(file).then((res) => {
                setLogoUrl(res)
                message.success("上传成功")
            }).catch((err) => {
            })
        } else if (suffixType === 'max') {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = (event) => {
                    const image = new Image();
                    image.onload = () => {
                        // 获取图片的实际宽度和高度
                        const width = image.width;
                        const height = image.height;
                        // 检查图片尺寸是否符合限制
                        if (width > requiredWidth || height !== requiredHeight) {
                            const errorMessage = `图片尺寸不符合要求，宽度必须小于等于${requiredWidth}px, 高度必须为${requiredHeight}px`;
                            message.error(errorMessage);
                            reject(errorMessage);
                        } else {
                            resolve(file);
                            uploadFile(file).then((res) => {
                                setLogoUrl(res);
                                message.success("上传成功");
                            }).catch((err) => {
                                // 处理上传失败情况
                            });
                        }
                    };
                    image.src = event.target.result;
                };
                reader.readAsDataURL(file);
            });
        }
        return false;
    }

    const uploadFile = (file) => {
        const returnUrl = host.split('//')[1]
        const uploadHost = returnUrl === 'admin.hotgame.bet/' ? 'www.hotgame.bet/' : 'www.hotgameclub.com/';
        setLoading(true)
        const uploadurl = `${uploadHost}upload/${uploadType}`;
        let baseS3UploadUrl
        if (suffixType === "game-icon-upload") {
            baseS3UploadUrl = uploadHost + `template/`;
        } else {
            baseS3UploadUrl = uploadHost + `upload/`;
        }
        const s3UploadUrl = `${baseS3UploadUrl}${uploadType.split('-')[0].toLowerCase()}/${uploadType.split('-')[2]}`;
        const params = {
            Bucket: suffixType === "game-icon-upload" ? `${baseS3UploadUrl}${uploadType.split('-')[0].toLowerCase()}` : uploadurl,  // 设置目标存储桶的名称
            Body: file,
            Key: suffixType === "game-icon-upload" ? `${uploadType.split('-')[2]}.${file.name.split('.')[1]}` : file.name,
        };
        return new Promise((resolve, reject) => {
            s3.putObject(params, (err, data) => {
                setLoading(false)
                if (err) {
                    message.error('上传失败')
                    console.error('上传失败:', err)
                    reject(err);
                } else {
                    let val;
                    if (suffixType === "game-icon-upload") {
                        if(returnUrl === 'admin.hotgame.bet/'){
                            val = `https://${s3UploadUrl}.${file.name.split('.')[1]}`;
                        } else {
                            val = `https://s3.ap-south-1.amazonaws.com/${s3UploadUrl}.${file.name.split('.')[1]}`;
                        }
                    } else {
                        if (returnUrl === 'admin.hotgame.bet/') {
                            val = `https://${uploadurl}/${file.name}`;
                        } else {
                            val = `https://s3.ap-south-1.amazonaws.com/${uploadurl}/${file.name}`;
                        }
                    }
                    console.log(val);
                    resolve(val);
                }
            });
        });
    }

    return (<Upload disabled={!formEdit} showUploadList={false}
        beforeUpload={(file) => beforeUpload(file)}>
        <Button disabled={loading} loading={loading} type="primary" icon={<UploadOutlined />}>
            {t('public.selectfile')}
        </Button>
    </Upload>)
}
