import React from 'react'
import { Layout } from 'antd'
import { CopyrightOutlined } from '@ant-design/icons'
import styles from './layout.module.less'
import { t } from "@tool"

export default function () {
    return (<Layout.Footer style={{ padding: 0 }}>
        <div className={styles.footer}>
            <div className={styles.links}>
                <a className={styles.item} title={t('footer.join')} href="https://www.ct-game-club.com" target="_blank"
                    rel="noopener noreferrer">{t('footer.join')}</a>
                <a title={t('footer.joins')} href="src/layout/Footer#" target="_blank"
                    rel="noopener noreferrer">{t('footer.joins')}</a>
            </div>
            <div style={{ color: 'rgba(0, 0, 0, .45)' }}>
                Copyright <CopyrightOutlined /> {new Date().getFullYear()} By {t('footer.detail')}
            </div>
        </div>
    </Layout.Footer>)
}
