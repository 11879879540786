



import React from 'react'

import { observer } from 'mobx-react'
import { Modal, Button, Row, Col } from 'antd'
import store from './callbackStore'
import { t } from '@tool'

export default observer(function () {
    const FooterButton = ({ onClick }) => (
        <Button type="primary" onClick={onClick}>
            {t('public.Cancel')}
        </Button>
    );
    return (// Modal 对话框
        <Modal
            width={'60%'}
            forceRender={true}
            open={store.formVisible}
            title={store.formTitle}
            closable={false}
            footer={<FooterButton onClick={() => store.formVisible = false} />}
        >
            <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
                <Col style={{
                    background: "#f8f8f8", borderRight: '1px solid #f0f0f0',
                    borderBottom: '1px solid #f0f0f0', fontWeight: 'bold', padding: '10px 0', textAlign: 'center'
                }} span={2}>
                    {t('Callback.name')}
                </Col>
                <Col style={{
                    borderBottom: '1px solid #f0f0f0',
                    background: "#f8f8f8", padding: '10px 10px'
                }} span={22}>
                    {store.record.name}
                </Col>
            </Row>
            <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
                <Col style={{
                    background: "#f8f8f8", borderRight: '1px solid #f0f0f0',
                    borderBottom: '1px solid #f0f0f0', fontWeight: 'bold', padding: '10px 0', textAlign: 'center'
                }} span={2}>
                    IP
                </Col>
                <Col style={{
                    borderBottom: '1px solid #f0f0f0',
                    background: "#f8f8f8", padding: '10px 10px'
                }} span={6}>
                    {store.record.ip}
                </Col>
                <Col style={{
                    background: "#f8f8f8", borderRight: '1px solid #f0f0f0',
                    borderBottom: '1px solid #f0f0f0', fontWeight: 'bold', padding: '10px 0', textAlign: 'center'
                }} span={2}>
                    {t('Callback.type')}
                </Col>
                <Col style={{
                    borderBottom: '1px solid #f0f0f0',
                    background: "#f8f8f8", padding: '10px 10px'
                }} span={6}>
                    {store.record.type}
                </Col>
                <Col style={{
                    background: "#f8f8f8", borderRight: '1px solid #f0f0f0',
                    borderBottom: '1px solid #f0f0f0', fontWeight: 'bold', padding: '10px 0', textAlign: 'center'
                }} span={2}>
                    {t('Callback.time')}
                </Col>
                <Col style={{
                    borderBottom: '1px solid #f0f0f0',
                    background: "#f8f8f8", padding: '10px 10px'
                }} span={6}>
                    {store.record.addTime}
                </Col>
            </Row>

            <Row style={{ marginTop: '20px' }} gutter={{ md: 8, lg: 24, xl: 48 }}>
                <Col span={24}>
                    <label style={{ fontSize: '16px',fontWeight: 'bold', }}>{t('Callback.content')}</label>
                    <div style={{
                        marginTop: '8px',
                        background: "#eee", fontSize: '15px', borderRadius: '10px',
                        minHeight: '150px', padding: '10px'
                    }}>
                        {store.record.content}
                    </div>
                </Col>
            </Row>
        </Modal>

    )
})