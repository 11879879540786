import store from './gameStore'
import { observable, computed, action } from 'mobx'
import { message } from "antd"
import { t, get } from '@tool'
import { getGameOperatorGames, addGameOperator } from '@api'

class GameStore {
    @observable formVisible = false

    @observable formEdit = false

    @observable record = {}

    @observable records = []

    // 默认第1页
    @observable pageNum = 1

    // 总共多少页
    @observable total = ''
    // 每页多少条
    @observable pageSize = 10
    @observable ids = ''

    //运营商选中的id
    @observable setID = ''

    //选择运营商弹窗
    @observable storeopen = false

    // 其他参数，例如排序、过滤等等
    @observable tableOptions = {}

    @observable isFetching = false

    @computed get dataSource() {
        return this.records
    }

    _getTableParams = () => ({
        pageNum: this.pageNum, pageSize: this.pageSize, ...this.tableOptions, status: 1,
        companyId: get('userType') === 'PLATFORM' ? get('companyId') : this.setID
    })

    @action setPageNum(val) {
        this.pageNum = val
    }
    @action setPageSize(val) {
        this.pageSize = val
    }
    @action setIds(ids) {
        this.ids = ids
    }

    //运营商弹窗
    onPopup = (data) => {
        this.storeopen = data
    }
    //查询
    fetchRecords = () => {
        const params = this._getTableParams()
        this.isFetching = true
        getGameOperatorGames(params).then(res => {
            this.total = res.totalCount
            this.records = res.list
        }).finally(() => this.isFetching = false);
    }
    //确定
    certainly = () => {
        if (this.ids.length === 0) {
            message.error(t('public.selectAdd'))
            return
        }
        this.isFetching = true
        let params = {
            'ids': this.ids,
            companyId: get('userType') === 'PLATFORM' ? get('companyId') : this.setID
        }
        addGameOperator(params).then(res => {
            if (res.status === 10000) {
                store.setPageNum(1);
                store.setPageSize(10);
                store.fetchRecords();
                this.setPageNum(1);
                this.setPageSize(10);
                this.fetchRecords();
                this.ids = [];
                store.formVisible = false;
            }
        }).finally(() => this.isFetching = false)
    }
}

export default new GameStore()