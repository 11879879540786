import React, { useState } from "react"
import { SearchOutlined, UndoOutlined } from '@ant-design/icons'
import { Button, Input, Select } from "antd"
import store from "./DetailStore"
import ComTable from "./DetailTable"
import ComForm from "./DetailForm"
import Demonstrate from "./DetailType"
import { pulldown, t, companyGameType } from '@tool'
import { MyForm } from '@components'

export default function UserIndex() {
    const [gameCompanyTypeValue, setGameCompanyTypeValue] = useState('');

    const onFinish = (params) => {
        // 重置为第一页
        store.setPageNum(1)
        params['companyType'] = 'GAME_COMPANY'
        store.tableOptions = params
        store.fetchRecords()
    }

    const handleReset = () => {
        // 清空筛选条件
        store.tableOptions = {};
        // 清空下拉框的值 
        setGameCompanyTypeValue('');
        console.log(gameCompanyTypeValue, '222');
        // 重置为第一页并刷新列表
        onFinish({});
    }

    return (
        <div>
            <MyForm onFinish={onFinish} initialValues={{ status: '', gameCompanyType: '' }}>
                <MyForm.Item span={6} title={t('GameCompanyDetail.type')} name={"gameCompanyType"}>
                    <Input allowClear name={'gameCompanyType'} id={'gameCompanyType'} autoComplete="off"
                           placeholder={`${t('public.input')} ${t('GameCompanyDetail.type')}`} />
                </MyForm.Item>

                <MyForm.Item span={6} title={t('Gamebusiness.status')} name={"status"}>
                    <Select id={'gameStatus'}
                        name={'status'}
                        value={''}>
                        {pulldown('statusOptionsSon').map(item => {
                            return <Select.Option value={item.value} key={item.label}>{item.label}</Select.Option>
                        })}
                    </Select>
                </MyForm.Item>

                <MyForm.Item span={6}>
                    <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>{t('public.inquire')}</Button>
                    <Button onClick={handleReset} style={{ marginLeft: '10px' }} icon={<UndoOutlined />}>{t('public.reset')}</Button>
                </MyForm.Item>
            </MyForm>
            <ComTable />
            <ComForm />
            <Demonstrate />
        </div>
    )
}
