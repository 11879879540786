import React, { useState } from "react"

import { SearchOutlined } from '@ant-design/icons'

import { Button, Input, DatePicker } from "antd"
import locale from 'moment/locale/zh-cn'
import moment from "moment/moment";
import store from "./LogStore"
import ComTable from "./LogTable"
import ComForm from "./LogForm"
import { MyForm } from '@components'
import { t } from '@tool'


const dateFormat = 'YYYY-MM-DD HH:mm:ss';

export default function LogIndex() {

    const [startTime, setStartTime] = useState('')
    const [endTime, setEndTime] = useState('')

    const onFinish = (params) => {
        if (startTime) {
            params['startTime'] = moment(startTime).format('YYYY-MM-DD HH:mm:ss')
        }
        if (endTime) {
            params['endTime'] = moment(endTime).format('YYYY-MM-DD HH:mm:ss')
        }
        // 重置为第一页
        store.setPageNum(1)
        store.tableOptions = params
        store.fetchRecords()
    }

    // 结束时间选择
    const disabledRegistrationEndDate = (endDate) => {
        return endDate < moment(startTime);
    }

    return (<div>
        <MyForm onFinish={onFinish} initialValues={{ status: '1', companyId: '', gameType: '' }}>
            <MyForm.Item span={6} title={t('Accesslog.address')} name={"logName"}>
                <Input allowClear autoComplete="off" placeholder={`${t('public.input')} ${t('Accesslog.address')}`} />
            </MyForm.Item>
            <MyForm.Item span={6} title={t('Accesslog.content')} name={"content"}>
                <Input allowClear autoComplete="off" placeholder={`${t('public.input')} ${t('Accesslog.content')}`} />
            </MyForm.Item>
            <MyForm.Item span={6} title={t('Accesslog.Start')}>
                <DatePicker style={{width:'100%'}} locale={locale}
                    format={dateFormat}
                    onChange={(value) => setStartTime(value)}
                    showTime
                />
            </MyForm.Item>
            <MyForm.Item span={6} title={t('Accesslog.End')}>
                <DatePicker style={{width:'100%'}} locale={locale}
                    disabledDate={disabledRegistrationEndDate}
                    format={dateFormat}
                    onChange={(value) => setEndTime(value)}
                    showTime
                />
            </MyForm.Item>
            <MyForm.Item span={4}>
                <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>{t('public.inquire')}</Button>
            </MyForm.Item>
        </MyForm>
        <ComTable />
        <ComForm />
    </div>)
}