import React, { useState } from "react"

import { SearchOutlined } from '@ant-design/icons'

import { Button, Input, Select, Form } from "antd"

import store from "./UserStore"
import UserTable from "./UserTable"
import UserForm from "./UserForm"
import { t, pulldown } from '@tool'
import { MyForm, PwdModal } from '@components'

export default function UserIndex() {
    const [form] = Form.useForm()
    const [pwdVisible, setPwdVisible] = useState(null);
    const onFinish = (params) => {
        // 重置为第一页
        store.setPageNum(1)
        store.tableOptions = params
        store.fetchRecords()
    }
    const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
    return (<div>
        <MyForm form={form} labelCol={{ span: 7 }} onFinish={onFinish} initialValues={{ status: '1', userType: '' }}>
            <MyForm.Item span={6} title={t('user.account')} name={"loginName"}>
                <Input allowClear name={"loginName"} autoComplete="off"
                    placeholder={t('public.input')} />
            </MyForm.Item>
            <MyForm.Item span={6} title={t('user.companyname')} name={"companyId"}>
                <Select
                    placeholder={t('public.Select')}
                    name="companyId"
                    showSearch
                    allowClear
                    filterOption={filterOption}
                    options={JSON.parse(localStorage.getItem('companyList'))}
                >
                </Select>
            </MyForm.Item>
            <MyForm.Item span={6} title={t('user.mailbox')} name={"email"}>
                <Input allowClear name={'email'} autoComplete="off"
                    placeholder={t('public.input')} />
            </MyForm.Item>
            <MyForm.Item span={6} title={t('user.mobile')} name={"mobile"}>
                <Input allowClear name={'mobile'} autoComplete="off"
                    placeholder={t('public.input')} />
            </MyForm.Item>
            <MyForm.Item span={6} title={t('user.Usertype')} name={"userType"}>
                <Select
                    name={'userType'}
                    placeholder=""
                    value={'CUSTOMER'}>
                    {pulldown('userTypeOptionsSon').map(item => {
                        return <Select.Option value={item.value} key={item.label}>{item.label}</Select.Option>
                    })}
                </Select>
            </MyForm.Item>
            <MyForm.Item span={6} title={t('user.status')} name={"status"}>
                <Select
                    name={'status'}
                    value={''}>
                    {pulldown('statusOptionsSon').map(item => {
                        return <Select.Option value={item.value} key={item.label}>{item.label}</Select.Option>;
                    })}
                </Select>
            </MyForm.Item>
            <MyForm.Item span={6}>
                <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>{t('public.inquire')}</Button>
            </MyForm.Item>
        </MyForm>
        <UserTable setPwdVisible={setPwdVisible} />
        {/* formVisible 控制表单显示 */}
        <UserForm />

        <PwdModal pwdVisible={pwdVisible} setPwdVisible={setPwdVisible} />
    </div>)
}