import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import {Modal, Form, Input, message, Row, Col, Select, Upload, Button, Image, Radio} from 'antd';
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import { MyForm } from '@components'
import store from './DetailStore';
import { pulldown, languages, t, host } from '@tool';
import { saveCompanyGameDetail, updateCompanyGameDetail,getCompanyValue } from '@api/CompanyGameDetail';

export default observer(function () {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [imageUrlList, setImageUrlList] = useState([]);
    const [imageUrl, setImageUrl] = useState('');

    const gameCompanyOptions = JSON.parse(localStorage.getItem('gameCompanyList'))
    const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    useEffect(() => {
        form.resetFields();

        if (store.record.id) {
            getCompanyValue(store.record).then(res =>{
                console.log(res)
                console.log(res.msg)
                console.log(res.data)
                if (res.data !==  null){
                    store.record.platform = res.map((item)=>item.value);
                    form.setFieldValue('platform',store.record.platform);
                }
            })
            form.setFieldsValue(store.record);
        }
    }, [store.record]);

    const handleChange = (info) => {
        if (info.file.status === 'done') {
            const { response } = info.file;
            if (response.status === 10000) {
                const newImageUrlList = [...imageUrlList, response.data];
                setLoading(false)
                setImageUrlList(newImageUrlList);
                message.success('上传成功');
            } else {
                message.error(response.msg);
                setLoading(false)
            }
        }
    }

    const handleRemove = (index) => {
        const newImageUrlList = [...imageUrlList];
        newImageUrlList.splice(index, 1);
        setImageUrlList(newImageUrlList);
    }
 
    // s3上传的时候走这个保存多张图片
    const setImageUrlStr = (str) => {
        store.imgUrlList.push(str)
        console.log(store.imgUrlList, '00000');
        // debugger
        setImageUrlList(store.imgUrlList)
    }

    const uploadProps = {
        name: 'file',
        action: '/ctgameclub/upload/uploadFile',
        data: { type: 'uploadImg' },
        accept: '.jpg,.png,.jpge',
        multiple: true,
        onChange: handleChange,
        beforeUpload: (file) => {
            setLoading(true)
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
            if (!isJpgOrPng) {
                message.error('只能上传 JPG/PNG 格式的图片!');
                setLoading(false)
            }
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
                message.error('图片必须小于 2MB!');
                setLoading(false)
            }
            return isJpgOrPng && isLt2M;
        },
        showUploadList: false,
    }

    const uploadProp = {
        name: 'file',
        action: '/ctgameclub/upload/uploadFile',
        data: { type: 'uploadImg' },
        accept: '.jpg,.png,.jpge',
        multiple: true,
        onChange: (info) => {
            if (info.file.status === 'done' && info.file.response && info.file.response.status === 10000) {
                console.log(info.file.response.data);
                setLoading(false)
                setImageUrl(info.file.response.data);
            }
        },
        beforeUpload: (file) => {
            setLoading(true)
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
            if (!isJpgOrPng) {
                message.error('只能上传 JPG/PNG 格式的图片!');
                setLoading(false)
            }
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
                message.error('图片必须小于 2MB!');
                setLoading(false)
            }
            return isJpgOrPng && isLt2M;
        },
        showUploadList: false,
    }


    const handleSubmit = () => {
        form.submit();
    }

    const onFinish = (values) => {
        setLoading(true);

        if (store.record.id) {
            values['id'] = store.record.id
            const posem = JSON.parse(JSON.stringify(values))

            updateCompanyGameDetail(posem).then(res => {
                if (res > 0) {
                    store.formVisible = false
                    store.fetchRecords()
                    message.success(t('Partner.Success'))
                } else {
                    message.error(t(res.msg))
                }
            }).finally(() => setLoading(false))
        } else {
            const posem = JSON.parse(JSON.stringify(values))
            saveCompanyGameDetail(posem).then(res => {
                console.log(res);
                if (res > 0) {
                    store.formVisible = false
                    store.fetchRecords()
                    message.success(t('Partner.Success'))
                } else {
                    message.error(t(res.msg))
                }
            }).finally(() => setLoading(false))
        }
    }
    return (// Modal 对话框
        <Modal
            width={'90%'}
            forceRender={true}
            style={{ top: '2vh', }}
            open={store.formVisible}
            title={store.formTitle}
            onCancel={() => store.formVisible = false}
            confirmLoading={loading}
            onOk={handleSubmit}

        >
            <div style={{
                maxHeight: '74vh', // 设置最大高度为500px
                overflowX: 'hidden', // 溢出时出现滚动条
                overflowY: 'scroll', // 溢出时出现滚动条

            }}>
                <Form form={form} labelCol={{ span: 8 }} onFinish={onFinish} initialValues={{ languages: 'en'}}>
                    <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
                        {
                            false &&
                            <Col span={8}>
                                <Form.Item
                                    label="语&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;言"
                                    name={"languages"}>
                                    <Select
                                        disabled={!store.formEdit}
                                        style={{ width: '100%' }}
                                        allowClear
                                        placeholder="请选择语言"
                                        value={'en'}>
                                        {languages.map(item => {
                                            if (item.value != '') {
                                                return <Select.Option value={item.value}
                                                    key={item.label}>{item.label}</Select.Option>
                                            }
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                        }
                    </Row>

                    <Row gutter={{ md: 8, lg: 24, xl: 48 }}>

                        <Col span={11}>
                            <Form.Item label={t('GameCompanyDetail.type')}
                                rules={[{required: true}]}
                                labelCol={{ span: 8 }}
                                name={"type"}>
                                <Input disabled={!store.formEdit} autoComplete="off" allowClear
                                    placeholder={`${t('public.input')}${t('GameCompanyDetail.type')}`}
                                />
                            </Form.Item>
                        </Col>

                    </Row>


                    <Row gutter={{ md: 8, lg: 24, xl: 48 }}>

                        <Col span={11}>
                            <Form.Item label={t('GameCompanyDetail.typeKey')}
                                       rules={[{required: true}]}
                                       labelCol={{ span: 8 }}
                                       name={"typeKey"}>
                                <Input disabled={!store.formEdit} autoComplete="off" allowClear
                                       placeholder={`${t('public.input')}${t('GameCompanyDetail.typeKey')}`}
                                />
                            </Form.Item>
                        </Col>

                    </Row>


                    <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
                        <MyForm.Item span={11} title={t('GameCompanyDetail.gameCompany')} name={"platform"}>
                                <Select
                                    mode="multiple"
                                    allowClear
                                    name={'platform'}
                                    style={{ width: '100%' }}
                                    showSearch
                                    placeholder={t('Carriergame.GameCompanyPlease')}
                                    filterOption={filterOption}
                                    options={gameCompanyOptions}
                                >
                                </Select>
                            </MyForm.Item>
                    </Row>

                    <Row gutter={{md: 8, lg: 24, xl: 48}}>
                        <Col span={11}>
                            <Form.Item span={6} label={t('GameCompanyDetail.status')}
                                       rules={[{required: true}]}
                                       initialValue={store.record.status == null ? 1:store.record.status}
                                       name={"status"}>
                                <Radio.Group disabled={!store.formEdit}>
                                    <Radio value={1}>{t('GameCompanyDetail.available')}</Radio>
                                    <Radio value={0}>{t('GameCompanyDetail.disable')}</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>


                </Form>
            </div>

        </ Modal>)
})