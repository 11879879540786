import React from 'react'
import { observer } from 'mobx-react'
import { Modal, message, Button, Input, Select } from 'antd'
import { TableCard, AuthButton, } from '@components'
import store from './gameStore'
import tableStore from './tableStore'
import { t, gameGameName, pulldown} from '@tool'
import { SearchOutlined } from '@ant-design/icons'
import { MyForm } from '@components'
const rowSelection = {
    onChange: (selectedRowKeys) => {
        tableStore.setIds(selectedRowKeys)
    }, getCheckboxProps: (record) => ({
        disabled: record.id === 'Disabled Index', // Column configuration not to be checked
        name: record.id,
    }),
}
@observer
class ComTable extends React.Component {
    componentDidMount() {
        tableStore.fetchRecords()
    }
    columns = [
        { title: t('Carriergame.name'), width: '80px', key: gameGameName(), dataIndex: gameGameName() },
        { title: t('Carriergame.Affiliated'), width: '80px', key: 'companyName', dataIndex: 'companyName' },
        {
            title: t('Carriergame.type'), width: '60px', key: 'gameType', dataIndex: 'gameType', render: (text) => {
                const filtered = pulldown('gameTypeOptionsSon').filter(item => item.value === text);
                if (filtered.length > 0) {
                    return filtered[0].label;
                } else {
                    return '-'; // 或者返回其他默认值
                }
            }
        },
        { title: t('Carriergame.ID'), width: '86px', key: 'gameKey', dataIndex: 'gameKey' },
        { title: t('Carriergame.Bonus'), width: '60px', key: 'gameRtp', dataIndex: 'gameRtp' }
    ]
    handleTableChange = ({ current, pageSize }, filters, sorter) => {
        tableStore.pageNum = current
        tableStore.pageSize = pageSize
        tableStore.tableOptions = {
            ...tableStore.tableOptions, // 排序：好像只支持单个排序
            sortField: sorter.field, sortOrder: sorter.order, ...filters
        }
        tableStore.fetchRecords()
    }
    clicKclose = () => {
        tableStore.setPageNum(1)
        store.formVisible = false;
    }
    onFinish = (params) => {
        // 重置为第一页
        tableStore.setPageNum(1)
        tableStore.tableOptions = params
        tableStore.fetchRecords()
    }
    render() {
        return (
            <Modal
                width={'54%'}
                forceRender={true}
                open={store.formVisible}
                title={store.formTitle}
                footer={[]}
                onCancel={this.clicKclose}
                style={{
                    top: '3vh',
                }}
            >
                <MyForm onFinish={this.onFinish} initialValues={{ gameType: '', gameName: '' }}>
                    <MyForm.Item span={9} title={t('Carriergame.name')} name={"gameName"}>
                        <Input allowClear name={'gameName'} autoComplete="off"
                            placeholder={`${t('public.input')} ${t('Carriergame.name')}`} />
                    </MyForm.Item>
                    <MyForm.Item span={9} title={t('Carriergame.type')} name={"gameType"}>
                        <Select
                            name={'gameType'}
                            placeholder={`${t('public.Select')} ${t('Carriergame.type')}`}>
                            {pulldown('gameTypeOptionsSon').map(item => {
                                return <Select.Option value={item.value} key={item.value}>{item.label}</Select.Option>
                            })}
                        </Select>
                    </MyForm.Item>
                    <MyForm.Item span={6}>
                        <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>{t('public.inquire')}</Button>
                    </MyForm.Item>
                </MyForm>
                <TableCard
                    scroll={{ y: '45vh' }}
                    tableLayout={'table-layou'}
                    size={'small'}
                    rowKey="id"
                    onhide={true}
                    rowSelection={{
                        type: 'checkbox', ...rowSelection
                    }}
                    onChange={this.handleTableChange}
                    // 后端的数据源
                    dataSource={tableStore.dataSource}
                    // 分页器
                    pagination={{
                        showSizeChanger: true,
                        showLessItems: true,
                        showTotal: total => t('public.intotal', total),
                        pageSizeOptions: ['10', '20', '50', '100'], // 如果不传 total，则以后端返回数据条数作为 total 的值
                        total: tableStore.total, // 如果不传，则默认是第一条，如果需要默认显示第3条，则必须传
                        current: tableStore.pageNum,
                        pageSize: tableStore.pageSize,
                    }}
                    columns={this.columns} />
                <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'flex-end' }}>
                    <Button onClick={this.clicKclose}>{t('public.Cancel')}</Button>
                    <Button onClick={tableStore.certainly} style={{ marginLeft: '10px' }} type="primary">{t('public.OK')}</Button>
                </div>

            </Modal>
        )
    }
}

export default ComTable
