import React, { useEffect, useState } from 'react'

import { observer } from 'mobx-react'
import { Modal, Form, Input, message, Row, Col, Select } from 'antd'
import { t } from '@tool'
import store from './CurrencyStore'

import { saveCurrency, updateCurrency } from '@api/Currency'

export default observer(function () {
    // 文档中未找到这种解构使用方法
    const [form] = Form.useForm()
    // useState 函数组件中使用 state
    // loading 默认是 flase
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        form.resetFields()
        if (store.record.id) {
            form.setFieldsValue(store.record)
        }

    }, [store.record])

    const handleSubmit = () => {
        form.submit()
    }

    const onFinish = (values) => {
        setLoading(true)
        if (store.record.id) {
            values['id'] = store.record.id
            updateCurrency(values).then(res => {
                if (res > 0) {
                    message.success(t('public.success'))
                    store.formVisible = false
                    store.fetchRecords()
                } else {
                    message.error(t('company.failure'))
                }
            }).finally(() => setLoading(false))
        } else {
            saveCurrency(values).then(res => {
                if (res > 0) {
                    store.formVisible = false
                    store.fetchRecords()
                }
            }).finally(() => setLoading(false))
        }
    }

    return (// Modal 对话框
        <Modal
            width={'54%'}
            forceRender={true}
            open={store.formVisible}
            title={store.formTitle}
            onCancel={() => store.formVisible = false}
            confirmLoading={loading}
            // ok 按钮 props
            onOk={handleSubmit}>
            <Form form={form} labelCol={{ span: 7 }} onFinish={onFinish}>
                <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
                    <Col span={12}>
                        <Form.Item label={t('Currency.currency')}
                            rules={[{ required: true, message: `${t('public.input')}${t('Currency.currency')}` }]}
                            name={"currency"}>
                            <Input allowClear autoComplete="off" disabled={!store.formEdit}
                                placeholder={`${t('public.input')}${t('Currency.currency')}`} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={t('Currency.name')}
                            rules={[{ required: true, message: `${t('public.input')}${t('Currency.currency')}` }]}
                            name={"name"}>
                            <Input allowClear autoComplete="off" disabled={!store.formEdit}
                                placeholder={`${t('public.input')}${t('Currency.name')}`} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={t('Currency.nameZh')}
                            rules={[{ required: true, message: `${t('public.input')}${t('Currency.nameZh')}` }]}
                            name={"nameZh"}>
                            <Input disabled={!store.formEdit}
                                autoComplete="off"
                                allowClear
                                placeholder={`${t('public.input')}${t('Currency.nameZh')}`} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={t('Currency.rate')}
                            rules={[{ required: false, message: `${t('public.input')}${t('Currency.rate')}` }]}
                            name={"rate"}>
                            <Input disabled={!store.formEdit}
                                autoComplete="off"
                                allowClear
                                placeholder={`${t('public.input')}${t('Currency.rate')}`} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={t('Currency.address')}
                            rules={[{ required: false, message: `${t('public.input')}${t('Currency.address')}` }]}
                            name={"address"}>
                            <Input disabled={!store.formEdit}
                                autoComplete="off"
                                allowClear
                                placeholder={`${t('public.input')}${t('Currency.address')}`} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={t('Currency.addressZh')}
                            rules={[{ required: false, message: `${t('public.input')}${t('Currency.addressZh')}` }]}
                            name={"addressZh"}>
                            <Input disabled={!store.formEdit}
                                autoComplete="off"
                                allowClear
                                placeholder={`${t('public.input')}${t('Currency.addressZh')}`} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={t('Currency.lang')}
                            rules={[{ required: false, message: `${t('public.input')}${t('Currency.lang')}` }]}
                            name={"lang"}>
                            <Input disabled={!store.formEdit}
                                autoComplete="off"
                                allowClear
                                placeholder={`${t('public.input')}${t('Currency.lang')}`} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>)
})