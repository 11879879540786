import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Modal, Button, Input, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { t } from '@tool'
import store from './DetailTypeStore';
import { MyForm, TableCard } from '@components'


export default observer(function () {

    
    const handleSubmit = () => {
        console.log('123123');
    }


    const onFinish = (params) => {
        store.tableOptions = params
        store.fetchRecords()
    }
    const columns = [
        {
            title: t('类别'),
            width: '150px',
            key: 'type',
            dataIndex: 'type',
            ellipsis: true,

        },
        {
            title: t('Gamebusiness.companyname'),
            width: '150px',
            key: 'companyName',
            dataIndex: 'companyName',
            ellipsis: true,

        },
        {
            title: t('邮箱'),
            width: '150px',
            key: 'email',
            dataIndex: 'email',
            ellipsis: true,

        },
        {
            title: t('电话'),
            width: '150px',
            key: 'phone',
            dataIndex: 'phone',
            ellipsis: true,

        },
    ]

    const handleTableChange = ({ current, pageSize }, filters, sorter) => {
        store.pageNum = current
        store.pageSize = pageSize
        store.tableOptions = {
            ...store.tableOptions, // 排序：好像只支持单个排序
            sortField: sorter.field, sortOrder: sorter.order, ...filters
        }
        store.fetchRecords()
    }
    return (// Modal 对话框
        <Modal
            width={'90%'}
            forceRender={true}
            style={{ top: '2vh', }}
            open={store.formVisible}
            title={store.formTitle}
            onCancel={() => store.formVisible = false}
            onOk={handleSubmit}

        >
            <MyForm onFinish={onFinish} initialValues={{ status: '1', gameCompanyType: '' }}>
                <MyForm.Item span={6} title={t('Gamebusiness.companyname')} name={"companyName"}>
                    <Input
                        allowClear
                        name={'companyName'}
                        id={'gameCompanyName'}
                        autoComplete="off"
                        placeholder={`${t('public.input')} ${t('Gamebusiness.companyname')}`} />
                </MyForm.Item>
                <MyForm.Item span={6}>
                    <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>{t('public.inquire')}</Button>
                </MyForm.Item>
            </MyForm>
            <TableCard
                tableLayout={'fixed'}
                size={'small'}
                scroll={{
                    // x: 1500,
                    y: '44vh'
                }}
                rowKey="id"

                title={t('Gamebusiness.vendorsList')}
                loading={store.isFetching}
                // 后端的数据源
                dataSource={store.dataSource}
                onReload={store.fetchRecords}
                onChange={handleTableChange}
                actions={[]}
                // 分页器
                pagination={{
                    showSizeChanger: true,
                    showLessItems: true,
                    showTotal: total => t('public.intotal', total),
                    pageSizeOptions: ['10', '20', '50', '100'], // 如果不传 total，则以后端返回数据条数作为 total 的值
                    total: store.total, // 如果不传，则默认是第一条，如果需要默认显示第3条，则必须传
                    current: store.pageNum,
                    pageSize: store.pageSize,
                }}
                columns={columns} />

        </ Modal>)
})