import {post} from "@http"

//添加系统参数
export function addSystemParam(params) {
    return post("/manager/system_param/add", params)
}

//修改系统参数信息
export function modifySystemParam(params) {
    return post("/manager/system_param/modify", params)
}

//修改状态
export function batchStatusSystemParam(params) {
    return post("/manager/system_param/batchStatus", params)
}

//获取系统参数列表
export function getSystemParamList(params) {
    return post("/manager/system_param/getList", params)
}

//加载系统参数
export function loadSystemParam(params) {
    return post("/manager/system_param/load", params)
}