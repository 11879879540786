import { observable, computed, action } from 'mobx'
import { getCompanyList } from '@api'
class GameStore {
    @observable formVisible = false

    @observable formEdit = false

    @observable formTitle = ''

    @observable record = {}

    @observable records = []

    // 默认第1页
    @observable pageNum = 1
    // 每页多少条
    @observable pageSize = 10
    // 总共多少页
    @observable total = ''

    @observable ids = []

    // 其他参数，例如排序、过滤等等
    @observable tableOptions = {}

    @observable isFetching = false



    @computed get dataSource() {
        return this.records
    }
    _getTableParams = () => ({
        pageNum: this.pageNum, pageSize: this.pageSize, ...this.tableOptions, status: 1,
        //是运营商就获取本地里面的id 不是就使用storevalue
        companyId: localStorage.getItem('userType') === 'PLATFORM' ? localStorage.getItem('companyId') :
            Array.isArray(this.storevalue) === true ? '' : this.storevalue
    })

    @action setPageNum(val) {
        this.pageNum = val
    }
    @action setPageSize(val) {
        this.pageSize = val
    }
    @action setIds(ids) {
        this.ids = ids
    }

    //查询
    fetchRecords = () => {
        const params = this._getTableParams()
        this.isFetching = true
        getCompanyList(params).then(res => {
            this.total = res.totalCount
            this.records = res.list
        }).finally(() => this.isFetching = false);
    }
}

export default new GameStore()