import React from 'react'

import { observer } from 'mobx-react'
import { Modal, Button, Row, Col } from 'antd'
import store from './paramStore'
import { t } from '@tool'

export default observer(function () {
    const FooterButton = ({ onClick }) => (
        <Button type="primary" onClick={onClick}>
          {t('public.Cancel')}
        </Button>
    );
    return (// Modal 对话框
        <Modal
            width={'60%'}
            forceRender={true}
            open={store.detailsVisible}
            title={store.formTitle}
            closable={false}
            footer={<FooterButton onClick={() => store.detailsVisible = false} />}
        >
            <Row style={{ borderBottom: '1px solid #f0f0f0', fontWeight: 'bold', fontSize: '15px', 
            background: "#e9f6e4", padding: '10px 0' }} gutter={{ md: 8, lg: 24, xl: 48 }}>
                <Col span={24}>
                    {store.record.paramKey}
                </Col>
            </Row>
            {store.record.valueList && store.record.valueList.map((item, index) => (
                <Row key={index}  gutter={{ md: 8, lg: 24, xl: 48 }}>
                    <Col style={{ background: "#f8f8f8", borderRight: '1px solid #f0f0f0', 
                    borderBottom: '1px solid #f0f0f0', padding: '10px 0', textAlign: 'center' }} span={8}>
                        {item.key}
                    </Col>
                    <Col style={{ borderBottom: '1px solid #f0f0f0', 
                    background: "#f8f8f8", padding: '10px 10px' }} span={16}>
                        {item.value}
                    </Col>
                </Row>
            ))}
            <Row style={{ marginTop:'20px'}} gutter={{ md: 8, lg: 24, xl: 48 }}>
                <Col span={24}>
                    <label style={{fontSize:'16px',fontWeight: 'bold',}}>{t('public.remark')}</label>
                    <div style={{ marginTop:'8px', 
                     background: "#eee",fontSize:'15px', borderRadius:'10px',
                      minHeight:'150px' ,padding: '10px' }}>
                    {store.record.remark}
                    </div>
                </Col>
            </Row>
        </Modal>

    )
})