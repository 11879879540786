export const Currency = [
    {lable: 'AED', value: '阿拉伯联合酋长国迪拉姆', rate: 1},
    {lable: 'AFN', value: '阿富汗', rate: 1},
    {lable: 'ALL', value: '阿尔巴尼亚列克', rate: 1},
    {lable: 'AMD', value: '亚美尼亚德拉姆', rate: 1},
    {lable: 'ANG', value: '安的列斯盾', rate: 1},
    {lable: 'AOA', value: '安哥拉宽扎', rate: 1},
    {lable: 'ARS', value: '阿根廷比索', rate: 1},
    {lable: 'AWG', value: '阿鲁巴弗罗林', rate: 1},
    {lable: 'AZN', value: '阿塞拜疆马纳特', rate: 1},
    {lable: 'BAM', value: '波黑可兑换马克', rate: 1},
    {lable: 'BBD', value: '巴巴多斯元', rate: 1},
    {lable: 'BDT', value: '孟加拉塔卡', rate: 1},
    {lable: 'BGN', value: '保加利亚列弗', rate: 1},
    {lable: 'BHD', value: '巴林第纳尔', rate: 1},
    {lable: 'BIF', value: '布隆迪法郎', rate: 1000},
    {lable: 'BMD', value: '百慕大元', rate: 1},
    {lable: 'BND', value: '汶莱元', rate: 1},
    {lable: 'BOB', value: '玻利维亚诺', rate: 1},
    {lable: 'BRL', value: '巴西里亚伊（雷亚尔）', rate: 1},
    {lable: 'BSD', value: '巴哈马元', rate: 1},
    {lable: 'BTN', value: '不丹努尔特鲁姆', rate: 1},
    {lable: 'BWP', value: '博茨瓦纳普拉', rate: 1},
    {lable: 'BYN', value: '白俄罗斯卢布', rate: 1},
    {lable: 'BYR', value: '白俄罗斯卢布', rate: 1},
    {lable: 'BZD', value: '伯利兹元', rate: 1},
    {lable: 'CAD', value: '加拿大元', rate: 1},
    {lable: 'CDF', value: '刚果法郎', rate: 1000},
    {lable: 'CHF', value: '瑞士法郎', rate: 1},
    {lable: 'CLP', value: '智利比索', rate: 1},
    {lable: 'CNY', value: '人民币', rate: 1},
    {lable: 'COP', value: '哥伦比亚比索', rate: 1000},
    {lable: 'CRC', value: '哥斯达黎加科朗', rate: 1},
    {lable: 'CSD', value: '塞爾維亞第納爾', rate: 1},
    {lable: 'CUP', value: '古巴比索', rate: 1},
    {lable: 'CVE', value: '佛得角埃斯库多', rate: 1},
    {lable: 'CZK', value: '捷克克朗', rate: 1},
    {lable: 'DJF', value: '吉布提法郎', rate: 1},
    {lable: 'DKK', value: '丹麦克朗', rate: 1},
    {lable: 'DOP', value: '多米尼加比索', rate: 1},
    {lable: 'DZD', value: '阿尔及利亚第纳尔', rate: 1},
    {lable: 'EGP', value: '埃及镑', rate: 1},
    {lable: 'ERN', value: '厄立特里亚纳克法', rate: 1},
    {lable: 'ETB', value: '埃塞俄比亚比尔', rate: 1},
    {lable: 'EUR', value: '欧元', rate: 1},
    {lable: 'FJD', value: '斐济元', rate: 1},
    {lable: 'FKP', value: '福克兰岛磅', rate: 1},
    {lable: 'GBP', value: '英镑', rate: 1},
    {lable: 'GEL', value: '格鲁吉亚拉里', rate: 1},
    {lable: 'GHS', value: '加纳塞地', rate: 1},
    {lable: 'GIP', value: '直布罗陀庞德', rate: 1},
    {lable: 'GMD', value: '冈比亚货币', rate: 1},
    {lable: 'GNF', value: '几内亚法郎', rate: 1000},
    {lable: 'GTQ', value: '危地马拉格查尔', rate: 1},
    {lable: 'GYD', value: '圭亚那元', rate: 1},
    {lable: 'HNL', value: '洪都拉斯伦皮拉', rate: 1},
    {lable: 'HTG', value: '海地古德', rate: 1},
    {lable: 'HUF', value: '匈牙利福林', rate: 1},
    {lable: 'IDR', value: '印度尼西亚卢比盾', rate: 1000},
    {lable: 'ILS', value: '以色列谢克尔', rate: 1},
    {lable: 'INR', value: '印度卢比', rate: 1},
    {lable: 'IQD', value: '伊拉克第纳尔', rate: 1000},
    {lable: 'IRR', value: '伊朗里亚尔', rate: 1000},
    {lable: 'ISK', value: '冰岛克朗', rate: 1},
    {lable: 'JMD', value: '牙买加元', rate: 1},
    {lable: 'JOD', value: '约旦第纳尔', rate: 1},
    {lable: 'JPY', value: '日元', rate: 1},
    {lable: 'KES', value: '肯尼亚先令', rate: 1},
    {lable: 'KGS', value: '吉尔吉斯斯坦索姆', rate: 1},
    {lable: 'KHR', value: '柬埔寨利尔斯', rate: 1000},
    {lable: 'KMF', value: '科摩罗法郎', rate: 1},
    {lable: 'KPW', value: '北朝鲜元', rate: 1},
    {lable: 'KRW', value: '韩元', rate: 1000},
    {lable: 'KWD', value: '科威特第纳尔', rate: 1},
    {lable: 'KYD', value: '开曼岛元', rate: 1},
    {lable: 'KZT', value: '坚戈', rate: 1},
    {lable: 'LAK', value: '老挝基普', rate: 1000},
    {lable: 'LBP', value: '黎巴嫩镑', rate: 1000},
    {lable: 'LKR', value: '斯里兰卡卢比', rate: 1},
    {lable: 'LRD', value: '黎巴嫩元', rate: 1},
    {lable: 'LSL', value: '莱索托洛蒂', rate: 1},
    {lable: 'LVL', value: '拉脱维亚拉特', rate: 1},
    {lable: 'LYD', value: '利比亚第纳尔', rate: 1},
    {lable: 'MAD', value: '摩洛哥迪拉姆', rate: 1},
    {lable: 'MBTC', value: '比特币（虚拟货币）', rate: 1},
    {lable: 'MDL', value: '摩尔多瓦列伊', rate: 1},
    {lable: 'MGA', value: '马达加斯加阿里亚里', rate: 1000},
    {lable: 'MKD', value: '马其顿第纳尔', rate: 1},
    {lable: 'MMK', value: '缅甸元', rate: 1000},
    {lable: 'MNT', value: '蒙古圖格裡克', rate: 1000},
    {lable: 'MUR', value: '毛里求斯卢比', rate: 1},
    {lable: 'MVR', value: '马尔代夫罗非亚', rate: 1},
    {lable: 'MWK', value: '马拉维克瓦查', rate: 1},
    {lable: 'MXN', value: '墨西哥比索', rate: 1},
    {lable: 'MZN', value: '莫桑比克梅蒂卡尔', rate: 1},
    {lable: 'NAD', value: '纳米比亚元', rate: 1},
    {lable: 'NGN', value: '尼日利亚奈拉', rate: 1},
    {lable: 'NIO', value: '尼加拉瓜科多巴', rate: 1},
    {lable: 'NOK', value: '挪威克朗', rate: 1},
    {lable: 'NPR', value: '尼泊尔卢比', rate: 1},
    {lable: 'NZD', value: '新西兰元', rate: 1},
    {lable: 'OMR', value: '阿曼里亚尔', rate: 1},
    {lable: 'PAB', value: '巴拿马巴波亚', rate: 1},
    {lable: 'PEN', value: '秘鲁索尔', rate: 1},
    {lable: 'PGK', value: '巴布亚新几内亚基纳', rate: 1},
    {lable: 'PHP', value: '菲律宾比索', rate: 1},
    {lable: 'PKR', value: '巴基斯坦卢比', rate: 1},
    {lable: 'PLN', value: '波兰兹罗提', rate: 1},
    {lable: 'PYG', value: '巴拉圭瓜拉尼', rate: 1000},
    {lable: 'QAR', value: '卡塔尔里亚尔', rate: 1},
    {lable: 'RON', value: '罗马尼亚列伊', rate: 1},
    {lable: 'RSD', value: '塞尔维亚第纳尔', rate: 1},
    {lable: 'RUB', value: '俄罗斯卢布', rate: 1},
    {lable: 'RWF', value: '卢旺达法郎', rate: 1000},
    {lable: 'SAR', value: '沙特里亚尔', rate: 1},
    {lable: 'SBD', value: '所罗门群岛元', rate: 1},
    {lable: 'SCR', value: '塞舌尔卢比', rate: 1},
    {lable: 'SDG', value: '苏丹镑', rate: 1},
    {lable: 'SEK', value: '瑞典克朗', rate: 1},
    {lable: 'SHP', value: '圣赫勒拿磅', rate: 1},
    {lable: 'SLL', value: '塞拉利昂利昂', rate: 1000},
    {lable: 'SOS', value: '索马里先令', rate: 1},
    {lable: 'SRD', value: '苏里南元', rate: 1},
    {lable: 'SVC', value: '萨尔瓦多科朗', rate: 1},
    {lable: 'SYP', value: '叙利亚镑', rate: 1},
    {lable: 'SZL', value: '斯威士马兰吉尼', rate: 1},
    {lable: 'THB', value: '泰铢', rate: 1},
    {lable: 'TJS', value: '塔吉克斯坦索莫尼', rate: 1},
    {lable: 'TMT', value: '土库曼斯坦新马纳特', rate: 1},
    {lable: 'TND', value: '突尼斯第纳尔', rate: 1},
    {lable: 'TOP', value: '汤加潘加', rate: 1},
    {lable: 'TRY', value: '土耳其里拉', rate: 1},
    {lable: 'TTD', value: '特立尼达与多巴哥元', rate: 1},
    {lable: 'TUSD', value: 'TrueUSD（虚拟货币）', rate: 1},
    {lable: 'TZS', value: '坦桑尼亚先令', rate: 1000},
    {lable: 'UAH', value: '乌克兰赫夫米', rate: 1},
    {lable: 'UBTC', value: '微型比特币', rate: 1},
    {lable: 'UGX', value: '乌干达先令', rate: 1000},
    {lable: 'USD', value: '美元', rate: 1},
    {lable: 'USDC', value: 'USDCoin（虚拟货币）', rate: 1},
    {lable: 'USDT', value: '泰达币', rate: 1},
    {lable: 'UYU', value: '乌拉圭比索', rate: 1},
    {lable: 'UZS', value: '乌兹别克斯坦苏姆', rate: 1000},
    {lable: 'VND', value: '越南盾', rate: 1000},
    {lable: 'VUV', value: '瓦努阿图瓦图', rate: 1},
    {lable: 'WST', value: '萨摩亚塔拉', rate: 1},
    {lable: 'XAF', value: '中非金融合作法郎', rate: 1},
    {lable: 'XCD', value: '东加勒比元', rate: 1},
    {lable: 'XOF', value: 'CFA法郎', rate: 1},
    {lable: 'XPF', value: '太平洋法郎', rate: 1},
    {lable: 'YER', value: '也门里亚尔', rate: 1},
    {lable: 'ZAR', value: '南非兰特', rate: 1},
    {lable: 'ZMW', value: '赞比亚克瓦查', rate: 1}
]