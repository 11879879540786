import React from 'react';
import { observer } from 'mobx-react';
import { Space, Modal, Input, message, Upload, Button, Spin } from 'antd';
import { CloudDownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { TableCard, AuthButton } from '@components';
import store from './GameIconStore';
import { t } from '@tool';


const rowSelection = {
    onChange: (selectedRowKeys) => {
        store.setIds(selectedRowKeys);
    },
    getCheckboxProps: (record) => ({
        disabled: record.id === 'Disabled Index', // Column configuration not to be checked
        name: record.id,
    }),
};

@observer
class ComTable extends React.Component {
    state = {
        inputModalVisible: false,
        selectedRecord: null,
        inputValue: '',
    };

    componentDidMount() {
        store.tableOptions = {
            ...store.tableOptions,
            status: 1,
        };
        store.fetchRecords();
    }

    columns = [
        { title: t('company.companyname'), width: '80px', key: 'companyName', dataIndex: 'companyName', ellipsis: true },
        {
            title: t('company.status'),
            width: '50px',
            key: 'status',
            dataIndex: 'status',
            ellipsis: true,
            render: (text) => (text === 1 ? t('public.effective') : t('public.invain')),
        },
        {
            title: t('company.mailbox'),
            width: '80px',
            key: 'email',
            dataIndex: 'email',
            ellipsis: true,
            render: (text) => (
                <div className="ellipsis" style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }} title={t('public.Clickcopy')} onClick={store.copy}>
                    {text}
                </div>
            ),
        },
        { title: t('company.mobile'), width: '100px', key: 'phone', dataIndex: 'phone' },
        {
            title: t('company.type'),
            width: '60px',
            key: 'companyType',
            dataIndex: 'companyType',
            ellipsis: true,
            render: (text) => {
                let type = t('public.other');
                if (text === 'GAME_COMPANY') {
                    type = t('public.business');
                } else if (text === 'OPERATOR_COMPANY') {
                    type = t('public.operator');
                }
                return type;
            },
        },
        {
            title: t('company.website'),
            width: '90px',
            key: 'officialWebsite',
            dataIndex: 'officialWebsite',
            ellipsis: true,
            render: (text) => (
                <div className="ellipsis" style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }} title={t('public.Clickcopy')} onClick={store.copy}>
                    {text}
                </div>
            ),
        },
        {
            title: t('public.remark'),
            width: '90px',
            key: 'remark',
            dataIndex: 'remark',
            ellipsis: true,
            render: (text) => (
                <div className="ellipsis" style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }} title={t('public.Clickcopy')} onClick={store.copy}>
                    {text}
                </div>
            ),
        },
        {
            title: t('public.Controls'),
            fixed: 'right',
            width: '180px',
            key: 'id',
            render: (_, record) => {
                if (record.syncSwitch === 1) {
                    return (
                        <AuthButton
                            type="primary" ghost
                            icon={<CloudDownloadOutlined />}
                            onClick={() => store.syncGameIcon(record.brandName)}>
                            {t('Gamemanage.DownloadPPIcon')}
                        </AuthButton>
                    )
                } else {
                    return (
                        <Upload
                            showUploadList={false}
                            beforeUpload={(file) => {
                                this.handleFileUpload(file, record.companyName);
                                return false; // 阻止默认上传行为
                            }}
                        >
                            <Button type="primary" icon={<UploadOutlined />}>
                                {t('Gamemanage.UploadGameIcon')}
                            </Button>
                        </Upload>
                    )
                }
            },
        },
    ];

    handleFileUpload = (file, companyName) => {
        store.isFetching = true
        // 检查文件扩展名是否是压缩包格式
        const allowedExtensions = ['zip']; // 允许的压缩包格式
        const fileExtension = file.name.split('.').pop().toLowerCase();
        if (!allowedExtensions.includes(fileExtension)) {
            message.error('只支持zip格式的压缩包');
            store.isFetching = false
            return;
        }

        console.log(companyName);
        const formData = new FormData();
        formData.append('file', file);
        formData.append('brandName', companyName);

        // 发起文件上传请求
        store.uploadGameIcon(formData)
            .then(response => {
                if (response.status === 10000) {
                    message.success(response.data);
                    store.isFetching = false
                } else {
                    message.error(response.msg);
                    store.isFetching = false
                }
            })
    }

    handleTableChange = ({ current, pageSize }, filters, sorter) => {
        store.pageNum = current;
        store.pageSize = pageSize;
        store.tableOptions = {
            ...store.tableOptions, // 排序：好像只支持单个排序
            sortField: sorter.field,
            sortOrder: sorter.order,
            ...filters,
        };
        store.fetchRecords();
    };

    render(_, record) {
        // const { inputModalVisible, inputValue, uploading } = this.state;

        return (
            <>
                <TableCard
                    tableLayout="fixed"
                    size="small"
                    handleClickRow={store.handleClickRow}
                    scroll={{ x: 1500, y: '44vh' }}
                    rowKey="id"
                    rowSelection={{ type: 'checkbox', ...rowSelection }}
                    title={t('company.companyList')}
                    loading={store.isFetching}
                    dataSource={store.dataSource}
                    onReload={store.fetchRecords}
                    onChange={this.handleTableChange}
                    actions={[]}
                    pagination={{
                        showSizeChanger: true,
                        showLessItems: true,
                        showTotal: (total) => t('public.intotal', total),
                        pageSizeOptions: ['10', '20', '50', '100'],
                        total: store.total,
                        current: store.pageNum,
                        pageSize: store.pageSize,
                    }}
                    columns={this.columns}
                />
            </>
        );
    }
}

export default ComTable;
