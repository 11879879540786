import React from 'react'
import { Modal, Form, Input, message } from 'antd'
import { updatePwd } from '@api'
import { encryptPassword } from '@tool'
export default function PwdModal(props) {
    const { pwdVisible, setPwdVisible } = props;
    // 文档中未找到这种解构使用方法
    const [form] = Form.useForm()
    const onPwdFinish = (values) => {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d_@.#-]+$/;
        if (values.newPwd != values.pwdConfirm) {
            message.error('两次输入密码不一致')
            return
        }
        for (let [key, value] of Object.entries(values)) {
            if (!regex.test(value)) {
                const msg = key == 'oldPwd' ? '旧密码' : key == 'newPwd' ? '新密码' : '确认密码'
                message.error(`${msg}只能输入6~12位 数字 英文必须包含大小,特殊符只能输入@#_-.`)
                return
            }
        }
        const type = typeof pwdVisible === 'object'
        const data = JSON.parse(JSON.stringify(values));
        const key = type === true ? pwdVisible.userEncrypt : localStorage.getItem('token')
        for (let prop in data) {
            if (data.hasOwnProperty(prop)) {
                data[prop] = encryptPassword(data[prop], key);
            }
        }
        const param = {
            ...data,
            id: type === true ? pwdVisible.id : localStorage.getItem('id')
        }
        updatePwd(param).then((res) => {
            if (res.status === 10000) {
                message.success('修改成功')
                setPwdVisible(null)
            } else {
                message.error(res.msg)
            }
        }).catch((error) => {
            message.error(error)
        })
    }


    return (// Modal 对话框
        <Modal
            title="修改密码"
            open={pwdVisible}
            okText={"确定"}
            cancelText={"取消"}
            onOk={() => form.submit()}
            onCancel={() => setPwdVisible(null)}
        >
            <Form form={form} onFinish={onPwdFinish}>
                <Form.Item span={6} label="旧密码" rules={[{ required: true, message: '请输入旧密码' }]} name={"oldPwd"}>
                    <Input.Password
                        autoComplete="off"
                        allowClear name={"oldPwd"}
                        placeholder="请输入旧密码"

                    />
                </Form.Item>
                <Form.Item span={6} label="新密码" rules={[{ required: true, message: '请输新密码' },]}
                    name={"newPwd"}>
                    <Input.Password autoComplete="off"
                        allowClear name={"newPwd"} placeholder="请输新密码"

                    />
                </Form.Item>
                <Form.Item span={6} label="确认密码" name={"pwdConfirm"}
                    rules={[{ required: true, message: '请输确认密码' }]}>
                    <Input.Password autoComplete="off"
                        allowClear name={"pwdConfirm"} placeholder="请输确认密码" />
                </Form.Item>
            </Form>
        </Modal>)
}