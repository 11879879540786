import React, { useState } from "react"

import { SearchOutlined } from '@ant-design/icons'

import { Button, Input, Select, Form } from "antd"

import store from "./AuthoritySetStore"
import AuthoritySetTable from "./AuthoritySetTable"
import { t } from '@tool'
import { getCompanyList } from '@api'

import { MyForm } from '@components'

export default function UserIndex() {

    const [form] = Form.useForm()

    const [companyList, setCompanyList] = useState([]) //模糊企业搜索下拉
    const [keyword, setKeyword] = useState('') //模糊关键词

    // 防抖
    const deBounce = (fn, delay = 1000) => {
        let timer = null
        // 闭包
        return function f(args) {
            if (timer) clearTimeout(timer)
            timer = setTimeout(() => {
                timer = null
                fn.call(this, args)
            }, delay)
        }
    }

    // 模糊搜索
    const getCompany = async (value) => {
        let params = {
            status: 1
        }
        if (value) {
            params['companyName'] = value
        }
        getList(params)
    }

    // 点击查询，通过公司名获取公司信息
    const getList = (params) => {
        if (!params) {
            params = {
                status: 1,
                pageNum: 1,
                pageSize: 99999
            }
        }
        getCompanyList(params).then(res => {
            if (res.hasOwnProperty('list')) {
                let list = [{ 'companyId': '0', 'companyName': '全部' }]
                res.list.map((item) => {
                    list.push(item)
                })
                setCompanyList(list)
            }
        })
    }

    const onFinish = (params) => {
        // 重置为第一页
        store.setPageNum(1)
        store.tableOptions = params
        store.fetchRecords()
    }

    return (<div>
        <MyForm form={form} onFinish={onFinish} initialValues={{ status: '1', userType: '' }}>
            <MyForm.Item span={6} title={t('userlimit.Account')} name={"loginName"}>
                <Input allowClear name={"loginName"} autoComplete="off"
                    placeholder={`${t('public.input')} ${t('userlimit.Account')}`} />
            </MyForm.Item>
            <MyForm.Item span={6} title={t('userlimit.name')} name={"companyId"}>
                <Select placeholder={`${t('public.Select')} ${t('userlimit.name')}`} showSearch value={keyword}
                    className="enterCompanyName" name="companyId"
                    onSearch={deBounce(function (value) {
                        // 模糊搜索获取公司名
                        getCompany(value)
                    }, 700)}
                    onChange={(val) => {
                        // 选中下拉时，也将下拉值作为输入框的值
                        setKeyword(val)
                    }}
                    onFocus={() => {
                        getList()
                    }}
                    filterOption={false} //必须加这个，否则会对返回值进行筛选
                    onBlur={(e) => {
                        // 当是选中值的时候，就没有e.target.value，所以需要通过原生获取节点的值
                        let value = e.target.value || document.getElementsByClassName('enterCompanyName')[0]
                            ?.querySelector('.ant-select-selection-item')?.innerText
                        // 再次设置关键词
                        setKeyword(value)
                    }}
                >{companyList.map((item, index) => <Select.Option key={index}
                    value={item.id}>{item.companyName}</Select.Option>)}
                </Select>
            </MyForm.Item>
            <MyForm.Item span={6}>
                <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>{t('public.inquire')}</Button>
            </MyForm.Item>
        </MyForm>
        <AuthoritySetTable />
    </div>)
}