import React, { useEffect, useState } from 'react'
import { CompanyList } from '@tool'
import { observer } from 'mobx-react'
import { Modal, Form, Radio, Input, message, Row, Col, Select } from 'antd'

import store from './OperatorStore'
import { companyTypeOptions, languages, t } from '@tool'

import { addCompanyDetail, modifyCompanyDetail, getAllCurrency } from '@api'

export default observer(function () {
    // 文档中未找到这种解构使用方法
    const [form] = Form.useForm()
    // useState 函数组件中使用 state
    // loading 默认是 flase
    const [loading, setLoading] = useState(false)
    const [currencyOption, setCurrencyOption] = useState([])

    useEffect(() => {
        form.resetFields()
        if (store.record.id) {
            form.setFieldsValue(store.record)
            onGetCurrencyList();
        }
    }, [store.record])

    const handleSubmit = () => {
        form.submit()
    }

    const onGetCurrencyList = () => {
        if (currencyOption.length > 0) return;
        getAllCurrency().then(res => {
            const currencyList = res.map((item) => {
                return {
                    value: item.currency,
                    label: `${item.nameZh}(${item.currency})`
                }
            })
            setCurrencyOption(currencyList);
        }).catch(() => { message.error(t('public.error')) });
    }

    const onFinish = (values) => {
        setLoading(true)
        if (store.record.id) {
            values['id'] = store.record.id
            if (store.record.companyDetailId) {
                values['companyDetailId'] = store.record.companyDetailId
            }

            modifyCompanyDetail(values).then(res => {
                if (res.status === 10000) {
                    store.formVisible = false
                    store.fetchRecords()
                    //运营商
                    CompanyList()
                } else {
                    message.error("保存失败")
                }
            }).finally(() => setLoading(false))
        } else {
            addCompanyDetail(values).then(res => {
                if (res.status === 10000) {
                    store.formVisible = false
                    store.fetchRecords()
                    //运营商
                    CompanyList()
                }
            }).finally(() => setLoading(false))
        }
    }
    return (// Modal 对话框
        <Modal
            width={'88%'}
            forceRender={true}
            open={store.formVisible}
            title={store.formTitle}
            onCancel={() => store.formVisible = false}
            confirmLoading={loading}
            // ok 按钮 props
            onOk={handleSubmit}
            style={{
                top: '3vh',
            }}
        >
            <div style={{
                height: '70vh',
                overflowX: 'hidden',
                overflowY: 'auto',
            }}>
                <Form form={form} onFinish={onFinish} labelCol={{ span: 8 }}
                    initialValues={{ languages: 'en', walletMode: 'single', rtpSwitch: 0 }}>
                    <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
                        <Col span={8}>
                            <Form.Item label={t('operator.Company')}
                                rules={[{
                                    required: true,
                                    message: `${t('public.input')} ${t('operator.Company')}`
                                }]}
                                name={"companyName"}>
                                <Input allowClear autoComplete="off" disabled={!store.formEdit}
                                    placeholder={`${t('public.input')} ${t('operator.Company')}`} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label={t('operator.Type')}
                                rules={[{
                                    required: true,
                                    message: `${t('public.Select')} ${t('operator.Type')}`
                                }]}
                                name={"companyType"}>
                                <Select disabled={!store.formEdit}
                                    style={{ width: '100%' }}
                                    allowClear
                                    placeholder={`${t('public.Select')} ${t('operator.Type')}`}
                                    value={'COMPANY'}>
                                    {companyTypeOptions.map(item => {
                                        if (item.value != '') {
                                            return <Select.Option value={item.value}
                                                key={item.label}>{item.label}</Select.Option>
                                        }
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label={t('operator.mailbox')}
                                rules={[{
                                    required: true,
                                    message: `${t('public.input')} ${t('operator.mailbox')}`
                                }]}
                                name={"email"}>
                                <Input disabled={!store.formEdit} autoComplete="off" allowClear
                                    placeholder={`${t('public.input')} ${t('operator.mailbox')}`} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label={t('operator.mobile')}
                                name={"phone"}>
                                <Input disabled={!store.formEdit} autoComplete="off" allowClear
                                    placeholder={`${t('public.input')} ${t('operator.mobile')}`} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label={t('operator.Brand')}
                                name={"brandName"}
                                rules={[{
                                    required: true,
                                    message: `${t('public.input')} ${t('operator.Brand')}`
                                }]}>
                                <Input disabled={!store.formEdit} autoComplete="off" allowClear
                                    placeholder={`${t('public.input')} ${t('operator.Brand')}`} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label={t('operator.realBrandName')}
                                       name={"realBrandName"}
                                       rules={[{
                                           required: true,
                                           message: `${t('public.input')} ${t('operator.realBrandName')}`
                                       }]}>
                                <Input disabled={!store.formEdit} autoComplete="off" allowClear
                                       placeholder={`${t('public.input')} ${t('operator.realBrandName')}`} />
                            </Form.Item>
                        </Col>


                        {store.record.id && (
                            <Col span={10}>
                                <Form.Item label={t('operator.status')}
                                    labelCol={{ span: 6 }}
                                    rules={[{
                                        required: true,
                                        message: `${t('public.Select')} ${t('operator.status')}`
                                    }]}
                                    initialValue={store.record.status === 1}
                                    name={"status"}>
                                    <Radio.Group disabled={!store.formEdit}>
                                        <Radio value={1}>{t('public.effective')}</Radio>
                                        <Radio value={0}>{t('public.invain')}</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                        )}
                        <Col span={8}>
                            <Form.Item label={t('reward.Sand')}
                                labelCol={{ span: 6 }}
                                rules={[{
                                    required: true,
                                    message: `${t('public.Select')} ${t('reward.Sand')}`
                                }]}
                                name={"rtpSwitch"}>
                                <Radio.Group disabled={!store.formEdit}>
                                    <Radio value={1}>开启</Radio>
                                    <Radio value={0}>关闭</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>

                        {
                            false && <Col>
                                <Form.Item span={8}
                                    label="语&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;言"
                                    name={"languages"}>
                                    <Select disabled={!store.formEdit}
                                        style={{ width: "100%" }}
                                        allowClear
                                        placeholder="请选择语言"
                                        value={'en'}>
                                        {languages.map(item => {
                                            if (item.value != '') {
                                                return <Select.Option value={item.value}
                                                    key={item.label}>{item.label}</Select.Option>
                                            }
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                        }
                    </Row>

                    <Row>
                        <Col span={20}>
                            <Form.Item label={t('operator.ip')}
                                labelCol={{ span: 3 }}
                                name={"ip"}>
                                <Input disabled={!store.formEdit} autoComplete="off" allowClear
                                    placeholder={`${t('public.input')}${t('operator.ip')}`} />
                            </Form.Item>
                        </Col>
                    </Row>


                    <Row gutter={{ md: 8, lg: 24, xl: 48 }}>

                        <Col span={13}>
                            <Form.Item label={t('operator.address')}
                                labelCol={{ span: 5 }}
                                name={"apiUrl"}>
                                <Input disabled={!store.formEdit} autoComplete="off" allowClear
                                    placeholder={`${t('public.input')} ${t('operator.address')}`} />
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item label={t('operator.Enterprise')}
                                name={"officialWebsite"}>
                                <Input disabled={!store.formEdit} autoComplete="off" allowClear
                                    placeholder={`${t('public.input')} ${t('operator.Enterprise')}`} />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label={t('operator.Platform')}
                                labelCol={{ span: 3 }}
                                name="platformPubKey">
                                <Input.TextArea disabled={!store.formEdit} rows={5} autoComplete="off"
                                    allowClear
                                    placeholder={`${t('public.input')} ${t('operator.Platform')}`} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label={t('operator.private')}
                                labelCol={{ span: 3 }}
                                name="platformPriKey">
                                <Input.TextArea disabled={!store.formEdit} rows={5} autoComplete="off"
                                    allowClear
                                    placeholder={`${t('public.input')} ${t('operator.private')}`} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label={t('operator.Public')}
                                labelCol={{ span: 3 }}
                                name="pubKey">
                                <Input.TextArea disabled={!store.formEdit} rows={5} autoComplete="off" allowClear
                                    placeholder={t('operator.Publics')} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label={t('public.remark')}
                                labelCol={{ span: 3 }}
                                name="remark">
                                <Input.TextArea disabled={!store.formEdit} rows={5} autoComplete="off" allowClear
                                    placeholder={`${t('public.input')} ${t('operator.remark')}`} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>

        </Modal>)
})