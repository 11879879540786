import React, { useState, useEffect } from 'react'
import { LeftOutlined, SearchOutlined } from '@ant-design/icons'
import { Button, Select, message } from "antd"
import { getIntegralWarnRecord, getAllCurrency,  warnClose } from '@api'
import { TableCard } from '@components'
import { history } from '@http'
import { MyForm } from '@components'
import { get,t } from '@tool'
export default function () {
    const [loading, setLoading] = useState(false);
    const [records, setRecords] = useState([]);
    const [pageNum, setpageNum] = useState(1);
    const [pageSize, setpageSize] = useState(10);
    const [total, settotal] = useState(0);
    const [setCurrencyOptionValue, setSetCurrencyOption] = useState([]);
    const [companyOptionValue, setCompanyOption] = useState(JSON.parse(get('operatorList')));
    const pulldowns = [
        {
            label: '全部',
            value: ''
        },
        {
            label: '待处理',
            value: 0
        },
        {
            label: '已关闭',
            value: 1
        }
    ];
    useEffect(() => {
        onGetCurrencyList();
        fetchRecords();
    }, [])
    useEffect(() => {
        fetchRecords();
    }, [pageNum, pageSize])

    const fetchRecords = (val) => {
        const params = {
            pageNum: pageNum,
            pageSize: pageSize,
            ...val,
        }
        setLoading(true);
        getIntegralWarnRecord({ ...params }).then(res => {
            settotal(res.totalCount);
            setRecords(res.list);
        }).finally(() => setLoading(false));

    }

    const onHandle = (val) => {
        warnClose({ id: val.id }).then(res => {
            if (res === 1) {
                message.success(t('public.success'))
            } else {
                message.error(t('public.error'))
            }
            fetchRecords();
        }).catch(() => { message.error(t('public.error')) });
    }
    const handleTableChange = ({ current, pageSize }, filters, sorter) => {
        setpageNum(current)
        setpageSize(pageSize)
    }
    const columns = [
        { title: '预警时间', width: '50px', key: 'warnTime', dataIndex: 'warnTime', ellipsis: true },
        { title: '运营商', width: '50px', key: 'companyName', dataIndex: 'companyName', ellipsis: true },
        { title: '币种', width: '50px', key: 'currency', dataIndex: 'currency', ellipsis: true, },
        { title: '剩余积分', width: '50px', key: 'integral', dataIndex: 'integral', ellipsis: true, },
        { title: '联系方式', width: '50px', key: 'email', dataIndex: 'email', ellipsis: true },
        {
            title: t('user.status'),
            width: '6vh',
            key: 'warnStatus',
            dataIndex: 'warnStatus',
            ellipsis: true,
            render: (text) => {
                const rewt = pulldowns.find((item) => item.value === text)
                return rewt.label;
            }
        },
        { title: '处理时间', width: '50px', key: 'closeTime', dataIndex: 'closeTime', ellipsis: true },
        { title: '跟进人', width: '50px', key: 'followUser', dataIndex: 'followUser', ellipsis: true },
        {
            title: '操作', width: '30px', render: (_, record) => {
                return (

                    record.warnStatus == 0 && <Button
                        type={"primary"}
                        onClick={() => { onHandle(record) }}
                    >
                        处理
                    </Button>


                )
            },

        },
    ]

    const onFinish = (params) => {
        setRecords([])
        // 重置为第一页
        setpageNum(1)
        fetchRecords(params);
    }
    //获取币种
    const onGetCurrencyList = () => {
        if (setCurrencyOptionValue.length > 0) return;
        getAllCurrency().then(res => {
            const currencyList = res.map((item) => {
                return {
                    value: item.currency,
                    label: `${item.nameZh}(${item.currency})`
                }
            })
            setSetCurrencyOption(currencyList);
        }).catch(() => { message.error(t('public.error')) });
    }

    return (
        <div>
            <div style={{ background: '#fff' }}>             <Button onClick={() => history.goBack()}
                style={{ margin: '10px' }} type={"primary"}
                icon={<LeftOutlined />}> {t('public.Back')}</Button></div>
            <MyForm onFinish={onFinish} initialValues={{ warnStatus: '' }}>
                <MyForm.Item span={5} title={'币种'} name={"currency"}>
                    <Select
                        name={'currency'}
                        placeholder="请选择币种"
                        options={setCurrencyOptionValue}
                        allowClear
                        showSearch
                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                    />
                </MyForm.Item>
                <MyForm.Item span={5} title={'运营商'} name={"companyName"}>
                    <Select
                        name={'companyName'}
                        placeholder="请选择运营商"
                        options={companyOptionValue}
                        allowClear
                        showSearch
                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                    />
                </MyForm.Item>
                <MyForm.Item span={5} title={t('user.status')} name={"warnStatus"}>
                    <Select
                        name={'warnStatus'}
                 >
                        {pulldowns.map(item => {
                            return <Select.Option value={item.value} key={item.label}>{item.label}</Select.Option>;
                        })}
                    </Select>
                </MyForm.Item>

                <MyForm.Item span={3}>
                    <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>{t('public.inquire')}</Button>
                </MyForm.Item>
            </MyForm>

            <TableCard
                tableLayout={'fixed'}
                size={'small'}
                scroll={{
                    x: 1200,
                    y: '44vh'
                }}
                rowKey="id"
                title={'积分提醒'}
                loading={loading}
                // 后端的数据源
                dataSource={records}
                onReload={() => { fetchRecords() }}
                onChange={handleTableChange}
                actions={[]}
                // 分页器
                pagination={{
                    showSizeChanger: true,
                    showLessItems: true,
                    showTotal: total => t('public.intotal', total),
                    pageSizeOptions: ['10', '20', '50', '100'], // 如果不传 total，则以后端返回数据条数作为 total 的值
                    total: total, // 如果不传，则默认是第一条，如果需要默认显示第3条，则必须传
                    current: pageNum,
                    pageSize: pageSize,
                }}
                columns={columns} />
        </div>
    )
}
