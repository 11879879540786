import React from 'react'
import { observer } from 'mobx-react'
import { Space, Popconfirm } from 'antd'
import { DeleteOutlined, EditOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { TableCard, AuthButton, } from '@components'
import store from './gamepackStore'
import { pulldown, t } from '@tool'
const rowSelection = {
    onChange: (selectedRowKeys) => {
        store.setIds(selectedRowKeys)
    }, getCheckboxProps: (record) => ({
        disabled: record.id === 'Disabled Index', // Column configuration not to be checked
        name: record.id,
    }),
}
@observer
class ComTable extends React.Component {
    componentDidMount() {
        store.tableOptions = {
            ...store.tableOptions,
            status: '1',
            companyType: 'OPERATOR_COMPANY'

        }
        store.fetchRecords()
    }

    columns = [
        { title: t('Gamepack.name'), width: '120px', key: 'packetName',   ellipsis: true, dataIndex: 'packetName' },
        {
            title: t('Gamepack.status'), width: '40px', key: 'status', dataIndex: 'status',   ellipsis: true, render: (text) => {
                const filtered = pulldown("statusOptionsSon").filter(item => item.value === String(text));
                if (filtered.length > 0) {
                    return filtered[0].label;
                } else {
                    return '-'; // 或者返回其他默认值
                }
            }
        },
        { title: t('public.remark'), width: '120px', key: 'remark',   ellipsis: true, dataIndex: 'remark' },
        { title: t('Gamepack.time'), width: '100px', key: 'addTime',    ellipsis: true, dataIndex: 'addTime' },
        {  title: t('public.Controls'), fixed: 'right',width: '14vh',   key: 'id', render: (_, record) => {
                return (<Space size="middle">
                    <AuthButton
                        type={"primary"}
                        ghost
                        auth="game.packet.add"
                        icon={<PlusOutlined />}
                        onClick={() => store.showForm(record, false)}>
                        {t('public.Newgame')}
                    </AuthButton>
                    <AuthButton
                        type={"primary"}
                        ghost
                        auth="game.packet.edit"
                        icon={<EditOutlined />}
                        onClick={() => store.showForm(record, true)}>
                        {t('public.edit')}
                    </AuthButton>
                    <Popconfirm
                        title={t('public.sure')}
                        onConfirm={() => store.onDelete(record)}
                        okText={t('public.OK')}
                        cancelText={t('public.Cancel')}
                    >
                        <AuthButton
                            auth="game.packet.delete"
                            type={"primary"}
                            danger
                            ghost
                            icon={<DeleteOutlined />}>
                            {t('public.delete')}
                        </AuthButton>
                    </Popconfirm>
                </Space>)
            },
        },
    ]
    handleTableChange = ({ current, pageSize }, filters, sorter) => {
        store.pageNum = current
        store.pageSize = pageSize
        store.tableOptions = {
            ...store.tableOptions, // 排序：好像只支持单个排序
            sortField: sorter.field, sortOrder: sorter.order, ...filters
        }
        store.fetchRecords()
    }
    render() {
        return (
            <div style={{ background: '#fff' }}>
                <TableCard
                tableLayout={'fixed'}
                    size={'small'}
                    scroll={{
                        x: 1600,
                        y: '44vh'
                    }}
                    rowKey="id"
                    //多选
                    rowSelection={{
                        type: 'checkbox', ...rowSelection,
                    }}
                    title={t('Gamepack.packlist')}
                    loading={store.isFetching}
                    // 后端的数据源
                    dataSource={store.dataSource}
                    onReload={store.fetchRecords}
                    onChange={this.handleTableChange}
                    actions={[
                        <Popconfirm
                            title={`${t('public.Are')}${store.ids.length}?`}
                            open={store.conDelete}
                            onConfirm={() => store.onDelete(-1)}
                            onCancel={() => { store.confirmDelete(false) }}
                            okText={t('public.OK')}
                            cancelText={t('public.Cancel')}
                        >
                            <AuthButton
                                type="primary"
                                onClick={() => store.confirmDelete(true)}
                                icon={<MinusCircleOutlined />}
                                auth="game.packet.delete"
                            >
                        {t('public.Batchdeletion')}
                            </AuthButton>
                        </Popconfirm>,
                        <AuthButton
                            type="primary"
                            icon={<PlusOutlined />}
                            auth="game.packet.add"
                            onClick={() => store.showForm({}, true)}>
                               {t('public.Newgamepack')}
                        </AuthButton>,
                    ]}
                    // 分页器
                    pagination={{
                        showSizeChanger: true,
                        showLessItems: true,
                        showTotal: total => t('public.intotal',total) ,
                        pageSizeOptions: ['10', '20', '50', '100'], // 如果不传 total，则以后端返回数据条数作为 total 的值
                        total: store.total, // 如果不传，则默认是第一条，如果需要默认显示第3条，则必须传
                        current: store.pageNum,
                        pageSize: store.pageSize,
                    }}
                    columns={this.columns} />
            </div>

        )
    }
}

export default ComTable
