import React from "react"

import {SearchOutlined} from '@ant-design/icons'

import {Button, Input, Select} from "antd"

import store from "./AuthorityStore"
import ComTable from "./AuthorityTable"
import ComForm from "./AuthorityForm"

import { t,  pulldown } from '@tool'

import {MyForm} from '@components'

export default function UserIndex() {

    const onFinish = (params) => {
        // 重置为第一页
        store.setPageNum(1)
        store.tableOptions = params
        store.fetchRecords()
    }

    return (<div>
        <MyForm onFinish={onFinish} initialValues={{status: '1', companyType: ''}}>
            <MyForm.Item span={7} title={t('limit.name')} name={"authName"}>
                <Input allowClear name={'authName'} autoComplete="off"
                       placeholder={`${t('public.input')} ${t('limit.name') }`}/>
            </MyForm.Item>
            <MyForm.Item span={6} title={t('limit.status')} name={"status"}>
                <Select
                    name={'status'}
                    placeholder={`${t('public.Select')} ${t('limit.status') }`}>
                    { pulldown('statusOptionsSon').map(item => {
                        return <Select.Option value={item.value} key={item.label}>{item.label}</Select.Option>
                    })}
                </Select>
            </MyForm.Item>
            <MyForm.Item span={6}>
                <Button type="primary" htmlType="submit" icon={<SearchOutlined/>}>{t('public.inquire')}</Button>
            </MyForm.Item>
        </MyForm>
        <ComTable/>
        {/* formVisible 控制表单显示 */}
        <ComForm/>
    </div>)
}