import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Modal, Button, Input, Select, message } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { MyForm,TableCard } from '@components'
import store from './gamevendorsetupStore'
import storeson from './gamesetupsonStore'

import { gamesSet, gamestup } from '@api'
import { pulldown, t } from '@tool'
export default observer(function () {
    // loading 默认是 flase
    const [loading, setLoading] = useState(false)
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    //查询选中的
    const fetchGamestup = (e) => {
        gamestup(e).then(res => {
            storeson.setIds(res.gameCompanyIds)
            setSelectedRowKeys(res.gameCompanyIds)
        }).finally();
    }
    useEffect(() => {
        storeson.tableOptions = {
            ...storeson.tableOptions, companyType: 'GAME_COMPANY', status: 1
        }
        storeson.fetchRecords()
        if (store.id) {
            fetchGamestup(store.id)
        }
    }, [store.id])

    //请求接口
    const handleSubmit = () => {
        if (storeson.ids.length === 0) {
            message.error('请勾选要添的数据')
            return
        }
        const params = {
            companyId: store.id,
            gameCompanyIds: selectedRowKeys
        }
        setLoading(true)
        gamesSet(params).then(res => {
            console.log(res,"Res")
            if (res === '操作成功'){
                message.success(res)
            }else {
                message.error(res.msg);
            }
            storeson.setPageNum(1)
            storeson.setPageSize(10);
            storeson.fetchRecords();
            store.formVisible = false
            setLoading(false)

        }).finally(() => setLoading(false))

    }
    const onFinish = (params) => {
        // 重置为第一页
        storeson.setPageNum(1)
        params['companyType'] = 'GAME_COMPANY'
        storeson.tableOptions = params
        storeson.fetchRecords()
    }
    const handleTableChange = ({ current, pageSize }, filters, sorter) => {
        storeson.pageNum = current
        storeson.pageSize = pageSize
        storeson.tableOptions = {
            ...storeson.tableOptions, // 排序：好像只支持单个排序
            sortField: sorter.field, sortOrder: sorter.order, ...filters
        }
        storeson.fetchRecords()
    }
    const rowSelection = {
        selectedRowKeys,
        preserveSelectedRowKeys: true, 
        onChange: (val) => {
            setSelectedRowKeys(val)
            storeson.setIds(val)
        }, getCheckboxProps: (record) => ({
            disabled: record.id === 'Disabled Index', // Column configuration not to be checked
            name: record.id,
        }),
    }

    const columns = [
        {
            title: t('Gamebusiness.companyname'),
            width: '80px',
            key: 'companyName',
            dataIndex: 'companyName',
            ellipsis: true,
            fixed: 'left',
        },
        {
            title: t('Gamebusiness.status'),
            width: '50px',
            key: 'status',
            dataIndex: 'status',
            ellipsis: true,
            render: (text) => (text === 1) ? t('public.effective') : t('public.invain'),
        },
        {
            title: t('Gamebusiness.mailbox'), width: '80px', key: 'email', dataIndex: 'email', ellipsis: true,
        },
        {
            title: t('Gamebusiness.Background'), width: '100px', ellipsis: true, key: 'backOffice', dataIndex: 'backOffice',
        },
        {
            title: t('Gamebusiness.apiaddress'), width: '100px', key: 'apiUrl', dataIndex: 'apiUrl', ellipsis: true,
        },
        {
            title: t('public.remark'),
            width: '100px',
            key: 'remark',
            dataIndex: 'remark',
            ellipsis: true,
        },
    ]
    return (// Modal 对话框
        <Modal
            width={'60%'}
            forceRender={true}
            open={store.formVisible}
            title={'设置'}
            onCancel={() => store.formVisible = false}
            confirmLoading={loading}
            // ok 按钮 props
            onOk={handleSubmit}>
            <MyForm onFinish={onFinish} initialValues={{ status: '1' }}>
                <MyForm.Item span={8} title={t('Gamebusiness.companyname')} name={"companyName"}>
                    <Input allowClear name={'companyName'} id={'gameCompanyName'} autoComplete="off"
                        placeholder={`${t('public.input')} ${t('Gamebusiness.companyname')}`} />
                </MyForm.Item>
                <MyForm.Item span={8} title={t('Gamebusiness.status')} name={"status"}>
                    <Select id={'gameStatus'}
                        name={'status'}
                        value={''}>
                        {pulldown('statusOptionsSon').map(item => {
                            return <Select.Option value={item.value} key={item.label}>{item.label}</Select.Option>
                        })}
                    </Select>
                </MyForm.Item>
                <MyForm.Item span={8}>
                    <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>{t('public.inquire')}</Button>
                </MyForm.Item>
            </MyForm>

            <TableCard
                tableLayout={'fixed'}
                scroll={{
                    x: 1500,
                    y: '38vh'
                }}
                size={'small'}
                rowKey="id"
                rowSelection={{
                    type: 'checkbox', ...rowSelection,
                }}
                title={t('Carriergame.carrierList')}
                loading={storeson.isFetching}
                // 后端的数据源
                dataSource={storeson.dataSource}
                onReload={storeson.fetchRecords}
                onChange={handleTableChange}
                actions={[]}
                // 分页器
                pagination={{
                    showSizeChanger: true,
                    showLessItems: true,
                    showTotal: total => t('public.intotal', total),
                    pageSizeOptions: ['10', '20', '50', '100'], // 如果不传 total，则以后端返回数据条数作为 total 的值
                    total: storeson.total, // 如果不传，则默认是第一条，如果需要默认显示第3条，则必须传
                    current: storeson.pageNum,
                    pageSize: storeson.pageSize,
                }}
                columns={columns} />
        </Modal>)
})