import { post } from "@http"

// 添加ip
export function addWhiteIp(params) {
    return post("/manager/ip/save", params)
}

// 添加ip
export function updateWhiteIp(params) {
    return post("/manager/ip/update", params)
}

// 删除ip
export function removeWhiteIp(params) {
    return post("/manager/ip/remove", params)
}

// 查询ip列表
export function getWhiteIpList(params) {
    return post("/manager/ip/getList", params)
}

// 同步WAF中ip
export function syncIp(params) {
    return post("/manager/ip/sync", params)
}