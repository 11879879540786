import React, { useEffect, useState } from 'react'

import { observer } from 'mobx-react'
import { Modal, Form, Input, message, Row, Col, Select } from 'antd'
import { CompanyList, pulldown, t, log } from '@tool'
import store from './CompanyStore'

import { addCompany, modifyCompany } from '@api'

export default observer(function () {
    // 文档中未找到这种解构使用方法
    const [form] = Form.useForm()
    // useState 函数组件中使用 state
    // loading 默认是 flase
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        form.resetFields()
        if (store.record.id) {
            form.setFieldsValue(store.record)
        }

    }, [store.record])

    const handleSubmit = () => {
        form.submit()
    }

    const onFinish = (values) => {
        setLoading(true)
        if (store.record.id) {
            values['id'] = store.record.id
            modifyCompany(values).then(res => {
                if (res.status === 10000) {
                    store.formVisible = false
                    store.fetchRecords()
                    //运营商
                    CompanyList()
                } else {
                    message.error(t('company.failure'))
                }
            }).finally(() => setLoading(false))
        } else {
            addCompany(values).then(res => {
                if (res.status === 10000) {
                    store.formVisible = false
                    store.fetchRecords()
                    //运营商
                    CompanyList()
                }
            }).finally(() => setLoading(false))
        }
    }

    return (// Modal 对话框
        <Modal
            width={'54%'}
            forceRender={true}
            open={store.formVisible}
            title={store.formTitle}
            onCancel={() => store.formVisible = false}
            confirmLoading={loading}
            // ok 按钮 props
            onOk={handleSubmit}>
            <Form form={form} labelCol={{ span: 7 }} onFinish={onFinish}>
                <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
                    <Col span={12}>
                        <Form.Item label={t('company.companyname')}
                            rules={[{ required: true, message: `${t('public.input')}${t('company.companyname')}` }]}
                            name={"companyName"}>
                            <Input allowClear autoComplete="off" disabled={!store.formEdit}
                                placeholder={`${t('public.input')}${t('company.companyname')}`} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={t('company.Type')}
                            rules={[{ required: true, message: `${t('public.Select')}${t('company.Type')}` }]}
                            name={"companyType"}>
                            <Select disabled={!store.formEdit}
                                style={{ width: "100%" }}
                                allowClear
                                placeholder={`${t('public.Select')}${t('company.Type')}`}
                                value={'COMPANY'}>
                                {pulldown('companyTypeOptionsSon').map(item => {
                                    if (item.value != '') {
                                        return <Select.Option value={item.value}
                                            key={item.label}>{item.label}</Select.Option>
                                    }
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={t('company.mailbox')}
                            rules={[{ required: true, message: `${t('public.input')}${t('company.mailbox')}` }]}
                            name={"email"}>
                            <Input disabled={!store.formEdit} autoComplete="off" allowClear placeholder={`${t('public.input')}${t('company.mailbox')}`} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={t('company.mobile')}
                            name={"phone"}>
                            <Input disabled={!store.formEdit} autoComplete="off" allowClear placeholder={`${t('public.input')}${t('company.mobile')}`} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={t('company.website')}
                            name={"officialWebsite"}>
                            <Input disabled={!store.formEdit} autoComplete="off" allowClear
                                placeholder={`${t('public.input')}${t('company.website')}`} />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            labelCol={{ span: 2 }}
                            label={t('public.remark')}
                            name="remark">
                            <Input.TextArea rows={5} disabled={!store.formEdit} autoComplete="off" />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>)
})