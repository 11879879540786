import {apiGameHost} from '@tool'

export const startGame = apiGameHost + '/game-test/startGame-test'

export const getWalletTest = apiGameHost + '/game-test/getWallet-test'

export const transferInTest = apiGameHost + '/game-test/transferIn-test'

export const transferOutTest = apiGameHost + '/game-test/transferOut-test'

export const regGameUserTest = apiGameHost + '/game-test/regGameUserTest'

export const apiSyncGameInfo = apiGameHost + '/api/game/syncGameInfo'