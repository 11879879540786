import {observable, computed, action} from 'mobx'
import {message} from "antd"

import axios from 'axios'

import copy from 'copy-to-clipboard';

import {oneKeyOperator, getOpenOperator, getOpenOperatorList} from '@api'

import {log, t, host, get} from '@tool'

const mimeMap = {
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', zip: 'application/zip'
}

class OpenOperatorStore {
    @observable formVisible = false

    @observable formTitle = '开户列表'

    @observable record = {}

    @observable records = []

    // 默认第1页
    @observable pageNum = 1
    // 每页多少条
    @observable pageSize = 10
    // 总共多少页
    @observable total = ''

    // 其他参数，例如排序、过滤等等
    @observable tableOptions = {}

    @observable isFetching = false

    @computed get dataSource() {
        return this.records
    }

    _getTableParams = () => ({pageNum: this.pageNum, pageSize: this.pageSize, ...this.tableOptions})

    @action setPageNum(val) {
        this.pageNum = val
    }

    @action setPageSize(val) {
        this.pageSize = val
    }

    fetchRecords = () => {
        const params = this._getTableParams()

        this.isFetching = true

        getOpenOperatorList(params).then(res => {
            this.total = res.totalCount
            this.records = res.list
        }).finally(() => this.isFetching = false)
    }

    // 查询详情
    onDetail = (val) => {
        getOpenOperator(val).then(res => {
            this.record = res;
        }).finally(() => this.isFetching = false);
    }

    // 显示新增弹框
    showForm = (info = {}) => {
        this.formVisible = true

        this.formTitle = '查看开户详情'

        if (info.id) {
            this.onDetail(info.id);
        }
    }

    handleClickRow = (record) => {
        return {
            onDoubleClick: () => {
                this.showForm(record)
            }
        }
    }

    oneKeyOperator = (record) => {
        this.isFetching = true

        oneKeyOperator(record.id).then(res => {
            if (res.status === 10000) {
                message.success("开户成功")
            }
        }).finally(() => this.isFetching = false)
    }

    onDownload = (record) => {
        this.isFetching = true

        let url = host + 'ctgameclub/manager/openOperator/downloadAuthorize'

        axios({
            method: 'post',
            url: url,
            responseType: 'blob',
            data: record.id,
            headers: {
                'Content-Type': 'application/json; application/octet-stream',
                'X-Token': get('token')
            }
        }).then(res => {
            this.resolveBlob(res, mimeMap.zip)
        }).finally(() => this.isFetching = false)
    }

    copy = (e) => {
        copy(e.target.innerText)
        message.success(t('public.copy'))
    }

    /**
     * 解析blob响应内容并下载
     * @param {*} res blob响应内容
     * @param {String} mimeType MIME类型
     */
    resolveBlob = (res, mimeType) => {
        log(res)
        const blob = new Blob([res.data]);
        const downloadElement = document.createElement("a");
        const href = window.URL.createObjectURL(blob);

        let patt = new RegExp('fileName=([^;]+\\.[^\\.;]+);*')

        let contentDisposition = decodeURI(res.headers['content-disposition'])

        let result = patt.exec(contentDisposition)
        let fileName = result[1]

        fileName = fileName.replace(/\"/g, '')

        //后台再header中传文件名
        const name = fileName;
        downloadElement.href = href;
        downloadElement.download = name;
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement); // 下载完成移除元素
        window.URL.revokeObjectURL(href); // 释放掉blob对象
    }
}

export default new OpenOperatorStore()