import { observable, computed, action } from 'mobx'

import copy from 'copy-to-clipboard';

import { getBettingLogHistoryList, exportTransferList } from '@api'
import { t, getHistoryDaysAgoFromDate, Datetime, get } from '@tool'
import { message } from "antd";

class UserStore {
    @observable formVisible = false


    @observable record = {}

    @observable records = []

    // 默认第1页
    @observable pageNum = 1
    // 每页多少条
    @observable pageSize = 10
    // 总共多少页
    @observable total = ''
    @observable startTime = getHistoryDaysAgoFromDate(Datetime())
    @observable endTime = Datetime()
    // 其他参数，例如排序、过滤等等
    @observable tableOptions = {}

    @observable isFetching = false
    @observable playerNameSearchType = 'eq'

    @computed get dataSource() {
        return this.records
    }

    _getTableParams = () => ({
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        ...this.tableOptions
    })

    @action setPageNum(val) {
        this.pageNum = val
    }

    @action setPageSize(val) {
        this.pageSize = val
    }

    fetchRecords = () => {
        const params = this._getTableParams()
        this.isFetching = true
        params.languages = get('Language');
        params.company_id = get('userType') == 'PLATFORM' ? get('companyId') : params.company_id

        getBettingLogHistoryList(params).then(res => {
            this.total = res.totalCount
            this.records = res.list
        }).finally(() => this.isFetching = false)
    }

    copy = (e) => {
        copy(e.target.innerText)
        message.success(t('public.copy'))
    }

    exportBet = () => {
        const params = this._getTableParams();
        this.isFetching = true;
        params.startTime = params.startTime ? params.startTime : this.startTime;
        params.endTime = params.endTime ? params.endTime : this.endTime;
        params.exportType = 1;
        
        exportTransferList(params)
            .then(downloadUrl => {
                if (downloadUrl.endsWith('.xlsx')) {
                    // 创建一个<a>标签
                    const a = document.createElement('a');
                    a.href = downloadUrl;
                    a.download = "";  // download属性确保直接下载
                    a.style.display = 'none';  // 隐藏a标签

                    // 将标签添加到DOM中，然后触发点击事件
                    document.body.appendChild(a);
                    a.click();

                    // 移除a标签
                    document.body.removeChild(a);
                } else {
                    message.error(downloadUrl);
                }

            })
            .catch(error => {
                message.error("导出失败", error);
            })
            .finally(() => this.isFetching = false);
    }

}

export default new UserStore()