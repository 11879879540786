import React from 'react'
import { observer } from 'mobx-react'
import { Space, Popconfirm, Modal, Input, message } from 'antd'
import { DeleteOutlined, EditOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { TableCard, AuthButton, } from '@components'
import store from './UserStore'
import { login, restPwd } from '@api'
import { get, encryptPassword, t, pulldown } from '@tool'

const rowSelection = {
    onChange: (selectedRowKeys) => {
        store.setIds(selectedRowKeys)
    }, getCheckboxProps: (record) => ({
        disabled: record.id === 'Disabled Index', // Column configuration not to be checked
        name: record.id,
    }),
}

@observer
class ComTable extends React.Component {

    componentDidMount() {
        store.tableOptions = {
            ...store.tableOptions, status: 1
        }
        store.fetchRecords()
    }

    columns = [
     
        { title: t('user.account'),  width: '2vh', key: 'loginName', dataIndex: 'loginName', ellipsis: true, }
        ,
        {
            title: t('user.nickname'),
            width: '7vh',
            key: 'nickName',
            dataIndex: 'nickName', ellipsis: true,
        }, {
            title: t('user.status'),
            width: '6vh',
            key: 'status',
            dataIndex: 'status',
            ellipsis: true,
            render: (text) => (text === 1) ? t('public.effective') : t('public.invain'),
        },
        { title: t('user.mailbox'), width: '10vh', key: 'email', dataIndex: 'email', ellipsis: true, },
        {
            title: t('user.Usertype'), width: '8vh', key: 'userType', dataIndex: 'userType', ellipsis: true, render: (text) => {
                const filtered = pulldown("userTypeOptionsSon").filter(item => item.value === text);
                if (filtered.length > 0) {
                    return filtered[0].label;
                } else {
                    return '-'; // 或者返回其他默认值
                }
            }
        }, {
            title: t('public.Controls'), fixed: 'right', width: '14vh', key: 'id', render: (_, record) => {
                return (<Space size="middle">
                    <AuthButton type={"primary"} ghost icon={<EditOutlined />}
                        onClick={() => this.props.setPwdVisible(record)} auth="user.edit">{t('public.Change')}</AuthButton>
                    {
                        'ADMIN' == get("userType") && String(record.id) !== get("id") &&
                        <AuthButton type={"primary"} ghost icon={<EditOutlined />}
                            onClick={() => this.onResetpassword(record)} auth="user.rest_pwd">{t('public.Reset')}</AuthButton>
                    }
                    <AuthButton type={"primary"} ghost icon={<EditOutlined />}
                        onClick={() => store.showForm(record, true)} auth="user.edit">{t('public.edit')}</AuthButton>

                    {String(record.id) !== get("id") &&
                        <Popconfirm
                            title={t('public.sure')}
                            onConfirm={() => store.onDelete(record)}
                            okText={t('public.OK')}
                            cancelText={t('public.Cancel')}
                        >
                            <AuthButton
                                type={"primary"}
                                danger
                                ghost
                                icon={<DeleteOutlined />}
                                auth="user.delete">
                                {t('public.delete')}
                            </AuthButton>
                        </Popconfirm>

                    }
                </Space>
                )

            },
        },]

    handleTableChange = ({ current, pageSize }, filters, sorter) => {
        store.pageNum = current
        store.pageSize = pageSize
        store.tableOptions = {
            ...store.tableOptions, // 排序：好像只支持单个排序
            sortField: sorter.field, sortOrder: sorter.order, ...filters
        }
        store.fetchRecords()
    }
    onResetpassword = (record) => {
        store.updateStatus(true, record)
    }
    onSide = (row) => {
        store.rowval = row
    }
    onRestpwd = () => {
        restPwd({ id: store.record.id }).then(res => {
            if (10000) {
                store.processing = res;
                message.success(t('user.The')`${store.processing}`)
                return
            }
            message.error(res.msg)
        }).catch((error) => {
            message.error(error)
        })
    }
    //调用登录接口验证
    handleOk = () => {
        if (store.processing !== '') {
            message.error(t('user.reset'))
            return
        }
        if (!store.rowval) {
            message.error(t('user.your'))
            return
        }
        const dispose = encryptPassword(store.rowval, '0000018c3896b653000000007e0login')
        const params = {
            loginName: get('loginName'),
            loginPwd: dispose,
        }
        login(params).then(res => {
            if (res.status === 10003) {
                message.error(t('user.worderror'))
                return
            }
            this.onRestpwd()
        }).catch((error) => {
            message.error(error)
        })
    }

    render() {
        return (
            <div>
                <TableCard
                    tableLayout={'fixed'}
                    size={'small'}
                    scroll={{
                        x: 1800,
                        y: '38vh'
                    }}
                    handleClickRow={store.handleClickRow}
                    rowKey="id"
                    rowSelection={ {
                        type: 'checkbox', ...rowSelection,
                    }}
                    title={t('user.Userlist')}
                    loading={store.isFetching}
                    // 后端的数据源
                    dataSource={store.dataSource}
                    onReload={store.fetchRecords}
                    onChange={this.handleTableChange}
                    actions={[
                        <Popconfirm
                            title={`${t('public.Are', store.ids.length)} ?`}
                            open={store.conDelete}
                            onConfirm={() => store.onDelete(-1)}
                            onCancel={() => { store.confirmDelete(false) }}
                            okText={t('public.OK')}
                            cancelText={t('public.Cancel')}
                        >
                            <AuthButton
                                type="primary"
                                onClick={() => store.confirmDelete(true)}
                                icon={<MinusCircleOutlined />}
                                auth="user.delete"
                            >
                                {t('public.Batchdeletion')}
                            </AuthButton>
                        </Popconfirm>
                        ,
                        <AuthButton
                            type="primary"
                            icon={<PlusOutlined />}
                            auth="user.add"
                            onClick={() => store.showForm({}, true)}>
                            {t('public.new')}
                        </AuthButton>]}
                    // 分页器
                    pagination={{
                        showSizeChanger: true,
                        showLessItems: true,
                        showTotal: total => t('public.intotal', total),
                        pageSizeOptions: ['10', '20', '50', '100'], // 如果不传 total，则以后端返回数据条数作为 total 的值
                        total: store.total, // 如果不传，则默认是第一条，如果需要默认显示第3条，则必须传
                        current: store.pageNum,
                        pageSize: store.pageSize,
                    }}
                    columns={this.columns} />

                <Modal title={t('user.authen')} open={store.ResetPassword} onOk={this.handleOk} onCancel={() => { store.updateStatus(false, {}) }}>
                    <Input.Password disabled={store.processing !== ''} onChange={(e) => (this.onSide(e.target.value))} allowClear placeholder={t('user.your')} />
                    {store.processing &&
                        <div style={{ marginTop: '20px' }}>
                            <label >{t('user.The')}</label>
                            <Input style={{ marginTop: '5px' }} readOnly value={store.processing} />
                        </div>
                    }
                </Modal>
            </div>)
    }
}

export default ComTable
