import React from 'react'

import {observer} from 'mobx-react'
import {Space,} from 'antd'
import {
    FileSearchOutlined, PlusOutlined, CloudDownloadOutlined
} from '@ant-design/icons'

import {TableCard, AuthButton,} from '@components'

import store from './OpenOperatorStore'

import {log, t} from '@tool'

@observer
class ComTable extends React.Component {

    componentDidMount() {
        store.tableOptions = {
            ...store.tableOptions, status: 1
        }
        store.fetchRecords()
    }

    columns = [
        {title: '商务姓名', width: '80px', key: 'saleUserName', dataIndex: 'saleUserName', ellipsis: true,},
        {title: "商户编号", width: '60px', key: 'companyNumber', dataIndex: 'companyNumber', ellipsis: true},
        {title: '公司名称', width: '80px', key: 'companyName', dataIndex: 'companyName', ellipsis: true,},
        {title: 'email', width: '60px', key: 'email', dataIndex: 'email', ellipsis: true},
        {
            title: '钱包模式', width: '60px', key: 'walletMode', dataIndex: 'walletMode', ellipsis: true,
            render: (text) => (text === 'single') ? '单一钱包' : '转账钱包',
        },
        {title: '申请时间', width: '80px', key: 'auditTime', dataIndex: 'auditTime', ellipsis: true,},
        {title: '开户时间', width: '80px', key: 'openTime', dataIndex: 'openTime', ellipsis: true,},
        {
            title: t('public.Controls'), fixed: 'right', width: '100px', key: 'id', render: (_, record) => {
                return (<Space size="middle">
                    <AuthButton
                        type={"primary"}

                        icon={<FileSearchOutlined/>}
                        auth="open.operator.view"
                        onClick={() => store.showForm(record)}>
                        查看详情
                    </AuthButton>
                    {record.isOpen === 0 ?
                        <AuthButton
                            type={"primary"}
                            auth="open.operator.edit"
                            ghost
                            onClick={() => store.oneKeyOperator(record)}
                            icon={<PlusOutlined/>}>
                            一键开户
                        </AuthButton>
                        :
                        <AuthButton
                            type={"primary"}
                            auth="open.operator.edit"
                            ghost
                            onClick={() => store.onDownload(record)}
                            icon={<CloudDownloadOutlined/>}>
                            下载授权
                        </AuthButton>
                    }
                </Space>)
            },
        },]

    handleTableChange = ({current, pageSize}, filters, sorter) => {
        store.pageNum = current
        store.pageSize = pageSize
        store.tableOptions = {
            ...store.tableOptions, // 排序：好像只支持单个排序
            sortField: sorter.field, sortOrder: sorter.order, ...filters
        }
        store.fetchRecords()
    }

    state = {
        inputValue: '', // 输入框的值
        modalVisible: false, // 控制模态框的显示隐藏
    };

    render() {
        return (<TableCard
            tableLayout={'fixed'}
            size={'small'}
            scroll={{
                x: 1500,
                y: '38vh'
            }}
            handleClickRow={store.handleClickRow}
            title={'开户列表'}
            loading={store.isFetching}
            // 后端的数据源
            dataSource={store.dataSource}
            onReload={store.fetchRecords}
            onChange={this.handleTableChange}

            // 分页器
            pagination={{
                showSizeChanger: true,
                showLessItems: true,
                showTotal: total => t('public.intotal', total),
                pageSizeOptions: ['10', '20', '50', '100'], // 如果不传 total，则以后端返回数据条数作为 total 的值
                total: store.total, // 如果不传，则默认是第一条，如果需要默认显示第3条，则必须传
                current: store.pageNum,
                pageSize: store.pageSize,
            }}
            columns={this.columns}/>)
    }
}

export default ComTable
