import React, { useEffect, useState } from 'react'
import { CompanyList } from '@tool'
import { observer } from 'mobx-react'
import { Modal, Form, message, Row, Col, Table } from 'antd'

import store from './OperatorStore'
import { t } from '@tool'

import { addCompanyDetail, modifyCompanyDetail } from '@api'

export default observer(function () {
    // 文档中未找到这种解构使用方法
    const [form] = Form.useForm()
    // useState 函数组件中使用 state
    // loading 默认是 flase
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        form.resetFields()
        if (store.record.id) {
            form.setFieldsValue(store.record)
        }
    }, [store.record])

    const handleSubmit = () => {
        form.submit()
    }

    const columns = [
        { title: '游戏商名称', dataIndex: 'gameCompanyName' },
        { title: '币种', dataIndex: 'currency' },
        { title: '点位', dataIndex: 'price' }
    ];

    const onFinish = (values) => {
        setLoading(true)
        if (store.record.id) {
            values['id'] = store.record.id
            if (store.record.companyDetailId) {
                values['companyDetailId'] = store.record.companyDetailId
            }

            modifyCompanyDetail(values).then(res => {
                if (res.status === 10000) {
                    store.formVisible = false
                    store.fetchRecords()
                    //运营商
                    CompanyList()
                } else {
                    message.error("保存失败")
                }
            }).finally(() => setLoading(false))
        } else {
            addCompanyDetail(values).then(res => {
                if (res.status === 10000) {
                    store.formVisible = false
                    store.fetchRecords()
                    //运营商
                    CompanyList()
                }
            }).finally(() => setLoading(false))
        }
    }
    console.log(store.record);
    return (// Modal 对话框
        <Modal
            width={'88%'}
            forceRender={true}
            open={store.formVisible}
            title={store.formTitle}
            onCancel={() => store.formVisible = false}
            confirmLoading={loading}
            // ok 按钮 props
            onOk={handleSubmit}
            style={{
                top: '3vh',
            }}
        >
            <Form form={form} onFinish={onFinish} labelCol={{ span: 8 }}
                initialValues={{ languages: 'en', walletMode: 'single', rtpSwitch: 0 }}>
                <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
                    <Col span={8}>
                        <Form.Item label={t('operator.Company')} name={'companyName'}>
                            {store.record.companyName}
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={t('operator.mailbox')} name={'email'}>
                            {store.record.email}
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
                <Col span={24}>
                    <Table columns={columns} dataSource={store.tableRecords}></Table>
                </Col>
            </Row>
        </Modal>)
})