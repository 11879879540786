import React, {useEffect, useState} from 'react'

import {observer} from 'mobx-react'
import {Modal, Form, Radio, Input, message, Row, Col, Checkbox} from 'antd'

import store from './AuthorityStore'
import {log, t} from '@tool'

import {addAuthority, modifyAuthority} from '@api'

export default observer(function () {
    // 文档中未找到这种解构使用方法
    const [form] = Form.useForm()
    // useState 函数组件中使用 state
    // loading 默认是 flase
    const [loading, setLoading] = useState(false)
    const [isParent, setIsParent] = useState(1)

    useEffect(() => {
        form.resetFields()
        if (store.record.id) {
            form.setFieldsValue(store.record)
            setIsParent(store.record.isParent)
        }
    }, [store.record])

    const handleSubmit = () => {
        form.submit()
    }

    const onFinish = (values) => {
        setLoading(true)

        log(values)

        if (store.record.id) {
            values['id'] = store.record.id
            values['oldAuthKey'] = store.record.authKey

            modifyAuthority(values).then(res => {
                if (res.status === 10000) {
                    store.formVisible = false
                    store.fetchRecords()
                } else {
                    message.error(t('limit.failure'))
                }
            }).finally(() => setLoading(false))
        } else {
            addAuthority(values).then(res => {
                if (res.status === 10000) {
                    store.formVisible = false
                    store.fetchRecords()
                }
            }).finally(() => setLoading(false))
        }
    }

    const isParentOnChange = (e) => {
        setIsParent(e.target.value)
    }

    const authKeOnBlur = (e) => {
        let value = e.target.value
        log(value)
    }

    return (// Modal 对话框
        <Modal
            width={'54%'}
            forceRender={true}
            open={store.formVisible}
            title={store.formTitle}
            onCancel={() => store.formVisible = false}
            confirmLoading={loading}
            // ok 按钮 props
            onOk={handleSubmit}>

            <Form form={form} onFinish={onFinish}>
                <Row gutter={{md: 8, lg: 24, xl: 48}}>
                    <Col>
                        <Form.Item span={6} label={t('limit.name')}
                                   rules={[{required: true, message: `${t('public.input')} ${t('limit.name')}`}]}
                                   name={"authName"}>
                            <Input allowClear autoComplete="off" disabled={!store.formEdit}
                                   placeholder={`${t('public.input')} ${t('limit.name')}`}/>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item span={6} label={t('limit.KEY')}
                                   rules={[{required: true, message: `${t('public.input')} ${t('limit.KEY')}`}]}
                                   name={"authKey"}>
                            <Input allowClear autoComplete="off" disabled={!store.formEdit}
                                   placeholder={`${t('public.input')} ${t('limit.KEY')}`} onBlur={authKeOnBlur}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={{md: 8, lg: 24, xl: 48}}>
                    <Col style={{width: '49%'}}>
                        <Form.Item span={6} label={t('limit.Parent')} rules={[{required: true}]}
                                   initialValue={1} name={"isParent"}>
                            <Radio.Group disabled={store.record.id}
                                         onChange={isParentOnChange}>
                                <Radio value={1}>{t('public.Yes')}</Radio>
                                <Radio value={0}>{t('public.No')}</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                    <Col>
                        {isParent == 1 ? '' : <Form.Item span={6}
                                                         label={t('limit.ID')}
                                                         name={"authorityId"}>
                            <Input disabled={!store.formEdit} autoComplete="off" allowClear
                                   placeholder={`${t('public.input')} ${t('limit.ID')}`}/>
                        </Form.Item>}
                    </Col>
                </Row>
                {store.record.id && (<Row gutter={{md: 8, lg: 24, xl: 48}}>
                    <Col span={11}>
                        <Form.Item span={6} label={t('limit.status')}
                                   rules={[{required: true, message: `${t('public.Select')} ${t('limit.status')}`}]}
                                   initialValue={store.record.status === 1}
                                   name={"status"}>
                            <Radio.Group disabled={!store.formEdit}>
                                <Radio value={1}>{t('public.effective')}</Radio>
                                <Radio value={0}>{t('public.invain')}</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>)}
                {isParent == 0 ? '' : <Row gutter={{md: 8, lg: 24, xl: 48}}>
                    <Col style={{width: 664}}>
                        <Form.Item label={t('limit.Permission')} name={'authList'}
                                   rules={[{required: true, message: `${t('public.input')} ${t('limit.Permission')}`}]}>
                            <Checkbox.Group disabled={!store.formEdit}>
                                <Checkbox value={'view'}>{t('public.view')}</Checkbox>
                                <Checkbox value={'add'}>{t('public.new')}</Checkbox>
                                <Checkbox value={'edit'}>{t('public.edit')}</Checkbox>
                                <Checkbox value={'delete'}>{t('public.delete')}</Checkbox>
                                <Checkbox value={'import'}>{t('public.lead')}</Checkbox>
                                <Checkbox value={'export'}>{t('public.derive')}</Checkbox>
                            </Checkbox.Group>
                        </Form.Item>
                    </Col>
                </Row>}
            </Form>
        </Modal>)
})