import React, {useState } from "react"

import { SearchOutlined } from '@ant-design/icons'

import { Button, Input, DatePicker, Select } from "antd"
import locale from 'moment/locale/zh-cn'
import moment from "moment/moment";
import store from "./OperateLogStore"
import ComTable from "./OperateLogTable"
import ComForm from "./OperateLogForm"
import { MyForm } from '@components'
import { t } from '@tool'
const dateFormat = 'YYYY-MM-DD HH:mm:ss';

export default function OperateLogIndex() {

    const [startTime, setStartTime] = useState('')
    const [endTime, setEndTime] = useState('')

    const onFinish = (params) => {
        if (startTime) {
            params['startTime'] = moment(startTime).format('YYYY-MM-DD HH:mm:ss')
        }
        if (endTime) {
            params['endTime'] = moment(endTime).format('YYYY-MM-DD HH:mm:ss')
        }
        // 重置为第一页
        store.setPageNum(1)
        store.tableOptions = params
        store.fetchRecords()
    }

    // 结束时间选择
    const disabledRegistrationEndDate = (endDate) => {
        return endDate < moment(startTime);
    }

    return (<div>
        <MyForm onFinish={onFinish} labelCol={{ span: 7 }} initialValues={{ status: '' }}>
            <MyForm.Item span={6} title={t('Operationlog.type')} name={"logType"}>
                <Input allowClear autoComplete="off" placeholder={`${t('public.input')} ${t('Operationlog.type')}`} />
            </MyForm.Item>
            <MyForm.Item span={6} title={t('Operationlog.content')} name={"optParam"}>
                <Input allowClear autoComplete="off" placeholder={`${t('public.input')} ${t('Operationlog.content')}`} />
            </MyForm.Item>
            <MyForm.Item span={6} title={t('Operationlog.Operating')} name={"userName"}>
                <Input allowClear autoComplete="off" placeholder={`${t('public.input')} ${t('Operationlog.Operating')}`} />
            </MyForm.Item>
            <MyForm.Item span={6} title={t('Operationlog.status')} name={"status"}>
                <Select>
                    <Select.Option value={''}>{t('Operationlog.All')}</Select.Option>
                    <Select.Option value={'1'}>{t('Operationlog.Normal')}</Select.Option>
                    <Select.Option value={'0'}>{t('Operationlog.Abnormal')}</Select.Option>
                </Select>
            </MyForm.Item>
            <MyForm.Item span={6} title={t('Operationlog.Start')}>
                <DatePicker style={{ width: '100%' }} locale={locale}
                    format={dateFormat}
                    onChange={(value) => setStartTime(value)}
                    showTime
                />
            </MyForm.Item>
            <MyForm.Item span={6} title={t('Operationlog.End')}>
                <DatePicker style={{ width: '100%' }} locale={locale}
                    disabledDate={disabledRegistrationEndDate}
                    format={dateFormat}
                    onChange={(value) => setEndTime(value)}
                    showTime
                />
            </MyForm.Item>
            <MyForm.Item span={4}>
                <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>{t('public.inquire')}</Button>
            </MyForm.Item>
        </MyForm>
        <ComTable />
        <ComForm />
    </div>)
}