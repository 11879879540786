import { observable, computed, action } from 'mobx'
import { message } from "antd"
import copy from 'copy-to-clipboard';
import {X_TOKEN} from '@tool/http/functools'
import axios from 'axios';

import { getCompanyList, batchStatusCompany, dwonloadGameIcon, syncGameIcon } from '@api'

import { t } from '@tool'

class CompanyStore {
    @observable formVisible = false

    @observable formEdit = false

    @observable formTitle = t('company.Viewcompany')

    @observable record = {}

    @observable records = []

    // 默认第1页
    @observable pageNum = 1
    // 每页多少条
    @observable pageSize = 10
    // 总共多少页
    @observable total = ''
    //二次确认删除
    @observable conDelete = false

    @observable ids = ''

    // 其他参数，例如排序、过滤等等
    @observable tableOptions = {}

    @observable isFetching = false

    @computed get dataSource() {
        return this.records
    }

    _getTableParams = () => ({ pageNum: this.pageNum, pageSize: this.pageSize, ...this.tableOptions, companyType: 'GAME_COMPANY' })

    @action setPageNum(val) {
        this.pageNum = val
    }

    @action setPageSize(val) {
        this.pageSize = val
    }

    @action setIds(ids) {
        this.ids = ids
    }

    fetchRecords = () => {
        const params = this._getTableParams()

        this.isFetching = true

        getCompanyList(params).then(res => {
            this.total = res.totalCount
            this.records = res.list
        }).finally(() => this.isFetching = false);
    }

    onDelete = (record) => {
        let id = []
        if (record !== -1) {
            id.push(record.id)
        } else {
            id = this.ids
        }
        this.isFetching = true
        let params = { 'ids': id, 'status': 0 }

        batchStatusCompany(params).then(res => {
            if (res.status === 10000) {
                this.setPageNum(1)
                this.setPageSize(10)
                this.fetchRecords()
                this.conDelete = false
            }
        }).finally(() => this.isFetching = false)
    }

    // 显示新增弹框
    // info 或许是为了编辑
    showForm = (info = {}, formEdit) => {
        this.formVisible = true

        this.formEdit = formEdit
        if (formEdit) {
            this.formTitle = info.id ? t('company.Editorial') : t('company.Newcompany')
        } else {
            this.formTitle = t('company.Viewcompany')
        }
        this.record = info
    }

    downloadGameIcon = (total) => {
        this.isFetching = true
        console.log("total", total);
        const param = {
            total: total
        }
        dwonloadGameIcon(param).then(res => {
            if (res.status === 10000) {
                this.fetchRecords()
                message.success(t('Partner.Success'))
            } else {
                message.error(res.msg)
            }
        }).finally(() => {
            this.isFetching = false
        })
    }

    syncGameIcon = (brandName) => {
        this.isFetching = true
        const params = {
            brandName: brandName
        }
        syncGameIcon(params).then(res => {
            console.log(res, "res");
            if (res.status === 10000) {
                this.fetchRecords()
                message.success(res)
            } else if (res !== null) {
                if (typeof res === 'string') {
                    message.success(res)
                } else {
                    message.error(res.msg)
                }
            } else {
                message.error(res.msg)
            }
        }).finally(() => {
            this.isFetching = false
        })
    }

    uploadGameIcon(formData) {
        return new Promise((resolve, reject) => {
            // 发送 POST 请求到后端接口，添加请求头
            axios.post('/ctgameclub/manager/v2/gameInfo/upload-game-icon', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',  // 设置内容类型
                    'X-Token': X_TOKEN  // 示例：添加 Authorization 请求头
                }
            })
            .then(response => {
                // 处理成功响应
                resolve(response.data);
            })
            .catch(error => {
                // 处理错误
                reject(error);
            });
        });
    }
    

    handleClickRow = (record) => {
        return {
            onDoubleClick: () => {
                this.showForm(record, false)
            }
        }
    }
    //二次确认删除
    confirmDelete = (data) => {
        if (this.ids.length === 0) {
            message.error(t('public.selectDelete'))
            return
        }
        this.conDelete = data
    }
    copy = (e) => {
        copy(e.target.innerText)
        message.success(t('public.copy'))
    }
}

export default new CompanyStore()