import React, {useState} from "react"

import {SearchOutlined} from '@ant-design/icons'

import {Button, DatePicker, Input, Select} from "antd"

import locale from 'moment/locale/zh-cn'
import moment from "moment/moment";

import store from "./OpenOperatorStore"
import OpenOperatorTable from "./OpenOperatorTable"
import OpenOperatorForm from "./OpenOperatorForm"

import {openOperatorStatus, log, t,} from '@tool'

import {MyForm} from '@components'

export default function GameIndex() {

    const [startTime, setStartTime] = useState('')
    const [endTime, setEndTime] = useState('')
    const dateFormat = 'YYYY-MM-DD hh:mm:ss';

    const onFinish = (params) => {
        if (startTime) {
            params['startTime'] = moment(startTime).format(dateFormat)
        }
        if (endTime) {
            params['endTime'] = moment(endTime).format(dateFormat)
        }
        // 重置为第一页
        store.setPageNum(1)
        store.tableOptions = params
        store.fetchRecords()
    }

    return (<div>
        <MyForm onFinish={onFinish} labelCol={{span: 8}} initialValues={{isOpen: 0}}>
            <MyForm.Item span={7} title={'申请开户人'} name={"saleUserName"}>
                <Input allowClear autoComplete="off"
                       placeholder={`${t('public.input')}申请开户人`}/>
            </MyForm.Item>
            <MyForm.Item span={7} title={'开户状态'} name={"isOpen"}>
                <Select
                    value={''}>
                    {openOperatorStatus.map(item => {
                        return <Select.Option value={item.value} key={item.label}>{item.label}</Select.Option>
                    })}
                </Select>
            </MyForm.Item>
            <MyForm.Item span={8}>
            </MyForm.Item>
            <MyForm.Item span={7} title={t('Bet.startTime')}>
                <DatePicker
                    style={{width: '100%'}}
                    locale={locale}
                    format={dateFormat}
                    onChange={(value) => setStartTime(value)}
                />
            </MyForm.Item>
            <MyForm.Item span={7} title={t('Bet.endTime')}>
                <DatePicker style={{width: '100%'}}
                            locale={locale}
                            format={dateFormat}
                            onChange={(value) => setEndTime(value)}
                />
            </MyForm.Item>
            <MyForm.Item span={3}>
                <Button type="primary" htmlType="submit" icon={<SearchOutlined/>}>{t('public.inquire')}</Button>
            </MyForm.Item>
        </MyForm>
        <OpenOperatorTable/>
        <OpenOperatorForm/>
    </div>)
}