import React, {useEffect, useState} from 'react'

import './index.css'
import {BugOutlined} from "@ant-design/icons";
import {Popover, DatePicker, Button, message, Select} from 'antd';
import {get, t} from '@tool'
import moment from 'moment';
import {history} from '@http'
import {playerBetCount, createRateApi, getCompanyList} from '@api'
import {AuthButton,} from '@components'

export default function HomeIndex() {
    const [open, setOpen] = useState(false);
    const [startTime, setStartTime] = useState('');

    const [options, setOptions] = useState([]);

    const [id, setId] = useState(0);

    useEffect(() => {
        onRelseswq()
    }, [])

    //获取运营商
    const onRelseswq = () => {
        getCompanyList({
            pageSize: 999999,
            pageNum: 1,
            status: 1
        }).then(res => {
            const filteredData = res.list.filter(item => item.companyType === 'OPERATOR_COMPANY');
            const operatorList = filteredData.map((item => {
                return {
                    value: item.id,
                    label: item.companyName
                };
            }));
            setOptions(operatorList)
        })
    }

    const openGameLobby = (type) => {
        if (type === 1) {
            history.push('/operator')
        } else {
            history.push('/operator-zh')
        }
    }

    const onRenewal = () => {
        if (!startTime) {
            return message.warning('请选择日期');
        }
        playerBetCount({startTime: startTime}).then(res => {
            if (res) {
                message.success(t('public.success'));
                setOpen(!open)
            }
        }).catch(() => {
            message.error(t('public.error'))
        })
    }

    const onChange = (date, dateString) => {
        setStartTime(dateString)
    }

    const disabledDate = (current) => {
        // 今天的日期
        const today = moment().startOf('day');
        // 将要选择的日期
        const selected = moment(current).startOf('day');
        // 禁用选择当天的日期
        return selected.isSame(today);
    };

    const content = (
        <div>
            <DatePicker onChange={onChange} disabledDate={disabledDate}/>
            <div style={{display: 'flex', marginTop: '10px', alignItems: 'center', justifyContent: 'space-between'}}>
                <Button type="primary" onClick={() => {
                    onRenewal()
                }}> 确定 </Button>
                <Button onClick={() => {
                    setOpen(!open)
                }}> 取消 </Button>
            </div>
        </div>
    );

    const handleChange = (e) => {
        setId(e)
    }

    const createRate = () => {
        createRateApi(id).then(res => {
            console.log(res)
        })
    }

    const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    return (<div className='home'>
        {
            (get('userType') === 'ADMIN' || get('userType') === 'COMPANY_ADMIN' || get('userType') === 'CUSTOMER') ? (
                <div>
                    <AuthButton type="primary" icon={<BugOutlined/>} style={{marginRight: 20}}
                                onClick={() => openGameLobby(1)}>游戏大厅(单一钱包)</AuthButton>
                    <AuthButton type="primary" icon={<BugOutlined/>} style={{marginRight: 20}}
                                onClick={() => openGameLobby(2)}>游戏大厅(转账钱包)</AuthButton>
                </div>) : ''

        }
        <div>
            <Popover content={content} open={open} placement="bottom" trigger="click">
                <AuthButton type="primary" icon={<BugOutlined/>} style={{marginRight: 20}}
                            onClick={() => {
                                setOpen(!open)
                            }}>更新首页图表数据</AuthButton>
            </Popover>
        </div>

        <div>
            <Select
                onChange={handleChange}
                style={{width: 160, marginRight: 4}}
                showSearch
                filterOption={filterOption}
                placeholder='请选择'
                options={options}
            >
            </Select>
            <AuthButton type="primary" icon={<BugOutlined/>} style={{marginRight: 20}}
                        onClick={() => createRate()}>生成运营商点位数据</AuthButton>
        </div>
    </div>)
}