import {post} from "@http"

//获取所有玩家信息
export function getGameUserList(params) {
    return post("/manager/game-user/getList", params)
}
//投注列表
export function getTransferList(params) {
    return post("/manager/game-user/getBettingList", params)
}
//投注汇总（按玩家）
export function getTranssummaryList(params) {
    return post("/manager/game-user/countBetting/game-user", params)
}
//每日投注汇总列表
export function getTranssummaryDayList(params) {
    return post("/manager/game-user/countDayBetting/game-user", params)
}

//投注汇总（按游戏）
export function countBettingByGame(params) {
    return post("/manager/game-user/countBetting/game", params)
}

//每日投注汇总（按游戏）
export function countDayBettingByGame(params) {
    return post("/manager/game-user/countDayBetting/game", params)
}

//导出投注列表
export function exportTransferList(params) {
    return post("/manager/game-user/exportBetList", params)
}

//查询投注历史记录列表
export function getBettingLogHistoryList(params) {
    return post("/manager/game-user/getBettingLogHistoryList", params)
}