import { observable, computed, action } from 'mobx'


import { getGameUserList } from '@api'
import { t,get } from '@tool'

class UserStore {
    @observable formVisible = false

    @observable formTitle = t('Player.Details')

    @observable record = {}

    @observable records = []

    // 默认第1页
    @observable pageNum = 1
    // 每页多少条
    @observable pageSize = 10
    // 总共多少页
    @observable total = ''


    // 其他参数，例如排序、过滤等等
    @observable tableOptions = {}

    @observable isFetching = false

    @computed get dataSource() {
        return this.records
    }

    _getTableParams = () => ({ pageNum: this.pageNum, pageSize: this.pageSize, ...this.tableOptions })

    @action setPageNum(val) {
        this.pageNum = val
    }
    @action setPageSize(val) {
        this.pageSize = val
    }

    fetchRecords = () => {
        const params = this._getTableParams()
        this.isFetching = true
        params.companyId = get('userType') == 'PLATFORM' ? get('companyId') : params.companyId
        getGameUserList(params).then(res => {
            this.total = res.totalCount
            this.records = res.list
        }).finally(() => this.isFetching = false)
    }
    // 显示新增弹框
    showForm = (info = {}, formEdit) => {
        this.formVisible = formEdit
        this.record = info
    }

}

export default new UserStore()