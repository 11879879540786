import React from 'react'

import { observer } from 'mobx-react'
import { Modal, Tooltip} from 'antd'
import { t} from '@tool'

import store from './LogStore'

export default observer(function () {
    return (// Modal 对话框
        <Modal
            width={'54%'}
            footer={null}
            forceRender={true}
            open={store.formVisible}
            title={store.formTitle}
            onCancel={() => store.formVisible = false}>
            <div style={{ display: "flex", alignItems: 'center', borderBottom: '1px solid #f0f0f0', height: '40px', lineHeight: '40px' }}>
                <p style={{ margin: '0', width: 'fit-content', textAlign: "right", letterSpacing: '2px', fontWeight: "bold" }}>{t('Accesslog.URL')}:</p>
                <Tooltip color={'#000'} placement="topLeft" title={store.record.log_name} >
                    <p style={{ margin: '0', padding: '0 10px', maxWidth: '895px', whiteSpace: 'nowrap',overflow: 'hidden',textOverflow: 'ellipsis' }}>  
                      {store.record.log_name || '--'}
                      </p>
                </Tooltip>
            </div>
            <div style={{ display: "flex", alignItems: 'center', borderBottom: '1px solid #f0f0f0', height: '40px', lineHeight: '40px' }}>
                <p style={{ margin: '0', width: 'fit-content', textAlign: "right", letterSpacing: '2px', fontWeight: "bold" }}>{t('Accesslog.argument')}:</p>
                <Tooltip color={'#000'} placement="topLeft" title={store.record.content} >
                    <p style={{ margin: '0', padding: '0 10px', maxWidth: '895px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {store.record.content || '--'}
                    </p>
                </Tooltip>
            </div>
            <div style={{ display: "flex", alignItems: 'center', borderBottom: '1px solid #f0f0f0', height: '40px', lineHeight: '40px' }}>
                <p style={{ margin: '0', width: 'fit-content', textAlign: "right", letterSpacing: '2px', fontWeight: "bold" }}>{t('Accesslog.Request')}:</p>
                <Tooltip color={'#000'} placement="topLeft" title={store.record.add_time} >
                    <p style={{ margin: '0', padding: '0 10px', maxWidth: '895px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {store.record.add_time || '--'}
                    </p>
                </Tooltip>
            </div>
            <div style={{ display: "flex", alignItems: 'center', borderBottom: '1px solid #f0f0f0', height: '40px', lineHeight: '40px' }}>
                <p style={{ margin: '0', width: 'fit-content', textAlign: "right", letterSpacing: '2px', fontWeight: "bold" }}>apiKey:</p>
                <Tooltip color={'#000'} placement="topLeft" title={store.record.api_key} >
                    <p style={{ margin: '0', padding: '0 10px', maxWidth: '895px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {store.record.api_key || '--'}
                    </p>
                </Tooltip>
            </div>
        </Modal>)
})