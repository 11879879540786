import React, { Component } from 'react'
import './i18n/config'; // 引用配置文件
import zh from 'antd/es/locale/zh_CN'
import en from 'antd/lib/locale/en_US';
import { Router } from 'react-router-dom'
// 登录组件
import Login from '@pages/login'
import { history } from '@http'
import { get } from "@tool"

// 模拟 Layout 组件
import Layout from './layout'
import { ConfigProvider } from 'antd'
import { Switch, Route } from 'react-router-dom'

// 定义一个类组件
class App extends Component {
    componentDidMount() {
        let id = get('id')
        if (id) {
            history.push('index')
        }
    }
    render() {
        return (
            // 只渲染其中一个 Route
            // exact 精确匹配
            // component={Login} 路由组件（不同于一般组件，其 props 中有路由相关方法。）
            <Router history={history}>
                <ConfigProvider locale={get('Language') === null ? en : get('Language') === 'zh' ? zh : en}>
                    <Switch>
                        <Route path="/" exact component={Login} />
                        {/* 没有匹配则进入 Layout */}
                        <Route component={Layout} />
                    </Switch>
                </ConfigProvider>
            </Router>
        )
    }
}

export default App