import React from 'react'
import {Layout, Menu} from 'antd'
import {history} from '@http'
import styles from './layout.module.less'

import logo from '../assets/img/logo.png'

export default function Sider(props) {

    // 下面的className都仅仅让样式好看点,对功能没有影响。
    return (// Sider：侧边栏，自带默认样式及基本功能，其下可嵌套任何元素，只能放在 Layout 中。
        // collapsed - 当前收起状态。这里设置为默认展开
        <Layout.Sider width={248} collapsed={props.collapsed} className={styles.sider}>
            {/* 图标 */}
            <div className={styles.logo}>
                <img src={logo} alt="Logo" style={{height: '30px'}}/>
            </div>

            <div className={styles.menus} style={{height: `${document.body.clientHeight - 64}px`}}>
                {/* 导航菜单。使用的是`缩起内嵌菜单` */}
                <Menu
                    theme="dark"
                    mode="inline"
                    className={styles.menus}
                    items={props.menus}
                    // 当前选中的菜单项 key 数组
                    // 路由切换。点击哪个导航，url和路由就会切换到该路劲
                    onSelect={item => {
                        props.setTitle(item.item.props.title)
                        history.push(item.key)
                    }}
                >
                </Menu>
            </div>
        </Layout.Sider>)
}