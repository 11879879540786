import React from 'react'
import {
    HomeOutlined,
    DesktopOutlined,
    UserOutlined,
    ContainerOutlined,
    BugOutlined,
    UsergroupDeleteOutlined,
    ScheduleOutlined,
    DatabaseOutlined,
    FileTextOutlined,
    TeamOutlined,
    MacCommandOutlined,
    ContactsOutlined,
    BarChartOutlined,
    GlobalOutlined,
    TransactionOutlined,
    UsergroupAddOutlined,
    AppstoreOutlined
} from '@ant-design/icons'

//首页
import HomeIndex from '@pages/home'

//用户
import User from '@pages/users'

//公司
import Company from '@pages/company'
import GameCompany from '@pages/company/game'
import OperatorCompany from '@pages/company/operator'

//游戏管理
import Game from '@pages/game'
//游戏管理/游戏包管理/游戏列表
import Gamepackson from '@pages/operatorgamemanagement/gamepackson'
//充值记录
import Rechargerecord from '@pages/home/rechargerecord'
//积分提醒
import Integralreminder from '@pages/home/integralreminder'
//月投注和输赢积分记录
import IntegralMonth from '@pages/home/integralMonth'
//月输赢积分
import PointsUsage from '@pages/home/pointsUsage'
// 游戏公司详情
import GameCompanyDetail from '@pages/operatorgamemanagement/gamecompanydetail'
// 游戏icon管理
import GameIcon from '@pages/game/gameicon'

//玩家列表
import Playerlist from '@pages/player'
//玩家投注列表
import Betting from '@pages/player/betting'
import BetHistory from '@pages/player/bethistory'
//玩家投注汇总
import Collect from '@pages/player/collect'
//玩家每日投注汇总
import Everyday from '@pages/player/everyday'
//玩家投注汇总
import UserCollect from '@pages/player/collect/user'
//玩家每日投注汇总
import UserEveryday from '@pages/player/everyday/user'
//投注汇总（按游戏商）
import GameCompanyCollect from '@pages/betting/gameCompany'

//运营商/游戏管理
import Gamemanager from '@pages/operatorgamemanagement/gamemanager'
//运营商/游戏包管理
import Gamepackmanager from '@pages/operatorgamemanagement/gamepackmanager'
//运营商/游戏商设置
import Gamevendorsetup from '@pages/operatorgamemanagement/gamevendorsetup'
// 开户管理
import OpenOperator from '@pages/openoperator'
// 点位信息
import OperatorPrice from '@pages/operatorgamemanagement/operatorprice'

//权限管理
import Authority from '@pages/authority'
import AuthoritySet from '@pages/authority/set'
import AuthoritySetForm from '@pages/authority/set/AuthoritySetForm'
//数据看板
import Day from '@pages/databoard/day'
import Weeks from '@pages/databoard/weeks'
import Month from '@pages/databoard/month'

//运营商页面
import Operator from '@pages/operator'
import OperatorZh from '@pages/operator/zh'
import Gamemanagercopy from '@pages/operatorgamemanagement/gamemanagercopy'
//日志
//访问日志
import LogInfo from '@pages/system/log'
//回调日志
import Callback from '@pages/system/log/callback'
//操作日志
import OperateLog from '@pages/system/log/operate'
//系统参数
import SystemParam from '@pages/system/param'
//开发调试页
import Development from '@pages/system/development'
import {t} from "@tool"

// 币种管理
import Currency from '@pages/currency'

// 角色管理
import Role from '@pages/role'

// 运营商白名单管理
import WhiteIp from '@pages/whiteip'
// 游戏商白名单管理
import WhiteIpGameCompany from '@pages/whiteip/gamecompany'
// 游戏平台白名单管理
import PlatformCompany from '@pages/whiteip/platform'


const adminMenus = [
    {
        key: '/index', icon: <HomeOutlined/>, title: t('route.index'), label: t('route.index'),
        path: '/index', component: HomeIndex
    },
    {
        key: '/user', icon: <TeamOutlined/>, title: t('route.user'), label: t('route.user'),
        path: '/user', component: User, auth: 'user.view'
    },
    {
        key: '/role', icon: <UsergroupAddOutlined/>, title: t('route.Role'), label: t('route.Role'),
        path: '/role', component: Role, auth: 'Role.View'
    },
    {
        icon: <GlobalOutlined/>, label: t('route.WhiteIp'),
        auth: 'WhiteIp.view|WhiteIp.GameCompany.view|WhiteIp.platform.view',
        children: [
            {
                key: '/whiteip', icon: <DesktopOutlined/>, title: t('WhiteIp.Operator'), label: t('WhiteIp.Operator'),
                path: '/whiteip', component: WhiteIp, auth: 'WhiteIp.view'
            },
            {
                key: '/whiteip-game-company', icon: <BugOutlined/>, title: t('WhiteIp.Game'), label: t('WhiteIp.Game'),
                path: '/whiteip-game-company', component: WhiteIpGameCompany, auth: 'WhiteIpGameCompany.view',
            },
            {
                key: '/whiteip-platform',
                icon: <AppstoreOutlined/>,
                title: t('WhiteIp.Platform'),
                label: t('WhiteIp.Platform'),
                path: '/whiteip-platform',
                component: PlatformCompany,
                auth: 'PlatformCompany.view',
            }
        ]
    },
    {
        key: '/company', icon: <DesktopOutlined/>, title: t('route.company'), label: t('route.company'),
        path: '/company', component: Company, auth: 'company.view'
    },
    {
        key: '/currency', icon: <TransactionOutlined/>, title: t('route.Currency'), label: t('route.Currency'),
        path: '/currency', component: Currency, auth: 'Currency.view'
    },
    {
        icon: <BugOutlined/>, label: t('route.Game'),
        auth: 'game.company.view|game.view|game.packet.view|game.company.detail.view',
        children: [
            {
                key: '/game-company',
                path: '/game-company',
                title: t('route.game'),
                icon: <DesktopOutlined/>,
                auth: 'game.company.view',
                label: t('route.game'),
                component: GameCompany
            },
            {

                key: '/game-company-detail',
                path: '/game-company-detail',
                title: t('route.GameCompanyDetail'),
                icon: <DesktopOutlined/>,
                auth: 'game.company.detail.view',
                label: t('route.GameCompanyDetail'),
                component: GameCompanyDetail
            },
            {
                key: '/game-info',
                path: '/game-info',
                title: t('route.Game'),
                auth: 'game.view',
                icon: <BugOutlined/>,
                label: t('route.Game'),
                component: Game
            },
            {
                key: '/game-icon',
                path: '/game-icon',
                title: t('route.GameIcon'),
                auth: 'game.view',
                icon: <BugOutlined/>,
                label: t('route.GameIcon'),
                component: GameIcon
            },
            {
                key: '/gamepack-manager',
                path: '/gamepack-manager',
                title: t('route.gamepack'),
                auth: 'game.packet.view',
                icon: <MacCommandOutlined/>,
                label: t('route.gamepack'),
                component: Gamepackmanager
            }
        ]
    },
    {key: '/gamepack-son', path: '/gamepack-son', component: Gamepackson},
    {key: '/integral-reminder', path: '/integral-reminder', component: Integralreminder},
    {key: '/recharge_record', path: '/recharge_record', component: Rechargerecord},
    {key: '/integral_month_record', path: '/integral_month_record', component: IntegralMonth},
    {key: '/points-usage', path: '/points-usage', component: PointsUsage},
    {
        icon: <ScheduleOutlined/>,
        label: t('route.Playerl'),
        auth: 'game.user.view|game.user.report.view|game.company.report.view',
        children: [
            {
                key: '/player-list', icon: <ContactsOutlined/>, title: t('route.Player'), auth: 'game.user.view',
                label: t('route.Player'), path: '/player-list', component: Playerlist
            },

            {
                key: '/betting-list',
                icon: <ContactsOutlined/>,
                title: t('route.Betting'),
                auth: 'game.user.report.view',
                label: t('route.Betting'),
                path: '/betting-list',
                component: Betting
            },
            {
                key: '/bet-history-list',
                icon: <ContactsOutlined/>,
                title: t('route.BetHistory'),
                auth: 'game.user.report.view',
                label: t('route.BetHistory'),
                path: '/bet-history-list',
                component: BetHistory
            },
            {
                key: '/user-collect', icon: <ContactsOutlined/>, title: t('route.summary'),
                auth: 'game.user.report.view',
                label: t('route.summary'), path: '/user-collect', component: UserCollect
            },
            {
                key: '/user-everyday', icon: <ContactsOutlined/>, title: t('route.Daily'),
                auth: 'game.user.report.view',
                label: t('route.Daily'), path: '/user-everyday', component: UserEveryday
            },
            {
                key: '/collect', icon: <ContactsOutlined/>, title: t('route.summaryGame'),
                auth: 'game.user.report.view',
                label: t('route.summaryGame'), path: '/collect', component: Collect
            },
            {
                key: '/everyday', icon: <ContactsOutlined/>, title: t('route.DailyGame'),
                auth: 'game.user.report.view',
                label: t('route.DailyGame'), path: '/everyday', component: Everyday
            },
        ]
    },
    {
        icon: <ScheduleOutlined/>,
        label: t('route.operator'),
        auth: 'operator.game.view|operator.company.view|operator.setup.view|operator.currency.view|open.operator.view|operator.price.view',
        children: [
            {
                key: '/operator-company',
                icon: <ContainerOutlined/>,
                title: t('route.operator'),
                auth: 'operator.company.view',
                label: t('route.operator'),
                path: '/operator-company',
                component: OperatorCompany
            },
            {
                key: '/game-manager',
                path: '/game-manager',
                title: t('route.Carrier'),
                icon: <BugOutlined/>,
                auth: 'operator.game.view',
                label: t('route.Carrier'),
                component: Gamemanager
            },
            {
                key: '/gamevendorsetup',
                path: '/gamevendorsetup',
                title: '游戏商设置',
                icon: <BugOutlined/>,
                auth: 'operator.setup.view',
                label: '游戏商设置',
                component: Gamevendorsetup
            },
            {
                key: '/openOperator',
                path: '/openOperator',
                title: '开户管理',
                icon: <BugOutlined/>,
                auth: 'open.operator.view',
                label: '开户管理',
                component: OpenOperator
            },
            {
                key: '/operatorPrice',
                path: '/operatorPrice',
                title: '点位信息',
                icon: <BugOutlined/>,
                auth: 'operator.price.view',
                label: '点位信息',
                component: OperatorPrice
            },
        ]
    },
    {key: '/gamepack-son', path: '/gamepack-son', component: Gamepackson},
    {
        icon: <ScheduleOutlined/>,
        label: t('route.bettingAll'),
        auth: 'game.user.view|game.user.report.view|game.company.report.view',
        children: [
            {
                key: '/game-company-collect', icon: <ContactsOutlined/>, title: t('route.summaryGameCompany'),
                auth: 'game.company.report.view',
                label: t('route.summaryGameCompany'), path: '/game-company-collect', component: GameCompanyCollect
            },
        ]
    },
    {key: '/gamemanager-copy', path: '/gamemanager-copy', component: Gamemanagercopy},
    {
        icon: <ScheduleOutlined/>,
        label: t('route.authority'),
        auth: 'authority.view|authorityset.view',
        children: [
            {
                icon: <ScheduleOutlined/>,
                label: t('route.authority'),
                key: '/authority',
                path: '/authority',
                title: t('route.authority'),
                component: Authority,
                auth: 'authority.view',
            },
            {
                key: '/authority-set',
                path: '/authority-set',
                title: t('route.authorityset'),
                component: AuthoritySet,
                icon: <UsergroupDeleteOutlined/>,
                label: t('route.authorityset'),
                auth: 'authorityset.view',
            },
        ]
    },
    {
        icon: <DatabaseOutlined/>,
        label: t('route.System'),
        auth: 'operate.log.view|log.view|callback.view|system.param.view|dev.debug.view',
        children: [
            {
                key: '/operatelog',
                icon: <FileTextOutlined/>,
                title: t('route.operatelog'),
                label: t('route.operatelog'),
                path: '/operatelog',
                component: OperateLog,
                auth: 'operate.log.view',
            },
            {
                key: '/loginfo', icon: <FileTextOutlined/>, title: t('route.access'), label: t('route.access'),
                path: '/loginfo', component: LogInfo, auth: 'log.view',
            },
            {
                key: '/callback-log', icon: <DesktopOutlined/>, title: t('route.callback'), label: t('route.callback'),
                path: '/callback-log', component: Callback, auth: 'callback.view',
            },
            {
                key: '/system-param', icon: <UserOutlined/>, title: t('route.parameter'), label: t('route.parameter'),
                path: '/system-param', component: SystemParam, auth: 'system.param.view',
            },
            {
                key: '/Develo-pment', icon: <DesktopOutlined/>, title: t('route.Debug'), label: t('route.Debug'),
                path: '/Develo-pment', component: Development, auth: 'dev.debug.view',
            }

        ]
    },
    {key: '/operator', path: '/operator', component: Operator},
    {key: '/operator-zh', path: '/operator-zh', component: OperatorZh},
    {key: '/authority-set-form', path: '/authority-set-form', component: AuthoritySetForm},

    // {
    //     icon: <BarChartOutlined/>,
    //     label: t('route.board'),
    //     auth: 'authority.view|authorityset.view',
    //     children: [
    //         {
    //             icon: <BarChartOutlined/>,
    //             label: t('route.Day'),
    //             key: '/Day',
    //             path: '/Day',
    //             title: t('route.Day'),
    //             component: Day,
    //             auth: 'authority.view',
    //         },
    //         {
    //             key: '/Weeks',
    //             path: '/Weeks',
    //             title: t('route.Weeks'),
    //             component: Weeks,
    //             icon: <BarChartOutlined/>,
    //             label: t('route.Weeks'),
    //             auth: 'authorityset.view',
    //         },
    //         {
    //             key: '/Month',
    //             path: '/Month',
    //             title: t('route.Month'),
    //             component: Month,
    //             icon: <BarChartOutlined/>,
    //             label: t('route.Month'),
    //             auth: 'authorityset.view',
    //         },
    //     ]
    // },
]

export default adminMenus