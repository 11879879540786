import i18n from 'i18next';
import React from 'react'
import ReactDOM from 'react-dom/client'
import {updatePermissions} from '@http'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
// 权限和 token 相关。
updatePermissions()

document.title = i18n.t('footer.detail')

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <App/>
)

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()