import {post} from "@http"

//登录
export function login(params) {
    return post("/manager/user/login", params)
}

//登出
export function loginOut() {
    return post("/manager/user/loginOut", {})
}

//注册
export function registerUser(params) {
    return post("/manager/user/register", params)
}

//修改用户信息
export function modifyUser(params) {
    return post("/manager/user/modify", params)

}

//修改密码
export function updatePwd(params) {
    return post("/manager/user/updatePwd", params)
}

//重置密码
export function restPwd(params) {
    return post("/manager/user/rest_pwd", params)
}

//修改状态
export function batchStatusUser(params) {
    return post("/manager/user/batchStatus", params)
}

//获取所有用户
export function getUserList(params) {
    return post("/manager/user/getList", params)
}