import React from 'react'

import { observer } from 'mobx-react'
import { Popconfirm, Space } from 'antd'
import { DeleteOutlined, EditOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { TableCard, AuthButton, } from '@components'
import store from './CompanyStore'
import { t } from '@tool'
const rowSelection = {
    onChange: (selectedRowKeys) => {
        store.setIds(selectedRowKeys)
    }, getCheckboxProps: (record) => ({
        disabled: record.id === 'Disabled Index', // Column configuration not to be checked
        name: record.id,
    }),
}

@observer
class ComTable extends React.Component {

    componentDidMount() {
        store.tableOptions = {
            ...store.tableOptions, status: 1
        }
        store.fetchRecords()
    }
    columns = [
        { title: t('company.companyname'), width: '80px',
         key: 'companyName', dataIndex: 'companyName', ellipsis: true, },
        {
            title: t('company.status'),
            width: '50px',
            key: 'status',
            dataIndex: 'status',
            ellipsis: true,
            render: (text) => (text === 1) ? t('public.effective') : t('public.invain'),
        },
        {
            title: t('company.mailbox'), width: '80px', key: 'email', dataIndex: 'email',  ellipsis: true, render: (text) => {
                return (
                    <div className="ellipsis" style={{
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                    }} title={t('public.Clickcopy')} onClick={store.copy}>
                        {text}
                    </div>)
            }
        },
        { title: t('company.mobile'), width: '100px', key: 'phone', dataIndex: 'phone', },
        {
            title: t('company.type'), width: '60px', key: 'companyType', dataIndex: 'companyType', ellipsis: true, render: (text) => {
                let type = t('public.other')
                if (text === 'GAME_COMPANY') {
                    type = t('public.business')
                } else if (text === 'OPERATOR_COMPANY') {
                    type = t('public.operator')
                }
                return type
            }
        },
        {
            title: t('company.website'), width: '90px', key: 'officialWebsite', dataIndex: 'officialWebsite',ellipsis: true, render: (text) => {
                return (
                    <div className="ellipsis" style={{
                
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                    }} title={t('public.Clickcopy')} onClick={store.copy}>
                        {text}
                    </div>)
            }
        },
        {
            title: t('public.remark'), width: '90px', key: 'remark', dataIndex: 'remark', ellipsis: true, render: (text) => {
                return (
                    <div className="ellipsis" style={{
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                    }} title={t('public.Clickcopy')} onClick={store.copy}>
                        {text}
                    </div>)
            }
        },
        {
            title: t('public.Controls'), fixed: 'right', width: '140px', key: 'id', render: (_, record) => {
                if (record.id === 1) {
                    return (<Space size="middle">
                        <AuthButton
                            auth="company.edit"
                            type={"primary"}
                            ghost icon={<EditOutlined />}
                            onClick={() => store.showForm(record, 1)}>
                            {t('public.edit')}
                        </AuthButton>
                    </Space>)
                } else {
                    return (<Space size="middle">
                        <AuthButton
                            type={"primary"}
                            ghost
                            icon={<EditOutlined />}
                            onClick={() => store.showForm(record, 1)}
                            auth="company.edit" >
                            {t('public.edit')}
                        </AuthButton>
                        <Popconfirm
                            title={t('public.sure')}
                            onConfirm={() => store.onDelete(record)}
                            okText={t('public.OK')}
                            cancelText={t('public.Cancel')}
                        >
                            <AuthButton
                                type={"primary"}
                                danger
                                ghost
                                auth="company.delete"
                                icon={<DeleteOutlined />}>
                                {t('public.delete')}
                            </AuthButton>
                        </Popconfirm>
                    </Space>)
                }
            },
        },]

    handleTableChange = ({ current, pageSize }, filters, sorter) => {
        store.pageNum = current
        store.pageSize = pageSize
        store.tableOptions = {
            ...store.tableOptions, // 排序：好像只支持单个排序
            sortField: sorter.field, sortOrder: sorter.order, ...filters
        }
        store.fetchRecords()
    }

    render() {
        return (<TableCard
            tableLayout={'fixed'}
            size={'small'}
            handleClickRow={store.handleClickRow}
            scroll={{
                x: 1500,
                y: '44vh'
            }}
            rowKey="id"
            rowSelection={{
                type: 'checkbox', ...rowSelection,
            }}
            title={t('company.companyList')}
            loading={store.isFetching}
            // 后端的数据源
            dataSource={store.dataSource}
            onReload={store.fetchRecords}
            onChange={this.handleTableChange}
            actions={[
                <Popconfirm
                    title={`${t('public.Are')}${store.ids.length}?`}
                    open={store.conDelete}
                    onConfirm={() => store.onDelete(-1)}
                    onCancel={() => { store.confirmDelete(false) }}
                    okText={t('public.OK')}
                    cancelText={t('public.Cancel')}
                >
                    <AuthButton
                        type="primary"
                        icon={<MinusCircleOutlined />}
                        auth="company.delete"
                        onClick={() => store.confirmDelete(true)}>
                        {t('public.Batchdeletion')}
                    </AuthButton>
                </Popconfirm>,
                <AuthButton
                    type="primary"
                    icon={<PlusOutlined />}
                    auth="company.add"
                    onClick={() => store.showForm({}, 2)}>
                    {t('public.new')}
                </AuthButton>]}
            // 分页器
            pagination={{
                showSizeChanger: true,
                showLessItems: true,
                showTotal: total => t('public.intotal', total),
                pageSizeOptions: ['10', '20', '50', '100'], // 如果不传 total，则以后端返回数据条数作为 total 的值
                total: store.total, // 如果不传，则默认是第一条，如果需要默认显示第3条，则必须传
                current: store.pageNum,
                pageSize: store.pageSize,
            }}
            columns={this.columns} />)
    }
}

export default ComTable
