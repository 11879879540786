import { observable, computed, action } from 'mobx'
import { getCompanyGameByTypeList } from '@api/CompanyGameDetail'

import { log, t } from '@tool'

class GameStore {
    @observable formVisible = false

    @observable formTitle = t('Gamebusiness.View')

    @observable record = {}

    @observable records = []

    // 默认第1页
    @observable pageNum = 1
    // 每页多少条
    @observable pageSize = 10
    // 总共多少页
    @observable total = ''

    @observable id = ''

    // 其他参数，例如排序、过滤等等
    @observable tableOptions = {}

    @observable isFetching = false

    @computed get dataSource() {
        return this.records
    }

    _getTableParams = () => ({ pageNum: this.pageNum, pageSize: this.pageSize, id: this.id, ...this.tableOptions })

    @action setPageNum(val) {
        this.pageNum = val
    }
    @action setPageSize(val) {
        this.pageSize = val
    }

    fetchRecords = () => {
        const params = this._getTableParams()

        this.isFetching = true

        getCompanyGameByTypeList(params).then(res => {
            this.total = res.totalCount
            this.records = res.list
        }).finally(() => this.isFetching = false)
    }

    // 显示新增弹框
    showForm = (info) => {
        this.id = info.id
        this.formVisible = true
        console.log(info, this.formVisible);
        this.fetchRecords()
    }
}

export default new GameStore()