import React, { useState } from 'react'
import { Button, Form, Input, message, Tabs } from 'antd'
import { CopyrightOutlined, LockOutlined, UserOutlined } from '@ant-design/icons'
import styles from './login.module.css'
import Internation from '../../layout/internation'
import { history, updatePermissions } from '@http'

import { login } from '@api'

import { CompanyList,encryptPassword, saveToken, log, t } from "@tool"

const items = [{ label: t('public.count'), key: 'account', }, { label: '', key: '', },]

export default function LoginIndex() {

    const [form] = Form.useForm()

    // 控制登录按钮
    const [loading, setLoading] = useState(false)

    // 登录
    function handleSubmit() {
        const formData = form.getFieldsValue();
        const data = JSON.parse(JSON.stringify(formData));
        data.loginPwd = encryptPassword(data.loginPwd, '0000018c3896b653000000007e0login');

        setLoading(true)
        login(data).then(res => {
            if (res.hasOwnProperty("user")) {
                doLogin(res)
            } else {
                message.error(res.msg)
            }
        }).finally(() => setLoading(false))
    }

    // 将登录返回的数据存入本地，并更新权限和 token
    function doLogin(res) {
        saveToken(res)

        // 权限和 token 相关。
        updatePermissions()

        if (history.location.state && history.location.state['from']) {
            history.push(history.location.state['from'])
        } else {
            CompanyList();
            history.push('/index')
        }
    }

    return (
        <div className={styles.container}>

            <div className={styles.formContainer}>
                <div style={{padding:"10px 0 10px 10px", cursor:'pointer',
                 background:'#fff', borderRadius:'0 13px 0 13px', position: 'absolute', right: '0', top: '0' }}>
                    <Internation />
                </div>
                {/* 仅做样式，默认选中第一个 tabpane。没有选项卡内容 */}
                <Tabs className={styles.tabs} items={items} />
                {/* 使用 Form.useForm 创建表单数据域进行控制 */}
                <Form form={form} initialValues={{ remember: true }} onFinish={handleSubmit}>

                    <Form.Item name="loginName"
                        rules={[{ required: true, message: `${t('public.input')}${t('public.account')}` }]}
                        className={styles.formItem}>
                        <Input
                            size="large"
                            // 关闭自动完成的选项
                            autoComplete="off"
                            placeholder={`${t('public.input')}${t('public.account')}`}
                            // 人头像的 icon
                            prefix={<UserOutlined className={styles.icon} />} />
                    </Form.Item>

                    <Form.Item name="loginPwd"
                        rules={[{ required: true, message: `${t('public.input')}${t('public.password')}` }]}
                        className={styles.formItem}>
                        <Input.Password autoComplete="off" placeholder={`${t('public.input')}${t('public.password')}`}
                            prefix={<LockOutlined className={styles.icon} />} />
                    </Form.Item>
                    <Form.Item>
                        <Button
                            htmlType="submit"
                            // block 属性将使按钮适合其父宽度。
                            block
                            size="large"
                            type="primary"
                            className={styles.button}
                            loading={loading}>{t('public.Log')}</Button>
                    </Form.Item>
                </Form>

            </div>
            {/* 网站底部统一信息。这里是`官网`、`github 地址`、`文档` */}
            <div className={styles.footerZone}>
                <div className={styles.linksZone}>
                    <a className={styles.links} title={t('footer.join')} href="https://www.ct-game-club.com" target="_blank"
                        rel="noopener noreferrer">{t('footer.join')}</a>
                    <a title={t('footer.joins')} href="#" target="_blank"
                        rel="noopener noreferrer">{t('footer.joins')}</a>
                </div>
                <div style={{ color: '#fff' }}>Copyright <CopyrightOutlined /> {new Date().getFullYear()} By {t('footer.detail')}
                </div>
            </div>

        </div>)
}
