import React from 'react'

import { observer } from 'mobx-react'
import { Popconfirm, Space, message, Upload, Button } from 'antd'
import { DownloadOutlined, UploadOutlined, DeleteOutlined, EditOutlined, MinusCircleOutlined, PlusOutlined, RedoOutlined } from '@ant-design/icons'
import { TableCard, AuthButton, } from '@components'
import store from './CurrencyStore'
import { loadCurrency, importCurrency } from '@api'

import { t } from '@tool'
const rowSelection = {
    onChange: (selectedRowKeys) => {
        store.setIds(selectedRowKeys)
    }, getCheckboxProps: (record) => ({
        disabled: record.id === 'Disabled Index', // Column configuration not to be checked
        name: record.id,
    }),
}

@observer
class ComTable extends React.Component {

    componentDidMount() {
        store.tableOptions = {
            ...store.tableOptions, status: 1
        }
        store.fetchRecords()
    }
    columns = [
        {
            title: t('Currency.currency'),
            width: '80px',
            key: 'currency',
            dataIndex: 'currency',
            ellipsis: true,
        },
        {
            title: t('Currency.name'),
            width: '80px',
            key: 'name',
            dataIndex: 'name',
            ellipsis: true,
        },
        {
            title: t('Currency.nameZh'),
            width: '100px',
            key: 'nameZh',
            dataIndex: 'nameZh',
        },
        {
            title: t('Currency.rate'),
            width: '100px',
            key: 'rate',
            dataIndex: 'rate',
        },
        {
            title: t('Currency.address'),
            width: '100px',
            key: 'addressZh',
            dataIndex: 'addressZh',
        },
        {
            title: t('company.status'),
            width: '50px',
            key: 'status',
            dataIndex: 'status',
            ellipsis: true,
            render: (text) => (text === 1) ? t('public.effective') : t('public.invain'),
        },
        {
            title: t('Currency.lang'),
            width: '100px',
            key: 'lang',
            dataIndex: 'lang',
        },
        {
            title: t('public.Controls'),
            fixed: 'right',
            width: '140px',
            key: 'id',
            render: (_, record) => {
                return (<Space size="middle">
                    <AuthButton
                        type={"primary"}
                        ghost
                        icon={<EditOutlined />}
                        onClick={() => store.showForm(record, true)}
                        auth="company.edit" >
                        {t('public.edit')}
                    </AuthButton>
                    <Popconfirm
                        title={t('public.sure')}
                        onConfirm={() => store.onDelete(record)}
                        okText={t('public.OK')}
                        cancelText={t('public.Cancel')}
                    >
                        <AuthButton
                            type={"primary"}
                            danger
                            ghost
                            auth="company.delete"
                            icon={<DeleteOutlined />}>
                            {t('public.delete')}
                        </AuthButton>
                    </Popconfirm>
                </Space>)
            },
        },]

    handleTableChange = ({ current, pageSize }, filters, sorter) => {
        store.pageNum = current
        store.pageSize = pageSize
        store.tableOptions = {
            ...store.tableOptions, // 排序：好像只支持单个排序
            sortField: sorter.field, sortOrder: sorter.order, ...filters
        }
        store.fetchRecords()
    }

    handleClickLoad = () => {
        loadCurrency().then((res) => {
            if (res.status === 10000) {
                message.success(t('public.success'))
                store.fetchRecords()
            } else {
                message.error(t('public.error'))
            }
        }).catch((err) => {
            message.error(t('public.error'))
        });
    }

    handleFileUpload = (file) => {
        // 只能上传excel文件
        const allowedExtensions = ['xls', 'xlsx'];
        const fileExtension = file.name.split('.').pop().toLowerCase();
        if (!allowedExtensions.includes(fileExtension)) {
            message.error('请选择excel文件');
            return;
        }
        const formData = new FormData();
        formData.append('file', file);

        // 发起文件上传请求
        importCurrency(formData)
            .then(res => {
                if (res.status === 10000) {
                    message.success(res)
                } else if (res !== null) {
                    if (typeof res === 'string') {
                        message.success(res)
                        store.fetchRecords();
                    } else {
                        message.error(res.msg)
                    }
                } else {
                    message.error(res.msg)
                }
            })
    }

    handleDownloadTemplate = () => {
        fetch('/ctgameclub/manager/currency/download', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
        })
            .then(response => {
                // 将文件内容作为 Blob 对象读取
                return response.blob();
            })
            .then(blob => {
                // 创建一个临时 URL，并使用 a 标签下载文件
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'currency_template.xlsx');
                document.body.appendChild(link);
                link.click();
                // 清理临时 URL
                window.URL.revokeObjectURL(url);
            })
            .catch(error => {
                console.error('下载模板失败:', error);
            });
    }

    render() {
        return (<TableCard
            tableLayout={'fixed'}
            size={'small'}
            handleClickRow={store.handleClickRow}
            scroll={{
                x: 1500,
                y: '44vh'
            }}
            rowKey="id"
            rowSelection={{
                type: 'checkbox', ...rowSelection,
            }}
            title={t('Currency.List')}
            loading={store.isFetching}
            // 后端的数据源
            dataSource={store.dataSource}
            onReload={store.fetchRecords}
            onChange={this.handleTableChange}
            actions={[
                <AuthButton
                    type="primary"
                    icon={<RedoOutlined />}
                    auth="Currency.Load"
                    onClick={() => this.handleClickLoad()}>
                    {t('Currency.Load')}
                </AuthButton>,
                <AuthButton
                    type="primary"
                    icon={<DownloadOutlined />}
                    auth="Currency.Download"
                    onClick={() => this.handleDownloadTemplate()}>
                    {t('Currency.Download')}
                </AuthButton>,
                <Upload
                    showUploadList={false}
                    beforeUpload={(file) => {
                        this.handleFileUpload(file);
                        return false; // 阻止默认上传行为
                    }}
                >
                    <Button type="primary" icon={<UploadOutlined />}>
                        {t('Currency.Import')}
                    </Button>
                </Upload>,
                <Popconfirm
                    title={`${t('public.Are')}${store.ids.length}?`}
                    open={store.conDelete}
                    onConfirm={() => store.onDelete(-1)}
                    onCancel={() => { store.confirmDelete(false) }}
                    okText={t('public.OK')}
                    cancelText={t('public.Cancel')}
                >
                    <AuthButton
                        type="primary"
                        icon={<MinusCircleOutlined />}
                        auth="company.delete"
                        onClick={() => store.confirmDelete(true)}>
                        {t('public.Batchdeletion')}
                    </AuthButton>
                </Popconfirm>,
                <AuthButton
                    type="primary"
                    icon={<PlusOutlined />}
                    auth="company.add"
                    onClick={() => store.showForm({}, true)}>
                    {t('public.new')}
                </AuthButton>
            ]}
            // 分页器
            pagination={{
                showSizeChanger: true,
                showLessItems: true,
                showTotal: total => t('public.intotal', total),
                pageSizeOptions: ['10', '20', '50', '100'], // 如果不传 total，则以后端返回数据条数作为 total 的值
                total: store.total, // 如果不传，则默认是第一条，如果需要默认显示第3条，则必须传
                current: store.pageNum,
                pageSize: store.pageSize,
            }}
            columns={this.columns} />)
    }
}

export default ComTable
