import { observable, computed, action } from 'mobx'
import { message } from "antd"
import copy from 'copy-to-clipboard';

import { getCompanyList, batchStatusCompany, getCompanyById, getOperatePriceInfo } from '@api'

import { t } from '@tool'

class GameStore {
    @observable formVisible = false

    @observable formEdit = false

    @observable formTitle = t('operator.View')

    @observable record = {}

    @observable records = []


    @observable tableRecords = []

    // 默认第1页
    @observable pageNum = 1

    // 每页多少条
    @observable pageSize = 10

    // 总共多少页
    @observable total = ''

    //二次确认删除
    @observable conDelete = false

    @observable ids = ''

    // 其他参数，例如排序、过滤等等
    @observable tableOptions = {}

    @observable isFetching = false

    @computed get dataSource() {
        return this.records
    }

    _getTableParams = () => ({ pageNum: this.pageNum, pageSize: this.pageSize, ...this.tableOptions })

    _getTabParams = () => ({ pageNum: 1, pageSize: 9999 })

    @action setPageNum(val) {
        this.pageNum = val
    }



    @action setPageSize(val) {
        this.pageSize = val
    }


    @action setIds(ids) {
        this.ids = ids
    }

    fetchRecords = () => {
        const params = this._getTableParams()

        this.isFetching = true

        getCompanyList(params).then(res => {
            this.total = res.totalCount
            this.records = res.list;

        }).finally(() => this.isFetching = false);
    }

    onDelete = (record) => {
        let id = []
        if (record !== -1) {
            id.push(record.id)
        } else {
            id = this.ids
        }
        this.isFetching = true
        let params = { 'ids': id, 'status': 0 }

        batchStatusCompany(params).then(res => {
            if (res.status === 10000) {
                this.setPageNum(1)
                this.setPageSize(10);
                this.fetchRecords()
                this.conDelete = false
            }
        }).finally(() => this.isFetching = false)
    }

    // 显示新增弹框
    // info 或许是为了编辑
    showForm = (info = {}) => {
        this.formVisible = true

        this.formTitle = '查看游戏商点位信息'

        if (info.id) {
            this.onDetail(info.id);
        }
    }

    handleClickRow = (record) => {
        return {
            onDoubleClick: () => {
                this.showForm(record, 0)
            }
        }
    }
    //二次确认删除
    confirmDelete = (data) => {
        if (this.ids.length === 0) {
            message.error(t('public.selectDelete'))
            return
        }
        this.conDelete = data
    }
    copy = (e) => {
        copy(e.target.innerText)
        message.success(t('public.copy'))
    }

    getDetail = (id) => {
        getCompanyById(id).then((res) => {
            this.record = res
        }).catch((err) => {
            message.error(t('public.error'))
        });
    }

    // 查询详情
    onDetail = (val) => {
        const params = this._getTabParams()
        params.operateCompanyId = val
        getOperatePriceInfo(params).then(res => {
            this.tableRecords = res.priceList.list;
            this.record = res
        }).finally(() => this.isFetching = false);
    }
}

export default new GameStore()