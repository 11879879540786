import React, {useEffect, useState} from 'react'

import {Input, Select, message} from "antd";
import {getAllCurrency} from '@api'
import {get, t, log, Currency} from '@tool'
import store from "./GameInfoStore";
import GameInfoRow from "./GameInfoRow";

import {startGame} from '@api'

import {AuthButton,} from '@components'

import {post} from '@http'
import {TeamOutlined} from "@ant-design/icons";

export default function OperatorIndex() {

    //本地测试机
    const [apiKey, setApiKey] = useState('')
    const [currency, setCurrency] = useState('BRL')
    const [playerName, setPlayerName] = useState('test')
    const [gameKey, setGameKey] = useState('test')
    const [currencyList, setCurrencyList] = useState([])

    let ip = '212.107.30.145'

    //获取币种
    const onGetCurrencyList = () => {
        if (currencyList.length > 0) return;
        getAllCurrency().then(res => {
            const allCurrencyList = res.map((item) => {
                return {
                    value: item.currency,
                    label: `${item.nameZh}(${item.currency})`
                }
            });
            setCurrencyList(allCurrencyList)
        }).catch(() => {
            message.error(t('public.error')).then(() => '系统错误')
        });
    }

    const openGame = (item) => {
        if (!apiKey) {
            message.error(t('public.select'))
            return
        }

        let backUrl = window.location.href

        let gameKey = item.gameKey
        setGameKey(gameKey)

        let values = "type=single&apiKey=" + apiKey + "&playerName=" + playerName + "&gameKey=" + gameKey + "&ip=" + ip + "&timestamp=" + getCurrentTimestamp() + "&currency=" + currency + "&backUrl=" + backUrl

        post(startGame, values).then(res => {
            console.log(res)

            if (res.hasOwnProperty('status') && res.status !== 10000) {
                message.error('返回状态：' + res.status + "/msg=" + res.msg)
                return
            }

            if (res.hasOwnProperty('type')) {
                if (res.type === 'html') {
                    let newPage = window.open('', '_blank')
                    newPage.document.open();
                    newPage.document.write(res.data);//运行 HTML 代码
                    newPage.document.close();
                } else {
                    window.open(res.data, '_blank')
                }
            }
        })
    }

    const openLobby = () => {
        if (!apiKey) {
            message.error(t('public.select'))
            return
        }

        let backUrl = window.location.href

        let values = "type=web-lobby&walletMode=single&apiKey=" + apiKey + "&playerName=" + playerName + "&ip=" + ip + "&timestamp=" + getCurrentTimestamp() + "&currency=" + currency + "&backUrl=" + backUrl

        post(startGame, values).then(res => {
            console.log(res)
            if (res.hasOwnProperty('type')) {
                if (res.type === 'html') {
                    let newPage = window.open('', '_blank')
                    newPage.document.open();
                    newPage.document.write(res.data);//运行 HTML 代码
                    newPage.document.close();
                } else {
                    window.open(res.data, '_blank')
                }
            }
        })
    }

    const onBlur = (e) => {
        let value = e.target.value || document.getElementsByClassName('enterCompanyName')[0]
            ?.querySelector('.ant-select-selection-item')?.innerText

        setPlayerName(value)
    }

    const setBrandName = (val) => {
        store.tableOptions = {
            brandName: val
        }

        store.fetchRecords()
    }

    const getCurrentTimestamp = () => {
        return Math.floor(Date.now() / 1000)
    }

    return (<div>
        <div style={{width: '100%'}}>
            {t('index.player')}<Input style={{width: '32vh', marginRight: '2vh'}} onBlur={onBlur} allowClear
                                      autoComplete="off" placeholder={t('public.input')} name={"playerName"}
                                      defaultValue={playerName}/>

            {t('index.carrier')}<Select
            style={{width: 160, marginRight: 30}}
            placeholder={t('public.select')}
            name="apiKey"
            showSearch
            allowClear
            onSelect={(val) => setApiKey(val)}
            options={JSON.parse(get('operatorApiKeyList'))}
        />

            币种：<Select
            style={{width: 240}}
            placeholder={'请选择币种'}
            name="currency"
            showSearch
            allowClear
            onChange={onGetCurrencyList()}
            onSelect={(val) => setCurrency(val)}>
            {currencyList.map(item => {
                return (
                    <Select.Option value={item.value} key={item.value}>
                        {item.value} ({item.label})
                    </Select.Option>
                );
            })}

        </Select>

            <AuthButton type="primary" icon={<TeamOutlined/>} style={{marginRight: 20, marginLeft: 30}}
                        onClick={() => openLobby()}>启动游戏大厅</AuthButton>

            <br/><br/>

            所属游戏商：<Select
            style={{width: 140, marginRight: 30}}
            placeholder={'请选择游戏商'}
            name="apiKey"
            defaultValue={"PG"}
            showSearch
            allowClear
            onSelect={(val) => setBrandName(val)}
            options={JSON.parse(get('gameCompanyList'))}
        />
        </div>
        <br/>
        <div style={{overflow: 'auto', height: 430}}>
            <GameInfoRow openGame={openGame}/>
        </div>
    </div>)
}