import { observable, computed, action } from 'mobx'


import copy from 'copy-to-clipboard';
import { t, getSevenDaysAgoFromDate, Datetime, get } from '@tool'
import {getTransSummaryListByGameCompany} from "../../../api";
import {message} from "antd";

class UserStore {
    @observable formVisible = false


    @observable record = {}

    @observable records = []

    // 默认第1页
    @observable pageNum = 1
    // 每页多少条
    @observable pageSize = 10
    // 总共多少页
    @observable total = ''
    @observable startTime = getSevenDaysAgoFromDate(Datetime())
    @observable endTime = Datetime()
    // 其他参数，例如排序、过滤等等
    @observable tableOptions = {}

    @observable isFetching = false
    @observable playerNameSearchType = 'eq'
    @observable platform = 'EG'

    @computed get dataSource() {
        return this.records
    }

    _getTableParams = () => ({
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        ...this.tableOptions
    })

    @action setPageNum(val) {
        this.pageNum = val
    }
    @action setPageSize(val) {
        this.pageSize = val
    }
    fetchRecords = () => {
        const params = this._getTableParams()
        this.isFetching = true
        params.startTime = params.startTime ? params.startTime : this.startTime;
        params.endTime = params.endTime ? params.endTime : this.endTime;
        params.platform = params.platform? params.platform : this.platform
        params.playerNameSearchType = this.playerNameSearchType;
        params.languages = get('Language');
        params.company_id = get('userType') == 'PLATFORM' ? get('companyId') : params.company_id
        console.log(params,"params-----------")
        getTransSummaryListByGameCompany(params).then(res => {
            console.log(res,"res")
            if (res.status === 10999){
                message.error(res.msg)
            }else {
                this.total = res.totalCount
                this.records = res.list
            }
        }).finally(() => this.isFetching = false)
    }

    copy = (e) => {
        copy(e.target.innerText)
        message.success(t('public.copy'))
    }
}

export default new UserStore()