import React from 'react'
import {observer} from 'mobx-react'
import {Button, Space, Popconfirm, Popover, Select, message} from 'antd'
import {DeleteOutlined, MinusCircleOutlined, PlusOutlined, CloudDownloadOutlined} from '@ant-design/icons'
import {TableCard, AuthButton,} from '@components'
import store from './gameStore'
import tableStore from './tableStore'
import fastStore from './fastaddgameStore'
import {t, gameGameName, pulldown} from '@tool'

const rowSelection = {
    onChange: (selectedRowKeys) => {
        store.setIds(selectedRowKeys)
    }, getCheckboxProps: (record) => ({
        disabled: record.id === 'Disabled Index', // Column configuration not to be checked
        name: record.id,
    }),
}

@observer
class ComTable extends React.Component {
    condition = localStorage.getItem('userType') !== 'PLATFORM'

    componentDidMount() {
        store.tableOptions = {
            ...store.tableOptions,
            companyType: 'OPERATOR_COMPANY'
        }
        store.fetchRecords()
    }

    onCnge = (e) => {
        tableStore.setID = e
    }
    onCnge1 = (e) => {
        fastStore.setID = e
    }
    onCnge2 = (e) => {
        store.setID = e
    }
    filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
    options = JSON.parse(localStorage.getItem('operatorList'))
    columns = [
        {
            title: "logo", width: '50px', key: 'gameIcon', dataIndex: 'gameIcon', ellipsis: true, render: (text) => {
                return (
                    <div className="ellipsis" style={{
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                    }} title={t('public.Clickcopy')} onClick={store.copy}>
                        <img src={text} style={{width: 26, height: 26}} alt={''}/>
                    </div>)
            }
        },
        {title: t('Carriergame.operator'), width: '50px', ellipsis: true, key: 'companyName', dataIndex: 'companyName'},
        {title: t('Carriergame.name'), width: '50px', ellipsis: true, key: gameGameName(), dataIndex: gameGameName()},
        {
            title: t('Carriergame.type'),
            width: '50px',
            ellipsis: true,
            key: 'gameType',
            dataIndex: 'gameType',
            render: (text) => {
                const filtered = pulldown('gameTypeOptionsSon').filter(item => item.value === text);
                if (filtered.length > 0) {
                    return filtered[0].label;
                } else {
                    return '-'; // 或者返回其他默认值
                }
            }
        },
        {
            title: t('Carriergame.ID'),
            width: '86px',
            ellipsis: true,
            key: 'gameKey',
            dataIndex: 'gameKey',
            render: (text) => {
                return (
                    <div className="ellipsis" style={{
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                    }} title={t('public.Clickcopy')} onClick={store.copy1}>
                        {text}
                    </div>)
            }
        },
        {title: '厂家名称', width: '60px', ellipsis: true, key: 'gameCompanyName', dataIndex: 'gameCompanyName'},
        {title: '品牌分类', width: '60px', ellipsis: true, key: 'gameRtp', dataIndex: 'gameCategoryCode'},
        // {title: 'RTP', width: '60px', ellipsis: true, key: 'gameRtp', dataIndex: 'gameRtp'},
        {
            title: t('public.Controls'), fixed: 'right', width: '5vh', key: 'id', render: (_, record) => {
                return (<Space size="middle">
                    <Popconfirm
                        title={t('public.sure')}
                        onConfirm={() => store.onDelete(record)}
                        okText={t('public.OK')}
                        cancelText={t('public.Cancel')}
                    >
                        <AuthButton
                            auth="operator.game.delete"
                            type={"primary"}
                            danger
                            ghost
                            icon={<DeleteOutlined/>}>
                            {t('public.delete')}
                        </AuthButton>
                    </Popconfirm>
                </Space>)
            },
        },
    ]
    content = (
        <div>
            <div>
                <Select
                    style={{width: '200px'}}
                    showSearch
                    filterOption={this.filterOption}
                    placeholder={t('Carriergame.Please')}
                    options={this.options}
                    onChange={this.onCnge}
                >
                </Select>
            </div>
            <div style={{marginTop: '10px', display: 'flex', justifyContent: 'flex-end'}}>
                <Button onClick={() => {
                    tableStore.onPopup(false)
                }}>{t('public.Cancel')} </Button>
                <Button onClick={() => {
                    this.onOpers(1)
                }} style={{marginLeft: '10px'}} type="primary">{t('public.OK')}</Button>
            </div>
        </div>
    );
    content1 = (
        <div>
            <div>
                <Select
                    style={{width: '200px'}}
                    showSearch
                    filterOption={this.filterOption}
                    placeholder={t('Carriergame.Please')}
                    options={this.options}
                    onChange={this.onCnge1}
                >
                </Select>
            </div>
            <div style={{marginTop: '10px', display: 'flex', justifyContent: 'flex-end'}}>
                <Button onClick={() => {
                    fastStore.onPopup(false)
                }}>{t('public.Cancel')}</Button>
                <Button onClick={() => {
                    this.onOpers(2)
                }} style={{marginLeft: '10px'}} type="primary">{t('public.OK')}</Button>
            </div>
        </div>
    );
    content2 = (
        <div>
            <div>
                <Select
                    style={{width: '200px'}}
                    showSearch
                    filterOption={this.filterOption}
                    placeholder={t('Carriergame.Please')}
                    options={this.options}
                    onChange={this.onCnge2}
                >
                </Select>
            </div>
            <div style={{marginTop: '10px', display: 'flex', justifyContent: 'flex-end'}}>
                <Button onClick={() => {
                    store.onPopup(false)
                }}>{t('public.Cancel')}</Button>
                <Button onClick={() => {
                    this.onOpe()
                }} style={{marginLeft: '10px'}} type="primary">{t('public.OK')}</Button>
            </div>
        </div>
    );
    handleTableChange = ({current, pageSize}, filters, sorter) => {
        store.pageNum = current
        store.pageSize = pageSize
        store.tableOptions = {
            ...store.tableOptions, // 排序：好像只支持单个排序
            sortField: sorter.field, sortOrder: sorter.order, ...filters
        }
        store.fetchRecords()
    }
    onOpers = (e) => {
        if (e === 1) {
            if (tableStore.setID !== '') {
                tableStore.onPopup(false)
                store.showForm()
                tableStore.fetchRecords()
            } else {
                message.error(t('Carriergame.Pleases'))
            }
        } else {
            if (fastStore.setID !== '') {
                fastStore.onPopup(false)
                fastStore.showForm(true)
                fastStore.fetchRecords()
            } else {
                message.error(t('Carriergame.Pleases'))
            }
        }

    }
    onQuickAdd = (e) => {

        if (this.condition) {
            if (e) {
                tableStore.onPopup(true)
            } else {
                fastStore.onPopup(true)
            }
        } else {
            if (e) {
                store.showForm()
            } else {
                fastStore.showForm(true)
            }
        }
    }
    onAdd = () => {
        if (this.condition) {
            store.onPopup(true)

        } else {
            store.managerimport()
        }

    }
    onOpe = () => {
        if (store.setID !== '') {
            store.managerimport()
            store.onPopup(false)
        } else {
            message.error(t('Carriergame.exportct'))
        }
    }

    render() {
        return (
            <div style={{background: '#fff'}}>
                <TableCard
                    size={'small'}
                    rowKey="id"
                    rowSelection={{
                        type: 'checkbox', ...rowSelection,
                    }}
                    title={t('Carriergame.carrierList')}
                    loading={store.isFetching}
                    // 后端的数据源
                    dataSource={store.dataSource}
                    onReload={store.fetchRecords}
                    onChange={this.handleTableChange}
                    actions={[
                        <Popconfirm
                            title={`${t('public.Are')}${store.ids.length}?`}
                            open={store.conDelete}
                            onConfirm={() => store.onDelete(-1)}
                            onCancel={() => {
                                store.confirmDelete(false)
                            }}
                            okText={t('public.OK')}
                            cancelText={t('public.Cancel')}
                        >
                            <AuthButton
                                type="primary"
                                onClick={() => store.confirmDelete(true)}
                                icon={<MinusCircleOutlined/>}
                                auth="operator.game.delete"
                            >     {t('public.Batchdeletion')}
                            </AuthButton>
                        </Popconfirm>,
                        <Popover
                            content={this.content2}
                            title={t('Carriergame.Please')}
                            trigger="click"
                            open={store.storeopen}
                        >
                            <AuthButton
                                type="primary"
                                icon={<CloudDownloadOutlined/>}
                                auth="operator.game.export"
                                onClick={() => this.onAdd()}>
                                {t('public.derive')}
                            </AuthButton>
                        </Popover>,
                        <Popover
                            content={this.content1}
                            title={t('Carriergame.Please')}
                            trigger="click"
                            open={fastStore.storeopen}
                        >
                            <AuthButton
                                type="primary"
                                icon={<PlusOutlined/>}
                                auth="operator.game.add"
                                onClick={() => this.onQuickAdd(false)}>
                                {t('Carriergame.Quick')}
                            </AuthButton>
                        </Popover>,
                        <Popover
                            content={this.content}
                            title={t('Carriergame.Please')}
                            trigger="click"
                            open={tableStore.storeopen}
                        >
                            <AuthButton
                                type="primary"
                                icon={<PlusOutlined/>}
                                auth="operator.game.add"
                                onClick={() => this.onQuickAdd(true)}>
                                {t('public.Newgame')}
                            </AuthButton>
                        </Popover>,
                    ]}
                    // 分页器
                    pagination={{
                        showSizeChanger: true,
                        showLessItems: true,
                        showTotal: total => t('public.intotal', total),
                        pageSizeOptions: ['10', '20', '50', '100'], // 如果不传 total，则以后端返回数据条数作为 total 的值
                        total: store.total, // 如果不传，则默认是第一条，如果需要默认显示第3条，则必须传
                        current: store.pageNum,
                        pageSize: store.pageSize,
                    }}
                    columns={this.columns}/>
            </div>

        )
    }
}

export default ComTable
