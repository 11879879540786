import { post } from "@http"

// 添加角色
export function addRole(params) {
    return post("/manager/role/save", params)
}

// 删除角色
export function removeRole(params) {
    return post("/manager/role/remove", params)
}

// 修改角色
export function updateRole(params) {
    return post("/manager/role/update", params)
}

// 查询角色列表
export function getRoleList(params) {
    return post("/manager/role/getList", params)
}

// 查询权限列表
export function getAuthTree() {
    return post("/manager/role/getAuthTree")
}

// 查询权限列表
export function getAuthListByRoleId(roleId) {
    return post("/manager/role/getAuthList/" + roleId)
}