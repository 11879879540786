
import { post } from "@http"



//总投注积分||今日投注积分
export function homePageBet(params) {
    return post("/manager/page/homePageBet", params)
}
//总输赢积分||今日输赢积分
export function homePageProfit(params) {
    return post("/manager/page/homePageProfit", params)
}
//总用户||今日活跃
export function homePageLively(params) {
    return post("/manager/page/homePageLively", params)
}
//积分提醒
export function homePageIntegralWarn(params) {
    return post("/manager/page/homePageIntegralWarn", params)
}
//上月总投注积分||本月总投注积分
export function homegetBetMonth(params) {
    return post("/manager/page/getBetMonth", params)
}
export function homegetProfitonth(params) {
    return post("/manager/page/getProfitMonth", params)
}


//充值记录列表
export function getWalletPayRecordList(params) {
    return post("/manager/page/getIntegralPayRecord", params)
}
//充值记录列表
export function homePageIntegral(params) {
    return post("/manager/page/homePageIntegral", params)
}
//每月下注和输赢记录列表
export function getIntegralByMonth(params) {
    return post("/manager/page/getIntegralByMonth",params)
}
//积分使用情况
export function getOperateAndGameCompanyList(params){
    return post("/manager/page/getOperateAndGameCompanyList",params)
}
//积分使用情况详情
export function getGameCompanyCurrencyDetail(params){
    return post("/manager/page/getGameCompanyCurrencyDetail",params)
}



//积分提醒列表
export function getIntegralWarnRecord(params) {
    return post("/manager/integral-warn-record/getIntegralWarnRecord", params)
}
//关闭预警
export function warnClose(params) {
    return post("/manager/integral-warn-record/close", params)
}



//积分预警右窗口
export function integralWarnWindow(params) {
    return post("/manager/page/integralWarnWindow", params)
}
//关闭积分提醒弹窗
export function closewindow(params) {
    return post("/manager/integral-warn-record/close-window", params)
}



//近七天下注情况
export function getBetBetweenWeek(params) {
    return post("/manager/page/getBetBetweenWeek", params)
}
//近七天活跃用户
export function getActivePlayerBetweenWeek(params) {
    return post("/manager/page/getActivePlayerBetweenWeek", params)
}
//开发调试页面按照天数更新首页图表
export function playerBetCount(params) {
    return post("/manager/addData/playerBetCount", params)
}

//导出积分使用详情
export function exportIntegralUseDetail(params) {
    return post("/manager/page/exportIntegralUseDetail", params)
}


