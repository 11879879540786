import React from 'react'
import { observer } from 'mobx-react'
import { Modal, message, Button, Input, Select } from 'antd'
import { TableCard, AuthButton, } from '@components'
import store from './fastaddgameStore'
import { pulldown,t } from '@tool'
import { SearchOutlined } from '@ant-design/icons'
import { MyForm } from '@components'
const rowSelection = {
    onChange: (selectedRowKeys) => {
        store.setIds(selectedRowKeys)
    }, getCheckboxProps: (record) => ({
        disabled: record.id === 'Disabled Index', // Column configuration not to be checked
        name: record.id,
    }),
}
@observer
class ComTable extends React.Component {
    componentDidMount() {
        store.tableOptions = {
            ...store.tableOptions,
            status: '1',
            companyType: 'OPERATOR_COMPANY'
        }
        store.fetchRecords()
    }
    columns = [
        { title: t('Gamepack.name'), width: '120px', key: 'packetName', dataIndex: 'packetName' },
        {
            title: t('Gamepack.status'), width: '20px', key: 'status', dataIndex: 'status', render: (text) => {
                const filtered = pulldown('statusOptionsSon').filter(item => item.value === String(text));
                if (filtered.length > 0) {
                    return filtered[0].label;
                } else {
                    return '-'; // 或者返回其他默认值
                }
            }
        },
        { title: t('public.remark'), width: '120px', key: 'remark', dataIndex: 'remark' },
        { title: t('Gamepack.time'), width: '100px', key: 'addTime', dataIndex: 'addTime' },
    ]
    handleTableChange = ({ current,pageSize }, filters, sorter) => {
        store.pageNum = current
        store.pageSize = pageSize
        store.tableOptions = {
            ...store.tableOptions, // 排序：好像只支持单个排序
            sortField: sorter.field, sortOrder: sorter.order, ...filters
        }
        store.fetchRecords()
    }
    onFinish = (params) => {
        // 重置为第一页
        store.setPageNum(1)
        store.tableOptions = params
        store.fetchRecords()
    }
    render() {
        return (
            <Modal
                width={'54%'}
                forceRender={true}
                open={store.formVisible}
                title={store.formTitle}
                footer={[]}
                onCancel={() => store.showForm(false)}
                style={{
                    top: '3vh',
                  }}
            >
                <MyForm onFinish={this.onFinish} initialValues={{ status: '1', packetName: ''}}>
                    <MyForm.Item span={9} title={t('Gamepack.name')} name={"packetName"}>
                        <Input allowClear name={'packetName'} autoComplete="off"
                            placeholder={`${t('public.input')} ${t('Gamepack.name')}` }/>
                    </MyForm.Item>
                    <MyForm.Item span={6}>
                        <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>{t('public.inquire')}</Button>
                    </MyForm.Item>
                </MyForm>
                <TableCard
                    scroll={{ y: '40vh' }}
                    tableLayout={'table-layou'}
                    size={'small'}
                    rowKey="id"
                    //多选
                    rowSelection={{
                        type: 'checkbox', ...rowSelection,
                    }}
                    loading={store.isFetching}
                    // 后端的数据源
                    dataSource={store.dataSource}
                    onReload={store.fetchRecords}
                    onChange={this.handleTableChange}
                    onhide={true}
                    // 分页器
                    pagination={{
                        showSizeChanger: true,
                        showLessItems: true,
                        showTotal: total => t('public.intotal',total),
                        pageSizeOptions: ['10', '20', '50', '100'], // 如果不传 total，则以后端返回数据条数作为 total 的值
                        total: store.total, // 如果不传，则默认是第一条，如果需要默认显示第3条，则必须传
                        current: store.pageNum,
                        pageSize: store.pageSize,
                    }}
                    columns={this.columns} />
                <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'flex-end' }}>
                    <Button onClick={() => store.showForm(false)}>{t('public.Cancel')}</Button>
                    <Button onClick={store.certainly} style={{ marginLeft: '10px' }} type="primary">{t('public.OK')}</Button>
                </div>

            </Modal>
        )
    }
}

export default ComTable
