import React from 'react'
import { observer } from 'mobx-react'
import { TableCard, AuthButton } from '@components'
import { CloudDownloadOutlined } from '@ant-design/icons'
import store from "./playerStore"
import { t, get } from '@tool'
import { color } from 'echarts'

@observer
class ComTable extends React.Component {
    componentDidMount() {
        store.tableOptions = {
            ...store.tableOptions,

        }
        store.fetchRecords()
    }

    options = JSON.parse(localStorage.getItem('operatorList'))

    columns = [
        {
            title: t('Bet.date'),
            width: '3vh',
            key: 'betDate',
            dataIndex: 'betDate',
            ellipsis: true,
        },

        {
            title: t('Bet.transactionId'),
            width: '5vh',
            key: 'transactionId',
            dataIndex: 'transactionId',
            ellipsis: true,
            render: (text) => {
                return (
                    <div className="ellipsis" style={{
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                    }} title={t('public.Clickcopy')} onClick={store.copy}>
                        {text}
                    </div>)
            }
        },
        {
            title: t('Bet.roundId'),
            width: '3vh',
            key: 'roundId',
            dataIndex: 'roundId',
            ellipsis: true,
        },
        {
            title: t('Bet.betAmount'),
            width: '2vh',
            key: 'betAmount',
            dataIndex: 'betAmount',
            ellipsis: true,
        },
        {
            title: t('Bet.winAmount'),
            width: '3vh',
            key: 'winAmount',
            dataIndex: 'winAmount',
            ellipsis: true,
        },
        {
            title: t('Bet.playerName'),
            width: '3vh',
            key: 'playerName',
            dataIndex: 'playerName',
            ellipsis: true,
        },
        {
            title: t('Bet.game'),
            width: '3vh',
            key: 'game',
            dataIndex: 'game'
            , ellipsis: true,

        },
        {
            title: t('Bet.operatorCompany'),
            width: '2vh',
            key: 'companyId',
            dataIndex: 'companyId',
            ellipsis: true,
            hide: get('userType') == 'PLATFORM',
            render: (text) => {
                const filtered = this.options.filter(item => String(item.value) === text);
                if (filtered.length > 0) {
                    return filtered[0].label;
                } else {
                    return '-'; // 或者返回其他默认值
                }
            }
        },
        {
            title: t('Bet.platform'),
            width: '2vh',
            key: 'platform',
            dataIndex: 'platform',
            ellipsis: true,
        },
        {
            title: t('Bet.Currency'),
            width: '2vh',
            key: 'currency',
            dataIndex: 'currency',
            ellipsis: true,
        },
        {
            title: t('Bet.betType'),
            width: '2vh',
            key: 'betType',
            dataIndex: 'betType',
            ellipsis: true,
            render: (text) => {
                if (text === 0) {
                    return "投注派彩"
                } else if (text === 1) {
                    return "投注"
                } else if (text === 2) {
                    return "派彩"
                } else if (text === 3) {
                    return "取消"
                } else {
                    return "重置"
                }
            }
        },
    ]

    handleTableChange = ({ current, pageSize }, filters, sorter) => {
        store.pageNum = current
        store.pageSize = pageSize
        store.tableOptions = {
            ...store.tableOptions, // 排序：好像只支持单个排序
            sortField: sorter.field, sortOrder: sorter.order, ...filters
        }
        store.fetchRecords()
    }

    render() {
        return (
            <div>
                <TableCard
                    tableLayout={'fixed'}
                    size={'small'}
                    scroll={{
                        x: 1500,
                        y: '38vh'
                    }}
                    rowKey="id"
                    title={t('Bet.Bettingst')}
                    titles={
                        {
                            text: '此页面只展示30天数据，查看更多数据请前往 投注历史记录列表',
                            color: 'red',
                            fontSize: '20'
                        }
                    }
                    loading={store.isFetching}
                    // 后端的数据源
                    dataSource={store.dataSource}
                    onReload={store.fetchRecords}
                    onChange={this.handleTableChange}
                    actions={[
                        <AuthButton
                            type="primary"
                            icon={<CloudDownloadOutlined />}
                            auth="game.export"
                            onClick={() => store.exportBet()}>
                            {t('public.derive')}
                        </AuthButton>,
                    ]}
                    // 分页器
                    pagination={{
                        showSizeChanger: true,
                        showLessItems: true,
                        showTotal: total => t('public.intotal', total),
                        pageSizeOptions: ['10', '20', '50', '100'], // 如果不传 total，则以后端返回数据条数作为 total 的值
                        total: store.total, // 如果不传，则默认是第一条，如果需要默认显示第3条，则必须传
                        current: store.pageNum,
                        pageSize: store.pageSize,
                    }}
                    columns={this.columns}
                />


            </div>)
    }
}

export default ComTable
