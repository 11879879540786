import { post } from "@http"

//添加公司详情
export function saveCurrency(params) {
    return post("/manager/currency/save", params)
}

export function removeCurrency(params) {
    return post("/manager/currency/remove", params)
}

export function updateCurrency(params) {
    return post("/manager/currency/update", params)
}

//查询列表
export function getCurrencyList(params) {
    return post("/manager/currency/getList", params)
}

// 授权新增
export function authCurrencyAdd(params) {
    return post("/manager/company_detail/auth/save", params)
}
// 授权修改
export function authCurrencyUpdate(params) {
    return post("/manager/company_detail/auth/update", params)
}

// 获取授权信息
export function getCompanyAuth(params) {
    return post("/manager/company_detail/getAuth", params)
}
// 获取
export function getoperatorCompanyList(params) {
    return post("/manager/company_detail/auth/operatorCompanyList", params)
}
// 删除某一条 授权信息
export function removeCompanyAuth(params) {
    return post(`/manager/company_detail/auth/remove/${params}`)
}

// 获取币种列表
export function getAllCurrency() {
    return post("/manager/currency/getAllCurrency")
}

// 加载缓存
export function loadCurrency() {
    return post("/manager/currency/load")
}

// 导入币种
export function importCurrency(params) {
    return post("/manager/currency/import", params)
}