import React, {useState} from 'react'

import {Input, Select, message} from "antd";

import {get, t, Currency} from '@tool'
import store from "./GameInfoStore";
import GameInfoRow from "./GameInfoRow";

import {startGame, getWalletTest, transferInTest, transferOutTest, regGameUserTest} from '@api'

import {AuthButton,} from '@components'

import {UpOutlined, DownOutlined, DollarCircleOutlined, TeamOutlined} from "@ant-design/icons";

import {post} from '@http'

import {v4 as uuidv4} from 'uuid'

export default function OperatorIndex() {

    //本地测试机
    const [apiKey, setApiKey] = useState('')
    const [gameApiKey, setGameApiKey] = useState('PG')
    const [currency, setCurrency] = useState('BRL')
    const [wallet, setWallet] = useState('')
    const [playerName, setPlayerName] = useState('tilyy')

    let ip = '212.107.30.145'

    const openGame = (item) => {
        if (!apiKey) {
            message.error(t('public.select'))
            return
        }

        let gameKey = item.gameKey

        let values = "type=transfer&apiKey=" + apiKey + "&playerName=" + playerName + "&gameKey=" + gameKey + "&ip=" + ip + "&timestamp=" + getCurrentTimestamp() + "&currency=" + currency

        post(startGame, values).then(res => {
            console.log(res)
            if (res.hasOwnProperty('type')) {
                if (res.type === 'html') {
                    let newPage = window.open('', '_blank')
                    newPage.document.open();
                    newPage.document.write(res.data);//运行 HTML 代码
                    newPage.document.close();
                } else {
                    window.open(res.data, '_blank')
                }
            }
        })
    }

    const onBlur = (e) => {
        let value = e.target.value || document.getElementsByClassName('enterCompanyName')[0]
            ?.querySelector('.ant-select-selection-item')?.innerText

        setPlayerName(value)
    }

    const regGameUser = () => {
        if (!apiKey) {
            message.error(t('public.select'))
            return
        }

        let values = "apiKey=" + apiKey + "&playerName=" + playerName + "&ip=" + ip + "&timestamp=" + getCurrentTimestamp() + "&nickName=" + playerName + "&currency=" + currency

        post(regGameUserTest, values).then(res => {
            setWallet(res.balanceAmount + '(' + res.currency + ')')
        })
    }

    const getWallet = () => {
        if (!apiKey) {
            message.error(t('public.select'))
            return
        }
        if (!gameApiKey) {
            message.error('请选择游戏商')
            return
        }

        let values = "type=transfer&apiKey=" + apiKey + "&playerName=" + playerName + "&ip=" + ip + "&timestamp=" + getCurrentTimestamp() + "&platformType=" + gameApiKey

        post(getWalletTest, values).then(res => {
            setWallet(res.balanceAmount + '(' + res.currency + ')')
        })
    }

    const transferIn = () => {
        if (!apiKey) {
            message.error(t('public.select'))
            return
        }
        if (!gameApiKey) {
            message.error('请选择游戏商')
            return
        }

        const uuid = uuidv4()

        let values = "apiKey=" + apiKey + "&playerName=" + playerName + "&ip=" + ip + "&timestamp=" + getCurrentTimestamp() + "&amount=50.00&platformType=" + gameApiKey + "&transactionId=" + uuid + "&currency=" + currency

        post(transferInTest, values).then(res => {
            setWallet(res.balanceAmount + '(' + res.currency + ')')
        })
    }

    const transferOut = () => {
        if (!apiKey) {
            message.error(t('public.select'))
            return
        }
        if (!gameApiKey) {
            message.error('请选择游戏商')
            return
        }

        const uuid = uuidv4()

        let values = "apiKey=" + apiKey + "&playerName=" + playerName + "&ip=" + ip + "&timestamp=" + getCurrentTimestamp() + "&amount=10.00&platformType=" + gameApiKey + "&transactionId=" + uuid + "&currency=" + currency

        post(transferOutTest, values).then(res => {
            setWallet(res.balanceAmount + '(' + res.currency + ')')
        })
    }

    const getCurrentTimestamp = () => {
        return Math.floor(Date.now() / 1000)
    }

    const setBrandName = (val) => {
        setGameApiKey(val)

        store.tableOptions = {
            brandName: val
        }

        store.fetchRecords()
    }

    return (
        <div>
            <div style={{width: '100%'}}>{t('index.player')}
                <Input style={{width: '32vh', marginRight: '2vh'}} onBlur={onBlur} allowClear
                       autoComplete="off" placeholder={t('public.input')} name={"playerName"}
                       defaultValue={playerName}/>

                {t('index.carrier')}<Select
                    style={{width: 180, marginRight: 30}}
                    placeholder={t('public.select')}
                    name="apiKey"
                    showSearch
                    onSelect={(val) => setApiKey(val)}
                    options={JSON.parse(get('operatorApiKeyList'))}
                />

                币种：<Select
                    style={{width: 220}}
                    placeholder={'请选择币种'}
                    name="currency"
                    showSearch
                    onSelect={(val) => setCurrency(val)}>
                    {Currency.map(item => {
                        return <Select.Option value={item.lable}
                                              lable={item.lable}>{item.value}({item.lable})</Select.Option>
                    })}
                </Select>

                <AuthButton type="primary" icon={<TeamOutlined/>} style={{marginRight: 20, marginLeft: 30}}
                            onClick={() => regGameUser()}>注册用户</AuthButton>

                <br/><br/>

                所属游戏商：<Select
                    style={{width: 140, marginRight: 30}}
                    placeholder={'请选择游戏商'}
                    name="apiKey"
                    defaultValue={"PG"}
                    showSearch
                    onSelect={(val) => setBrandName(val)}
                    options={JSON.parse(get('gameCompanyList'))}
                />

                <AuthButton type="primary" icon={<DollarCircleOutlined/>} style={{marginRight: '2vh'}}
                            onClick={() => getWallet()}>获取钱包</AuthButton>

                <AuthButton type="primary" icon={<UpOutlined/>} style={{marginRight: 20}}
                            onClick={() => transferIn()}>充值50</AuthButton>

                <AuthButton type="primary" icon={<DownOutlined/>} style={{marginRight: 30}}
                            onClick={() => transferOut()}>提现10</AuthButton>

                钱包余额：{wallet}
            </div>
            <br/>
            <div style={{overflow: 'auto', height: 430}}>
                <GameInfoRow openGame={openGame}/>
            </div>
        </div>)
}