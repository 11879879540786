import { post } from "@http"
import { host } from "@tool"

//添加游戏
export function addGameOperator(params) {
    return post("/manager/game-operator/add", params)
}

//删除游戏
export function batchStatusGameOperator(params) {
    return post("/manager/game-operator/batchStatus", params)
}

//获取运营商游戏列表信息
export function getGameOperatorList(params) {
    return post("/manager/game-operator/getList", params)
}

//获取游戏列表信息
export function getGameOperatorGames(params) {
    return post("/manager/game-operator/getGames", params)
}

//获取游戏商品牌列表
export function getHaveSetGameCompanyBrands(operateCompanyId) {
    return post("/manager/game-operator/getBrands/" + operateCompanyId)
}

//获取已开通的游戏商品牌列表
export function getHaveGameCompanyBrandList(operateCompanyId) {
    return post("/manager/game-operator/getHaveGameCompanyBrandList/" + operateCompanyId)
}

//导出游戏列表
export const managerimport = host + "ctgameclub/manager/game-operator/export_game"
