import {observable, computed, action} from 'mobx'
import {message} from "antd"
import {getGameOperatorList, batchStatusGameOperator, managerimport} from '@api'
import {get, t} from '@tool'

import copy from 'copy-to-clipboard';

class GameStore {
    @observable formVisible = false

    @observable formEdit = false

    @observable formTitle = ''

    @observable record = {}

    @observable records = []

    // 默认第1页
    @observable pageNum = 1
    // 每页多少条
    @observable pageSize = 10
    // 总共多少页
    @observable total = ''

    @observable ids = ''
    //运营商选中的id
    @observable setID = ''
    //选择运营商弹窗
    @observable storeopen = false
    //二次确认删除
    @observable conDelete = false
    //运营商id
    @observable storevalue = ''

    // 其他参数，例如排序、过滤等等
    @observable tableOptions = {}

    @observable isFetching = false

    @computed get dataSource() {
        return this.records
    }

    _getTableParams = () => ({
        pageNum: this.pageNum, pageSize: this.pageSize, ...this.tableOptions, status: 1,
        //是运营商就获取本地里面的id 不是就使用storevalue
        companyId: localStorage.getItem('userType') === 'PLATFORM' ? localStorage.getItem('companyId') :
            Array.isArray(this.storevalue) === true ? '' : this.storevalue
    })

    @action setPageNum(val) {
        this.pageNum = val
    }

    @action setPageSize(val) {
        this.pageSize = val
    }

    @action setIds(ids) {
        this.ids = ids
    }

    @action setId(val) {
        this.setID = val
    }

    //运营商弹窗
    onPopup = (data) => {
        this.storeopen = data
    }

    //查询
    fetchRecords = () => {
        const params = this._getTableParams()
        this.isFetching = true
        getGameOperatorList(params).then(res => {
            this.total = res.totalCount
            this.records = res.list
        }).finally(() => this.isFetching = false);
    }

    //删除
    onDelete = (record) => {
        let id = []
        if (record !== -1) {
            id.push(record.id)
        } else {
            id = this.ids
        }
        this.isFetching = true
        let params = {'ids': id, 'status': 0}

        batchStatusGameOperator(params).then(res => {
            if (res.status === 10000) {
                this.setPageNum(1)
                this.setPageSize(10);
                this.fetchRecords()
                this.conDelete = false
            }
        }).finally(() => this.isFetching = false)
    }

    // 显示新增弹框
    // info 或许是为了编辑
    showForm = () => {
        this.formVisible = true
        this.formTitle = t('public.Newgame');
        const param = get('userType') === 'PLATFORM' ? get('companyId') : null
        this.setId(param);
    }

    //二次确认删除
    confirmDelete = (data) => {
        if (this.ids.length === 0) {
            message.error(t('public.selectDelete'))
            return
        }
        this.conDelete = data
    }

    copy = (e) => {
        copy(e.target.currentSrc)
        message.success(t('public.copy'))
    }

    copy1 = (e) => {
        copy(e.target.innerText)
        message.success(t('public.copy'))
    }

    managerimport = () => {
        const param = this.setID == '' ? localStorage.getItem('companyId') : this.setID
        window.open(`${managerimport}?companyId=${param}`)
    }

}

export default new GameStore()