import {action, computed, observable} from 'mobx'
import {message} from "antd"
import copy from 'copy-to-clipboard';
import {authCurrencyAdd, authCurrencyUpdate, getAllCurrency, getCompanyAuth, removeCompanyAuth,} from '@api'
import {t} from '@tool'

class GameStore {
    @observable formVisible = false

    @observable formTitle = t('CompanyAuthorize.AuthList');

    @observable record = {}

    @observable records = []

    // 默认第1页
    @observable pageNum = 1
    // 每页多少条
    @observable pageSize = 10
    // 总共多少页
    @observable total = ''
    //二次确认删除
    @observable conDelete = false

    @observable currencyList = []

    @observable setCurrencyOption = []

    @observable currencyVal = null

    @observable changeIntegralVal = null

    @observable rtpVal = null

    @observable walletModeVal = 'single'


    // 其他参数，例如排序、过滤等等
    @observable tableOptions = {}

    @observable isFetching = false

    @computed get dataSource() {
        return this.records
    }

    // 二级弹窗弹窗
    @observable formVisibleson = false;

    @observable recordson = {};

    @observable formEditson = false;

    @observable loading = false;

    //二级积分值弹窗
    @observable formVisbleIntegral = false;

    @observable formEditIntegral = false;

    //积分值确认框
    @observable formVisbleConfirmIntegral = false;
    @observable formEditConfirmIntegral = false;

    //二次确认积分充值
    @observable ConfirmIntegral = false

    _getTableParams = () => ({
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        companyId: this.record.companyId,
        currency: this.currencyVal,
        rtp: this.rtpVal,
        walletMode: this.walletModeVal,
        companyType: 'OPERATOR_COMPANY',
        changeIntegral: this.record.changeIntegral,
        ...this.tableOptions,
    })

    @action setLoading(val) {
        this.loading = val
    }

    @action setcurrency(val) {
        this.currencyVal = val
    }

    @action setrtp(val) {
        this.rtpVal = val
    }

    @action setwalletMode(val) {
        this.walletModeVal = val
    }

    @action setChangeIntegral(val) {
        this.changeIntegralVal = val
    }

    @action setPageNum(val) {
        this.pageNum = val
    }

    @action setPageSize(val) {
        this.pageSize = val
    }

    //获取币种
    onGetCurrencyList = () => {
        if (this.setCurrencyOption.length > 0) return;
        getAllCurrency().then(res => {
            this.setCurrencyOption = res.map((item) => {
                return {
                    value: item.currency,
                    label: `${item.nameZh}(${item.currency})`
                }
            });
        }).catch(() => {
            message.error(t('public.error')).then(() => '系统错误')
        });
    }

    //列表
    fetchRecords = () => {
        const params = this._getTableParams();
        this.isFetching = true;
        getCompanyAuth(params).then(res => {
            this.total = res.totalCount
            this.records = res.list
        }).finally(() => this.isFetching = false)
    }
    //搜索
    onSearch = () => {
        // 重置为第一页
        this.setPageNum(1)
        this.fetchRecords();
    }
    //删除
    onDelete = (val) => {
        console.log(val);
        this.isFetching = true;
        removeCompanyAuth(val.id).then(res => {
            if (res > 0) {
                message.success(t('public.success'));
                this.fetchRecords();
            }
        }).catch(() => {
            message.error(t('public.error'))
        }).finally(() => this.isFetching = false)
    }
    //二次确认删除
    confirmDelete = (data) => {
        if (this.ids.length === 0) {
            message.error(t('public.selectDelete'))
            return
        }
        this.conDelete = data
    }

    //二次确认积分充值
    conConfirmIntegral = (data) => {
        this.ConfirmIntegral = data
    }

    // 添加||修改
    onFinish = (values) => {
        const params = JSON.parse(JSON.stringify(values));
        params.id = this.recordson.id ? this.recordson.id : '';
        params.companyType = this.recordson.id ? '' : 'OPERATOR_COMPANY';
        const API = this.recordson.id ? authCurrencyUpdate : authCurrencyAdd;
        params.companyId = this.record.id;
        this.setLoading(true);
        API({...params}).then(res => {
            if (res > 0) {
                message.success(t('public.success'));
                this.formVisibleson = false;
                this.fetchRecords()
            } else {
                message.error(res.msg);
            }
        }).finally(() => {
            this.setLoading(false);
        })

    };

    // 显示弹框
    showForm = (info = {}, disc) => {
        if (this.setCurrencyOption.length === 0) {
            this.onGetCurrencyList();
        }
        this.formVisible = true;
        this.record = info;
    }
    // 显示二级弹窗弹框
    showFormSon = (info = {}, disc, swhe) => {
        this.formVisibleson = true;
        this.recordson = info;
        if (disc === 'Edit') {
            this.formTitleson = t('public.edit');
            this.formEditson = swhe;
        } else if (disc === 'Add') {
            this.formTitleson = t('public.new');
            this.formEditson = swhe;
        } else {
            this.formTitleson = t('public.view');
            this.formEditson = swhe;
        }
    }

    //显示修改积分二级弹窗弹框
    showFormIntegral = (info = {}, disc, swhe) => {
        this.formVisbleIntegral = true;
        this.recordson = info;
        this.formTitleIntegral = t('operator.EditIntegral');
        this.formEditIntegral = swhe;
    }
    //双击查看详情
    handleClickRow = (record) => {
        return {
            onDoubleClick: () => {
                this.showFormSon(record, 'View', true)
            }
        }
    }

    copy = (e) => {
        copy(e.target.innerText)
        message.success(t('public.copy'))
    }
}

export default new GameStore()