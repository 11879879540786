import React, {useState, useEffect} from 'react'
import {Switch, Route} from 'react-router-dom'
import {Layout} from 'antd'
// 404 对应的组件
import {NotFound} from '@components'
// 侧边栏
import Sider from './Sider'
// 头部
import Header from './Header'
// 页脚。例如版权
import Footer from './Footer'

import routes from '../router/routes'
import Pointnotification from './Pointnotification'

import {hasPermission} from '@http'

// 布局样式，直接拷贝 spug 中的样式即可
import styles from './layout.module.less'

// 将 route 中有权限的路由提取到 Routes 中
function initRoutes(Routes, routes) {
    let menu = []
    for (let route of routes) {
        // 叶子节点才有 component。没有 child 则属于叶子节点
        if (route.component) {
            // 如果不需要权限，或有权限则放入 Routes
            if (!route.auth || hasPermission(route.auth)) {
                if (route.label) {
                    menu.push(route)
                }
                Routes.push(<Route exact key={route.path} label={route.label} path={route.path}
                                   component={route.component}/>)
            }
        } else if (route.children) {
            if (!route.auth || hasPermission(route.auth)) {
                route['children'] = initRoutes(Routes, route.children)
                menu.push(route)
            }
        }
    }
    return menu
}

export default function () {

    // 侧边栏收缩状态。默认展开
    const [collapsed, setCollapsed] = useState(false)
    const [title, setTitle] = useState(false)
    // 路由，默认是空数组
    const [Routes, setRoutes] = useState([])
    const [menus, setMenus] = useState([])

    // 组件挂载后执行。相当于 componentDidMount()
    useEffect(() => {
        // 注：重新声明一个变量 Routes，比上文（useState 中的 Routes）的 Routes 作用域更小范围
        const Routes = []
        setMenus(initRoutes(Routes, routes))
        setRoutes(Routes)
    }, [])

    return (<Layout>
        {/* 左侧区域，对 antd 中 Sider 的封装 */}
        <Sider collapsed={collapsed} setTitle={setTitle} menus={menus}/>
        {/* 内容高度不够，版权信息在底部；内容高度太高，则需要滚动才可查看全部内容； */}
        <Layout style={{height: '100vh'}}>
            {/* 顶部区域， 对 antd 中 Layout.Header 的封装*/}
            <Header collapsed={collapsed} toggle={() => setCollapsed(!collapsed)} title={title}/>
            <Layout.Content className={styles.content}>
                {/* 只渲染第一个路径匹配的组件*/}
                <Switch>
                    {/* 路由数组。里面每项类似这样：<Route exact key={route.path} path='/home' component={HomeComponent}/> */}
                    {Routes}
                    {/* 没有匹配则进入 NotFound */}
                    <Route component={NotFound}/>
                </Switch>
                {/* 系统底部展示。例如版权、官网、文档链接、仓库链接*/}
                <Footer/>
            </Layout.Content>
        <Pointnotification />
        </Layout>
    </Layout>)
}
