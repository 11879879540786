import React, {useEffect, useState} from 'react'

import {observer} from 'mobx-react'
import {Modal, Form, Table, Row, Col,} from 'antd'

import store from './OpenOperatorStore'

import {log, t} from '@tool'


export default observer(function () {
    // 文档中未找到这种解构使用方法
    const [form] = Form.useForm()

    // useState 函数组件中使用 state
    // loading 默认是 flase
    const [loading, setLoading] = useState(false)

    useEffect(() => {
    }, [store.record])


    const handleSubmit = () => {
        form.submit()
    }

    const onFinish = (values) => {

    }

    const setOpenType = (openType) => {
        let text = '新开户'
        if (openType === "newCurrency") {
            text = '新增币种开户'
        } else if (openType === 'editPrice') {
            text = '修改点位开户'
        } else if (openType === 'newCurrencyAndEditPrice') {
            text = '新增币种并且修改了点位'
        }

        return text
    }

    const columns = [
        {title: '游戏商名称', dataIndex: 'gameCompanyName'},
        {title: '币种', dataIndex: 'currency'},
        {title: '点位', dataIndex: 'price'}
    ];

    return (// Modal 对话框
        <Modal
            width={'72%'}
            forceRender={true}
            open={store.formVisible}
            title={store.formTitle}
            onCancel={() => store.formVisible = false}
            confirmLoading={loading}
        >
            <Form form={form} labelCol={{span: 7}} onFinish={onFinish}>
                <Row gutter={{md: 8, lg: 24, xl: 48}}>
                    <Col span={8}>
                        <Form.Item label={'商务姓名'} name={'saleUserName'}>
                            {store.record.saleUserName}
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label={'商户名称'} name={'companyName'}>
                            {store.record.companyName}
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label={'商户编号'} name={'companyNumber'}>
                            {store.record.companyNumber}
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={{md: 8, lg: 24, xl: 48}}>
                    <Col span={8}>
                        <Form.Item label={'申请开户时间'} name={'extractTime'}>
                            {store.record.extractTime}
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label={'商户邮箱'} name={'email'}>
                            {store.record.email}
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label={'钱包类型'} name={'walletMode'}>
                            {store.record.walletMode === 'single' ? '单一钱包' : '转账钱包'}
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={{md: 8, lg: 24, xl: 48}}>
                    <Col span={8}>
                        <Form.Item label={'本次申请类型'} name={'openType'}>
                            {setOpenType(store.record.openType)}
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={{md: 8, lg: 24, xl: 48}}>
                    <Col span={8}>
                        <Form.Item label={'开通币种详情'}/>
                    </Col>
                </Row>

                <Row gutter={{md: 8, lg: 24, xl: 48}}>
                    <Col span={24}>
                        <Table columns={columns} dataSource={store.record.priceList}></Table>
                    </Col>
                </Row>

            </Form>
        </Modal>)
})