import React from 'react'
import { observer } from 'mobx-react'
import { TableCard } from '@components'
import store from './callbackStore'
import { t } from '@tool'



@observer
class ComTable extends React.Component {
    componentDidMount() {
        store.tableOptions = {
            ...store.tableOptions, status: 1
        }
        store.fetchRecords()
    }

    columns = [
        { title: t('Callback.name'), width: '7vh', key: 'name', dataIndex: 'name', ellipsis: true, }
        ,
        {
            title: 'IP',
            width: '4vh',
            key: 'ip',
            dataIndex: 'ip', ellipsis: true,
        },
        {
            title: t('Callback.type'),
            width: '4vh',
            key: 'type',
            dataIndex: 'type',
            ellipsis: true,
        },
        { title: t('Callback.time'), width: '3vh', key: 'addTime', dataIndex: 'addTime', ellipsis: true, },
        { title: t('Callback.content'), width: '10vh', key: 'content', dataIndex: 'content', ellipsis: true, },
    ]

    handleTableChange = ({ current, pageSize }, filters, sorter) => {
        store.pageNum = current
        store.pageSize = pageSize
        store.tableOptions = {
            ...store.tableOptions, // 排序：好像只支持单个排序
            sortField: sorter.field, sortOrder: sorter.order, ...filters
        }
        store.fetchRecords()
    }
    render() {
        return (
            <div>
                <TableCard
                    tableLayout={'fixed'}
                    size={'small'}
                    scroll={{
                        x: 1500,
                        y: '48vh'
                    }}
                    handleClickRow={store.handleClickRow}
                    rowKey="id"
                    title={t('Callback.list')}
                    loading={store.isFetching}
                    // 后端的数据源
                    dataSource={store.dataSource}
                    onReload={store.fetchRecords}
                    onChange={this.handleTableChange}
                    actions={[]}
                    // 分页器
                    pagination={{
                        showSizeChanger: true,
                        showLessItems: true,
                        showTotal: total => t('public.intotal', total),
                        pageSizeOptions: ['10', '20', '50', '100'], // 如果不传 total，则以后端返回数据条数作为 total 的值
                        total: store.total, // 如果不传，则默认是第一条，如果需要默认显示第3条，则必须传
                        current: store.pageNum,
                        pageSize: store.pageSize,
                    }}
                    columns={this.columns} />

            </div>)
    }
}

export default ComTable
