import { observable } from 'mobx'

import { getAuthTree } from '@api'
import { message } from "antd"

import { log, t } from '@tool'

class UserStore {
    @observable formVisible = false


    @observable formTitle = t('分配权限')

    @observable record = {}

    @observable records = []


    fetchRecords = () => {
        getAuthTree().then(res => {
            this.records = res.map((item) => {
                return {
                    title: item.authName,
                    key: item.id,
                    children: item.children.map((ie) => {
                        return {
                            title: ie.authName,
                            key: ie.id,
                        }
                    })
                }
            })
        }).finally(() => { })
    }

    // 显示新增弹框
    onshowForm = (info = {}) => {
        this.formVisible = true;
        this.formTitle = '分配权限';
        this.record = info;
        this.fetchRecords();
    }

}

export default new UserStore()