import React from 'react'

import {observer} from 'mobx-react'
import {Popconfirm, Space, Input, Modal, message} from 'antd'
import {
    DeleteOutlined,
    EditOutlined,
    CloudDownloadOutlined,
    MinusCircleOutlined,
    PlusOutlined,
    CloudUploadOutlined
} from '@ant-design/icons'

import {TableCard, AuthButton,} from '@components'
import store from './GameStore'
import {pulldown, gameGameName, t} from '@tool'
import {importGame} from '@api'

const rowSelection = {
    onChange: (selectedRowKeys) => {
        store.setIds(selectedRowKeys)
    }, getCheckboxProps: (record) => ({
        disabled: record.id === 'Disabled Index', // Column configuration not to be checked
        name: record.id,
    }),
}

@observer
class ComTable extends React.Component {

    componentDidMount() {
        store.tableOptions = {
            ...store.tableOptions, status: 1
        }
        store.fetchRecords()
    }

    columns = [
        {
            title: 'gameId',
            width: '80px',
            key: 'gameId',
            dataIndex: 'gameId',
            ellipsis: true,
            render: (text) => {
                return (
                    <div className="ellipsis" style={{

                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                    }} title={t('public.Clickcopy')} onClick={store.copy}>
                        {text}
                    </div>)
            }
        },
        {
            title: "logo", width: '40px', key: 'gameIcon', dataIndex: 'gameIcon', ellipsis: true, render: (text) => {
                return (<img src={text} style={{width: 26, height: 26}} alt={''}/>)
            }
        },
        {title: t('Gamemanage.gameNameZh'), width: '80px', key: 'gameNameZh', dataIndex: 'gameNameZh', ellipsis: true,},
        {
            title: t('Gamemanage.name'), width: '80px',
            key: 'gameName',
            dataIndex: 'gameName',
            ellipsis: true
        },
        {
            title: t('Gamemanage.Affiliated'), width: '70px', key: 'companyName',
            dataIndex: 'companyName', ellipsis: true,
        },
        {
            title: t('Gamemanage.status'),
            width: '50px',
            key: 'status',
            dataIndex: 'status',
            ellipsis: true,
            render: (text) => (text === 1) ? t('public.effective') : t('public.invain'),
        }, {
            title: t('Gamemanage.type'),
            width: '60px',
            key: 'gameType',
            dataIndex: 'gameType',
            ellipsis: true,
            render: (text) => {
                const filtered = pulldown('gameTypeOptionsSon').filter(item => item.value === text);
                if (filtered.length > 0) {
                    return filtered[0].label;
                } else {
                    return '-'; // 或者返回其他默认值
                }
            }
        }, {
            title: 'gameKey',
            width: '150px',
            key: 'gameKey',
            dataIndex: 'gameKey',
            ellipsis: true,
            render: (text) => {
                return (
                    <div className="ellipsis" style={{

                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                    }} title={t('public.Clickcopy')} onClick={store.copy}>
                        {text}
                    </div>)
            }
        },
        {
            title: t('Gamemanage.Degree'),
            width: '80px',
            key: 'gameRanking',
            dataIndex: 'gameRanking',
            ellipsis: true,
            render: (text) => {
                let val = '\uD83D\uDD25'
                if (text === '5') {
                    return val + val + val + val + val
                } else if (text === '4') {
                    return val + val + val + val
                } else if (text === '3') {
                    return val + val + val
                } else if (text === '2') {
                    return val + val
                } else if (text === '1') {
                    return val
                }
                return ''
            }
        },
        // {title: t('Gamemanage.rebate'), width: '60px', key: 'gameRtp', dataIndex: 'gameRtp', ellipsis: true,},
        {
            title: t('public.Controls'), fixed: 'right', width: '140px', key: 'id', render: (_, record) => {
                return (<Space size="middle">
                    <AuthButton
                        type={"primary"}
                        ghost
                        icon={<EditOutlined/>}
                        auth="game.edit"
                        onClick={() => store.showForm(record, true)}>
                        {t('public.edit')}
                    </AuthButton>
                    <Popconfirm
                        title={t('public.sure')}
                        onConfirm={() => store.onDelete(record)}
                        okText={t('public.OK')}
                        cancelText={t('public.Cancel')}
                    >
                        <AuthButton
                            type={"primary"}
                            danger
                            auth="game.delete"
                            ghost
                            icon={<DeleteOutlined/>}>
                            {t('public.delete')}
                        </AuthButton>
                    </Popconfirm>
                </Space>)
            },
        },]

    handleTableChange = ({current, pageSize}, filters, sorter) => {
        store.pageNum = current
        store.pageSize = pageSize
        store.tableOptions = {
            ...store.tableOptions, // 排序：好像只支持单个排序
            sortField: sorter.field, sortOrder: sorter.order, ...filters
        }
        store.fetchRecords()
    }

    onDownload = () => {

        if (importGame.indexOf("localhost") === -1) {
            let url = importGame.replace(':8880/data/', '/upload/')
            window.open(url)
        } else {
            window.open(importGame)
        }
    }


    state = {
        inputValue: '', // 输入框的值
        modalVisible: false, // 控制模态框的显示隐藏
    };

    handleInputChange = (e) => {
        this.setState({inputValue: e.target.value}); // 更新输入框的值
    };

    handleModalOk = () => {
        this.onDownloadIcon(); // 点击模态框确定按钮时触发下载方法
        this.setState({modalVisible: false}); // 关闭模态框
    };

    handleModalCancel = () => {
        this.setState({modalVisible: false}); // 关闭模态框
    };

    openModal = () => {
        this.setState({modalVisible: true}); // 打开模态框
    };

    onDownloadIcon = () => {
        const count = parseInt(this.state.inputValue, 10);
        if (!isNaN(count) && count > 0) {
            store.dwonloadGameIcon(count);
        } else {
            message.error("请输入正整数")
        }
    };


    render() {
        return (
            <TableCard
                tableLayout={'fixed'}
                size={'small'}
                // scroll={{
                //     x: 1500,
                //     y: '38vh'
                // }}
                handleClickRow={store.handleClickRow}
                rowKey="id"
                rowSelection={{
                    type: 'checkbox', ...rowSelection,
                }}
                title={t('Gamemanage.listgames')}
                loading={store.isFetching}
                // 后端的数据源
                dataSource={store.dataSource}
                onReload={store.fetchRecords}
                onChange={this.handleTableChange}
                actions={[
                    // <AuthButton
                    //     type="primary"
                    //     icon={<CloudDownloadOutlined/>}
                    //     onClick={this.openModal} // 点击按钮打开模态框
                    // >
                    //     {t('Gamemanage.DownloadPPIcon')}
                    // </AuthButton>,

                    // <Modal
                    //     title={t('Gamemanage.DownloadCount')}
                    //     open={this.state.modalVisible}
                    //     onOk={this.handleModalOk} // 点击模态框确定按钮
                    //     onCancel={this.handleModalCancel} // 点击模态框取消按钮或蒙层时
                    // >
                    //     <Input
                    //         value={this.state.inputValue}
                    //         onChange={this.handleInputChange}
                    //         placeholder={`${t('public.input')}${t('Gamemanage.DownloadCount')}`}
                    //     />
                    // </Modal>,
                    <AuthButton type="primary"
                                icon={<CloudDownloadOutlined/>}
                                onClick={() => this.onDownload()}>
                        {t('public.template')}
                    </AuthButton>,

                    <AuthButton
                        type="primary"
                        icon={<CloudUploadOutlined/>}
                        auth="game.import"
                        onClick={() => this.props.showUploadFrom(1)}>
                        {t('public.lead')}
                    </AuthButton>,
                    <AuthButton
                        type="primary"
                        icon={<CloudDownloadOutlined/>}
                        auth="game.export"
                        onClick={() => store.exportGame()}>
                        {t('public.derive')}
                    </AuthButton>,
                    <Popconfirm
                        title={` ${t('public.Are')}${store.ids.length}?`}
                        open={store.conDelete}
                        onConfirm={() => store.onDelete(-1)}
                        onCancel={() => {
                            store.confirmDelete(false)
                        }}
                        okText={t('public.OK')}
                        cancelText={t('public.Cancel')}
                    >
                        <AuthButton
                            type="primary"
                            icon={<MinusCircleOutlined/>}
                            auth="game.delete"
                            onClick={() => store.confirmDelete(true)}>
                            {t('public.Batchdeletion')}
                        </AuthButton>
                    </Popconfirm>
                    ,
                    <AuthButton
                        type="primary"
                        icon={<PlusOutlined/>}
                        auth="game.add"
                        onClick={() => store.showForm({}, true)}>
                        {t('public.new')}
                    </AuthButton>
                ]}

                // 分页器
                pagination={{
                    showSizeChanger: true,
                    showLessItems: true,
                    showTotal: total => t('public.intotal', total),
                    pageSizeOptions: ['10', '20', '50', '100'], // 如果不传 total，则以后端返回数据条数作为 total 的值
                    total: store.total, // 如果不传，则默认是第一条，如果需要默认显示第3条，则必须传
                    current: store.pageNum,
                    pageSize: store.pageSize,
                }}
                columns={this.columns}/>)
    }
}

export default ComTable
