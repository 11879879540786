import React, { useState } from 'react'
import { Layout, Dropdown, Avatar, Space, Modal } from 'antd'
import { MenuFoldOutlined, MenuUnfoldOutlined, LogoutOutlined, SettingOutlined } from '@ant-design/icons'

import styles from './layout.module.less'
import { history } from '@http'
import avatar from '../assets/img/avatar.png'
import { get, removeToken, log, t } from "@tool"
import Internation from './internation'
import { loginOut } from '@api'
import PwdModal from "@components/PwdModal";

export default function HeaderIndex(props) {
    const [pwdVisible, setPwdVisible] = useState(false);
    const [open, setOpen] = useState(false);
    const [openes, setOpenes] = useState(false);
    const items = [
        { label: <Space><SettingOutlined style={{ marginRight: 10 }} />{t('header.cipher')} </Space>, key: 'updatePwd' },
        { label: <Space><LogoutOutlined style={{ marginRight: 10 }} />{t('header.out')}</Space>, key: 'logout' },
    ]
    const handleMenuClick = (item) => {
        if ("logout" === item.key) {
            setOpen(true)
            setOpenes(false)
        } else {
            setOpenes(false)
            setOpen(false)
            setPwdVisible(true)
        }
    }
    const handleOk = () => {
        confirm()
        setOpen(false);
    };
    const handleCancel = () => {
        setOpen(false);
    };
    // 告诉后端退出登录
    const confirm = () => {
        setOpen(false)
        setOpenes(false)
        loginOut().then(res => {
            if (res.hasOwnProperty("status") && res.status == 10000) {
                removeToken()
                history.push('/')
            }
        }, (error) => log(error))
    };
    const handleOpenChange = (nextOpen, info) => {
        if (open === true) {
            setOpenes(true)
        } else {
            setOpenes(nextOpen)
        }
    };
    return (<Layout.Header className={styles.header}>
        {/* 收缩左侧导航按钮 */}
        <div className={styles.left}>
            {/* 点击触发父组件的 toggle 方法 */}
            <div className={styles.trigger} onClick={props.toggle}>
                {/* 根据父组件的 collapsed 属性显示对应图标*/}
                {props.collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            </div>
            <div style={{ fontWeight: 'bold' }}>{props.title}</div>
        </div>
        {/* 通知 */}
        <div></div>
        <Internation />
        <div className={styles.right}>
            <Dropdown
                menu={{ items, onClick: handleMenuClick }}
                style={{ background: '#000' }}
                onOpenChange={handleOpenChange}
                open={openes}
            >
                <span className={styles.action} style={{ paddingRight: 20 }}>
                    {t('header.nickname')}
                    <Avatar size="small" src={avatar} style={{ marginRight: 8 }} />
                    {/* 登录后设置过的昵称 */}
                    <span style={{ fontWeight: 'bold' }}>{get('nickName')}</span>
                </span>
            </Dropdown>
            <Modal width={'18%'} title={t('header.tips')} open={open} onOk={handleOk} onCancel={handleCancel}>
                <p style={{ width: '100%', fontSize: '18px', textAlign: "center" }}> {t('header.Tips')}</p>
            </Modal>
        </div>
        <PwdModal pwdVisible={pwdVisible} setPwdVisible={setPwdVisible} />
    </Layout.Header >)
}