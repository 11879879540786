import {get} from '../token'

// 准许。权限相关。模块私有
let Permission = {
    isReady: false, isSuper: false, permissions: []
}

//  数组包含关系判断
export function isSubArray(parent, child) {
    for (let item of child) {
        if (!parent.includes(item.trim())) {
            return false
        }
    }
    return true
}

export let X_TOKEN

export function updatePermissions() {
    // 读取 localStorage 项
    // 只在登录时设置：localStorage.setItem('token'
    X_TOKEN = get('token')
    Permission.isReady = true
    Permission.isSuper = get('isSupper') === 'true'
    try {
        Permission.permissions = JSON.parse(get('permissions') || '[]')
    } catch (e) {
    }
}

// 前端页面的权限判断(仅作为前端功能展示的控制，具体权限控制应在后端实现)
export function hasPermission(strCode) {
    const {isSuper, permissions} = Permission

    if (!permissions && !isSuper) {
        return false
    }

    if (!strCode || isSuper) return true
    for (let or_item of strCode.split('|')) {
        if (isSubArray(permissions, or_item.split('&'))) {
            return true
        }
    }
    return false
}