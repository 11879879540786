import React from 'react'
import {observer} from 'mobx-react'
import {Button, Space, Popconfirm} from 'antd'
import {DeleteOutlined, LeftOutlined, MinusCircleOutlined, PlusOutlined} from '@ant-design/icons'
import {TableCard, AuthButton,} from '@components'
import {history} from '@http'
import store from './gameStore'
import {pulldown, gameGameName, t, log} from '@tool'

const rowSelection = {
    onChange: (selectedRowKeys) => {
        store.setIds(selectedRowKeys)
    }, getCheckboxProps: (record) => ({
        disabled: record.id === 'Disabled Index', // Column configuration not to be checked
        name: record.id,
    }),
}

@observer
class ComTable extends React.Component {
    componentDidMount() {
        store.tableOptions = {
            ...store.tableOptions,
            companyType: 'OPERATOR_COMPANY'
        }
        store.fetchRecords()
    }

    columns = [
        {title: t('Carriergame.name'), width: '80px', key: gameGameName(), dataIndex: gameGameName()},
        {title: t('Carriergame.Affiliated'), width: '80px', key: 'companyName', dataIndex: 'companyName'},
        {
            title: t('Carriergame.type'), width: '60px', key: 'gameType', dataIndex: 'gameType', render: (text) => {
                const filtered = pulldown('gameTypeOptionsSon').filter(item => item.value === text);
                if (filtered.length > 0) {
                    return filtered[0].label;
                } else {
                    return '-'; // 或者返回其他默认值
                }
            }
        },
        {title: t('Carriergame.ID'), width: '86px', key: 'gameKey', dataIndex: 'gameKey'},
        {title: t('Carriergame.Bonus'), width: '60px', key: 'gameRtp', dataIndex: 'gameRtp'},
        {
            title: t('public.Controls'), width: '140px', key: 'id', render: (_, record) => {
                return (<Space size="middle">
                    <Popconfirm
                        title={t('public.sure')}
                        onConfirm={() => store.onDelete(record)}
                        okText={t('public.OK')}
                        cancelText={t('public.Cancel')}
                    >
                        <AuthButton
                            auth="operator.game.delete"
                            type={"primary"}
                            danger
                            ghost
                            icon={<DeleteOutlined/>}>
                            {t('public.delete')}
                        </AuthButton>
                    </Popconfirm>
                </Space>)
            },
        },
    ]
    handleTableChange = ({current, pageSize}, filters, sorter) => {
        store.pageNum = current
        store.pageSize = pageSize
        store.tableOptions = {
            ...store.tableOptions, // 排序：好像只支持单个排序
            sortField: sorter.field, sortOrder: sorter.order, ...filters
        }
        store.fetchRecords()
    }

    onShowSizeChange = (current, pageSize) => {
        log(current, pageSize);
    };

    render() {
        return (
            <div style={{background: '#fff'}}>
                <Button style={{margin: '10px'}} type="primary" onClick={() => history.goBack()}
                        icon={<LeftOutlined/>}>{t('public.Back')}</Button>
                <TableCard
                    tableLayout={'fixed'}
                    size={'small'}
                    rowKey="id"
                    rowSelection={{
                        type: 'checkbox', ...rowSelection,
                    }}
                    title={t('Carriergame.carrierList')}
                    loading={store.isFetching}
                    // 后端的数据源
                    dataSource={store.dataSource}
                    onReload={store.fetchRecords}
                    onChange={this.handleTableChange}
                    actions={[
                        <Popconfirm
                            title={`${t('public.Are')}${store.ids.length}?`}
                            open={store.conDelete}
                            onConfirm={() => store.onDelete(-1)}
                            onCancel={() => {
                                store.confirmDelete(false)
                            }}
                            okText={t('public.OK')}
                            cancelText={t('public.Cancel')}
                        >
                            <AuthButton
                                type="primary"
                                onClick={() => store.confirmDelete(true)}
                                icon={<MinusCircleOutlined/>}
                                auth="operator.game.delete"
                            >
                                {t('public.Batchdeletion')}
                            </AuthButton>
                        </Popconfirm>
                        ,
                        <AuthButton type="primary" icon={<PlusOutlined/>} auth="operator.game.add"
                                    onClick={() => store.showForm()}>{t('public.Newgame')}</AuthButton>,
                    ]}
                    // 分页器
                    pagination={{
                        showSizeChanger: true,
                        showLessItems: true,
                        showTotal: total => t('public.intotal', total),
                        pageSizeOptions: ['10', '20', '50', '100'], // 如果不传 total，则以后端返回数据条数作为 total 的值
                        total: store.total, // 如果不传，则默认是第一条，如果需要默认显示第3条，则必须传
                        current: store.pageNum,
                        pageSize: store.pageSize,
                    }}
                    columns={this.columns}/>
            </div>

        )
    }
}

export default ComTable
