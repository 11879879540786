import React, {useState} from "react"

import {SearchOutlined} from '@ant-design/icons'

import {Button, Input, Select} from "antd"

import store from "./GameStore"
import ComTable from "./GameTable"
import ComForm from "./GameForm"
import {UploadForm} from "@components"

import {pulldown, log, t} from '@tool'

import {MyForm} from '@components'
import {getCompanyList} from '@api'

export default function GameIndex() {
    const [uploadVisible, setUploadVisible] = useState(false)
    const [companyList, setCompanyList] = useState([]) //模糊企业搜索下拉
    const [keyword, setKeyword] = useState('') //模糊关键词

    // 防抖
    const deBounce = (fn, delay = 1000) => {
        let timer = null
        // 闭包
        return function f(args) {
            if (timer) clearTimeout(timer)
            timer = setTimeout(() => {
                timer = null
                fn.call(this, args)
            }, delay)
        }
    }

    // 模糊搜索
    const getCompany = async (value) => {
        let params = {
            status: 1
        }
        if (value) {
            params['companyName'] = value
        }
        getList(params)
    }

    // 点击查询，通过公司名获取公司信息
    const getList = (params) => {
        if (!params) {
            params = {
                status: 1,
                pageNum: 1,
                pageSize: 99999,
                companyType: 'GAME_COMPANY'
            }
        }
        getCompanyList(params).then(res => {
            if (res.hasOwnProperty('list')) {
                let list = [{
                    companyId: '0',
                    companyName: '全部',
                }]
                res.list.map((item) => {
                    list.push(item)
                })
                setCompanyList(list)
            }
        })
    }

    const onFinish = (params) => {
        // 重置为第一页
        store.setPageNum(1)
        store.tableOptions = params
        store.fetchRecords()
    }

    const showUploadFrom = (val) => {
        if (val === 3) {
            store.fetchRecords()
        }
        setUploadVisible(val === 1)
    }

    return (<div>
        <MyForm onFinish={onFinish} labelCol={{span: 8}} initialValues={{status: '1', companyId: '', gameType: ''}}>
            <MyForm.Item span={6} title={t('Gamemanage.name')} name={"gameName"}>
                <Input allowClear autoComplete="off"
                       placeholder={`${t('public.input')} ${t('Gamemanage.name')}`}/>
            </MyForm.Item>
            <MyForm.Item span={6} title={t('Gamemanage.Affiliated')} name={"companyId"}>
                <Select
                    showSearch value={keyword}
                    className="enterCompanyName" name="companyId"
                    onSearch={deBounce(function (value) {
                        // 模糊搜索获取公司名
                        getCompany(value)
                    }, 700)}
                    onChange={(val) => {
                        // 选中下拉时，也将下拉值作为输入框的值
                        setKeyword(val)
                    }}
                    onFocus={() => {
                        getList()
                    }}
                    filterOption={false} //必须加这个，否则会对返回值进行筛选

                    placeholder={`${t('public.Select')}} ${t('Gamemanage.Affiliated')}`}
                    onBlur={(e) => {
                        // 当是选中值的时候，就没有e.target.value，所以需要通过原生获取节点的值
                        let value = e.target.value || document.getElementsByClassName('enterCompanyName')[0]
                            ?.querySelector('.ant-select-selection-item')?.innerText
                        // 再次设置关键词
                        setKeyword(value)
                    }}
                >{companyList.map((item, index) =>
                    <Select.Option key={index} value={item.id}>{item.companyName}</Select.Option>)}
                </Select>
            </MyForm.Item>
            <MyForm.Item span={6} title={t('Gamemanage.type')} name={"gameType"}>
                <Select
                    placeholder={`${t('public.input')}} ${t('Gamemanage.type')}`}>
                    {pulldown("gameTypeOptionsSon").map(item => {
                        return <Select.Option value={item.value} key={item.label}>{item.label}</Select.Option>
                    })}
                </Select>
            </MyForm.Item>
            <MyForm.Item span={6} title={t('Gamemanage.status')} name={"status"}>
                <Select
                    value={''}>
                    {pulldown('statusOptionsSon').map(item => {
                        return <Select.Option value={item.value} key={item.label}>{item.label}</Select.Option>
                    })}
                </Select>
            </MyForm.Item>
            <MyForm.Item span={6} title={t('Gamemanage.rebate')} name={"gameRtp"}>
                <Input allowClear autoComplete="off"
                       placeholder={`${t('public.input')} ${t('Gamemanage.rebate')}：90%`}/>
            </MyForm.Item>
            <MyForm.Item span={6} title={t('Gamemanage.gameKey')} name={"gameKey"}>
                <Input allowClear autoComplete="off"
                       placeholder={`${t('public.input')} ${t('Gamemanage.gameKey')}`}/>
            </MyForm.Item>
            <MyForm.Item span={6} title={'GameId'} name={"gameId"}>
                <Input allowClear autoComplete="off"
                       placeholder={`${t('public.input')} GameId`}/>
            </MyForm.Item>
            <MyForm.Item span={4}>
                <Button type="primary" htmlType="submit" icon={<SearchOutlined/>}>{t('public.inquire')}</Button>
            </MyForm.Item>
        </MyForm>
        <ComTable showUploadFrom={showUploadFrom}/>
        <ComForm/>
        <UploadForm uploadVisible={uploadVisible} showUploadFrom={showUploadFrom} uploadType={"gameUpload"}/>
    </div>)
}