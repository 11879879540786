import _http from './http'
import _history from './history'

import {log} from '../'

// 导出一切。注：没有导出默认值
export * from './functools'

// 这个模块封装各种请求方式（如：get，post等等）
export function post(url, params) {
    return new Promise(function (resolve, reject) {
        log(`post----url=${url}, params=`, params)

        _http.post(url, params).then((res) => {
            if (res) {
                log(`post----url=${url}，res=`, res)
                if (!res.data) {
                    resolve(res)
                }
                resolve(res.data)
            } else {
                resolve()
            }
        }, (err) => {
            reject(err)
        }).catch((err) => {
            reject(err)
        })
    })
}

export function get(url, params) {
    let queryStr = ""
    for (let key in params) {
        if (params[key]) {
            queryStr += `${key}=${params[key]}&`
        }
    }

    return new Promise(function (resolve, reject) {
        log(`get=${url}?`, queryStr)

        _http.get(url + "?" + queryStr).then((res) => {
            log(`get=${url}?${queryStr}，res=`, res)
            if (!res.data) {
                resolve(res)
            }
            resolve(res.data)
        }, (err) => {
            reject(err)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const http = _http
export const history = _history