// export const host = 'https://admin.hotgame.bet/'             //正式机
// export const host = 'https://admin.hotgameclub.com/'           //客户用aws测试机
export const host = 'https://admin.ctgameclub.com'      //开发测试机
// export const host = 'http://192.168.0.137/ctgameclub'        //物理测试机
// export const host = 'http://127.0.0.1/ctgameclub'         //本地

// export const apiGameHost = 'https://api.gamelink.bet'      //正式机
// export const apiGameHost = 'https://www.hotgameclub.com'      //客户用aws测试机
export const apiGameHost = 'https://admin.ctgameclub.com'      //开发测试机
// export const apiGameHost = 'http://192.168.0.137'           //物理测试机
// export const apiGameHost = 'http://127.0.0.1'           //本地

export const statusOptions = [
    {value: '', label: '全部'},
    {value: '1', label: '有效'},
    {value: '0', label: '无效'},
]

export const statusOptionsEn = [
    {value: '', label: 'All'},
    {value: '1', label: 'effective'},
    {value: '0', label: 'In vain'},
]

export const userTypeOptions = [
    {value: '', label: '全部'},
    // { value: 'ADMIN', label: '系统管理员' },
    {value: 'COMPANY_ADMIN', label: '公司管理员'},
    {value: 'CUSTOMER', label: '普通用户'},
    {value: 'PLATFORM', label: '运营商'},
    {value: 'GAME_USER', label: '游戏商'},
    {value: 'CUSTOM', label: '官网用户'},
    {value: 'PACK', label: '游戏包网'},
]

export const userTypeOptionsEn = [
    {value: '', label: 'All'},
    {value: 'ADMIN', label: 'System administrator'},
    {value: 'COMPANY_ADMIN', label: 'Company administrator'},
    {value: 'CUSTOMER', label: 'Ordinary user'},
    {value: 'PLATFORM', label: 'operator'},
    {value: 'GAME_USER', label: 'Game business'},
]

export const companyTypeOptions = [
    {value: '', label: '全部'},
    {value: 'COMPANY', label: '其他'},
    {value: 'GAME_COMPANY', label: '游戏商'},
    {value: 'OPERATOR_COMPANY', label: '运营商'},
]

export const companyTypeOptionsEn = [
    {value: '', label: 'All'},
    {value: 'COMPANY', label: 'other'},
    {value: 'GAME_COMPANY', label: 'Game business'},
    {value: 'OPERATOR_COMPANY', label: 'operator'},
]

export const gameTypeOptions = [
    {value: '', label: '全部'},
    {value: 'SLOT', label: '老虎机'},
    {value: 'CARD', label: '棋牌'},
    {value: 'TABLE', label: '桌面游戏'},
    {value: 'LEISURE', label: '休闲'},
    {value: 'VIDEO', label: '真人视讯'},
    {value: 'SPORT', label: '体育（电子）竞技'},
]

export const productTypeOptions = [
    {value: '', label: '全部'},
    {value: 'yxbw', label: '游戏包网'},
    {value: 'pay', label: '支付渠道'},
    {value: 'market', label: '营销渠道'},
    {value: 'cloud', label: '云服务'},
]

export const gameTypeOptionsEn = [
    {value: '', label: 'All'},
    {value: 'SLOT', label: 'Slot machine'},
    {value: 'TABLE', label: 'Chess and cards'},
    {value: 'LEISURE', label: 'fallow'},
    {value: 'VIDEO', label: 'Live video'},
    {value: 'SPORT', label: 'Sports competition'},
]

export const gameRankingOptions = [
    {value: '0', label: '无'},
    {value: '5', label: '\uD83D\uDD25\uD83D\uDD25\uD83D\uDD25\uD83D\uDD25\uD83D\uDD25'},
    {value: '4', label: '\uD83D\uDD25\uD83D\uDD25\uD83D\uDD25\uD83D\uDD25'},
    {value: '3', label: '\uD83D\uDD25\uD83D\uDD25\uD83D\uDD25'},
    {value: '2', label: '\uD83D\uDD25\uD83D\uDD25'},
    {value: '1', label: '\uD83D\uDD25'},
]
export const gameRankingOptionsEn = [
    {value: '0', label: 'There is no'},
    {value: '5', label: '\uD83D\uDD25\uD83D\uDD25\uD83D\uDD25\uD83D\uDD25\uD83D\uDD25'},
    {value: '4', label: '\uD83D\uDD25\uD83D\uDD25\uD83D\uDD25\uD83D\uDD25'},
    {value: '3', label: '\uD83D\uDD25\uD83D\uDD25\uD83D\uDD25'},
    {value: '2', label: '\uD83D\uDD25\uD83D\uDD25'},
    {value: '1', label: '\uD83D\uDD25'},
]

export const languages = [
    {value: 'en', label: '英文'},
    {value: 'da', label: '丹麦文'},
    {value: 'de', label: '德文'},
    {value: 'es', label: '西班牙文'},
    {value: 'fi', label: '芬兰文'},
    {value: 'fr', label: '法文'},
    {value: 'id', label: '印尼文'},
    {value: 'it', label: '意大利文'},
    {value: 'ja', label: '日文'},
    {value: 'ko', label: '韩文'},
    {value: 'nl', label: '荷兰文'},
    {value: 'no', label: '挪威文'},
    {value: 'pl', label: '波兰文'},
    {value: 'pt', label: '葡萄牙文'},
    {value: 'ro', label: '罗马尼亚文'},
    {value: 'ru', label: '俄文'},
    {value: 'sv', label: '瑞典文'},
    {value: 'th', label: '泰文'},
    {value: 'tr', label: '土耳其文'},
    {value: 'vi', label: '越南文'},
    {value: 'zh', label: '中文'},
    {value: 'my', label: '缅甸文'},
]

export const followStatusOptions = [
    {value: '0', label: '未开始'},
    {value: '1', label: '进行中'},
    {value: '2', label: '已完成'}
]

export const followStatusOptionsEn = [
    {value: '0', label: 'Not yet started'},
    {value: '1', label: 'Under way'},
    {value: '2', label: 'completed'}
]

// 游戏商类型
export const companyGameType = [
    {label: '全部', value: ''},
    {label: '体育赛事', value: '体育赛事'},
    {label: '真人娱乐', value: '真人娱乐'},
    {label: '棋牌游戏', value: '棋牌游戏'},
    {label: '电子竞技', value: '电子竞技'},
    {label: '老虎机', value: '老虎机'},
    {label: '休闲游戏', value: '休闲游戏'},
    {label: '原创游戏', value: '原创游戏'},
]

// 广告banner类型
export const bannerType = [
    {label: '全部', value: ''},
    {label: '首页', value: 'home'},
    {label: '游戏', value: 'game'},
    {label: '资讯', value: 'news'},
]

// 活动类型
export const activityType = [
    {label: '普通活动', value: 'common'},
    {label: '特色活动', value: 'feature'},
    {label: '行业新闻', value: 'news'},
]

// 活动类型筛选
export const indexActivityType = [
    {label: '全部', value: ''},
    {label: '最新的', value: 'latest'},
    {label: '特色活动', value: 'feature'},
    {label: '即将举行', value: 'todo'},
    {label: '所有活动', value: 'all'},
    {label: '行业新闻', value: 'news'},
]

// 钱包模式
export const walletModeType = [
    {label: '单一钱包', value: 'single'},
    {label: '转账钱包', value: 'transfer'}
]

// 业务类型
export const productCategory = [
    {label: '全部', value: ''},
    {label: '我提供', value: '0'},
    {label: '我需要', value: '1'}
]

// 投注类型
export const betType = [
    {label: '全部', value: ''},
    {label: '投注派彩', value: '0'},
    {label: '投注', value: '1'},
    {label: '派彩', value: '2'},
    {label: '取消', value: '3'},
    {label: '重置', value: '4'}
]

// ip类型
export const ipType = [
    {label: '全部', value: ''},
    {label: '白名单', value: '0'},
    {label: '黑名单', value: '1'}
]

// ip版本
export const ipVersion = [
    {label: 'IPV4', value: 'IPV4'},
    {label: 'IPV6', value: 'IPV6'}
]

// 玩家是否体验账号
export const sampleType = [
    {label: '全部', value: ''},
    {label: '是', value: 1},
    {label: '否', value: 0}
]

// 游戏分类代码
export const gameCategoryCode = [
    {label: '全部', value: ''},
    {label: 'PG', value: 'PG'},
    {label: 'PP', value: 'PP'},
    {label: 'JILI/TADA', value: 'JILI/TADA'},
    {label: 'SPRIBE', value: 'SPRIBE'},
    {label: 'SPIRIT', value: 'SPIRIT'},
    {label: 'forture888', value: 'forture888'}
]

// 统计维度
export const countType = [
    { label: '当日', value: 2 },
    { label: '按日', value: 1 },
    { label: '按月', value: 0 },
]

//开户数据状态
export const openOperatorStatus = [
    {label: '全部', value: ''},
    {label: '开户完成', value: 1},
    {label: '待开户', value: 0},
    {label: '开户失败', value: 2}
]