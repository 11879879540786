import axios from 'axios'
import history from './history'

import {X_TOKEN} from './functools'
import {message} from 'antd'
import {removeToken} from '@tool'

window._history = history

const http = axios.create({
    baseURL: '/ctgameclub',
    timeout: 120000,
    headers: {//请求头
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
    }
})

// response处理
function handleResponse(response) {
    let result
    if (response.status === 401) {
        result = '会话过期，请重新登录'
        if (history.location.pathname !== '/') {

            message.error(result)
            removeToken()

            history.push('/')

            return Promise.reject(result)
        }
        return Promise.reject()
    } else if (response.status === 200) {
        if (response.headers['content-type'] === 'application/octet-stream') {
            return Promise.resolve(response)
        }
        let data = response.data
        if (data.status === 10004) {
            result = '会话过期，请重新登录'
            message.error(result)
            removeToken()

            history.push('/')
            return Promise.reject(result)
        }
        return Promise.resolve(response.data)
    } else {
        result = `请求失败: ${response.status} ${response.statusText}`
        removeToken()

        if (response.status === 504 || response.status === 502) {
            result = '服务器异常，请重新登录'

            history.push('/')
        } else if (response.status === 404) {
            //todo 需要增加404
        }
        message.error(result)
        return Promise.reject(result)
    }
}

// 请求拦截器
http.interceptors.request.use(request => {
    request.headers['X-Token'] = X_TOKEN
    request.timeout = request.timeout || 120000
    return request
})

// 返回拦截器
http.interceptors.response.use(response => {
    return handleResponse(response)
}, error => {
    if (error.response) {
        return handleResponse(error.response)
    }
    const result = '请求异常: ' + error.message
    message.error(result)
    return Promise.reject(result)
})

export default http
