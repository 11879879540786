import React, { useState } from "react"

import { SearchOutlined } from '@ant-design/icons'

import { Button, Input, Select, Form, DatePicker } from "antd"

import store from "./RoleStore"
import UserTable from "./RoleTable"
import UserForm from "./RoleForm"
import RoleAuthFrom from "./RoleAuthFrom"
import { t, pulldown } from '@tool'
import { MyForm, PwdModal } from '@components'

export default function UserIndex() {
    const [form] = Form.useForm()
    const [pwdVisible, setPwdVisible] = useState(null);
    const [startTimeValue, setStartTime] = useState('');
    const [endTimeValue, setEndTime] = useState('');
    const { RangePicker } = DatePicker;


    const onFinish = (params) => {
        // 重置为第一页
        store.setPageNum(1)

        params.startTime = startTimeValue
        params.endTime = endTimeValue

        store.tableOptions = params
        store.fetchRecords()
    }

    function handleRangePickerChange(dates, dateStrings) {
        setStartTime(dateStrings[0]);
        setEndTime(dateStrings[1]);
    }

    return (<div>
        <MyForm form={form} labelCol={{ span: 7 }} onFinish={onFinish} initialValues={{ status: '1', userType: '' }}>
            <MyForm.Item span={5} title={t('Role.roleName')} name={"roleName"}>
                <Input allowClear name={"roleName"} autoComplete="off"
                    placeholder={t('public.input')} />
            </MyForm.Item>

            <MyForm.Item span={5} title={t('Role.status')} name={"status"}>
                <Select
                    name={'status'}
                    value={''}>
                    {pulldown('statusOptionsSon').map(item => {
                        return <Select.Option value={item.value} key={item.label}>{item.label}</Select.Option>;
                    })}
                </Select>
            </MyForm.Item>

            <MyForm.Item span={8} title={t('Role.addTime')}>
                <RangePicker format={'YYYY-MM-DD HH:mm:ss'} showTime onChange={handleRangePickerChange} />
            </MyForm.Item>

            <MyForm.Item span={6}>
                <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>{t('public.inquire')}</Button>
            </MyForm.Item>
        </MyForm>
        <UserTable setPwdVisible={setPwdVisible} />
        {/* formVisible 控制表单显示 */}
        <UserForm />
        <RoleAuthFrom />
    </div>)
}