import React, { useEffect, useState } from 'react'

import { observer } from 'mobx-react'
import { Modal, Form, Radio, Input, message, Row, Col, Select, Upload, Button } from 'antd'

import store from './GameStore'

import { pulldown, gameGameName, log, t, host } from '@tool'

import { addGameInfo, modifyGameInfo, getCompanyList } from '@api'
import { DeleteOutlined } from "@ant-design/icons";

import { UploadBtn } from '@components'

export default observer(function () {
    // 文档中未找到这种解构使用方法
    const [form] = Form.useForm()
    // useState 函数组件中使用 state
    // loading 默认是 flase
    const [loading, setLoading] = useState(false)
    const [gameIconUrl, setGameIconUrl] = useState('')
    const [gameKey, setGameKey] = useState('')
    const [company, setCompany] = useState('')
    const [companys, setCompanys] = useState('')
    const [gameType, setGameType] = useState('')
    const [iconGameKey, setIconGameKey] = useState('')
    const [gameScreenshotUrl, setGameScreenshotUrl] = useState([])

    const [companyList, setCompanyList] = useState([]) //模糊企业搜索下拉

    // 点击查询，通过公司名获取公司信息
    const getList = () => {
        getCompanyList({
            status: 1,
            pageNum: 1,
            pageSize: 999999,
            companyType: 'GAME_COMPANY'
        }).then(res => {
            setCompanyList(res.list.map((item) => {
                return {
                    label: item.brandName,
                    value: item.companyId
                }

            }))
            if (companyList.length > 0) {
                const data = companyList.find(item => item.value === company);
                setCompanys(data?.label)
            }

        })
    }

    useEffect(() => {
        form.resetFields()
        setGameIconUrl('')
        setGameScreenshotUrl([])
        setGameKey()
        setCompany()
        setCompanys()
        setGameType()
        getList();
        setIconGameKey();
        if (store.record.id) {
            form.setFieldsValue(store.record)
            if (store.record.gameScreenshot) {
                setGameScreenshotUrl(store.record.gameScreenshot.split(','))
            }
            setGameKey(store.record.gameKey)
            setIconGameKey(store.record.iconGameKey)
            setCompany(store.record.companyId)
            setGameType(store.record.gameType)
            setGameIconUrl(store.record.gameIcon)

        }

    }, [store.record])


    const handleSubmit = () => {
        form.submit()
    }

    const onFinish = (values) => {
        setLoading(true)

        values['gameIcon'] = gameIconUrl;
        values['gameScreenshot'] = gameScreenshotUrl.join(',');

        if (store.record.id) {
            values['id'] = store.record.id
            modifyGameInfo(values).then(res => {
                if (res.status === 10000) {
                    store.formVisible = false
                    store.fetchRecords()
                } else {
                    message.error(res.msg)
                }
            }).finally(() => setLoading(false))
        } else {
            addGameInfo(values).then(res => {
                if (res.status === 10000) {
                    store.formVisible = false
                    store.fetchRecords()
                } else {
                    message.error(res.msg)
                }
            }).finally(() => setLoading(false))
        }
    }

    const onUrl = (url) => {
        setGameScreenshotUrl(prevUrls => [...prevUrls, url]);
    }
    const gameScreensReovme = (index) => {
        setGameScreenshotUrl(prevUrls => {
            const newUrls = [...prevUrls];
            newUrls.splice(index, 1);
            return newUrls;
        });
    }
    const onCompany = (val) => {
        setCompany(val)
        const data = companyList.find(item => item.value === val);
        setCompanys(data.label)
    }

    const onSetGameKey = (e) => {
        setGameKey(e.target.value)
        setIconGameKey(e.target.value)
    }
    console.log(companys);

    return (// Modal 对话框
        <Modal
            width={'70%'}
            forceRender={true}
            open={store.formVisible}
            title={store.formTitle}
            onCancel={() => store.formVisible = false}
            confirmLoading={loading}
            // ok 按钮 props
            onOk={handleSubmit}>

            <Form form={form} labelCol={{ span: 7 }} onFinish={onFinish}>
                <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
                    <Col span={8}>
                        <Form.Item label={t('Gamemanage.name')}
                            rules={[{ required: true, message: `${t('public.input')}${t('Gamemanage.name')}` }]}
                            name={'gameName'}>
                            <Input disabled={!store.formEdit} allowClear
                                placeholder={`${t('public.input')}${t('Gamemanage.name')}`}
                                autoComplete="off" />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={t('Gamemanage.gameNameZh')}
                            rules={[{ required: true, message: `${t('public.input')}${t('Gamemanage.gameNameZh')}` }]}
                            name={'gameNameZh'}>
                            <Input disabled={!store.formEdit} allowClear
                                placeholder={`${t('public.input')}${t('Gamemanage.gameNameZh')}`}
                                autoComplete="off" />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={t('Gamemanage.Affiliated')}
                            labelCol={{ span: 8 }}
                            rules={[{ required: true, message: `${t('public.Select')}${t('Gamemanage.Affiliated')}` }]}
                            name={"companyId"}>

                            <Select
                                disabled={!store.formEdit}
                                allowClear
                                style={{ width: '100%' }}
                                placeholder={`${t('public.Select')}${t('Gamemanage.Affiliated')}`}
                                onChange={(e) => onCompany(e)}
                            >
                                {companyList.map(item => {
                                    return <Select.Option value={item.value} key={item.label}>{item.label}</Select.Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    {/* <Col span={8}>

                        <Form.Item labelCol={{ span: 7 }} label={t('Gamemanage.ID')}
                            rules={[{ required: true, message: `${t('public.input')}${t('Gamemanage.ID')}` }]}
                            name={"gameKey"}>
                            <Input disabled={!store.formEdit} onChange={(e) => onSetGameKey(e)} allowClear autoComplete="off"
                                placeholder={`${t('public.input')}${t('Gamemanage.ID')}`} />
                        </Form.Item>
                    </Col> */}
                    <Col span={8}>
                        <Form.Item label={t('Gamemanage.gameId')}
                            rules={[{ required: true, message: `${t('public.input')}${t('Gamemanage.gameId')}` }]}
                            name={"gameId"}>
                            <Input disabled={!store.formEdit} allowClear autoComplete="off"
                                placeholder={`${t('public.input')}${t('Gamemanage.gameId')}`} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={t('Gamemanage.Degree')}
                            labelCol={{ span: 8 }}
                            name={"gameRanking"}>
                            <Select disabled={!store.formEdit}
                                style={{ width: '100%' }}
                                placeholder={`${t('public.input')}${t('Gamemanage.Degree')}`}>
                                {pulldown("gameRankingOptionsSon").map(item => {
                                    return <Select.Option value={item.value}
                                        key={item.label}>{item.label}</Select.Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={t('Gamemanage.rebate')}
                            labelCol={{ span: 6 }}
                            name={"gameRtp"}>
                            <Input disabled={!store.formEdit} allowClear autoComplete="off"
                                placeholder={`${t('public.input')}${t('Gamemanage.rebate')}`} />
                        </Form.Item>
                    </Col>
                    <Col span={8} >
                        <Form.Item label={t('Gamemanage.type')}
                            name={"gameType"}>
                            <Select
                                disabled={!store.formEdit}
                                onChange={(e) => setGameType(e)}
                                style={{ width: "100%" }}
                                placeholder={`${t('public.Select')}${t('Gamemanage.type')}`}>
                                {pulldown('gameTypeOptionsSon').map(item => {
                                    return <Select.Option value={item.value}
                                        key={item.label}>{item.label}</Select.Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    {gameKey &&
                        <Col span={8}>
                            <Form.Item label={t('Gamemanage.icon')} labelCol={{ span: 8 }}>
                                <div style={{ display: 'flex', alignItems: "center" }}>
                                    {gameIconUrl !== '' ? <img src={gameIconUrl}
                                        style={{ width: 80, height: 80, marginRight: '10px' }}
                                        alt={''}
                                    /> : ''}
                                    {/* {
                                        host === 'https://admin.hotgame.bet/' ?
                                            <UploadBtn
                                                uploadType={`${company}-${gameType}-${gameKey}`}
                                                suffixType={'game-icon-upload'}
                                                formEdit={store.formEdit}
                                                setLogoUrl={setGameIconUrl}
                                            />
                                            :
                                            (<Upload disabled={!store.formEdit} {...uploadProps}>
                                                <Button type="primary" icon={<UploadOutlined />}>
                                                    {t('public.selectfile')}
                                                </Button>
                                            </Upload>)
                                    } */}
                                    <UploadBtn
                                        uploadType={`${companys}-${gameType}-${gameKey}`}
                                        suffixType={'game-icon-upload'}
                                        formEdit={store.formEdit}
                                        setLogoUrl={setGameIconUrl}
                                    />
                                </div>
                            </Form.Item>
                        </Col>
                    }

                    <Col span={10}>
                        <Form.Item label={t('Gamemanage.lang')}
                            labelCol={{ span: 6 }}
                            name={"lang"}>
                            <Input disabled={!store.formEdit} allowClear autoComplete="off"
                                placeholder={`${t('public.input')}${t('Gamemanage.lang')}  格式为：,en,zh,`} />
                        </Form.Item>
                    </Col>

                    <Col span={10}>
                        <Form.Item label={t('Gamemanage.currency')}
                            labelCol={{ span: 6 }}
                            name={"currency"}>
                            <Input disabled={!store.formEdit} allowClear autoComplete="off"
                                placeholder={`${t('public.input')}${t('Gamemanage.currency')} 格式为：,COP,BRL,`} />
                        </Form.Item>
                    </Col>

                    <Col span={10}>
                        <Form.Item label={t('Gamemanage.maxWin')}
                            labelCol={{ span: 6 }}
                            name={"maxWin"}>
                            <Input disabled={!store.formEdit} allowClear autoComplete="off"
                                placeholder={`${t('public.input')}${t('Gamemanage.maxWin')}`} />
                        </Form.Item>
                    </Col>
                    {true && (<Col span={6}>
                        <Form.Item
                            label={t('Gamemanage.status')}
                            rules={[{ required: true, message: `${t('public.Select')}${t('Gamemanage.status')}` }]}
                            initialValue={store.record.status === 1}
                            name={"status"}>
                            <Radio.Group name={'status'} style={{ width: 204 }} disabled={!store.formEdit}>
                                <Radio value={1}>{t('public.effective')}</Radio>
                                <Radio value={0}>{t('public.invain')}</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>)}

                    <Col span={24}>
                        <Form.Item
                            label={t('Gamemanage.desc')}
                            labelCol={{ span: 2 }}
                            name="remark">
                            <Input.TextArea disabled={!store.formEdit} rows={4} allowClear autoComplete="off" />
                        </Form.Item>
                    </Col>
                </Row>

                <Col span={10}>
                    <Form.Item label={t('Gamemanage.gameScreenshot')} labelCol={{ span: 8 }}>
                        <div style={{ display: 'flex', alignItems: "center" }}>
                            {gameScreenshotUrl.length !== 0 ? gameScreenshotUrl.map((item, index) => {
                                return <div
                                    style={{ position: 'relative', width: '80px', height: '80px', marginRight: '10px', borderRadius: '6px', }}
                                    key={index}>
                                    <img
                                        style={{ width: '80px', height: '80px', }}
                                        src={item}
                                        alt={''}
                                    />
                                    <div onClick={() => { gameScreensReovme(index) }} style={{ position: 'absolute', cursor: 'pointer', top: 0, right: '-6px', }}>
                                        <DeleteOutlined />
                                    </div>
                                </div>
                            }) : ''}
                            <UploadBtn
                                uploadType={'uploadImg'}
                                suffixType={'max'}
                                formEdit={store.formEdit}
                                setLogoUrl={onUrl}
                            />
                        </div>
                    </Form.Item>
                </Col>

            </Form>
        </Modal>)
})