import { observable, computed, action } from 'mobx'

import copy from 'copy-to-clipboard';

import { getSystemParamList, batchStatusSystemParam, loadSystemParam } from '@api'
import { message } from "antd"
import { t } from "@tool"


class UserStore {
    @observable formVisible = false

    @observable detailsVisible = false

    @observable formEdit = false

    @observable formTitle = t('disposition.View')

    @observable record = {}

    @observable records = []

    // 默认第1页
    @observable pageNum = 1
    // 每页多少条
    @observable pageSize = 10
    // 总共多少页
    @observable total = ''

    //二次确认删除
    @observable conDelete = false

    @observable ids = ''

    // 其他参数，例如排序、过滤等等
    @observable tableOptions = {}

    @observable isFetching = false

    @computed get dataSource() {
        return this.records
    }

    _getTableParams = () => ({ pageNum: this.pageNum, pageSize: this.pageSize, ...this.tableOptions, })

    @action setPageNum(val) {
        this.pageNum = val
    }
    @action setPageSize(val) {
        this.pageSize = val
    }

    @action setIds(ids) {
        this.ids = ids
    }

    fetchRecords = () => {
        const params = this._getTableParams()
        this.isFetching = true
        getSystemParamList(params).then(res => {
            this.total = res.totalCount
            this.records = res.list
        }).finally(() => this.isFetching = false)
    }

    onDelete = (record) => {
        let id = []
        if (record !== -1) {
            id.push(record.id)
        } else {
            id = this.ids
        }
        this.isFetching = true
        let params = { 'ids': id, 'status': 0 }
        batchStatusSystemParam(params).then(res => {
            if (res.status === 10000) {
                message.success(t('public.success'))
                this.setPageNum(1)
                this.setPageSize(10);
                this.fetchRecords()
                this.conDelete = false
            }
        }).finally(() => this.isFetching = false)
    }

    loadSystemParam = (paramKey) => {
        this.isFetching = true
        loadSystemParam(paramKey).then(res => {
            if (res.status === 10000) {
                this.fetchRecords()
                message.success(t('public.success'))
            } else {
                message.error(res.msg)
            }
        }).finally(() => {
            this.isFetching = false
        })
    }

    // 显示新增弹框
    showForm = (info = {}, formEdit) => {
        if (formEdit) {
            this.formVisible = true
            this.formEdit = formEdit
            this.formTitle = info.id ? t('disposition.Edit') : t('disposition.New')
        } else {
            this.formTitle = t('disposition.View')
            this.detailsVisible = true
        }
        this.record = info
    }

    handleClickRow = (record) => {
        return {
            onDoubleClick: () => {
                this.showForm(record, false)
            }
        }
    }
    //二次确认删除
    confirmDelete = (data) => {
        if (this.ids.length === 0) {
            message.error(t('public.selectDelete'))
            return
        }
        this.conDelete = data
    }
    copy = (e) => {
        copy(e.target.innerText)
        message.success(t('public.copy'))
    }
}

export default new UserStore()