import React, { useEffect, useState } from "react"
import Chart from "./chart"
import Chart1 from "./chart1"
import "./index.css"
import { FileSyncOutlined, EyeOutlined } from '@ant-design/icons'
import { Button, message, Badge, Select } from "antd"
import {
    homePageBet,
    homePageLively,
    homePageProfit,
    homePageIntegralWarn,
    homegetBetMonth,
    getBetBetweenWeek,
    getActivePlayerBetweenWeek,
    getCompanyList,
    homePageIntegral,
    // getOperateAndGameCompanyList
} from '@api'
import { history } from '@http'
import { get, t } from '@tool'
import { homegetProfitonth } from "../../api";
export default function UserIndex() {
    const [PageBet, setPageBet] = useState({});
    const [PageLively, setPageLively] = useState({});
    const [Profit, setProfit] = useState({});
    const [IntegralWarn, setIntegralWarn] = useState({});
    const [BetMonth, setBetMonth] = useState({});
    const [ProfitMonth, setProfitMonth] = useState({});
    const [BetBetweenWeek, setBetBetweenWeek] = useState([]);
    const [PlayerBetweenWeek, setPlayerBetweenWeek] = useState([]);
    const [options, setOptions] = useState([]);
    const [dataval, setdataval] = useState([]);
    useEffect(() => {
        // if (get('userType') === 'ADMIN' || get('userType') === 'COMPANY_ADMIN') {
        //     onRelseswq();
        // }
        if (get('userType') === 'ADMIN') {
            onRelseswq();
        } else {
            if (get('permissions').includes('integral.query.view')) {
                onRelseswq();
            }
        }

        onhomePageIntegral();
        onHomePageBet();
        onHomePageLively();
        onHomePageProfit();
        onHomePageIntegralWarn();
        onHomegetBetMonth();
        onBetBetweenWeek();
        onActivePlayerBetweenWeek();
        onHomegetProfitMonth();
        // onHomePageIntegralUse();

    }, [])


    const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
    const filterpermissions = (val) => {
        if (get('userType') === 'ADMIN') return false;
        const hasPermission = get('permissions').includes(val);
        return !hasPermission;
    };
    //获取运营商
    const onRelseswq = () => {
        getCompanyList({
            pageSize: 999999,
            pageNum: 1,
            status: 1
        }).then(res => {
            const filteredData = res.list.filter(item => item.companyType === 'OPERATOR_COMPANY');
            const operatorList = filteredData.map((item => {
                return {
                    value: item.id,
                    label: item.companyName
                };
            }));
            setOptions(operatorList)
        })
    }
    //充值记录列表
    const onRelse = () => {
        history.push({ pathname: `/recharge_record` })
    }
    //总投注和输赢积分列表
    const onMonth = () => {
        history.push({ pathname: `/integral_month_record` })
    }
    //积分提醒列表
    const onIntegr = () => {
        history.push({ pathname: `/integral-reminder` })
    }
    //投注列表
    const onBetting = () => {
        // history.push({ pathname: `/betting-list` })
        history.push({ pathname: `/betting-list`, state: { params: true } })
    }
    //总活跃数列表
    const onPlayer = () => {
        // history.push({ pathname: `/player-list` })
        history.push({ pathname: `/player-list`, state: { params: true } })
    }
    //总输赢积分
    const onSumintegral = () => {
        history.push({ pathname: `/integral_month_record` })
    }
    //积分使用情况列表
    const onPointsusage = () => {
        history.push({ pathname: `/points-usage` })
    }


    const params = {
        companyId: get('companyId'),
        userType: get('userType')
    }

    //总投注积分||今日投注积分
    const onHomePageBet = () => {
        homePageBet({ ...params }).then(res => {
            setPageBet(res || {})
        }).catch(() => { message.error(t('public.error')) });

    }
    //总输赢积分||今日输赢积分
    const onHomePageProfit = () => {

        homePageProfit({ ...params }).then(res => {
            setProfit(res || {})
        }).catch(() => { message.error(t('public.error')) });

    }
    //总用户||今日活跃
    const onHomePageLively = () => {
        homePageLively({ ...params }).then(res => {
            setPageLively(res || {})
        }).catch(() => { message.error(t('public.error')) });

    }
    //积分提醒
    const onHomePageIntegralWarn = () => {
        homePageIntegralWarn({ ...params }).then(res => {
            setIntegralWarn(res || {})
        }).catch(() => { message.error(t('public.error')) });

    }
    //上月总投注积分||本月总投注积分
    const onHomegetBetMonth = () => {
        homegetBetMonth({ ...params }).then(res => {
            setBetMonth(res || {})
        }).catch(() => { message.error(t('public.error')) });
    }
    //上月总输赢积分||本月总输赢积分
    const onHomegetProfitMonth = () => {
        homegetProfitonth({ ...params }).then(res => {
            setProfitMonth(res || {})
        }).catch(() => { message.error(t('public.error')) });
    }
    //近七天下注情况
    const onBetBetweenWeek = () => {
        getBetBetweenWeek({ ...params }).then(res => {
            setBetBetweenWeek(res || []);
        }).catch(() => { message.error(t('public.error')) });
    }
    //近七天活跃用户
    const onActivePlayerBetweenWeek = () => {
        getActivePlayerBetweenWeek({ ...params }).then(res => {
            setPlayerBetweenWeek(res || []);
        }).catch(() => { message.error(t('public.error')) });
    }
    //当前积分
    const onhomePageIntegral = (val) => {
        const paramse = { companyId: val }
        if (get('userType') == 'ADMIN' && get('companyId') == 1) {
            val ? paramse.companyId = val : paramse.companyId = 0;
        } else {
            if (get('permissions').includes('integral.query.view')) {
                paramse.companyId = val
            } else {
                paramse.companyId = get('companyId')
            }

        }
        homePageIntegral({ ...paramse }).then(res => {
            setdataval(res.integralDetail || [])
        }).catch(() => { message.error(t('public.error')) });

    }

    // const onHomePageIntegralUse =(val)=>{
    //     const param = { companyId: val }
    //     if (get('userType') == 'ADMIN' && get('companyId') == 1) {
    //         val ? param.companyId = val : param.companyId = 0;
    //     } else {
    //         if (get('permissions').includes('pointsUsage.integral.view')) {
    //             param.companyId = val
    //         } else {
    //             param.companyId = get('companyId')
    //         }
    //         getOperateAndGameCompanyList({...param}).then(res => {
    //             setdataval(res.integralDetail || [])
    //         }).catch(() => { message.error(t('public.error')) });
    //     }
    // }

    //刷新
    const onRefresh = () => {
        setPageBet({});
        setPageLively({});
        setProfit({});
        setIntegralWarn({});
        setBetMonth({});
        setProfitMonth({});
        setBetBetweenWeek([])
        setPlayerBetweenWeek([]);
        setdataval([])



        onHomePageBet();
        onHomePageLively();
        onHomePageProfit();
        onHomePageIntegralWarn();
        onHomegetBetMonth();
        onBetBetweenWeek();
        onActivePlayerBetweenWeek();
        onhomePageIntegral();
        onHomegetProfitMonth();
    }
    return (
        <div>
            <div style={{
                width: '100%',
                background: '#fff',
                padding: '10px 30px 30px 30px',

            }}>
                <div style={{ display: "flex", alignItems: 'center', justifyContent: 'space-between', marginBottom: "10px" }}>
                    <div style={{ display: "flex", alignItems: 'center', }}>
                        <p style={{ fontSize: '18px', margin: '0 10px 0 0 ', fontWeight: 'bold', color: '#000' }}>首页</p>
                        <Button onClick={() => { onRefresh() }} type="primary" icon={<FileSyncOutlined />} >
                            刷新
                        </Button>
                    </div>
                    <div>
                        {
                            (get('userType') === 'ADMIN' || get('userType') === 'COMPANY_ADMIN') &&
                            <Button style={{ marginLeft: '10px' }}
                                onClick={() => {
                                    onRelse()
                                }} type="primary">
                                充值记录
                            </Button>
                        }
                        {/* <Button style={{ marginLeft: '10px' }}
                            onClick={() => { }} type="primary" >
                            交易对账报告
                        </Button> */}
                        {
                            (get('userType') === 'ADMIN' || get('userType') === 'COMPANY_ADMIN') &&
                            <Badge offset={[-5, 5]} count={IntegralWarn?.data || 0}>
                                <Button style={{ marginLeft: '10px' }}
                                    onClick={() => { onIntegr() }} type="primary" >
                                    积分提醒
                                </Button>
                            </Badge>
                        }
                    </div>
                </div>

                <div style={{
                    display: "flex",
                    alignItems: 'center',
                    justifyContent: "space-between",
                    marginBottom: '1%',
                }}>
                    <div style={{
                        width: '64vw',
                        display: "flex",
                        flexWrap: 'wrap',
                        justifyContent: "space-between",
                        marginRight: '1vw'
                    }}>
                        <div style={{
                            border: '1px solid #E1E1E1',
                            boxShadow: '1px 2px 8px #E1E1E1',
                            borderRadius: '8px',
                            width: '16vw',
                            marginBottom: '2%',
                            display: 'flex',
                            flexDirection: 'column',
                            cursor: 'pointer'
                        }} onClick={() => { onBetting() }}>
                            <div style={{
                                padding: '10px 12px 5px 12px',
                                paddingTop: '14px',
                                justifyContent: 'space-between',
                                display: 'flex',
                                height: '80px',
                                borderBottom: '1px solid #E1E1E1',

                            }}>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center'
                                }}>
                                    <span style={{
                                        fontSize: "17px",
                                        color: '#000',
                                        lineHeight: '17px',
                                        fontWeight: 'bold',
                                        marginBottom: '10px'
                                    }}>
                                        总投注积分
                                    </span>
                                    <span style={{ fontSize: "20px", color: '#D30826', fontWeight: 'bold', lineHeight: '18px' }}>
                                        {PageBet?.totalBet || 0}
                                    </span>
                                </div>
                                <EyeOutlined style={{ fontSize: '20px', marginTop: '-26px' }} />
                            </div>
                            <div style={{
                                height: '40px',
                                padding: '0 12px',
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                <span style={{
                                    display: 'inline-block',
                                    lineHeight: '14px',
                                    fontSize: "14px",
                                }}>
                                    今日投注积分:
                                </span>
                                <span style={{
                                    display: 'inline-block',
                                    fontSize: "18px",
                                    color: '#000',
                                    fontWeight: 'bold',
                                    lineHeight: '18px'
                                }}>
                                    {PageBet?.todayBet || 0}
                                </span>
                            </div>
                        </div>

                        <div style={{
                            border: '1px solid #E1E1E1',
                            boxShadow: '1px 2px 8px #E1E1E1',
                            borderRadius: '8px',
                            width: '16vw',
                            marginBottom: '2%',
                            display: 'flex',
                            flexDirection: 'column',
                            cursor: 'pointer'
                        }} onClick={() => onSumintegral()}>
                            <div style={{
                                padding: '10px 12px 5px 12px',
                                paddingTop: '14px',
                                justifyContent: 'space-between',
                                display: 'flex',
                                height: '80px',
                                borderBottom: '1px solid #E1E1E1',
                            }} >
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center'
                                }}>
                                    <span style={{
                                        fontSize: "17px",
                                        color: '#000',
                                        lineHeight: '17px',
                                        fontWeight: 'bold',
                                        marginBottom: '10px'
                                    }}>
                                        总输赢积分
                                    </span>
                                    <span style={{ fontSize: "20px", color: '#D30826', fontWeight: 'bold', lineHeight: '18px' }}>
                                        {Profit?.totalProfit || 0}
                                    </span>
                                </div>
                                <EyeOutlined style={{ fontSize: '20px', marginTop: '-26px' }} />
                            </div>
                            <div style={{
                                height: '40px',
                                padding: '0 12px',
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                <span style={{
                                    display: 'inline-block',
                                    lineHeight: '14px',
                                    fontSize: "14px",
                                }}>
                                    今日输赢积分：
                                </span>
                                <span style={{
                                    display: 'inline-block',
                                    fontSize: "18px",
                                    color: '#000',
                                    fontWeight: 'bold',
                                    lineHeight: '18px'
                                }}>
                                    {Profit?.todayProfit || 0}
                                </span>
                            </div>
                        </div>

                        <div style={{
                            border: '1px solid #E1E1E1',
                            boxShadow: '1px 2px 8px #E1E1E1',
                            borderRadius: '8px',
                            width: '16vw',
                            marginBottom: '2%',
                            display: 'flex',
                            flexDirection: 'column',
                            cursor: 'pointer'
                        }} onClick={() => { onPlayer() }}>
                            <div style={{
                                padding: '10px 12px 5px 12px',
                                paddingTop: '14px',
                                justifyContent: 'space-between',
                                display: 'flex',
                                height: '80px',
                                borderBottom: '1px solid #E1E1E1',
                            }} >
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center'
                                }}>
                                    <span style={{
                                        fontSize: "17px",
                                        color: '#000',
                                        lineHeight: '17px',
                                        fontWeight: 'bold',
                                        marginBottom: '10px'
                                    }}>
                                        总活跃数
                                    </span>
                                    <span style={{ fontSize: "20px", color: '#D30826', fontWeight: 'bold', lineHeight: '18px' }}>
                                        {PageLively?.totalLively || 0}
                                    </span>
                                </div>
                                <EyeOutlined style={{ fontSize: '20px', marginTop: '-26px' }} />
                            </div>
                            <div style={{
                                height: '40px',
                                padding: '0 12px',
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                <span style={{
                                    display: 'inline-block',
                                    lineHeight: '14px',
                                    fontSize: "14px",
                                }}>
                                    今日活跃数：
                                </span>
                                <span style={{
                                    display: 'inline-block',
                                    fontSize: "18px",
                                    color: '#000',
                                    fontWeight: 'bold',
                                    lineHeight: '18px'
                                }}>
                                    {PageLively?.todayLively || 0}
                                </span>
                            </div>
                        </div>

                        <div style={{
                            border: '1px solid #E1E1E1',
                            boxShadow: '1px 2px 8px #E1E1E1',
                            borderRadius: '8px',
                            width: '16vw',
                            display: 'flex',
                            flexDirection: 'column',
                            cursor: 'pointer'
                        }} onClick={() => { onMonth() }}>
                            <div style={{
                                padding: '10px 12px 5px 12px',
                                paddingTop: '14px',
                                justifyContent: 'space-between',
                                display: 'flex',
                                height: '80px',
                                borderBottom: '1px solid #E1E1E1',

                            }}>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center'
                                }}>
                                    <span style={{
                                        fontSize: "17px",
                                        color: '#000',
                                        lineHeight: '17px',
                                        fontWeight: 'bold',
                                        marginBottom: '10px'
                                    }}>
                                        本月总投注积分
                                    </span>
                                    <span style={{ fontSize: "20px", color: '#D30826', fontWeight: 'bold', lineHeight: '18px' }}>
                                        {BetMonth?.nowMonthBet || 0}
                                    </span>
                                </div>
                                <EyeOutlined style={{ fontSize: '20px', marginTop: '-26px' }} />
                            </div>
                            <div style={{
                                height: '40px',
                                padding: '0 12px',
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                <span style={{
                                    display: 'inline-block',
                                    lineHeight: '14px',
                                    fontSize: "14px",
                                }}>
                                    上月总投注积分：
                                </span>
                                <span style={{
                                    display: 'inline-block',
                                    fontSize: "18px",
                                    color: '#000',
                                    fontWeight: 'bold',
                                    lineHeight: '18px'
                                }}>
                                    {BetMonth?.lastMonthBet || 0}
                                </span>
                            </div>
                        </div>

                        <div style={{
                            border: '1px solid #E1E1E1',
                            boxShadow: '1px 2px 8px #E1E1E1',
                            borderRadius: '8px',
                            width: '16vw',
                            display: 'flex',
                            flexDirection: 'column',
                            cursor: 'pointer'
                        }} onClick={() => { onMonth() }}>
                            <div style={{
                                padding: '10px 12px 5px 12px',
                                paddingTop: '14px',
                                justifyContent: 'space-between',
                                display: 'flex',
                                height: '80px',
                                borderBottom: '1px solid #E1E1E1',

                            }}>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center'
                                }}>
                                    <span style={{
                                        fontSize: "17px",
                                        color: '#000',
                                        lineHeight: '17px',
                                        fontWeight: 'bold',
                                        marginBottom: '10px'
                                    }}>
                                        本月输赢积分
                                    </span>
                                    <span style={{ fontSize: "20px", color: '#D30826', fontWeight: 'bold', lineHeight: '18px' }}>
                                        {ProfitMonth?.nowMonthProfit || 0}
                                    </span>
                                </div>
                                <EyeOutlined style={{ fontSize: '20px', marginTop: '-26px' }} />
                            </div>
                            <div style={{
                                height: '40px',
                                padding: '0 12px',
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                <span style={{
                                    display: 'inline-block',
                                    lineHeight: '14px',
                                    fontSize: "14px",
                                }}>
                                    上月输赢积分：
                                </span>
                                <span style={{
                                    display: 'inline-block',
                                    fontSize: "18px",
                                    color: '#000',
                                    fontWeight: 'bold',
                                    lineHeight: '18px'
                                }}>
                                    {ProfitMonth?.lastMonthProfit || 0}
                                </span>
                            </div>
                        </div>

                        {
                            (get('userType') === 'ADMIN' || get('companyId') == 1 || get('permissions').includes('pointsUsage.integral.view')) &&
                            <div style={{
                                border: '1px solid #E1E1E1',
                                boxShadow: '1px 2px 8px #E1E1E1',
                                borderRadius: '8px',
                                width: '16vw',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                cursor: 'pointer',
                                fontSize: '18px',
                                fontWeight: 'bold'
                            }} onClick={() => { onPointsusage() }}>
                                积分使用情况
                            </div>
                        }
                        {
                            (get('userType') !== 'ADMIN' || get('userType') !== 'COMPANY_ADMIN') &&
                            <div style={{
                                width: '16vw'
                            }}>

                            </div>
                        }


                    </div>
                    <div style={{
                        width: '34vw',
                        borderRadius: '8px',
                        boxShadow: '1px 2px 8px #E1E1E1',
                        border: '1px solid #E1E1E1',
                        height: '264px',
                        overflow: 'hidden'

                    }}>
                        <p style={{
                            padding: '10px',
                            background: '#F2F2F2',
                            color: '#000',
                            fontSize: '17px',
                            fontWeight: 'bold',
                            margin: '0'
                        }}>
                            当前积分
                        </p>
                        {/* 
                        {
                            (get('userType') === 'ADMIN' || get('userType') === 'COMPANY_ADMIN') &&
                            <div style={{ width: '100%', marginTop: '10px', padding: '0 10px' }}>

                                <label style={{
                                    fontSize: '15px',
                                    fontWeight: 'bold',
                                    marginRight: '10px'
                                }}>
                                    运营商
                                </label>
                                <Select
                                    style={{ width: '16vw' }}
                                    showSearch
                                    filterOption={filterOption}
                                    placeholder='请选择'
                                    options={options}

                                    onChange={(e) => { onhomePageIntegral(e) }}
                                >
                                </Select>
                            </div>
                        } */}


                        <div style={{ width: '100%', marginTop: '10px', padding: '0 10px' }}>

                            <label style={{
                                fontSize: '15px',
                                fontWeight: 'bold',
                                marginRight: '10px'
                            }}>
                                运营商
                            </label>
                            <Select
                                style={{ width: '16vw' }}
                                showSearch
                                filterOption={filterOption}
                                placeholder='请选择'
                                options={options}
                                disabled={filterpermissions('integral.query.view')}
                                onChange={(e) => { onhomePageIntegral(e) }}
                            >
                            </Select>
                        </div>

                        <div style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: '10px'
                        }}>
                            <div style={{
                                width: '94%',
                                border: '1px solid #E1E1E1',
                                borderRadius: '8px'
                            }}>

                                <div style={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    background: '#F2F2F2'
                                }}>
                                    <span style={{ width: '8vw', borderRight: '1px solid #E1E1E1', height: '40px', lineHeight: '40px', textAlign: 'center' }}>币种</span>
                                    <span style={{ width: '8vw', borderRight: '1px solid #E1E1E1', height: '40px', lineHeight: '40px', textAlign: 'center' }}>剩余积分</span>
                                    <span style={{ width: '8vw', height: '40px', lineHeight: '40px', textAlign: 'center' }}>积分提醒</span>
                                </div>
                                <div className="louw" >
                                    {(dataval || []).map((item, index) => (
                                        <div key={index} style={{
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            borderTop: '1px solid #E1E1E1',
                                            borderBottom: '1px solid #E1E1E1',
                                        }}>
                                            <span style={{
                                                width: '8vw', borderRight: '1px solid #E1E1E1', height: '40px',
                                                lineHeight: '40px', textAlign: 'center'
                                            }}>{item.currency}</span>
                                            <span style={{
                                                width: '8vw', borderRight: '1px solid #E1E1E1', height: '40px',
                                                lineHeight: '40px', textAlign: 'center'
                                            }}>{item.integral}</span>
                                            <span style={{
                                                width: '8vw', height: '40px', lineHeight: '40px',
                                                textAlign: 'center',
                                                color: item.warn == true ? 'red' : ''
                                            }}>{
                                                    item.warn == true ? '积分不足' : '积分正常'
                                                } </span>
                                        </div>
                                    ))}
                                    {
                                        dataval.length === 0 &&
                                        <div style={{
                                            width: '100%',
                                            height: '110px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            borderTop: '1px solid #E1E1E1',
                                        }}> 暂无数据</div>
                                    }
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

                <div style={{ width: '100%', display: "flex", alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{
                        width: '49%',
                        height: '500px',
                        border: '1px solid #E1E1E1',

                        boxShadow: '1px 2px 8px #E1E1E1',
                    }}>
                        <Chart BetBetweenWeek={BetBetweenWeek} />
                    </div>
                    <div style={{
                        width: '49%',
                        height: '500px',
                        border: '1px solid #E1E1E1',

                        boxShadow: '1px 2px 8px #E1E1E1',
                    }}>
                        <Chart1 PlayerBetweenWeek={PlayerBetweenWeek} />
                    </div>
                </div>
            </div>
        </div >
    )
}