import {post} from "@http"

//添加包
export function addGamePacket(params) {
    return post("/manager/game-packet/add", params)
}

//修改包信息
export function modifyGamePacket(params) {
    return post("/manager/game-packet/modify", params)
}

//修改状态
export function batchStatusGamePacket(params) {
    return post("/manager/game-packet/batchStatus", params)
}

//获取列表信息
export function getGamePacketList(params) {
    return post("/manager/game-packet/getList", params)
}