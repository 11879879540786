import React, { Component } from "react"

import { Button, Checkbox, message } from "antd"
import { ArrowLeftOutlined } from '@ant-design/icons';
import { history } from '@http'
import { t } from '@tool'

import { getAuthorityByUserId, modifyUserAuthority, getAuthorityList } from '@api'
export default class AuthoritySetForm extends Component {
    constructor(props) {
        super(props);
        // 初始化state
        this.state = {
            list: [],
            dataList: [],
            id: props.location.state.params
        }
    }
    componentDidMount() {
        const params = { userId: this.state.id }
        getAuthorityByUserId(params).then((res) => {
            this.setState({ list: res })
            getAuthorityList(params).then((res) => {
                let data1 = this.state.list
                let data2 = res
                data2 = data2.map(item => {
                    item.children = item.children.map(child => {
                        const found = data1[item.id] && data1[item.id].find(dataItem => dataItem.id === child.id);
                        return found ? { ...child, defaultChecked: true } : { ...child, defaultChecked: false };
                    });
                    return item;
                });
                this.setState({ dataList: data2 })
            })
        })

    }
    render() {

        const onClick = () => {
            history.goBack()
        }
        let ids = []
        const onChange = (e, item, items) => {
            let index = this.state.dataList.findIndex(item => item.id == items.id);
            if (index !== -1) {
                let childIndex = this.state.dataList[index].children.findIndex(child => child.id == item.id);
                if (childIndex !== -1) {
                    this.state.dataList[index].children[childIndex].defaultChecked = !this.state.dataList[index].children[childIndex].defaultChecked;
                }
            }
            if (e) {
                ids.push(item.id)
            } else {
                ids = ids.filter(em => em !== item.id);
            }
        };
        const onConfirm = (e) => {
            let idsWithDefaultCheckedTrue = e.children.filter(child => child.defaultChecked === true).map(child => child.id);
            const params = {
                userId: this.state.id,
                id: e.id,
                ids: [...new Set([...ids, ...idsWithDefaultCheckedTrue])]
            }
            modifyUserAuthority(params).then((res) => {
                message.success(t('public.success'))
                ids = [];
                idsWithDefaultCheckedTrue = [];
            }).catch(() => {
                message.error(t('public.error'))
            })
        }
        return (<div style={{ width: '100%', height: '100%' }}>

            <div style={{ width: '100%', height: '100%', background: '#fff', marginTop: '10px', overflow: 'auto' }}>
                <Button style={{ margin: '6px 0 15px 10px' }} type="primary" onClick={onClick} icon={<ArrowLeftOutlined />}>
                   {t('public.Back')} 
                </Button>
                {this.state.dataList.map((items, index) => (
                    <div key={index} style={{
                        display: 'flex', flexDirection: 'column', justifyContent: 'center',
                        alignItems: 'center', width: '100%',
                    }}>
                        <div style={{
                            display: 'flex', justifyContent: 'space-between', alignItems: 'center',
                            width: '100%', fontSize: '17px', fontWeight: 'bold', height: '40px',
                            padding: '20px 10px', borderBottom: '1px solid #e9e9e9', background: '#f8f8f8'
                        }}>
                            <span>{items.authName}</span>
                        </div>
                        <div style={{ display: 'flex', width: '100%', alignItems: 'center', padding: '15px 10px' }}>
                            {items.children.map((item, index1) => (
                                <div key={index1} style={{ width: '100px' }} >
                                    <Checkbox defaultChecked={item.defaultChecked} onChange={(e) => onChange(e.target.checked, item, items)}>{item.authName}</Checkbox>
                                </div>
                            ))}
                            <Button style={{ marginLeft: '50px' }} onClick={() => onConfirm(items)}>{t('public.OK')}</Button>
                        </div>
                    </div>
                ))}
            </div>
        </div>)
    }
}