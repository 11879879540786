import React from 'react'
import { observer } from 'mobx-react'
import { Button, Popconfirm, Space } from 'antd'
import {
    DeleteOutlined,
    EditOutlined,
    MinusCircleOutlined,
    MinusSquareOutlined,
    PlusOutlined,
    PlusSquareOutlined
} from '@ant-design/icons'
import { TableCard, AuthButton, } from '@components'
import store from './AuthorityStore'
import { t } from '@tool'
const rowSelection = {
    onChange: (selectedRowKeys) => {
        store.setIds(selectedRowKeys)
    }, getCheckboxProps: (record) => ({
        disabled: record.id === 'Disabled Index', // Column configuration not to be checked
        name: record.id,
    }),
}

@observer
class AuthorityTable extends React.Component {

    componentDidMount() {
        store.tableOptions = {
            ...store.tableOptions, status: 1
        }
        store.fetchRecords()
    }

    columns = [
        { title: t('limit.KEY'), width: '120px', key: 'authKey', dataIndex: 'authKey', },
        { title: t('limit.names'), width: '80px', key: 'authName', dataIndex: 'authName', },
        {
            title: t('limit.status'),
            width: '44px',
            key: 'status',
            dataIndex: 'status',
            render: (text) => (text === 1) ? t('public.effective') : t('public.invain'),
        },
        {
            title: t('limit.Parent'),
            width: '66px',
            key: 'isParent',
            dataIndex: 'isParent',
            render: (text) => (text === 1) ? t('public.Yes') : t('public.No'),
        },
        {
            title: t('limit.ID'),
            width: '56px',
            key: 'authorityId',
            dataIndex: 'authorityId',
            render: (text) => (text === 0) ? "-" : text,
        },
        {
            title: t('public.Controls'), width: '240px', key: 'id', render: (_, record) => {
                return (<Space size="middle">
                    {
                        record.isParent === 1 ?
                            <Button
                                type={"primary"}
                                ghost
                                icon={<PlusOutlined />}
                                onClick={() => store.showForm(record, true)}>
                                {t('public.new')}
                            </Button>
                            : ''
                    }
                    <Button
                        type={"primary"}
                        ghost
                        icon={<EditOutlined />}
                        onClick={() => store.showForm(record, true)}>
                        {t('public.edit')}
                    </Button>
                    <Popconfirm
                        title={t('public.sure')}
                        onConfirm={() => store.onDelete(record)}
                        okText={t('public.OK')}
                        cancelText={t('public.Cancel')}
                    >
                        <Button
                            type={"primary"}
                            danger
                            ghost
                            icon={<DeleteOutlined />}>
                            {t('public.delete')}
                        </Button>
                    </Popconfirm>
                </Space>)
            },
        },]

    handleTableChange = ({ current }, filters, sorter) => {
        store.pageNum = current
        store.tableOptions = {
            ...store.tableOptions, // 排序：好像只支持单个排序
            sortField: sorter.field, sortOrder: sorter.order, ...filters
        }
        store.fetchRecords()
    }

    render() {
        return (<TableCard
            expandable={({ expanded, onExpand, record }) => { //expanded-是否可展开, onExpand-展开事件默认, record-每一项的值 设置自定义图标
                if (expanded) {//根据是否可以展开判断
                    return <MinusSquareOutlined type="minus-circle" onClick={e => onExpand(record, e)} />
                } else {
                    return <PlusSquareOutlined type="plus-circle" onClick={e => onExpand(record, e)} />
                }
            }}
            tableLayout={'fixed'}
            handleClickRow={store.handleClickRow}
            rowKey="id"
            rowSelection={{
                type: 'checkbox', ...rowSelection,
            }}
            size={'small'}
            title={t('limit.list')}
            loading={store.isFetching}
            // 后端的数据源
            dataSource={store.dataSource}
            onReload={store.fetchRecords}
            onChange={this.handleTableChange}
            actions={[
                <Popconfirm
                    title={`${t('public.Are')}${store.ids.length}?`}
                    open={store.conDelete}
                    onConfirm={() => store.onDelete(-1)}
                    onCancel={() => { store.confirmDelete(false) }}
                    okText={t('public.OK')}
                    cancelText={t('public.Cancel')}
                >
                    <AuthButton type="primary"
                        icon={<MinusCircleOutlined />}
                        onClick={() => store.confirmDelete(true)}>
                        {t('public.Batchdeletion')}
                    </AuthButton>
                </Popconfirm>
                ,
                <AuthButton
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => store.showForm({}, true)}>
                    {t('public.new')}
                </AuthButton>
            ]}
            columns={this.columns}
            pagination={false} />)
    }
}

export default AuthorityTable
