import React, {useEffect, useState} from 'react'
import {observer} from 'mobx-react'
import {DeleteOutlined, PlusOutlined, SearchOutlined, EditOutlined} from '@ant-design/icons'
import {Modal, Space, Popconfirm, Form, Button, Input, Select, Row, Col} from 'antd'
import store from './GameAuthStore'
import {pulldown, t} from '@tool'
import {TableCard, AuthButton,} from '@components'

export default observer(function () {
    const [form] = Form.useForm();
    const regx = /^\d*\.?\d{0,2}$/;
    useEffect(() => {
        form.resetFields();
        if (store.recordson.id) {
            form.setFieldsValue(store.recordson);
        }
    }, [store.recordson]);
    useEffect(() => {
        store.setcurrency(null)
        store.setrtp(null)
        store.setwalletMode('single')
        store.setoperatorCompanyId(0)
        if (store.record.id) {
            store.fetchRecords();
        }
    }, [store.record])
    const handleTableChange = ({current, pageSize}, filters, sorter) => {
        store.pageNum = current
        store.pageSize = pageSize
        store.tableOptions = {
            ...store.tableOptions, // 排序：好像只支持单个排序
            sortField: sorter.field, sortOrder: sorter.order, ...filters
        }
        store.fetchRecords()
    }
    const columns = [
        {
            title: '钱包模式',
            width: '30px',
            key: 'walletMode',
            dataIndex: 'walletMode', ellipsis: true,
            render: (text) => {
                return text == 'single' ? '单一钱包模式' : text == 'transfer' ? '转账模式' : '-'
            }
        },
        {

            title: '币种',
            width: '40px',
            key: 'currency',
            dataIndex: 'currency',
            ellipsis: true,
            render: (text) => {
                const filtered = store.setCurrencyOption.filter(item => item.value === text);
                if (filtered.length > 0) {
                    return filtered[0].label;
                } else {
                    return '-'; // 或者返回其他默认值
                }
            }

        },

        {
            title: '运营商',
            width: '30px',
            key: 'operatorCompanyId',
            dataIndex: 'operatorCompanyId',
            ellipsis: true,
            render: (text) => {
                const filtered = store.setCompanyOption.filter(item => item.value === text);
                if (filtered.length > 0) {
                    return filtered[0].label;
                } else {
                    return '-'; // 或者返回其他默认值
                }
            }
        },

        {
            title: '授权码',
            width: '60px',
            key: 'operatorToken',
            dataIndex: 'operatorToken',
            ellipsis: true,
            render: (text) => {
                return (<div className="ellipsis" style={{

                    overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis',
                }} title={t('public.Clickcopy')} onClick={store.copy}>
                    {text}
                </div>)
            }
        },
        {
            title: 'secretKey',
            width: '60px',
            key: 'secretKey',
            dataIndex: 'secretKey',
            ellipsis: true,
            render: (text) => {
                return (<div className="ellipsis" style={{

                    overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis',
                }} title={t('public.Clickcopy')} onClick={store.copy}>
                    {text}
                </div>)
            }
        },
        {
            title: t('CompanyAuthorize.salt'),
            width: '40px',
            key: 'salt',
            dataIndex: 'salt',
            ellipsis: true,
            render: (text) => {
                return (<div className="ellipsis" style={{

                    overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis',
                }} title={t('public.Clickcopy')} onClick={store.copy}>
                    {text}
                </div>)
            }
        },
        {
            title: t('CompanyAuthorize.dc'),
            width: '40px',
            key: 'dc',
            dataIndex: 'dc',
            ellipsis: true,
        },
        {
            title: 'apiUrl',
            width: '60px',
            key: 'authorizeApiUrl',
            dataIndex: 'authorizeApiUrl',
            ellipsis: true,
            render: (text) => {
                return (<div className="ellipsis" style={{

                    overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis',
                }} title={t('public.Clickcopy')} onClick={store.copy}>
                    {text}
                </div>)
            }
        },
        {
            title: '点位',
            width: '20px',
            key: 'companyRate',
            dataIndex: 'companyRate',
            ellipsis: true,
        },
        {
            title: t('public.Controls'), fixed: 'right', width: '80px', key: 'id', render: (_, record) => {
                return (<Space size="middle">

                    <AuthButton
                        type={"primary"}
                        ghost
                        icon={<EditOutlined/>}
                        onClick={() => store.showFormSon(record, 'Edit', false)}
                        // auth="game.company.edit"
                    >
                        {t('public.edit')}
                    </AuthButton>
                    <Popconfirm
                        title={t('public.sure')}
                        onConfirm={() => store.onDelete(record)}
                        okText={t('public.OK')}
                        cancelText={t('public.Cancel')}
                    >
                        <AuthButton
                            type={"primary"}
                            danger
                            ghost
                            icon={<DeleteOutlined/>}
                            // auth="game.company.delete"
                        >
                            {t('public.delete')}
                        </AuthButton>
                    </Popconfirm>

                </Space>)
            },
        },
    ]
    // Modal 对话框
    return (
        <Modal
            width={'100%'}
            forceRender={true}
            style={{top: '2vh',}}
            open={store.formVisible}
            title={store.formTitle}
            onCancel={() => store.formVisible = false}
            footer={null}
        >
            <div style={{display: 'flex', marginBottom: '20px', width: '100%'}}>
                <div style={{display: 'flex', alignItems: 'center', flex: '1'}}>
                    <p style={{width: '60px', margin: 0}}>{t('reward.Currency')} :</p>
                    <Select
                        onChange={(e) => {
                            store.setcurrency(e)
                        }}
                        name={'currency'}
                        style={{width: '100%'}}
                        value={store.currencyVal}
                        placeholder={`${t('public.Select')}${t('reward.Currency')}`}
                        options={store.setCurrencyOption}
                        allowClear
                        showSearch
                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                    />
                </div>
                <div style={{display: 'flex', flex: '1', alignItems: 'center', marginLeft: '10px'}}>
                    <p style={{width: '60px', margin: 0}}>{t('reward.Sand')}:</p>
                    <Select
                        onChange={(e) => {
                            store.setrtp(e)
                        }}
                        name={'rtp'}
                        style={{width: '100%'}}
                        allowClear
                        value={store.rtpVal}
                        placeholder={`${t('public.Select')}${t('reward.Sand')}`}>
                        <Select.Option value={80}> 80</Select.Option>
                        <Select.Option value={87}> 87</Select.Option>
                        <Select.Option value={93}> 93</Select.Option>
                        <Select.Option value={97}> 97</Select.Option>
                        <Select.Option value={105}> 105</Select.Option>
                        <Select.Option value={120}> 120</Select.Option>
                        <Select.Option value={150}> 150</Select.Option>
                    </Select>
                </div>
                <div style={{display: 'flex', flex: '1', alignItems: 'center', marginLeft: '10px'}}>
                    <p style={{width: '85px', margin: 0}}>钱包模式:</p>
                    <Select onChange={(e) => {
                        store.setwalletMode(e)
                    }} value={store.walletModeVal} style={{width: '100%'}} name={'walletMode'}
                            placeholder={`${t('public.Select')}钱包模式`}>
                        <Select.Option value={'single'}> 单一钱包模式</Select.Option>
                        <Select.Option value={'transfer'}> 转账模式</Select.Option>
                    </Select>
                </div>
                <div style={{display: 'flex', alignItems: 'center', flex: '1', marginLeft: '10px'}}>
                    <p style={{width: '60px', margin: 0}}>运营商:</p>
                    <Select
                        onChange={(e) => {
                            store.setoperatorCompanyId(e)
                        }}
                        name={'operatorCompanyId'}
                        style={{width: '100%'}}
                        value={store.operatorCompanyIdVal}
                        placeholder={`${t('public.Select')}${t('reward.Currency')}`}
                        options={store.setCompanyOption}
                        allowClear
                        showSearch
                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                    />
                </div>
                <div style={{width: '178px', marginLeft: '30px'}}>
                    <Button type="primary" onClick={() => {
                        store.onSearch()
                    }} icon={<SearchOutlined/>}>{t('public.inquire')}</Button></div>

            </div>
            <TableCard
                tableLayout={'fixed'}
                size={'small'}
                scroll={{
                    x: 1400, y: '44vh'
                }}
                handleClickRow={store.handleClickRow}
                rowKey="id"
                // title={t('Gamebusiness.vendorsList')}
                loading={store.isFetching}
                // 后端的数据源
                dataSource={store.dataSource}
                onReload={store.fetchRecords}
                onChange={handleTableChange}
                actions={[
                    <AuthButton
                        type="primary"
                        icon={<PlusOutlined/>}
                        // auth="game.company.add"
                        onClick={() => store.showFormSon({}, 'Add', false)}>
                        {t('public.new')}
                    </AuthButton>]}
                // 分页器
                pagination={{
                    showSizeChanger: true,
                    showLessItems: true,
                    showTotal: total => t('public.intotal', total),
                    pageSizeOptions: ['10', '20', '50', '100'], // 如果不传 total，则以后端返回数据条数作为 total 的值
                    total: store.total, // 如果不传，则默认是第一条，如果需要默认显示第3条，则必须传
                    current: store.pageNum,
                    pageSize: store.pageSize,
                }}
                columns={columns}/>
            {/* 嵌套的 Foem */}
            {store.formVisibleson && (
                <Modal
                    width={'75%'}
                    forceRender={true}
                    style={{top: '20vh'}}  // 可以调整嵌套模态框的位置
                    open={store.formVisibleson}
                    title={store.formTitleson}
                    onCancel={() => store.formVisibleson = false}
                    footer={null}
                >
                    <div>
                        <Form
                            form={form}
                            labelCol={{span: 6}}
                            onFinish={store.onFinish}
                            initialValues={{rtp: 97, rate: 1, operatorCompanyId: 0}}>

                            <Row gutter={{md: 8, lg: 24, xl: 48}}>
                                <Col span={8}>
                                    <Form.Item
                                        label={t('CompanyAuthorize.walletMode')}
                                        name="walletMode"
                                        rules={[
                                            {
                                                required: true,
                                                message: `${t('public.Select')}${t('CompanyAuthorize.walletMode')}`,
                                            },
                                        ]}>
                                        <Select
                                            disabled={store.formEditson}
                                            allowClear
                                            style={{width: '100%'}}
                                            placeholder={`${t('public.Select')}${t('CompanyAuthorize.walletMode')}`}>
                                            {pulldown('walletModeType').map(item => {
                                                return <Select.Option value={item.value}
                                                                      key={item.label}>{item.label}</Select.Option>
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        name="currency"
                                        label={t('CompanyAuthorize.currency')}
                                        rules={[
                                            {
                                                required: true,
                                                message: `${t('public.Select')}${t('CompanyAuthorize.currency')}`,
                                            },
                                        ]}>
                                        <Select
                                            disabled={store.formEditson}
                                            allowClear
                                            style={{width: '100%'}}
                                            placeholder={`${t('public.Select')}${t('CompanyAuthorize.currency')}`}
                                            options={store.setCurrencyOption}
                                            showSearch
                                            filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        name="operatorToken"
                                        label={t('CompanyAuthorize.operatorToken')}
                                        rules={[
                                            {
                                                required: true,
                                                message: `${t('public.input')}${t('CompanyAuthorize.operatorToken')}`,
                                            },
                                        ]}
                                    >
                                        <Input
                                            disabled={store.formEditson}
                                            allowClear
                                            placeholder={`${t('public.input')}${t('CompanyAuthorize.operatorToken')}`}
                                            style={{width: '100%'}}/>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        name="secretKey"
                                        label={t('CompanyAuthorize.secretKey')}
                                        rules={[
                                            {
                                                required: false,
                                                message: `${t('public.input')}${t('CompanyAuthorize.secretKey')}`,
                                            },
                                        ]}
                                    >
                                        <Input
                                            disabled={store.formEditson}
                                            allowClear
                                            placeholder={`${t('public.input')}${t('CompanyAuthorize.operatorToken')}`}
                                            style={{width: '100%'}}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        name="salt"
                                        label={t('CompanyAuthorize.salt')}
                                        rules={[
                                            {
                                                required: false,
                                                message: `${t('public.input')}${t('CompanyAuthorize.salt')}`,
                                            },
                                        ]}
                                    >
                                        <Input
                                            disabled={store.formEditson}
                                            allowClear
                                            placeholder={`${t('public.input')}${t('CompanyAuthorize.salt')}`}
                                            style={{width: '100%'}}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        name="dc"
                                        label={t('CompanyAuthorize.dc')}
                                        rules={[
                                            {
                                                required: false,
                                                message: `${t('public.input')}${t('CompanyAuthorize.dc')}`,
                                            },
                                        ]}
                                    >
                                        <Input
                                            disabled={store.formEditson}
                                            allowClear
                                            placeholder={`${t('public.input')}${t('CompanyAuthorize.dc')}`}
                                            style={{width: '100%'}}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={'点位信息(百分比)'}
                                        name={"companyRate"}
                                        rules={[
                                            {
                                                required: true,
                                                message: `${t('public.input')}点位信息`
                                            },
                                            ({getFieldValue}) => ({
                                                validator(_, value) {
                                                    console.log(value)
                                                    if (value) {
                                                        if (!regx.test(value)) {
                                                            return Promise.reject(new Error('请输入整数或小数，小数最多两位'));
                                                        }
                                                    }
                                                    return Promise.resolve();
                                                }
                                            })
                                        ]}>
                                        <Input
                                            disabled={store.formEditson}
                                            autoComplete="off"
                                            allowClear
                                            style={{width: '95%'}}
                                            placeholder={`${t('public.input')}点位信息`}/>

                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={'api地址'}
                                        name={"authorizeApiUrl"}>
                                        <Input
                                            disabled={store.formEditson}
                                            autoComplete="off"
                                            allowClear
                                            style={{width: '95%'}}
                                            placeholder={`${t('public.input')}apiUrl`}/>

                                    </Form.Item>
                                </Col>
                            </Row>
                            {
                                store.record.rtpSwitch == 1 &&
                                <Row gutter={{md: 8, lg: 24, xl: 48}}>
                                    <Col span={8}>
                                        <Form.Item
                                            name="rtp"
                                            label={t('reward.Sand')}
                                        >
                                            <Select
                                                style={{width: '100%'}}
                                                disabled={store.formEditson}
                                                allowClear
                                                placeholder={`${t('public.Select')}${t('reward.Sand')}`}>
                                                <Select.Option value={80}> 80</Select.Option>
                                                <Select.Option value={87}> 87</Select.Option>
                                                <Select.Option value={93}> 93</Select.Option>
                                                <Select.Option value={97}> 97</Select.Option>
                                                <Select.Option value={105}> 105</Select.Option>
                                                <Select.Option value={120}> 120</Select.Option>
                                                <Select.Option value={150}> 150</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            name="rate"
                                            label='倍率'
                                            rules={[
                                                {
                                                    required: false,
                                                },
                                                ({getFieldValue}) => ({
                                                    validator(_, value) {
                                                        if (value) {
                                                            if (!/^\d+$/.test(value)) {
                                                                return Promise.reject(new Error('请输入正整数'));
                                                            }
                                                            if (value == 1) {
                                                                return Promise.resolve();
                                                            }
                                                            if (value % 10 !== 0) {
                                                                return Promise.reject(new Error('请输入1到10的倍数'));
                                                            }

                                                        }
                                                        return Promise.resolve();
                                                    }
                                                })
                                            ]}
                                        >
                                            <Input
                                                disabled={store.recordson.id || store.formEditson}
                                                allowClear
                                                placeholder={`${t('public.input')}倍率`}
                                                style={{width: '100%'}}/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            name="operatorCompanyId"
                                            label='运营商'
                                        >
                                            <Select
                                                disabled={store.recordson.id || store.formEditson}
                                                allowClear
                                                style={{width: '100%'}}
                                                placeholder={`${t('public.Select')}${t('reward.Currency')}`}
                                                options={store.setCompanyOption}
                                                showSearch
                                                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            }
                            <Row gutter={{md: 8, lg: 24, xl: 48}}>
                                <div style={{
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                    {
                                        !store.formEditson &&
                                        <Button style={{marginRight: '15px'}} type="primary" loading={store.loading}
                                                htmlType="submit">{t('public.OK')}</Button>
                                    }
                                    <Button loading={store.loading} onClick={() => {
                                        store.formVisibleson = false
                                    }}>{t('public.Cancel')}</Button>
                                </div>
                            </Row>
                        </Form>

                    </div>
                </Modal>
            )}

        </ Modal>)
})