import React from 'react'
import { observer } from 'mobx-react'
import { Space, Popconfirm } from 'antd'
import { DeleteOutlined, EditOutlined, MinusCircleOutlined, PlusOutlined, ReloadOutlined } from '@ant-design/icons'
import { TableCard, AuthButton, } from '@components'
import { t } from '@tool'
import store from './paramStore'
const rowSelection = {
    onChange: (selectedRowKeys) => {
        store.setIds(selectedRowKeys)
    }, getCheckboxProps: (record) => ({
        disabled: record.id === 'Disabled Index', // Column configuration not to be checked
        name: record.id,
    }),
}

@observer
class ComTable extends React.Component {

    componentDidMount() {
        store.tableOptions = {
            ...store.tableOptions,
            status: '1'
        }
        store.fetchRecords()
    }

    handleLoadSystemParam = (key) => {
        // 调用引入的接口函数
        store.loadSystemParam({ paramKey: key });
    };


    columns = [
        {
            title: t('disposition.name'),
            width: '120px',
            key: 'paramKey',
            dataIndex: 'paramKey',
            ellipsis: true,
        },
        {
            title: t('disposition.value'),
            width: '40vh',
            key: 'paramValue',
            dataIndex: 'paramValue',
            ellipsis: true,
        },
        {
            title: t('public.remark'),
            width: '20vh',
            key: 'remark',
            dataIndex: 'remark',
            ellipsis: true,
        },
        {
            fixed: 'right',
            title: t('public.Controls'),
            width: '200px',
            key: 'id',
            render: (_, record) => {
                return (<Space size="middle">

                    <AuthButton
                        type="primary"
                        icon={<ReloadOutlined />}
                        auth="disposition.Load"
                        onClick={() => this.handleLoadSystemParam(record.paramKey)}
                    >
                        {t('disposition.Load')}
                    </AuthButton>

                    <AuthButton
                        type={"primary"}
                        ghost
                        icon={<EditOutlined />}
                        onClick={() => store.showForm(record, true)}
                        auth="user.edit">
                        {t('public.edit')}
                    </AuthButton>
                    <Popconfirm
                        title={t('public.sure')}
                        onConfirm={() => store.onDelete(record)}
                        okText={t('public.OK')}
                        cancelText={t('public.Cancel')}
                    >
                        <AuthButton
                            type={"primary"}
                            icon={<DeleteOutlined />}
                            danger
                            ghost
                            auth="user.edit">
                            {t('public.delete')}
                        </AuthButton>
                    </Popconfirm>

                </Space>)
            }
        }]

    handleTableChange = ({ current, pageSize }, filters, sorter) => {
        store.pageNum = current
        store.pageSize = pageSize
        store.tableOptions = {
            ...store.tableOptions, // 排序：好像只支持单个排序
            sortField: sorter.field, sortOrder: sorter.order, ...filters
        }
        store.fetchRecords()
    }

    render() {
        return (<TableCard
            tableLayout={'fixed'}
            size={'small'}
            scroll={{
                x: '1500',
                y: "46vh",
            }}
            handleClickRow={store.handleClickRow}
            rowKey="id"
            rowSelection={{
                type: 'checkbox', ...rowSelection,
            }}
            title={t('disposition.Parameter')}
            loading={store.isFetching}
            // 后端的数据源
            dataSource={store.dataSource}
            onReload={store.fetchRecords}
            onChange={this.handleTableChange}
            actions={[
                <Popconfirm
                    title={`${t('public.Are')}${store.ids.length}?`}
                    open={store.conDelete}
                    onConfirm={() => store.onDelete(-1)}
                    onCancel={() => { store.confirmDelete(false) }}
                    okText={t('public.OK')}
                    cancelText={t('public.Cancel')}
                >
                    <AuthButton
                        type="primary"
                        onClick={() => store.confirmDelete(true)}
                        icon={<MinusCircleOutlined />}
                        auth="user.delete"
                    >
                        {t('public.Batchdeletion')}
                    </AuthButton>
                </Popconfirm>,
                <AuthButton
                    type="primary"
                    icon={<PlusOutlined />}
                    auth="user.add"
                    onClick={() => store.showForm({}, true)}>
                    {t('public.new')}
                </AuthButton>]}
            // 分页器
            pagination={{
                showSizeChanger: true,
                showLessItems: true,
                showTotal: total => t('public.intotal', total),
                pageSizeOptions: ['10', '20', '50', '100'], // 如果不传 total，则以后端返回数据条数作为 total 的值
                total: store.total, // 如果不传，则默认是第一条，如果需要默认显示第3条，则必须传
                current: store.pageNum,
                pageSize: store.pageSize,
            }}
            columns={this.columns} />)
    }
}

export default ComTable
