import React from 'react'
import { observer } from 'mobx-react'
import { Space } from 'antd'
import { EditOutlined, } from '@ant-design/icons'
import { TableCard, AuthButton, } from '@components'
import store from './AuthoritySetStore'
import { t, pulldown } from '@tool'
import { history } from '@http'

@observer
class ComTable extends React.Component {

    componentDidMount() {
        store.tableOptions = {
            ...store.tableOptions, status: 1
        }
        store.fetchRecords()
    }

    columns = [{ title: t('userlimit.Account'), width: '60px', key: 'loginName', dataIndex: 'loginName', }, {
        title: t('userlimit.nickname'), width: '80px', key: 'nickName', dataIndex: 'nickName',
    }, {
        title: t('userlimit.status'),
        width: '50px',
        key: 'status',
        dataIndex: 'status',
        render: (text) => (text === 1) ? t('public.effective') : t('public.invain'),
    }, { title: t('userlimit.mailbox'), width: '120px', key: 'email', dataIndex: 'email', }, {
        title: t('userlimit.type'), width: '80px', key: 'userType', dataIndex: 'userType', render: (text) => {
            const filtered = pulldown("userTypeOptionsSon").filter(item => item.value === text);
            if (filtered.length > 0) {
                return filtered[0].label;
            } else {
                return '-'; // 或者返回其他默认值
            }
        }
    }, { title: t('userlimit.mobile'), width: '100px', key: 'mobile', dataIndex: 'mobile', }, {
        title: t('public.Controls'),fixed: 'right', width: '7vh', key: 'id', render: (_, record) => {
            return (<Space size="middle">
                <AuthButton type={"primary"} ghost icon={<EditOutlined />}
                    onClick={() => this.onClick(record)}>{t('public.Settings')}</AuthButton>
            </Space>)
        },
    },]

    handleTableChange = ({ current, pageSize }, filters, sorter) => {
        store.pageNum = current
        store.pageSize = pageSize
        store.tableOptions = {
            ...store.tableOptions, // 排序：好像只支持单个排序
            sortField: sorter.field, sortOrder: sorter.order, ...filters
        }
        store.fetchRecords()
    }

    onClick = (record) => {
        history.push({ pathname: `/authority-set-form`, state: { params: record.id } })
    }

    handleClickRow = (record) => {
        return {
            onDoubleClick: () => {
                history.push({ pathname: `/authority-set-form`, state: { params: record.id } })
            }
        }
    }

    render() {
        return (<TableCard
            tableLayout={'fixed'}
            scroll={{
                x: 1500,
                y: '44vh'
            }}
            size={'small'}
            handleClickRow={this.handleClickRow}
            rowKey="id"
            title={t('userlimit.User')}
            loading={store.isFetching}
            // 后端的数据源
            dataSource={store.dataSource}
            onReload={store.fetchRecords}
            onChange={this.handleTableChange}
            // 分页器
            pagination={{
                showSizeChanger: true,
                showLessItems: true,
                showTotal: total => t('public.intotal', total),
                pageSizeOptions: ['10', '20', '50', '100'], // 如果不传 total，则以后端返回数据条数作为 total 的值
                total: store.total, // 如果不传，则默认是第一条，如果需要默认显示第3条，则必须传
                current: store.pageNum,
                pageSize: store.pageSize,
            }}
            columns={this.columns} />)
    }
}

export default ComTable
