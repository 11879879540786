import React from "react"

import { SearchOutlined } from '@ant-design/icons'

import { Button, Input, Form } from "antd"

import store from "./callbackStore"
import CallbackTable from "./callbackTable"
import CallbackForm from "./callbackForm"
import { t } from '@tool'
import { MyForm } from '@components'

export default function UserIndex() {
    const [form] = Form.useForm()

    const onFinish = (params) => {
        // 重置为第一页
        store.setPageNum(1)
        store.tableOptions = params
        store.fetchRecords()
    }
    const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
    return (<div>
        <MyForm form={form} labelCol={{ span: 7 }} onFinish={onFinish} >
            <MyForm.Item span={6} title={t('Callback.name')} name={"name"}>
                <Input allowClear name={"name"} autoComplete="off"
                    placeholder={t('public.input')} />
            </MyForm.Item>
            <MyForm.Item span={6} title={t('Callback.content')} name={"content"}>
                <Input allowClear name={'content'} autoComplete="off"
                    placeholder={t('public.input')} />
            </MyForm.Item>
            <MyForm.Item span={6} title={t('Callback.type')} name={"type"}>
                <Input allowClear name={'type'} autoComplete="off"
                    placeholder={t('public.input')} />
            </MyForm.Item>
            <MyForm.Item span={6}>
                <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>{t('public.inquire')}</Button>
            </MyForm.Item>

        </MyForm>
        <CallbackTable />
        {/* formVisible 控制表单显示 */}
        <CallbackForm />

    </div>)
}