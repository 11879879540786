import { post } from "@http"

//查询列表
export function getPartnerList(params) {
    return post("/manager/partner/getList", params)
}

//添加合作伙伴
export function savePartner(params) {
    return post("/manager/partner/save", params)
}

//修改公司信息
export function modifyPartner(params) {
    return post("/manager/partner/update", params)
}

//修改状态
export function batchStatusPartner(params) {
    return post("/manager/partner/remove", params)
}

// H5启用/停用
export function h5EnableOrDisable(params) {
    return post("/manager/partner/h5/enable", params)
}

// web启用/停用
export function webEnableOrDisable(params) {
    return post("/manager/partner/web/enable", params)
}
