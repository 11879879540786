import React from "react"

import { SearchOutlined } from '@ant-design/icons'

import { Button, Input, Form, Select } from "antd"
import PaeamTable from "./paramTable"
import PaeamDetails from "./paramDetails"
import ParamForm from "./paramForm"
import store from "./paramStore"
import { pulldown,t } from '@tool'
import { MyForm } from '@components'

export default function UserIndex() {
    const [form] = Form.useForm()
    const onFinish = (params) => {
        // 重置为第一页
        store.setPageNum(1)
        store.tableOptions = params
        store.fetchRecords()
    }
    return (<div>
        <MyForm form={form} onFinish={onFinish} initialValues={{ paramKey: '', paramValue: '', status: '1' }}>

            <MyForm.Item span={6} title={t('disposition.name')} name={"paramKey"}>
                <Input allowClear name={'paramKey'} autoComplete="off"
                    placeholder={`${t('public.input')} ${t('disposition.name')}`}/>
            </MyForm.Item>

            <MyForm.Item span={6} title={t('disposition.value')} name={"paramValue"}>
                <Input allowClear name={'paramValue'} autoComplete="off"
                    placeholder={`${t('public.input')} ${t('disposition.value')}`} />
            </MyForm.Item>
            <MyForm.Item span={6} title={t('disposition.status')} name={"status"}>
                <Select
                    name={'status'}
                    value={''}>
                    {pulldown('statusOptionsSon').map(item => {
                        return <Select.Option value={item.value} key={item.label}>{item.label}</Select.Option>;
                    })}
                </Select>
            </MyForm.Item>

            <MyForm.Item span={6}>
                <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>{t('public.inquire')}</Button>
            </MyForm.Item>
        </MyForm>
        <PaeamTable />
        <ParamForm />
        <PaeamDetails />
    </div>)
}