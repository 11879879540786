import React from 'react'

import { observer } from 'mobx-react'

import { TableCard } from '@components'
import { t } from '@tool'
import store from './LogStore'

@observer
class ComTable extends React.Component {

    componentDidMount() {
        store.fetchRecords()
    }

    columns = [
        { title: t('Accesslog.URL'), width: '90px', key: 'log_name', dataIndex: 'log_name', ellipsis: true, },
        {
            title: t('Accesslog.argument'), width: '180px', key: 'content', dataIndex: 'content', ellipsis: true,
        },
        { title: t('Accesslog.Request'), width: '60px', key: 'add_time', dataIndex: 'add_time', ellipsis: true,},
        { title: 'apiKey', width: '120px', key: 'api_key', dataIndex: 'api_key',ellipsis: true, },
    ]

    handleTableChange = ({ current, pageSize }, filters, sorter) => {
        store.pageNum = current
        store.pageSize = pageSize
        store.tableOptions = {
            ...store.tableOptions, // 排序：好像只支持单个排序
            sortField: sorter.field, sortOrder: sorter.order, ...filters
        }
        store.fetchRecords()
    }

    render() {
        return (<TableCard
            tableLayout={'fixed'}
            size={'small'}
            scroll={{
                y: "44vh",
            }}
            rowKey="_id"
            handleClickRow={store.handleClickRow}
            title={t('Accesslog.log')}
            loading={store.isFetching}
            // 后端的数据源
            dataSource={store.dataSource}
            onReload={store.fetchRecords}
            onChange={this.handleTableChange}
            // 分页器
            pagination={{
                showSizeChanger: true,
                showLessItems: true,
                showTotal: total => t('public.intotal', total),
                pageSizeOptions: ['10', '20', '50', '100'], // 如果不传 total，则以后端返回数据条数作为 total 的值
                total: store.total, // 如果不传，则默认是第一条，如果需要默认显示第3条，则必须传
                current: store.pageNum,
                pageSize: store.pageSize,
            }}
            columns={this.columns} />)
    }
}

export default ComTable
