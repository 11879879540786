import React, { useEffect, useState } from 'react'

import { observer } from 'mobx-react'
import { Modal, Form, Input, message, Row, Col, Select, Radio} from 'antd'
import { t } from '@tool'
import store from './RoleStore'

import { addRole, updateRole } from '@api'

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

export default observer(function () {
    // 文档中未找到这种解构使用方法
    const [form] = Form.useForm()
    // useState 函数组件中使用 state
    // loading 默认是 flase
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        form.resetFields()
        if (store.record.id) {
            form.setFieldsValue(store.record)
        }
    }, [store.record])

    const handleSubmit = () => {
        form.submit()
    }

    const onFinish = (values) => {
        setLoading(true)
        if (store.record.id) {
            values['id'] = store.record.id
            const posem = JSON.parse(JSON.stringify(values))

            updateRole(posem).then(res => {
                console.log("res", res);
                if (res > 0) {
                    store.formVisible = false
                    store.fetchRecords()
                } else {
                    message.error(t('Collaborate.Failure'))
                }
            }).finally(() => setLoading(false))
        } else {
            const posem = JSON.parse(JSON.stringify(values))
            console.log(posem);
            addRole(posem).then(res => {
                if (res > 0) {
                    store.formVisible = false
                    store.fetchRecords()
                }
            }).finally(() => setLoading(false))
        }
    }

    const formatDate = (dateStr) => {
        let date = new Date(dateStr);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始，所以+1，并使用padStart确保总是两位数
        const day = String(date.getDate()).padStart(2, '0'); // 使用padStart确保总是两位数
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    return (// Modal 对话框
        <Modal
            width={'54%'}
            forceRender={true}
            open={store.formVisible}
            title={store.formTitle}
            onCancel={() => store.formVisible = false}
            confirmLoading={loading}
            // ok 按钮 props
            onOk={handleSubmit}>
            <Form form={form} labelCol={{ span: 7 }} onFinish={onFinish}>
                <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
                    <Col span={12}>
                        <Form.Item label={t('Role.roleName')}
                            rules={[{
                                required: true,
                                message: `${t('public.input')}${t('Role.roleName')}`
                            }]}
                            name={"roleName"}>
                            <Input allowClear autoComplete="off" disabled={!store.formEdit}
                                placeholder={`${t('public.input')} ${t('Role.roleName')}`} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
                    <Col span={12}>
                        <Form.Item label={t('Role.roleKey')}
                            rules={[{ required: true, message: `${t('public.input')}${t('Role.roleKey')}` }]}
                            name={"roleKey"}>
                            <Input allowClear autoComplete="off" disabled={!store.formEdit}
                                placeholder={`${t('public.input')} ${t('Role.roleKey')}`} />
                        </Form.Item>
                    </Col>
                </Row>
                <Col span={8}>
                    <Form.Item label={t('Role.status')}
                        labelCol={{ span: 8 }}
                        rules={[{
                            required: false,
                            message: `${t('public.Select')} ${t('Role.status')}`
                        }]}
                        initialValue={store.record.status === 0}
                        name={"status"}>
                        <Radio.Group disabled={!store.formEdit}>
                            <Radio value={1}>在用</Radio>
                            <Radio value={0}>禁用</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        labelCol={{ span: 2 }}
                        label={t('Role.remark')}
                        name="remark">
                        <Input.TextArea rows={5} disabled={!store.formEdit} autoComplete="off" />
                    </Form.Item>
                </Col>
            </Form>
        </Modal>)
})