import {post} from "@http"

//通过ID获取信息
export function getCompanyById(id) {
    return post("/manager/company/" + id, {})
}

//通过ID获取信息
export function gamestup(companyId) {
    return post("/manager/company/games/" + companyId, {})
}

//添加公司
export function addCompany(params) {
    return post("/manager/company/add", params)
}

//修改公司信息
export function modifyCompany(params) {
    return post("/manager/company/modify", params)
}

//修改状态
export function batchStatusCompany(params) {
    return post("/manager/company/batchStatus", params)
}

//获取所有公司信息
export function getCompanyList(params) {
    return post("/manager/company/getList", params)
}

//游戏商设置
export function gamesSet(params) {
    return post("/manager/company/games/set", params)
}

//投注汇总（按游戏商）
export function getTransSummaryListByGameCompany(params) {
    return post("/manager/company/game/countBetting", params)
}

//根据游戏商生成对应游戏的游戏秘钥
export function updateGameEncryptByCompanyId(companyId) {
    return post("/manager/gameInfo/updateGameEncryptByCompanyId/" + companyId)
}

//根据运营商查询游戏商点位详情
export function getOperatePriceInfo(params) {
    return post("/manager/company/getOperatePriceInfo", params)
}

//获取运营商对应游戏商点位信息
export function createRateApi(companyId) {
    return post("/manager/company/createRate/" + companyId, {})
}