import React from 'react'

import {observer} from 'mobx-react'
import store from "./GameInfoStore";
import {getGameName} from "@tool"

@observer
class GameInfoRow extends React.Component {

    componentDidMount() {
        store.tableOptions = {
            ...store.tableOptions,
            brandName: 'PG'
        }

        store.fetchRecords()
    }

    render() {
        return <div style={{width: '100%', display: 'flex', flexWrap: 'wrap', background: '#f8f8f8',}}>
            {store.records.map(item => {
                return <div
                    style={{marginLeft: '1rem'}}
                    key={item.id}
                    onClick={() => {
                        this.props.openGame(item)
                    }}
                >
                    <img
                        style={{width: '17vh', height: '18vh', borderRadius: '0.3rem'}}
                        alt=''
                        src={`${item.gameIcon}`}
                        title={getGameName(item)}
                    />
                    <p
                        title={getGameName(item)}
                        style={{
                            textAlign: 'center',
                            marginTop: '6px',
                            width: '17vh',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        }}>{getGameName(item)}</p>
                </div>


            })}
        </div>
    }
}

export default GameInfoRow