import React, {useState} from "react"

import {SearchOutlined} from '@ant-design/icons'

import {Button, Input, Select} from "antd"

import store from "./gameStore"
import ComTable from "./gameTable"
import ComForm from "./gameForm"
import ComForm1 from "./fastaddgameForm"

import {get, pulldown, t, gameCategoryCode} from '@tool'

import {MyForm} from '@components'

export default function UserIndex() {
    const gameCompanyOptions = JSON.parse(localStorage.getItem('gameCompanyList'))
    const options = JSON.parse(localStorage.getItem('operatorList'))

    const [valueList, setvalueList] = useState([]); // 创建状态变量和更新状态的函数

    const [zy, setZyValue] = useState(false); // 自研字段判断

    const onFinish = (params) => {
        // 重置为第一页
        store.setPageNum(1)
        store.tableOptions = params
        store.storevalue = valueList
        store.fetchRecords()
    }

    const onCnge = (e) => {
        setvalueList(e)
    }

    const gameOnChange = (e) => {
        if ('EG' === e) {
            setZyValue(true)
        } else {
            setZyValue(false)
        }
    }

    console.log(get('userType'))
    // Filter `option.label` match the user type `input`

    const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
    return (<div>
        <MyForm onFinish={onFinish} initialValues={{gameType: '', gameName: '', companyId: ''}}>
            <MyForm.Item span={7} title={t('Carriergame.name')} name={"gameName"}>
                <Input allowClear name={'gameName'} autoComplete="off"
                       placeholder={`${t('public.input')} ${t('Carriergame.name')}`}/>
            </MyForm.Item>
            <MyForm.Item span={6} title={t('Carriergame.type')} name={"gameType"}>
                <Select
                    name={'gameType'}
                    placeholder={`${t('public.Select')} ${t('Carriergame.type')}`}>
                    {pulldown('gameTypeOptionsSon').map(item => {
                        return <Select.Option value={item.value} key={item.value}>{item.label}</Select.Option>
                    })}
                </Select>
            </MyForm.Item>
            {get('userType') !== 'PLATFORM' &&
                <MyForm.Item span={6} title={t('Carriergame.operator')} name={"companyId"}>
                    <Select
                        name={"companyId"}
                        showSearch
                        allowClear
                        filterOption={filterOption}
                        placeholder={`${t('public.Select')} ${t('Carriergame.operator')}`}
                        options={options}
                        onChange={onCnge}
                    >
                    </Select>
                </MyForm.Item>}
            <MyForm.Item span={8} title={t('Bet.platform')} name={"platformType"}>
                <Select
                    name={'platformType'}
                    style={{width: '100%'}}
                    showSearch
                    allowClear
                    placeholder={t('Carriergame.GameCompanyPlease')}
                    filterOption={filterOption}
                    options={gameCompanyOptions}
                    onChange={gameOnChange}
                />
            </MyForm.Item>
            {zy && <MyForm.Item span={8} title={'品牌分类'} name={"gameCategoryCode"}>
                <Select
                    name={'gameCategoryCode'}
                    style={{width: '100%'}}
                    showSearch
                    allowClear
                    placeholder={'请选择品牌分类'}
                    filterOption={filterOption}
                    options={gameCategoryCode}

                />
            </MyForm.Item>}
            <MyForm.Item span={4}>
                <Button type="primary" htmlType="submit" icon={<SearchOutlined/>}>{t('public.inquire')}</Button>
            </MyForm.Item>
        </MyForm>
        <ComTable/>
        {/* formVisible 控制表单显示 */}
        <ComForm/>
        <ComForm1/>
    </div>)
}