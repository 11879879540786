import {post} from "@http"

//添加公司详情
export function saveCompanyGameDetail(params) {
    return post("/manager/company-game-type/save", params)
}

export function removeCompanyGameDetail(params) {
    return post("/manager/company-game-type/remove", params)
}

export function updateCompanyGameDetail(params) {
    return post("/manager/company-game-type/update", params)
}

//查询列表
export function getCompanyGameDetailList(params) {
    return post("/manager/company-game-type/getList", params)
}

//查询游戏商类型的游戏商列表
export function getCompanyGameByTypeList(params) {
    return post("/manager/company-game-type/companyList", params)
}

//查询游戏商类型的游戏商列表
export function getCompanyValue(params) {
    return post("/manager/company-game-type/companyValue", params)
}