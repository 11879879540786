import React from "react"

import {SearchOutlined} from '@ant-design/icons'

import {Button, Input, Select} from "antd"

import store from "./gameStore"
import ComTable from "./gameTable" 
import ComForm from "./gameForm"

import {pulldown,t} from '@tool'

import {MyForm} from '@components'

export default function UserIndex() {
    const onFinish = (params) => {
        // 重置为第一页
        store.setPageNum(1)
        store.tableOptions = params
        store.fetchRecords()
    }

    return (<div>
        <MyForm onFinish={onFinish} initialValues={{gameType: '', gameName: ''}}>
            <MyForm.Item span={7} title={t('Carriergame.name')} name={"gameName"}>
                <Input allowClear name={'gameName'} autoComplete="off"
                       placeholder={`${t('public.input')} ${t('Carriergame.name')}`}/>
            </MyForm.Item>
            <MyForm.Item span={6} title={t('Carriergame.type')} name={"gameType"}>
                <Select
                    name={'gameType'}
                    placeholder={`${t('public.Select')} ${t('Carriergame.type')}`}>
                    {pulldown('gameTypeOptionsSon').map(item => {
                        return <Select.Option value={item.value} key={item.value}>{item.label}</Select.Option>
                    })}
                </Select>
            </MyForm.Item>
            <MyForm.Item span={6}>
                <Button type="primary" htmlType="submit" icon={<SearchOutlined/>}>{t('public.inquire')}</Button>
            </MyForm.Item>
        </MyForm>
        <ComTable/>
        {/* formVisible 控制表单显示 */}
        <ComForm/>
    </div>)
}