import React from 'react'
import { observer } from 'mobx-react'
import { TableCard } from '@components'
import store from "./playerStore"
import { t, get, timestampTo,gameGameName } from '@tool'

@observer
class ComTable extends React.Component {
    componentDidMount() {
        store.tableOptions = {
            ...store.tableOptions,
            type: 'user',
        }
        store.fetchRecords()
    }
    options = JSON.parse(localStorage.getItem('operatorList'))
    columns = [
        {
            title:t('Bet.date') ,
            width: '3px',
            key: 'betDate',
            dataIndex: 'betDate',
            ellipsis: true,
        },
        {
            title: t('Bet.playerName'),
            width: '3px',
            key: 'playerName',
            dataIndex: 'playerName',
            ellipsis: true,
        },
        {
            title:  t('Bet.betAmount') ,
            width: '3px',
            key: 'betAmount',
            dataIndex: 'betAmount',
            ellipsis: true,
        },
        {
            title:t('Bet.winAmount') ,
            width: '3px',
            key: 'winAmount',
            dataIndex: 'winAmount',
            ellipsis: true,
        },
        {
            title: t('Bet.operatorCompany'),
            width: '3px',
            key: 'companyId',
            dataIndex: 'companyId',
            ellipsis: true,
            hide: get('userType') == 'PLATFORM',
            render: (text) => {
                const filtered = this.options.filter(item => String(item.value) === text);
                if (filtered.length > 0) {
                    return filtered[0].label;
                } else {
                    return '-'; // 或者返回其他默认值
                }
            }
        },
        {
            title:t('Bet.Currency') ,
            width: '3px',
            key: 'currency',
            dataIndex: 'currency', ellipsis: true,
        },
    ]

    handleTableChange = ({ current, pageSize }, filters, sorter) => {
        store.pageNum = current
        store.pageSize = pageSize
        store.tableOptions = {
            ...store.tableOptions, // 排序：好像只支持单个排序
            sortField: sorter.field, sortOrder: sorter.order, ...filters
        }
        store.fetchRecords()
    }
    render() {
        return (
            <div>
                <TableCard
                    tableLayout={'fixed'}
                    size={'small'}
                    scroll={{
                        x: 600,
                        y: '38vh'
                    }}
                    rowKey="id"
                    title={t('Bet.Dailyst')}
                    loading={store.isFetching}
                    // 后端的数据源
                    dataSource={store.dataSource}
                    onReload={store.fetchRecords}
                    onChange={this.handleTableChange}
                    actions={[]}
                    // 分页器
                    pagination={{
                        showSizeChanger: true,
                        showLessItems: true,
                        showTotal: total => t('public.intotal', total),
                        pageSizeOptions: ['10', '20', '50', '100'], // 如果不传 total，则以后端返回数据条数作为 total 的值
                        total: store.total, // 如果不传，则默认是第一条，如果需要默认显示第3条，则必须传
                        current: store.pageNum,
                        pageSize: store.pageSize,
                    }}
                    columns={this.columns}
                />


            </div>)
    }
}

export default ComTable
