import React, { useState, useEffect } from 'react'

import { observer } from 'mobx-react'
import { Modal, Form, Radio, Input, message, Button, Row, Col, Select } from 'antd'

import store from './UserStore'
import { userTypeOptions, encryptPassword, t } from '@tool'

import { registerUser, modifyUser } from '@api'

export default observer(function () {
    useEffect(() => {
        form.resetFields()
        if (store.record.id) {
            form.setFieldsValue(store.record)
        }
    }, [store.record])
    // 文档中未找到这种解构使用方法
    const [form] = Form.useForm()
    // useState 函数组件中使用 state
    // loading 默认是 false
    const [loading, setLoading] = useState(false)
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d_@.#-]+$/;
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const handleSubmit = () => {
        form.submit()
    }
    const onFinish = (values) => {
        setLoading(true)
        if (!emailPattern.test(values.email)) {
            message.error(t('user.email'))
            setLoading(false)
            return
        }
        if (store.record.id) {
            values['id'] = store.record.id
            modifyUser(values).then(res => {
                if (res.status === 10000) {
                    store.formVisible = false
                    store.fetchRecords()
                } else {
                    message.error(t('user.failure'))
                }
            }).finally(() => setLoading(false))
        } else {
            if (!regex.test(values.loginPwd)) {
                message.error(t('user.Inputonly'))
                setLoading(false)
                return
            }
            const data = JSON.parse(JSON.stringify(values));
            data.loginPwd = encryptPassword(data.loginPwd, '0000018c3896b653000000007e0login');
            registerUser(data).then(res => {
                if (res.status === 10000) {
                    store.formVisible = false
                    store.fetchRecords()
                }
            }).finally(() => setLoading(false))
        }
    }
    //生成位6~12密码
    const characters = () => {
        const arr1 = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
        const arr2 = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z']
        const arr3 = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0']
        const arr4 = ['_', '@', '#', '.', '-']
        let password = '';
        // 从arr1、arr2和arr3中随机选取两个字符  
        for (let i = 0; i < 2; i++) {
            const randomIndex = Math.floor(Math.random() * arr1.length);
            password += arr1[randomIndex];
        }
        for (let i = 0; i < 2; i++) {
            const randomIndex = Math.floor(Math.random() * arr2.length);
            password += arr2[randomIndex];
        }
        for (let i = 0; i < 2; i++) {
            const randomIndex = Math.floor(Math.random() * arr3.length);
            password += arr3[randomIndex];
        }

        // 从arr4中随机选取一个字符，并确保密码长度在6到12之间  
        const specialCharCount = Math.floor(Math.random() * (password.length - 4)) + 1; // 确保长度在6到12之间  
        for (let i = 0; i < specialCharCount; i++) {
            const randomIndex = Math.floor(Math.random() * arr4.length);
            password += arr4[randomIndex];
        }

        return password;
    }
    const onGenerate = () => {
        const password = characters()
        message.success(`${t('user.generate')}${password}`)
        form.setFieldValue('loginPwd', password)
    }
    const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
    return (// Modal 对话框
        <Modal
            width={'60%'}
            forceRender={true}
            open={store.formVisible}
            title={store.formTitle}
            onCancel={() => store.formVisible = false}
            confirmLoading={loading}
            // ok 按钮 props
            onOk={handleSubmit}>

            <Form form={form} onFinish={onFinish} labelCol={{ span: 6 }} initialValues={{ companyId: store.record.companyId }}>
                <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
                    <Col span={8}>
                        <Form.Item label={t('user.account')}
                            rules={[{ required: true, message: `${t('public.input')}${t('user.account')}` }]}
                            name={"loginName"}>
                            <Input disabled={store.record.id} allowClear name={"loginName"} autoComplete="off"
                                id={'userLoginName'} placeholder={`${t('public.input')}${t('user.account')}`} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={t('user.Usertype')}
                            rules={[{ required: true, message: `${t('public.Select')}${t('user.Usertype')}` }]}
                            name={"userType"}>
                            <Select disabled={!store.formEdit} id={'userUserType'}
                                style={{ width: '100%' }}
                                allowClear
                                name={'userType'}
                                placeholder={`${t('public.Select')}${t('user.subord')}`}
                                value={'CUSTOMER'}>
                                {userTypeOptions.map(item => {
                                    return <Select.Option value={item.value}
                                        key={item.label}>{item.label}</Select.Option>;
                                })}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item label={t('user.mailbox')}
                            rules={[{ required: true, message: `${t('public.input')}${t('user.mailbox')}` }]}
                            name={"email"}>
                            <Input disabled={!store.formEdit} allowClear name={'email'} id={'userEmail'}
                                autoComplete="off"
                                placeholder={`${t('public.input')}${t('user.mailbox')}`} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={t('user.subord')} rules={[{ required: true, message: `${t('public.input')}${t('user.subord')}` }]} name={"companyId"}>
                            <Select
                                style={{ width: '100%' }} id={'userCompanyId'} disabled={!store.formEdit}
                                placeholder={`${t('public.Select')}${t('user.subord')}`}
                                name="companyId"
                                showSearch
                                filterOption={filterOption}
                                options={JSON.parse(localStorage.getItem('companyList'))}
                            >
                            </Select>

                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={t('user.mobile')} name={"mobile"}>
                            <Input disabled={!store.formEdit} allowClear name={'mobile'} id={'userMobile'}
                                autoComplete="off"
                                placeholder={`${t('public.input')}${t('user.mobile')}`} />
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item label={t('user.nickname')}
                            name={"nickName"}>
                            <Input disabled={!store.formEdit} allowClear name={'nickName'} autoComplete="off"
                                placeholder={`${t('public.input')}${t('user.nickname')}`} />
                        </Form.Item>
                    </Col>
                    {!store.record.id && (
                        <>
                            <Col span={8}>
                                <Form.Item
                                    label={t('user.cipher')}
                                    name={"loginPwd"}
                                    rules={[{ required: true, message: `${t('public.input')}${t('user.cipher')}` }]}
                                >
                                    <Input
                                        id='loginPwd'
                                        allowClear
                                        name={'loginPwd'}
                                        autoComplete="off"
                                        placeholder={`${t('public.input')}${t('user.cipher')}`}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item >
                                    <Button type="primary" onClick={onGenerate} style={{ marginLeft: '24px' }}>{t('user.gene')}</Button>
                                </Form.Item>
                            </Col>
                        </>
                    )}

                    {store.record.id && (
                        <Col span={24}>
                            <Form.Item label={t('user.status')}
                                labelCol={{ span: 2 }}
                                rules={[{ required: true, message: `${t('public.Select')}${t('user.status')}` }]}
                                initialValue={store.record.status === 1}
                                name={"status"}>
                                <Radio.Group name={'status'} disabled={!store.formEdit}>
                                    <Radio value={1}>{t('public.effective')}</Radio>
                                    <Radio value={0}>{t('public.invain')}</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    )}
                    <Col span={24}>
                        <Form.Item
                            labelCol={{ span: 2 }}
                            style={{ width: '100%' }}
                            label={t('public.remark')}
                            name="remark">
                            <Input.TextArea disabled={!store.formEdit} rows={4} autoComplete="off" />
                        </Form.Item>
                    </Col>
                </Row>

            </Form>
        </Modal>)
})