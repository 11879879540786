import { observable, computed, action } from 'mobx'
import { message } from "antd"
import { getGamePacketList, addGameOperator } from '@api'
import { t } from '@tool'
import store from './gameStore'
class GameStore {
    @observable formVisible = false

    @observable formEdit = false

    @observable formTitle = t('Carriergame.Quick')

    @observable record = {}

    @observable records = []

    // 默认第1页
    @observable pageNum = 1
    // 每页多少条
    @observable pageSize = 10
    // 总共多少页
    @observable total = ''

    @observable ids = ''
    //运营商选中的id
    @observable setID = ''
    //选择运营商弹窗
    @observable storeopen = false
    // 其他参数，例如排序、过滤等等
    @observable tableOptions = {}

    @observable isFetching = false

    @computed get dataSource() {
        return this.records
    }
    _getTableParams = () => ({
        pageNum: this.pageNum, pageSize:this.pageSize, ...this.tableOptions, status: 1,
        companyId: localStorage.getItem('userType') === 'PLATFORM' ? localStorage.getItem('companyId') : this.setID
    })

    @action setPageNum(val) {
        this.pageNum = val
    }
    @action setPageSize(val) {
        this.pageSize = val
    }
    @action setIds(ids) {
        this.ids = ids
    }
    //运营商弹窗
    onPopup = (data) => {
        this.storeopen = data
    }
    //查询
    fetchRecords = () => {
        const params = this._getTableParams()
        this.isFetching = true
        getGamePacketList(params).then(res => {
            this.total = res.totalCount
            this.records = res.list
        }).finally(() => this.isFetching = false);
    }
    // 显示新增弹框
    // info 或许是为了编辑
    showForm = (data) => {
        this.pageNum = 1
        this.formVisible = data;
        this.formTitle = t('Carriergame.Quick');
    }
    //确定
    certainly = () => {
        if (this.ids.length === 0) {
            message.error(t('public.selectAdd'))
            return
        }
        this.isFetching = true
        let params = {
            gamePacketIds: this.ids,
            companyId: localStorage.getItem('userType') === 'PLATFORM' ? localStorage.getItem('companyId') : this.setID
        }
        addGameOperator(params).then(res => {
            if (res.status === 10000) {
                store.setPageNum(1);
                store.fetchRecords();
                this.setPageNum(1);
                this.setPageSize(10);
                this.fetchRecords();
                this.ids = [];
                this.formVisible = false;
            }
        }).finally(() => this.isFetching = false)
    }

}

export default new GameStore()