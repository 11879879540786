import { observable, computed, action } from 'mobx'
import { message } from "antd"

import copy from 'copy-to-clipboard';

import { getGameInfoList, batchStatusGameInfo, exportGame, dwonloadGameIcon, getGameInfoById } from '@api'

import { log, t, host } from '@tool'
import { constant } from 'lodash';

class GameStore {
    @observable formVisible = false

    @observable formEdit = false

    @observable formTitle = t('Gamemanage.View')

    @observable record = {}

    @observable records = []

    // 默认第1页
    @observable pageNum = 1
    // 每页多少条
    @observable pageSize = 10
    // 总共多少页
    @observable total = ''
    //二次确认删除
    @observable conDelete = false

    @observable ids = ''

    // 其他参数，例如排序、过滤等等
    @observable tableOptions = {}

    @observable isFetching = false

    @computed get dataSource() {
        return this.records
    }

    _getTableParams = () => ({ pageNum: this.pageNum, pageSize: this.pageSize, ...this.tableOptions })

    @action setPageNum(val) {
        this.pageNum = val
    }
    @action setPageSize(val) {
        this.pageSize = val
    }
    @action setIds(ids) {
        this.ids = ids
    }

    fetchRecords = () => {
        const params = this._getTableParams()

        this.isFetching = true

        getGameInfoList(params).then(res => {
            this.total = res.totalCount
            this.records = res.list
        }).finally(() => this.isFetching = false)
    }

    // 查询详情
    onDetail = (val) => {
        getGameInfoById(val).then(res => {
            this.record = res;
        }).finally(() => this.isFetching = false);
    }

    onDelete = (record) => {
        let id = []
        if (record !== -1) {
            id.push(record.id)
        } else {
            id = this.ids
        }
        this.isFetching = true
        let params = { 'ids': id, 'status': 0 }

        batchStatusGameInfo(params).then(res => {
            if (res.status === 10000) {
                this.setPageNum(1)
                this.setPageSize(10);
                this.fetchRecords()
                this.conDelete = false
            }
        }).finally(() => this.isFetching = false)
    }

    dwonloadGameIcon = (total) => {
        this.isFetching = true
        console.log("total", total);
        const param = {
            total: total
        }
        dwonloadGameIcon(param).then(res => {
            if (res.status === 10000) {
                this.fetchRecords()
                message.success(t('Partner.Success'))
            } else {
                message.error(t('Partner.Fail'))
            }
        }).finally(() => {
            this.isFetching = false
        })
    }

    // 显示新增弹框
    showForm = (info = {}, formEdit) => {
        this.formVisible = true
        this.formEdit = formEdit
        if (formEdit) {
            if (info.id) {
                this.onDetail(info.id);
            }
            this.formTitle = info.id ? t('Gamemanage.Edit') : t('Gamemanage.New')
        } else {
            this.formTitle = t('Gamemanage.View')
        }

        this.record = info
    }

    handleClickRow = (record) => {
        return {
            onDoubleClick: () => {
                this.showForm(record, false)
            }
        }
    }
    //二次确认删除
    confirmDelete = (data) => {
        if (this.ids.length === 0) {
            message.error(t('public.selectDelete'))
            return
        }
        this.conDelete = data
    }
    copy = (e) => {
        copy(e.target.innerText)
        message.success(t('public.copy'))
    }

    exportGame = () => {
        this.isFetching = true;
        exportGame({ ...this.tableOptions }).then(res => {
            // 创建一个临时 URL，并使用 a 标签下载文件
            const url = window.URL.createObjectURL(new Blob([res]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'currency_template.xlsx');
            document.body.appendChild(link);
            link.click();
            // 清理临时 URL
            window.URL.revokeObjectURL(url);
        }).catch(error => {
            console.error('下载模板失败:', error);
            this.isFetching = false
        }).finally(() => {
            this.isFetching = false
        })
    }


    // 下载文件
    downloadFile = (res, fileName) => {
        let blob = new Blob([res]); //res为从后台返回的数据
        if (!fileName) {
            fileName = res.headers['content-disposition'].split('filename=').pop();
        }
        if ('msSaveOrOpenBlob' in navigator) {
            window.navigator.msSaveOrOpenBlob(blob, fileName);
        } else {
            const elink = document.createElement('a');
            elink.download = fileName;
            elink.style.display = 'none';
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href);
            document.body.removeChild(elink);
        }
    }
}

export default new GameStore()