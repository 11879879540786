import React from "react"

import { SearchOutlined } from '@ant-design/icons'

import { Button, Input, Select } from "antd"

import store from "./OperatorStore"
import ComTable from "./OperatorTable"
import ComForm from "./OperatorForm"

import { pulldown, t } from '@tool'

import { MyForm } from '@components'

import AuthForm from "./OperatorAuthForm"

export default function UserIndex() {

    const onFinish = (params) => {
        // 重置为第一页
        store.setPageNum(1)
        params['companyType'] = 'OPERATOR_COMPANY'
        store.tableOptions = params
        store.fetchRecords()
    }

    return (<div>
        <MyForm onFinish={onFinish} initialValues={{ status: '1' }}>
            <MyForm.Item span={7} title={t('operator.Company')} name={"companyName"}>
                <Input allowClear name={'companyName'} id={'gameCompanyName'} autoComplete="off"
                    placeholder={`${t('public.input')} ${t('operator.Company')}`} />
            </MyForm.Item>
            <MyForm.Item span={6} title={t('operator.status')} name={"status"}>
                <Select id={'gameStatus'}
                    name={'status'}
                    value={''}>
                    {pulldown('statusOptionsSon').map(item => {
                        return <Select.Option value={item.value} key={item.label}>{item.label}</Select.Option>
                    })}
                </Select>
            </MyForm.Item>
            <MyForm.Item span={6}>
                <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>{t('public.inquire')}</Button>
            </MyForm.Item>
        </MyForm>
        <ComTable />
        <ComForm />
        <AuthForm/>
    </div>)
}