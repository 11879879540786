import React, { useState, useEffect } from 'react'
import { LeftOutlined, SearchOutlined } from '@ant-design/icons'
import {Button, Input, Select, DatePicker, message, Form} from "antd"
import { getIntegralByMonth } from '@api'
import { TableCard } from '@components'
import { history } from '@http'
import { MyForm } from '@components'
import { get, t } from '@tool'


export default function () {
    const [loading, setLoading] = useState(false);
    const [records, setRecords] = useState([]);
    const [pageNum, setpageNum] = useState(1);
    const [pageSize, setpageSize] = useState(10);
    const [total, settotal] = useState(0);
    const [startTimeValue, setStartTime] = useState('');
    const [endTimeValue, setEndTime] = useState('');
    const options = JSON.parse(localStorage.getItem('operatorList'))
    const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
    const [form] = Form.useForm()
    const { RangePicker } = DatePicker;
    useEffect(() => {
        fetchRecords();
    }, [pageNum, pageSize])
    const fetchRecords = (val) => {
        const params = {
            pageNum: pageNum,
            pageSize: pageSize,
            ...val,
            companyId: get('companyId'),
            userType: get('userType'),
            startTime: startTimeValue,
            endTime: endTimeValue
        }


        setLoading(true);
        getIntegralByMonth({ ...params }).then(res => {
            console.log(res)
            settotal(res.totalCount);
            setRecords(res.list)
        }).finally(() => setLoading(false));

    }
    const handleTableChange = ({ current, pageSize }, filters, sorter) => {
        setpageNum(current)
        setpageSize(pageSize)

    }
    const columns = [
        { title: '下注金额', width: '50px', key: 'betAmount', dataIndex: 'betAmount', ellipsis: true },
        { title: '输赢金额', width: '50px', key: 'profit', dataIndex: 'profit', ellipsis: true },
        { title: '时间', width: '80px', key: 'monthDate', dataIndex: 'monthDate', ellipsis: true, },
    ]

    const onFinish = (params) => {
        setRecords([])
        // 重置为第一页
        setpageNum(1)
        fetchRecords(params);
    }

    function handleMonthPickerChange(dates, dateStrings) {
        setStartTime(dateStrings[0]);
        setEndTime(dateStrings[1]);
    }


    return (
        <div>
            <div style={{ background: '#fff' }}>             <Button onClick={() => history.goBack()}
                style={{ margin: '10px' }} type={"primary"}
                icon={<LeftOutlined />}> {t('public.Back')}</Button></div>
            <MyForm form={form} labelCol={{ span: 7 }} onFinish={onFinish} initialValues={{ status: '1', sample: 0 }}>
                {get('userType') !== 'PLATFORM' &&
                <MyForm.Item span={6} title={t('Player.Owning')} name={"searchCompanyId"}>
                    <Select
                        name={'searchCompanyId'}
                        style={{ width: '100%' }}
                        showSearch
                        placeholder={t('Carriergame.Please')}
                        filterOption={filterOption}
                        options={options}
                        allowClear
                    >
                    </Select>
                </MyForm.Item>
                }

                <MyForm.Item span={7} title={'运营商'} >
                    <RangePicker placeholder={['开始日期','结束日期']} picker={'month'}
                                 format={'YYYY-MM'} showTime onChange={handleMonthPickerChange}/>
                </MyForm.Item>

                <MyForm.Item span={3}>
                    <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>{t('public.inquire')}</Button>
                </MyForm.Item>
            </MyForm>

            <TableCard
                tableLayout={'fixed'}
                size={'small'}
                scroll={{
                    x: 1200,
                    y: '44vh'
                }}
                rowKey="id"
                title={'下注积分记录'}
                loading={loading}
                // 后端的数据源
                dataSource={records}
                onReload={() => { fetchRecords() }}
                onChange={handleTableChange}
                actions={[]}
                // 分页器
                pagination={{
                    showSizeChanger: true,
                    showLessItems: true,
                    showTotal: total => t('public.intotal', total),
                    pageSizeOptions: ['10', '20', '50', '100'], // 如果不传 total，则以后端返回数据条数作为 total 的值
                    total: total, // 如果不传，则默认是第一条，如果需要默认显示第3条，则必须传
                    current: pageNum,
                    pageSize: pageSize,
                }}
                columns={columns} />
        </div>
    )
}
