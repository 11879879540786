import {getCompanyList} from '@api'
import CryptoJS from 'crypto-js';
import i18n from 'i18next';
import {get,} from './token';

import {
    userTypeOptions,
    userTypeOptionsEn,
    statusOptions,
    statusOptionsEn,
    companyTypeOptionsEn,
    companyTypeOptions,
    gameTypeOptions,
    gameTypeOptionsEn,
    gameRankingOptions,
    gameRankingOptionsEn,
    followStatusOptions,
    followStatusOptionsEn,
    companyGameType,
    bannerType,
    activityType,
    indexActivityType,
    walletModeType,
    productCategory,
    betType,
    ipType,
    ipVersion,
    sampleType,
    countType
} from './Global';

import {
    productType,
    productTypeEn
} from './Product'

export function CompanyList() {
    if (localStorage.getItem('token') == null) {
        return
    }
    getCompanyList({
        pageSize: 999999,
        status: 1,
        pageNum: 1
    }).then(res => {
        const filteredData = res.list.filter(item => item.companyType === 'OPERATOR_COMPANY');

        const gameCompanyData = res.list.filter(item => item.companyType === 'GAME_COMPANY');

        const options = res.list.map((item => {
            return {
                value: item.id,
                label: item.companyName
            };
        }));

        const operatorList = filteredData.map((item => {
            return {
                value: item.id,
                label: item.companyName
            };
        }));

        const operatorApiKeyList = filteredData.map((item => {
            return {
                value: item.apiKey,
                label: item.companyName
            };
        }));

        const gameCompanyList = gameCompanyData.map((item => {
            return {
                value: item.brandName,
                label: item.companyName
            };
        }))

        localStorage.setItem('operatorList', JSON.stringify(operatorList))
        localStorage.setItem('operatorApiKeyList', JSON.stringify(operatorApiKeyList))
        localStorage.setItem('companyList', JSON.stringify(options))
        localStorage.setItem('gameCompanyList', JSON.stringify(gameCompanyList))
    });
}

// 加密
export function encryptPassword(data, key) {
    const iv = '1008699920999299'; // 偏移量为16个字节长度的字符串
    if (typeof key !== 'string') {
        throw new Error('必须是字符串 string');
    }
    // 检查key长度
    if (key.length > 32) {
        throw new Error('密钥长度不能超过32个字符');
    }
    // 检查iv的长度（这里需要确保是16个字节）
    if (iv.length !== 16) {
        throw new Error('初始化向量长度必须是16个字节');
    }
    // 使用CBC模式，并指定偏移量和类型
    const encrypted = CryptoJS.AES.encrypt(data, CryptoJS.enc.Latin1.parse(key), {
        iv: CryptoJS.enc.Latin1.parse(iv),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();
}

//翻译
export function t(val, data) {
    return data ? `${i18n.t(val)}${data}` : i18n.t(val)
}

function findKeyAndValue(obj, keyToFind) {
    const keys = Object.keys(obj);
    const value = obj[keyToFind];

    if (keys.includes(keyToFind)) {
        return value
    } else {
        return null; // 或者你可以抛出一个错误或者返回一个默认值
    }
}

//下拉字段替换
export function pulldown(data) {
    const objectzh = {
        statusOptionsSon: statusOptions,
        userTypeOptionsSon: userTypeOptions,
        companyTypeOptionsSon: companyTypeOptions,
        gameTypeOptionsSon: gameTypeOptions,
        gameRankingOptionsSon: gameRankingOptions,
        productTypeOptions: productType,
        followStatusOptions: followStatusOptions,
        companyGameType: companyGameType,
        bannerType: bannerType,
        activityType: activityType,
        indexActivityType: indexActivityType,
        walletModeType: walletModeType,
        productCategory: productCategory,
        betType: betType,
        ipType: ipType,
        ipVersion: ipVersion,
        sampleType: sampleType,
        countType: countType
    }
    const objecten = {
        statusOptionsSon: statusOptionsEn,
        userTypeOptionsSon: userTypeOptionsEn,
        companyTypeOptionsSon: companyTypeOptionsEn,
        gameTypeOptionsSon: gameTypeOptionsEn,
        gameRankingOptionsSon: gameRankingOptionsEn,
        productTypeOptions: productTypeEn,
        followStatusOptions: followStatusOptionsEn,
    }
    switch (get('Language') || 'zh') {
        case 'en':
            return findKeyAndValue(objecten, data)
        case 'da':
            return [];
        case 'de':
            return [];
        case 'es':
            return [];
        case 'fi':
            return [];
        case 'fr':
            return [];
        case 'id':
            return [];
        case 'it':
            return [];
        case 'ja':
            return [];
        case 'ko':
            return [];
        case 'nl':
            return [];
        case 'no':
            return [];
        case 'pl':
            return [];
        case 'pt':
            return [];
        case 'ro':
            return [];
        case 'ru':
            return [];
        case 'sv':
            return [];
        case 'th':
            return [];
        case 'tr':
            return [];
        case 'vi':
            return [];
        case 'sv':
            return [];
        case 'zh':
            return findKeyAndValue(objectzh, data);
        case 'my':
            return [];
        default:
            return []; // 或者其他默认值  
    }
}

//字段替换
export function getGameName(item) {
    // 根据语言返回对应的游戏名称  
    switch (get('Language') || 'zh') {
        case 'en':
            return item.gameName;
        case 'da':
            return item.gameNameDa;
        case 'de':
            return item.gameNameDe;
        case 'es':
            return item.gameNameEs;
        case 'fi':
            return item.gameNameFi;
        case 'fr':
            return item.gameNameFr;
        case 'id':
            return item.gameNameId;
        case 'it':
            return item.gameNameIt;
        case 'ja':
            return item.gameNameJa;
        case 'ko':
            return item.gameNameKo;
        case 'nl':
            return item.gameNameNl;
        case 'no':
            return item.gameNameOn;
        case 'pl':
            return item.gameNamePl;
        case 'pt':
            return item.gameNamePt;
        case 'ro':
            return item.gameNameRo;
        case 'ru':
            return item.gameNameRu;
        case 'sv':
            return item.gameNameSv;
        case 'th':
            return item.gameNameTh;
        case 'tr':
            return item.gameNameTr;
        case 'vi':
            return item.gameNameVi;
        case 'sv':
            return item.gameNameSv;
        case 'zh':
            return item.gameNameZh;
        case 'my':
            return item.gameNameMy;
        default:
            return item.gameNameZh; // 或者其他默认值  
    }
}

export function gameGameName() {
    // 根据语言返回对应的游戏名称  
    switch (get('Language') || 'zh') {
        case 'en':
            return 'gameName';
        case 'da':
            return 'gameNameDa';
        case 'de':
            return 'gameNameDe';
        case 'es':
            return 'gameNameEs';
        case 'fi':
            return 'gameNameFi';
        case 'fr':
            return 'gameNameFr';
        case 'id':
            return 'gameNameId';
        case 'it':
            return 'gameNameIt';
        case 'ja':
            return 'gameNameJa';
        case 'ko':
            return 'gameNameKo';
        case 'nl':
            return 'gameNameNl';
        case 'no':
            return 'gameNameOn';
        case 'pl':
            return 'gameNamePl';
        case 'pt':
            return 'gameNamePt';
        case 'ro':
            return 'gameNameRo';
        case 'ru':
            return 'gameNameRu';
        case 'sv':
            return 'gameNameSv';
        case 'th':
            return 'gameNameTh';
        case 'tr':
            return 'gameNameTr';
        case 'vi':
            return 'gameNameVi';
        case 'sv':
            return 'gameNameSv';
        case 'zh':
            return 'gameNameZh';
        case 'my':
            return 'gameNameMy';
        default:
            return 'gameNameZh'; // 或者其他默认值  
    }
}

//获取当前日期
export function Datetime() {
    const date = new Date();
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
}

//获取前7天日期
export function getSevenDaysAgoFromDate(dateStr) {
    const date = new Date(dateStr);
    date.setDate(date.getDate() - 7);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

//获取前30天日期
export function getHistoryDaysAgoFromDate(dateStr) {
    const date = new Date(dateStr);
    date.setDate(date.getDate() - 30);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

// 时间戳转日期
export function timestampTo(timestamp) {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return year + '-' + month + '-' + day;
}
