import store from './gamepacksonStore'
import { history } from '@http'
import { observable, computed, action } from 'mobx'
import { message } from "antd"
import {  t } from '@tool'
import { getGamePacketDetailGames, addGamePacketDetail } from '@api'

class GameStore {
    @observable formVisible = false

    @observable formEdit = false

    @observable record = {}

    @observable records = []

    // 默认第1页
    @observable pageNum = 1
    // 每页多少条
    @observable pageSize = 10
    // 总共多少页
    @observable total = ''

    @observable ids = ''

    // 其他参数，例如排序、过滤等等
    @observable tableOptions = {}

    @observable isFetching = false

    @computed get dataSource() {
        return this.records
    }

    _getTableParams = () => ({
        pageNum: this.pageNum, ...this.tableOptions, pageSize: this.pageSize, gamePacketId: history.location.state?.params
    })

    @action setPageNum(val) {
        this.pageNum = val
    }
    @action setPageSize(val) {
        this.pageSize = val
    }
    @action setIds(ids) {
        this.ids = ids
    }
    //查询
    fetchRecords = () => {
        const params = this._getTableParams()
        this.isFetching = true
        getGamePacketDetailGames(params).then(res => {
            this.total = res.totalCount
            this.records = res.list
        }).finally(() => this.isFetching = false);
    }
    //确定
    certainly = () => {
        if (this.ids.length === 0) {
            message.error(t('public.selectAdd'))
            return
        }
        this.isFetching = true
        let params = {
            'ids': this.ids,
            gamePacketId: history.location.state?.params
        }
        addGamePacketDetail(params).then(res => {
            if (res.status === 10000) {
                store.setPageNum(1);
                store.setPageSize(10);
                store.fetchRecords();
                this.setPageNum(1);
                this.setPageSize(10);
                this.fetchRecords();
                this.ids = [];
                store.formVisible = false;
            }
        }).finally(() => this.isFetching = false)
    }


}

export default new GameStore()