import React, {useState, useEffect} from 'react'
import {LeftOutlined, SearchOutlined, CloudDownloadOutlined} from '@ant-design/icons'
import {Button, Select, DatePicker, message} from "antd"
import {getOperateAndGameCompanyList, exportIntegralUseDetail} from '@api'
import {TableCard} from '@components'
import {history} from '@http'
import {MyForm, AuthButton} from '@components'
import {get, t, pulldown,} from '@tool'
import dayjs from 'dayjs';


export default function () {
    const currentYearMonth = new Date().toISOString().slice(0, 7);
    const [loading, setLoading] = useState(false);
    const [records, setRecords] = useState([]);

    const [companyId, setCompanyId] = useState(null);
    const [gameCompanyName, setGameCompanyName] = useState(null);
    const [isByDay, setIsByDay] = useState(2);

    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    const [total, setTotal] = useState(0);
    const [startTimeValue, setStartTime] = useState(currentYearMonth);
    const [endTimeValue, setEndTime] = useState(currentYearMonth);

    const [gameCompanyValue, setGameCompany] = useState(JSON.parse(get('gameCompanyList')));
    const [companyOptionValue, setCompanyOption] = useState(JSON.parse(get('operatorList')));

    const {RangePicker} = DatePicker;

    useEffect(() => {
        fetchRecords();
    }, [pageNum, pageSize, isByDay])

    const fetchRecords = () => {
        setRecords([])

        const params = {
            pageNum: pageNum,
            pageSize: pageSize,
            startTime: startTimeValue,
            endTime: endTimeValue,
            companyId: companyId,
            gameCompanyName: gameCompanyName,
            isByDay: isByDay
        };

        if (get('userType') !== 'ADMIN' && get('userType') !== 'COMPANY_ADMIN') {
            params.companyId = get('companyId');
        }

        setLoading(true);

        getOperateAndGameCompanyList(params).then(res => {
            setTotal(res.totalCount);
            setRecords(res.list)
        }).finally(() => setLoading(false));
    }

    const handleTableChange = ({current, pageSize}, filters, sorter) => {
        setPageNum(current)
        setPageSize(pageSize)
    }

    const columns = [{title: '结算日期', key: 'countTime', dataIndex: 'countTime', ellipsis: true,}, {
        title: '运营商', key: 'companyName', dataIndex: 'companyName', ellipsis: true
    }, {title: '游戏商', key: 'gameCompanyName', dataIndex: 'gameCompanyName', ellipsis: true,}, {
        title: '币种', key: 'currency', dataIndex: 'currency', ellipsis: true,
    }, {title: '总投注积分', key: 'sumAmount', dataIndex: 'sumAmount', ellipsis: true,}, {
        title: '总输赢积分', key: 'profitAmount', dataIndex: 'profitAmount', ellipsis: true,
    },]

    const onFinish = (params) => {
        setRecords([])
        // 重置为第一页
        setPageNum(1)

        setCompanyId(params["companyId"])
        setGameCompanyName(params["gameCompanyName"])

        isByDayChange(params["isByDay"])

        fetchRecords();
    }

    function handleMonthPickerChange(dates, dateStrings) {
        setStartTime(dateStrings[0]);
        setEndTime(dateStrings[1]);
    }

    const companyChange = (item) => {
        setCompanyId(item)
    }

    const gameCompanyChange = (item) => {
        setGameCompanyName(item)
    }

    const isByDayChange = (item) => {
        setIsByDay(item)
        setPageNum(1)
    }

    const exportIntegral = () => {
        const params = {
            startTime: startTimeValue,
            endTime: endTimeValue,
            companyId: companyId,
            gameCompanyName: gameCompanyName,
            isByDay: isByDay
        };
        exportIntegralUseDetail({...params})
            .then(downloadUrl => {
                if (downloadUrl.endsWith('.xlsx')) {
                    // 创建一个<a>标签
                    const a = document.createElement('a');
                    a.href = downloadUrl;
                    a.download = "";  // download属性确保直接下载
                    a.style.display = 'none';  // 隐藏a标签

                    // 将标签添加到DOM中，然后触发点击事件
                    document.body.appendChild(a);
                    a.click();

                    // 移除a标签
                    document.body.removeChild(a);
                } else {
                    message.error(downloadUrl);
                }

            })
            .catch(error => {
                message.error("导出失败", error);
            })
    }

    return (<div>
        <div style={{background: '#fff'}}>
            <Button
                onClick={() => history.goBack()}
                style={{margin: '10px'}}
                type={"primary"}
                icon={<LeftOutlined/>}>
                {t('public.Back')}
            </Button>
        </div>

        <MyForm onFinish={onFinish} initialValues={{isByDay: 2}}>
            <MyForm.Item span={5} title={'统计维度'} name={"isByDay"}>
                <Select
                    onChange={isByDayChange}
                    name={'isByDay'}
                >
                    {pulldown('countType').map(item => {
                        return <Select.Option value={item.value} key={item.label}>{item.label}</Select.Option>;
                    })}
                </Select>
            </MyForm.Item>

            {(get('userType') === 'ADMIN' || get('userType') === 'COMPANY_ADMIN') &&
                <MyForm.Item span={5} title={'运营商'} name={"companyId"}>
                    <Select
                        name={'companyId'}
                        placeholder="请选择运营商"
                        options={companyOptionValue}
                        allowClear
                        showSearch
                        onChange={companyChange}
                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                    />
                </MyForm.Item>}

            <MyForm.Item span={5} title={'游戏厂商'} name={"gameCompanyName"}>
                <Select
                    name={'gameCompanyName'}
                    placeholder="请选择游戏商"
                    options={gameCompanyValue}
                    allowClear
                    onChange={gameCompanyChange}
                    showSearch
                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                />
            </MyForm.Item>

            {isByDay !== 2 && < MyForm.Item span={7} title={'结算时间'}>
                <RangePicker
                    placeholder={['开始日期', '结束日期']}
                    picker={'month'}
                    format={'YYYY-MM'}
                    showTime
                    defaultValue={[dayjs(currentYearMonth, 'YYYY-MM'), dayjs(currentYearMonth, 'YYYY-MM')]}  // 设置默认值
                    onChange={handleMonthPickerChange}
                />
            </MyForm.Item>}

            <MyForm.Item span={3}>
                <Button type="primary" htmlType="submit" icon={<SearchOutlined/>}>{t('public.inquire')}</Button>
            </MyForm.Item>
        </MyForm>

        <TableCard
            tableLayout={'fixed'}
            size={'small'}
            scroll={{
                x: 1200, y: '44vh'
            }}
            rowKey="id"
            title={'积分使用情况'}
            loading={loading}
            // 后端的数据源
            dataSource={records}
            onReload={() => {
                fetchRecords()
            }}
            onChange={handleTableChange}
            actions={[<AuthButton
                type="primary"
                icon={<CloudDownloadOutlined/>}
                auth="pointsUsage.integral.export"
                onClick={() => exportIntegral()}>
                {t('public.derive')}
            </AuthButton>,]}
            // 分页器
            pagination={{
                showSizeChanger: true,
                showLessItems: true,
                showTotal: total => t('public.intotal', total),
                pageSizeOptions: ['10', '20', '50', '100'], // 如果不传 total，则以后端返回数据条数作为 total 的值
                total: total, // 如果不传，则默认是第一条，如果需要默认显示第3条，则必须传
                current: pageNum,
                pageSize: pageSize,
            }}
            columns={columns}/>
    </div>)
}
