import React, {useState} from "react"

import {Modal, Input, Upload, Button, Form, message} from 'antd'
import {UploadOutlined} from '@ant-design/icons'

import {log} from '@tool'

export default function UploadForm(props) {

    // useState 函数组件中使用 state
    const {uploadVisible, showUploadFrom, uploadType} = props
    const [fileName, setFileName] = useState('')

    const uploadProps = {
        name: 'file', action: '/ctgameclub/upload/uploadFile',//参数地址  详见antd中上传组件参数
        data: {fileName: fileName, type: uploadType},//参数名  详见antd中上传组件参数
        accept: '.xls,.xlsx',//上传文件限制
        multiple: false,
        onChange(info) {
            const {status, response} = info.file
            if (status === 'done') {//这个判断是监控上传状态
                log('upload done', response)
                if (response.status === 10000) { //示其借口返回 示情况选择判断字段验证
                    message.success(`上传成功！`)
                    showUploadFrom(3)
                } else {
                    message.error(response.msg)
                }
            } else if (status === 'error') {
                message.error("上传文件失败")
            }
        }, showUploadList: false,
    }

    const onBlur = (e) => {
        let value = e.target.value || document.getElementsByClassName('enterCompanyName')[0]
            ?.querySelector('.ant-select-selection-item')?.innerText
        setFileName(value)
    }

    return (<Modal
        forceRender={true}
        open={uploadVisible}
        footer={null}
        onCancel={() => showUploadFrom(2)}
        //点击按钮提价表单并上传文件
        title="文件上传">
        <Form layout="vertical" initialValues={{fileName: ''}}>
            <Form.Item label="文件名(如输入了文件名则使用该文件名)" name={"fileName"}
                       rules={[{required: false, message: '请输入文件名'}, {
                           message: '请输入正确的文件名',
                           pattern: /^[0-9a-z-]+$/,
                       }]}>
                <Input onBlur={onBlur} name={'fileName'} autoComplete="off" allowClear placeholder={"请输入文件名"}/>
            </Form.Item>
            <Form.Item>
                <div key={Math.random()}>
                    <Upload {...uploadProps}>
                        <Button type="primary" icon={<UploadOutlined/>}>
                            选择文件
                        </Button>
                    </Upload>
                </div>
            </Form.Item>
        </Form>
    </Modal>)
}