import React, { useState, useEffect } from 'react'
import { LeftOutlined, SearchOutlined } from '@ant-design/icons'
import { Button, Input, Select, DatePicker, message } from "antd"
import { getWalletPayRecordList, getAllCurrency } from '@api'
import { TableCard } from '@components'
import { history } from '@http'
import { MyForm } from '@components'
import { get, t } from '@tool'


export default function () {
    const [loading, setLoading] = useState(false);
    const [records, setRecords] = useState([]);
    const [pageNum, setpageNum] = useState(1);
    const [pageSize, setpageSize] = useState(10);
    const [total, settotal] = useState(0);
    const [startTimeValue, setStartTime] = useState('');
    const [endTimeValue, setEndTime] = useState('');
    const [setCurrencyOptionValue, setSetCurrencyOption] = useState([]);
    const [companyOptionValue, setCompanyOption] = useState(JSON.parse(get('operatorList')));
    const { RangePicker } = DatePicker;
    useEffect(() => {
        onGetCurrencyList();
        fetchRecords();
    }, [])
    useEffect(() => {
        fetchRecords();
    }, [pageNum, pageSize])
    const fetchRecords = (val) => {
        const params = {
            pageNum: pageNum,
            pageSize: pageSize,
            ...val,
            startTime: startTimeValue,
            endTime: endTimeValue
        }


        setLoading(true);
        getWalletPayRecordList({ ...params }).then(res => {
            settotal(res.totalCount);
            setRecords(res.list)
        }).finally(() => setLoading(false));

    }
    const handleTableChange = ({ current, pageSize }, filters, sorter) => {
        setpageNum(current)
        setpageSize(pageSize)

    }
    const columns = [
        { title: '运营商', width: '50px', key: 'companyName', dataIndex: 'companyName', ellipsis: true },
        { title: '订单编号', width: '80px', key: 'orderNo', dataIndex: 'orderNo', ellipsis: true, },
        { title: '币种', width: '50px', key: 'currency', dataIndex: 'currency', ellipsis: true, },
        { title: '充值金额/USDT', width: '60px', key: 'payIntegral', dataIndex: 'payIntegral', ellipsis: true },
        { title: '充值积分值', width: '60px', key: 'chargeIntegral', dataIndex: 'chargeIntegral', ellipsis: true },
        { title: '当前汇率', width: '50px', key: 'exchangeRate', dataIndex: 'exchangeRate', ellipsis: true },
        { title: '费率/%', width: '50px', key: 'rate', dataIndex: 'rate', ellipsis: true },
        { title: '充值时间', width: '80px', key: 'payTime', dataIndex: 'payTime', ellipsis: true },
        { title: '操作人员', width: '50px', key: 'payUser', dataIndex: 'payUser', ellipsis: true },
    ]

    const onFinish = (params) => {
        setRecords([])
        // 重置为第一页
        setpageNum(1)
        fetchRecords(params);
    }

    function handleRangePickerChange(dates, dateStrings) {
        setStartTime(dateStrings[0]);
        setEndTime(dateStrings[1]);
    }
    //获取币种
    const onGetCurrencyList = () => {
        if (setCurrencyOptionValue.length > 0) return;
        getAllCurrency().then(res => {
            const currencyList = res.map((item) => {
                return {
                    value: item.currency,
                    label: `${item.nameZh}(${item.currency})`
                }
            })
            setSetCurrencyOption(currencyList);
        }).catch(() => { message.error(t('public.error')) });
    }

    return (
        <div>
            <div style={{ background: '#fff' }}>             <Button onClick={() => history.goBack()}
                style={{ margin: '10px' }} type={"primary"}
                icon={<LeftOutlined />}> {t('public.Back')}</Button></div>
            <MyForm onFinish={onFinish} initialValues={{}}>
                <MyForm.Item span={5} title={'订单编号'} name={"orderNo"}>
                    <Input allowClear name={'orderNo'} autoComplete="off"
                        placeholder="请输入订单编号" />
                </MyForm.Item>
                <MyForm.Item span={5} title={'币种'} name={"currency"}>
                    <Select
                        name={'currency'}
                        placeholder="请选择币种"
                        options={setCurrencyOptionValue}
                        allowClear
                        showSearch
                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                    />
                </MyForm.Item>
                <MyForm.Item span={5} title={'运营商'} name={"companyName"}>
                    <Select
                        name={'companyName'}
                        placeholder="请选择运营商"
                        options={companyOptionValue}
                        allowClear
                        showSearch
                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                    />
                </MyForm.Item>
                <MyForm.Item span={7} title={'充值时间'} >
                    <RangePicker format={'YYYY/MM/DD HH:mm:ss'} showTime onChange={handleRangePickerChange} />
                </MyForm.Item>

                <MyForm.Item span={3}>
                    <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>{t('public.inquire')}</Button>
                </MyForm.Item>
            </MyForm>

            <TableCard
                tableLayout={'fixed'}
                size={'small'}
                scroll={{
                    x: 1200,
                    y: '44vh'
                }}
                rowKey="id"
                title={'充值记录'}
                loading={loading}
                // 后端的数据源
                dataSource={records}
                onReload={() => { fetchRecords() }}
                onChange={handleTableChange}
                actions={[]}
                // 分页器
                pagination={{
                    showSizeChanger: true,
                    showLessItems: true,
                    showTotal: total => t('public.intotal', total),
                    pageSizeOptions: ['10', '20', '50', '100'], // 如果不传 total，则以后端返回数据条数作为 total 的值
                    total: total, // 如果不传，则默认是第一条，如果需要默认显示第3条，则必须传
                    current: pageNum,
                    pageSize: pageSize,
                }}
                columns={columns} />
        </div>
    )
}
