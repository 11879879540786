import {post} from "@http"

//添加权限
export function addAuthority(params) {
    return post("/manager/authority/add", params)
}

//修改权限信息
export function modifyAuthority(params) {
    return post("/manager/authority/modify", params)
}

//修改状态
export function batchStatusAuthority(params) {
    return post("/manager/authority/batchStatus", params)
}

//获取所有权限信息
export function getAuthorityList(params) {
    return post("/manager/authority/getList", params)
}