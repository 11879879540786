import React, {useState} from "react"

import {SearchOutlined, ExclamationCircleOutlined} from '@ant-design/icons'
import {Button, Input, Select, Form, DatePicker, Dropdown} from "antd"
import store from "./gameCompanyStore"
import PlayerTable from "./gameCompanyTable"
import {t, Datetime, getSevenDaysAgoFromDate, get, log} from '@tool'
import {MyForm} from '@components'
import locale from 'moment/locale/zh-cn'
import moment from "moment/moment";

export default function UserIndex() {
    const [form] = Form.useForm()
    const [startTime, setStartTime] = useState(moment(getSevenDaysAgoFromDate(Datetime())))
    const [endTime, setEndTime] = useState(moment(Datetime()))
    const dateFormat = 'YYYY-MM-DD';

    const onFinish = (params) => {
        if (startTime) {
            params['startTime'] = moment(startTime).format('YYYY-MM-DD')
        }
        if (endTime) {
            params['endTime'] = moment(endTime).format('YYYY-MM-DD')
        }
        params['type'] = 'game'
        log(params)
        // 重置为第一页
        store.setPageNum(1)
        store.tableOptions = params
        store.fetchRecords()
    }

    const gameCompanyOptions = JSON.parse(localStorage.getItem('gameCompanyList'))
    const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
    return (<div>
        <MyForm form={form} labelCol={{span: 7}} onFinish={onFinish} initialValues={{}}>
            <MyForm.Item span={6} title={t('Bet.game')} name={"game"}>
                <Input style={{marginRight: "10px"}} allowClear name={'game'} autoComplete="off"
                       placeholder={t('public.input')}/>
            </MyForm.Item>

            <MyForm.Item span={6} title={t('Bet.transactionId')} name={"transactionId"}>
                <Input style={{marginRight: "10px"}} allowClear name={'transactionId'} autoComplete="off"
                       placeholder={t('public.input')}/>
            </MyForm.Item>

            <MyForm.Item span={6}  title={t('Bet.startTime')}>
                    <DatePicker
                        style={{ width: '100%' }}
                        locale={locale}
                        format={dateFormat}
                        onChange={(value) => setStartTime(value)}
                        defaultValue={moment(getSevenDaysAgoFromDate(Datetime()))}
                    />
                </MyForm.Item>
                <MyForm.Item span={6}  title={t('Bet.endTime')}>
                    <DatePicker style={{ width: '100%' }}
                        locale={locale}
                        format={dateFormat}
                        onChange={(value) => setEndTime(value)}
                        defaultValue={moment(moment(Datetime()))}
                    />
                </MyForm.Item>
            {get('userType') !== 'PLATFORM' &&
                <MyForm.Item span={6} title={t('Bet.platform')} name={"platform"}>
                    <Select
                        name={'platform'}
                        style={{ width: '100%' }}
                        showSearch
                        defaultValue={{value:'EG',label:'EG'}}
                        placeholder={t('Carriergame.GameCompanyPlease')}
                        filterOption={filterOption}
                        options={gameCompanyOptions}
                    >
                    </Select>
                </MyForm.Item>
            }

            <MyForm.Item span={6}>
                <Button type="primary" htmlType="submit" icon={<SearchOutlined/>}>{t('public.inquire')}</Button>
            </MyForm.Item>
        </MyForm>

        <PlayerTable/>

    </div>)
}