import {post} from "@http"
import {host} from '@tool'

//通过ID获取信息
export function getGameInfoById(id) {
    return post("/manager/gameInfo/" + id, {})
}

//添加游戏
export function addGameInfo(params) {
    return post("/manager/gameInfo/add", params)
}

//修改游戏信息
export function modifyGameInfo(params) {
    return post("/manager/gameInfo/modify", params)
}

//修改状态
export function batchStatusGameInfo(params) {
    return post("/manager/gameInfo/batchStatus", params)
}

//获取所有游戏信息
export function getGameInfoList(params) {
    return post("/manager/gameInfo/getList", params)
}

export function exportGame(params) {
    return post("/manager/gameInfo/export_game", params)
}

// //导出游戏
// export const exportGame = host + '/manager/gameInfo/export_game'

//导入游戏模板
export const importGame = 'https://www.hotgame.bet/template/gameImport.xlsx'

//下载游戏Icon
export function dwonloadGameIcon(total) {
    return post("/manager/gameInfo/downloadAndUpload?" + new URLSearchParams(total))
}


//同步游戏Icon --- 废弃
export function synchGameIcon(params) {
    return post("/manager/gameInfo/downloadAndUpload", params)
}

//同步游戏Icon V2版
export function syncGameIcon(params) {
    return post("/manager/v2/gameInfo/sync-game-icon", params)
}