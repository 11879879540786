import { observable, computed, action } from 'mobx'
import { getCompanyList } from '@api'
class GameStore {
    @observable formVisible = false

    @observable record = {}

    @observable records = []

    // 默认第1页
    @observable pageNum = 1
    // 每页多少条
    @observable pageSize = 10
    // 总共多少页
    @observable total = ''

    @observable id = ''

    // 其他参数，例如排序、过滤等等
    @observable tableOptions = {}

    @observable isFetching = false

    @computed get dataSource() {
        return this.records
    }

    _getTableParams = () => ({ pageNum: this.pageNum, pageSize: this.pageSize, ...this.tableOptions })

    @action setPageNum(val) {
        this.pageNum = val
    }
    @action setPageSize(val) {
        this.pageSize = val
    }
    @action setIds(ids) {
        this.ids = ids
    }

    // 获取列表
    fetchRecords = () => {
        const params = this._getTableParams()
        this.isFetching = true
        getCompanyList(params).then(res => {
            this.total = res.totalCount
            this.records = res.list
        }).finally(() => this.isFetching = false)
    }
    //打开弹窗
    onSetup = (record) => {
        this.id = record.id;
        this.formVisible = true;
    }
}

export default new GameStore()