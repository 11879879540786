//产品区域
export const productArea = [
    {label: '不限', value: '不限'},
    {label: '印度', value: '印度'},
    {label: '巴西', value: '巴西'},
    {label: '菲律宾', value: '菲律宾'},
    {label: '越南', value: '越南'},
    {label: '印尼', value: '印尼'},
    {label: '尼日利亚', value: '尼日利亚'},
    {label: '墨西哥', value: '墨西哥'},
    {label: '泰国', value: '泰国'},
    {label: '马来西亚', value: '马来西亚'},
    {label: '柬埔寨', value: '柬埔寨'},
    {label: '新加坡', value: '新加坡'},
    {label: '英国', value: '英国'},
    {label: '香港', value: '香港'},
    {label: '中东', value: '中东'},
    {label: '非洲', value: '非洲'},
    {label: '北美', value: '北美'},
    {label: '南美', value: '南美'},
    {label: '俄罗斯', value: '俄罗斯'},
    {label: '土耳其', value: '土耳其'},
    {label: '日韩', value: '日韩'},
    {label: '欧美', value: '欧美'},
    {label: '阿根廷', value: '阿根廷'},
    {label: '南非', value: '南非'},
    {label: '迪拜', value: '迪拜'},
    {label: '台湾', value: '台湾'},
]

//产品类型
export const productType = [
    {label: '全部', value: ''},
    {label: '游戏包网', value: '游戏包网'},
    {label: '支付渠道', value: '支付渠道'},
    {label: '营销渠道', value: '营销渠道'},
    {label: '云服务', value: '云服务'},
    {label: '游戏api', value: '游戏api'},
]

// 英文
export const productTypeEn = [
    {label: 'All', value: ''},
    {label: 'Game bag net', value: '游戏包网'},
    {label: 'Payment channel', value: '支付渠道'},
    {label: 'Marketing channel', value: '营销渠道'},
    {label: 'Cloud service', value: '云服务'},
    {label: 'Game api', value: '游戏api'},
]

//产品审核状态
export const productAudit = [
    {label: '全部', value: ''},
    {label: '未审核', value: '0'},
    {label: '已通过', value: '1'},
    {label: '已拒绝', value: '2'},
    {label: '已关闭', value: '3'},
]