import {post} from "@http"

//通过用户ID获取权限信息
export function getAuthorityByUserId(params) {
    return post("/manager/user-authority/get", params)
}

//修改用户权限信息
export function modifyUserAuthority(params) {
    return post("/manager/user-authority/modify", params)
}
