export function saveToken(res) {
    //id
    set('id', res.user['id'])
    // token
    set('token', res.user['userEncrypt'])
    // 昵称
    set('nickName', res.user['nickName'])
    // companyId
    set('companyId', res.user['companyId'])
    // userType
    set('userType', res.user['userType'])
    //loginName
    set('loginName', res.user['loginName'])
    // isSupper
    set('isSupper', res['isSupper'])


    // 权限
    set('permissions', JSON.stringify(res['permissions']))
}

export function removeToken() {
 const value = localStorage.getItem('Language')
    localStorage.clear();
    localStorage.setItem('Language', value)
    
}

export function get(key) {
    return localStorage.getItem(key)
}

export function set(key, value) {
    localStorage.setItem(key, value)
}