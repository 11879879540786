import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Modal, Form, Radio, Input, message, Row, Upload, Button, Col, Select } from 'antd'
import store from './GameStore'
import { UploadOutlined } from '@ant-design/icons';
import { UploadBtn } from '@components'
import { pulldown, languages, t, host } from '@tool'
import { addCompanyDetail, modifyCompanyDetail, getTypeList } from '@api'

export default observer(function () {
    // 文档中未找到这种解构使用方法
    const [form] = Form.useForm()

    // useState 函数组件中使用 state
    const [loading, setLoading] = useState(false)
    const [TypeList, setTypeList] = useState([])
    const [typeStr, setTypeStr] = useState('')
    const [typeOption, setTypeOption] = useState([])
    const [currencyStr, setcurrencyStr] = useState('')
    const [iconUrl, setIconUrl] = useState('');

    useEffect(() => {
        // setTypeOption([])
        form.resetFields();
        setIconUrl('')
        if (store.record.id) {
            const pame = JSON.parse(JSON.stringify(store.record))
            pame.type = pame.type === null ? [] : pame.type.split(",").map((val) => {
                return {
                    label: val,
                    value: val
                }
            })
            form.setFieldsValue(pame)
            setTypeStr(pame.type.map(obj => obj.label).join(","));
            setIconUrl(store.record.iconUrl)
        }
        onGetTypeList()
    }, [store.record])

    const handleSubmit = () => {
        form.submit()
    }
    const uploadProp = {
        name: 'file',
        action: '/ctgameclub/upload/uploadFile',
        data: { type: 'uploadImg' },
        accept: '.jpg,.png,.jpge',
        multiple: true,
        onChange: (info) => {
            if (info.file.status === 'done' && info.file.response && info.file.response.status === 10000) {
                console.log(info.file.response.data);
                setLoading(false)
                setIconUrl(info.file.response.data);
            }
        },
        beforeUpload: (file) => {
            setLoading(true)
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
            if (!isJpgOrPng) {
                message.error('只能上传 JPG/PNG 格式的图片!');
                setLoading(false)
            }
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
                message.error('图片必须小于 2MB!');
                setLoading(false)
            }
            return isJpgOrPng && isLt2M;
        },
        showUploadList: false,
    }
    const onGetTypeList = async () => {
        if (TypeList.length > 0) return
        const res = await getTypeList()
        setTypeOption(res.map((e) => {
            return {
                label: e.type,
                value: e.type,
            }
        }))
        setTypeList(typeOption)
    }

    const saveType = (val) => {
        const name = val.map((item) => {
            const arr = TypeList.find((e) => item == e.value)
            if (arr) {
                return arr.label
            }
        })
        const concatenatedString = name.join(",");
        setTypeStr(concatenatedString)
    }
    const onFinish = (values) => {
        if (iconUrl !== '') {
            values['iconUrl'] = iconUrl
        }
        setLoading(true)
        values['type'] = typeStr
        values['currencies'] = currencyStr

        if (store.record.id) {
            values['id'] = store.record.id
            if (store.record.companyDetailId) {
                values['companyDetailId'] = store.record.companyDetailId
            }
            modifyCompanyDetail(values).then(res => {
                if (res.status === 10000) {
                    store.formVisible = false
                    store.fetchRecords()
                } else {
                    message.error(t('Gamebusiness.failure'))
                }
            }).finally(() => setLoading(false))
        } else {
            addCompanyDetail(values).then(res => {
                if (res.status === 10000) {
                    store.formVisible = false
                    store.fetchRecords()
                }
            }).finally(() => setLoading(false))
        }
    }

    return (// Modal 对话框
        <Modal
            width={'90%'}
            forceRender={true}
            style={{ top: '2vh', }}
            open={store.formVisible}
            title={store.formTitle}
            onCancel={() => store.formVisible = false}
            confirmLoading={loading}
            onOk={handleSubmit}

        >
            <div style={{
                maxHeight: '74vh', // 设置最大高度为500px
                overflowX: 'hidden', // 溢出时出现滚动条
                overflowY: 'scroll', // 溢出时出现滚动条

            }}>
                <Form form={form} labelCol={{ span: 8 }} onFinish={onFinish} initialValues={{ languages: 'en', rtpSwitch: 0, syncSwitch: 0, isOpen: 0 }}>
                    <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
                        <Col span={8}>
                            <Form.Item label={t('Gamebusiness.companyname')}
                                rules={[{ required: true, message: `${t('public.input')}${t('Gamebusiness.companyname')}` }]}
                                name={"companyName"}>
                                <Input allowClear autoComplete="off" disabled={!store.formEdit}
                                    placeholder={`${t('public.input')}${t('Gamebusiness.companyname')}`} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label={t('Gamebusiness.Type')}
                                rules={[{ required: true, message: `${t('public.Select')}${t('Gamebusiness.Type')}` }]}
                                name={"companyType"}>
                                <Select
                                    disabled={!store.formEdit}
                                    style={{ width: "100%" }}
                                    allowClear
                                    placeholder={`${t('public.Select')}${t('Gamebusiness.Type')}`}
                                    value={'COMPANY'}>
                                    {pulldown('companyTypeOptionsSon').map(item => {
                                        if (item.value != '') {
                                            return <Select.Option value={item.value}
                                                key={item.label}>{item.label}</Select.Option>
                                        }
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label={t('Gamebusiness.mailbox')}
                                rules={[{ required: true, message: `${t('public.input')}${t('Gamebusiness.mailbox')}` }]}
                                name={"email"}>
                                <Input autoComplete="off" disabled={!store.formEdit} allowClear
                                    placeholder={`${t('public.input')}${t('Gamebusiness.mailbox')}`} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label={t('Gamebusiness.mobile')}
                                name={"phone"}>
                                <Input disabled={!store.formEdit} autoComplete="off"
                                    allowClear placeholder={`${t('public.input')}${t('Gamebusiness.mobile')}`} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label={t('Gamebusiness.Brand')}
                                rules={[{ required: true, message: `${t('public.input')}${t('Gamebusiness.Brand')}` }]}
                                name={"brandName"}>
                                <Input disabled={!store.formEdit} autoComplete="off" allowClear
                                    placeholder={`${t('public.input')}${t('Gamebusiness.Brand')}`} />
                            </Form.Item>
                        </Col >
                        <Col span={8}>
                            <Form.Item label={t('Gamebusiness.realBrandName')}
                                       rules={[{ required: true, message: `${t('public.input')}${t('Gamebusiness.realBrandName')}` }]}
                                       name={"realBrandName"}>
                                <Input disabled={!store.formEdit} autoComplete="off" allowClear
                                       placeholder={`${t('public.input')}${t('Gamebusiness.realBrandName')}`} />
                            </Form.Item>
                        </Col >

                        <Col span={8}>
                            <Form.Item label={t('Gamebusiness.companyGameType')}
                                rules={[{ required: true, message: `${t('public.input')}${t('Gamebusiness.companyGameType')}` }]}
                                name={"type"}>

                                <Select
                                    mode='multiple'
                                    placeholder={`${t('public.Select')}${t('Gamebusiness.companyGameType')}`}
                                    options={typeOption}
                                    onChange={(e) => { saveType(e) }}
                                    // isDisabled={}
                                    disabled={!store.formEdit}
                                >
                                </Select>
                            </Form.Item>
                        </Col >


                        {
                            false &&
                            <Col span={8}>
                                <Form.Item
                                    label="语&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;言"
                                    name={"languages"}>
                                    <Select
                                        disabled={!store.formEdit}
                                        style={{ width: '100%' }}
                                        allowClear
                                        placeholder="请选择语言"
                                        value={'en'}>
                                        {languages.map(item => {
                                            if (item.value != '') {
                                                return <Select.Option value={item.value}
                                                    key={item.label}>{item.label}</Select.Option>
                                            }
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                        }
                    </Row>

                    <Row>
                        {store.record.id && (
                            <Col span={8} >
                                <Form.Item label={t('Gamebusiness.status')}
                                    rules={[{ required: true, message: `${t('public.Select')}${t('Gamebusiness.status')}` }]}
                                    initialValue={store.record.status === 1}
                                    name={"status"}>
                                    <Radio.Group disabled={!store.formEdit}>
                                        <Radio value={1}>{t('public.effective')}</Radio>
                                        <Radio value={0}>{t('public.invain')}</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                        )}
                        <Col span={8}>
                            <Form.Item label={t('reward.Sand')}
                                labelCol={{ span: 8 }}
                                rules={[{
                                    required: true,
                                    message: `${t('public.Select')} ${t('reward.Sand')}`
                                }]}
                                name={"rtpSwitch"}>
                                <Radio.Group disabled={!store.formEdit}>
                                    <Radio value={1}>开启</Radio>
                                    <Radio value={0}>关闭</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col span={8} >
                            <Form.Item label={t('Gamebusiness.isOpen')}
                                rules={[{ required: true, message: `${t('public.Select')}${t('Gamebusiness.isOpen')}` }]}
                                initialValue={store.record.isOpen === 0}
                                name={"isOpen"}>
                                <Radio.Group disabled={!store.formEdit}>
                                    <Radio value={1}>{t('public.Yes')}</Radio>
                                    <Radio value={0}>{t('public.No')}</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={{ md: 8, lg: 24, xl: 48 }}>

                    <Col span={24}>
                            <Form.Item label={t('operator.ip')}
                                labelCol={{ span: 3 }}
                                name={"ip"}>
                                <Input disabled={!store.formEdit} autoComplete="off" allowClear
                                    placeholder={`${t('public.input')}${t('operator.ip')}`} />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item label={t('Gamebusiness.support')}
                                labelCol={{ span: 3 }}
                                name={"support"}>
                                <Input disabled={!store.formEdit} autoComplete="off" allowClear
                                    placeholder={`${t('public.input')}${t('Gamebusiness.support')}`} />
                            </Form.Item>
                        </Col>

                        {store.record.id &&
                            <Col span={24}>
                                <Form.Item label={'apiKey'}
                                    labelCol={{ span: 3 }}
                                    name={"apiKey"}>
                                    <Input disabled={true} autoComplete="off" allowClear
                                        placeholder={`${t('public.input')}${'apiKey'}`} />
                                </Form.Item>
                            </Col>

                        }
                        <Col span={24}>
                            <Form.Item label={t('Gamebusiness.Enterprise')}
                                labelCol={{ span: 3 }}
                                name={"officialWebsite"}>
                                <Input disabled={!store.formEdit} autoComplete="off" allowClear
                                    placeholder={`${t('public.input')}${t('Gamebusiness.Enterprise')}`} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label={t('Gamebusiness.Backg')}
                                labelCol={{ span: 3 }}
                                name={"backAccountPassword"}>
                                <Input disabled={!store.formEdit} autoComplete="off" allowClear
                                    placeholder={`${t('public.input')}${t('Gamebusiness.Backg')}`} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label={t('Gamebusiness.apiaddress')}
                                labelCol={{ span: 3 }}
                                name={"apiUrl"}>
                                <Input disabled={!store.formEdit} autoComplete="off" allowClear
                                    placeholder={`${t('public.input')}${t('Gamebusiness.apiaddress')}`} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label={t('Gamebusiness.Background')}
                                labelCol={{ span: 3 }}
                                name={"backOffice"}>
                                <Input disabled={!store.formEdit} autoComplete="off" allowClear
                                    placeholder={`${t('public.input')}${t('Gamebusiness.Background')}`} />
                            </Form.Item>
                        </Col>

                    </Row>

                    <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
                        <Col span={12} >
                            <Form.Item label={t('Gamebusiness.Test')}
                                labelCol={{ span: 4 }}
                                name={"testApiUrl"}>
                                <Input disabled={!store.formEdit} autoComplete="off" allowClear
                                    placeholder={`${t('public.input')}${t('Gamebusiness.Test')}`} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={t('Gamebusiness.Testaccount')}
                                labelCol={{ span: 7 }}
                                name={"testAccountPassword"}>
                                <Input disabled={!store.formEdit} autoComplete="off" allowClear
                                    placeholder={`${t('public.input')}${t('Gamebusiness.Testaccount')}`} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
                        <Col span={8}>
                            <Form.Item label={'游戏公司图片'} name={"iconUrl"}>
                                {iconUrl ? <img src={iconUrl}
                                    style={{ width: 100, height: 100 }}
                                    alt={''}
                                /> : ''}
                                <UploadBtn
                                    uploadType={'companyIcon'}
                                    suffixType={'img'}
                                    formEdit={store.formEdit}
                                    setLogoUrl={setIconUrl}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item label={t('Gamebusiness.syncSwitch')}
                                labelCol={{ span: 8 }}
                                rules={[{
                                    required: false,
                                    message: `${t('public.Select')} ${t('Gamebusiness.syncSwitch')}`
                                }]}
                                initialValue={store.record.syncSwitch === 0}
                                name={"syncSwitch"}>
                                <Radio.Group disabled={!store.formEdit}>
                                    <Radio value={1}>开启</Radio>
                                    <Radio value={0}>关闭</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
                        <Col span={24} >
                            <span style={{ color: "red" }}>{t('Gamebusiness.conversion')}</span>
                        </Col>
                    </Row>

                    <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
                        <Col span={12}>
                            <Form.Item label={t('Gamebusiness.Operating')}
                                labelCol={{ span: 6 }}
                                name={"operatingMarket"}>
                                <Input disabled={!store.formEdit} autoComplete="off" allowClear
                                    placeholder={`${t('public.input')}${t('Gamebusiness.Operating')}：BRL;KRW;MXN`} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
                        <Col span={24}>
                            <Form.Item
                                label={t('Gamebusiness.Platform')}
                                labelCol={{ span: 3 }}
                                name="platformPubKey">
                                <Input.TextArea disabled={!store.formEdit} rows={5} autoComplete="off" allowClear
                                    placeholder={`${t('public.input')}${t('Gamebusiness.Platform')}`} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
                        <Col span={24}>
                            <Form.Item
                                label={t('Gamebusiness.private')}
                                labelCol={{ span: 3 }}
                                name="platformPriKey">
                                <Input.TextArea disabled={!store.formEdit} rows={5} autoComplete="off" allowClear
                                    placeholder={`${t('public.input')}${t('Gamebusiness.private')}`} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
                        <Col span={24}>
                            <Form.Item
                                label={t('Gamebusiness.Public')}
                                labelCol={{ span: 3 }}
                                name="pubKey">
                                <Input.TextArea disabled={!store.formEdit} rows={5} autoComplete="off" allowClear
                                    placeholder={`${t('public.input')}${t('Gamebusiness.operator')}`} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
                        <Col span={24}>
                            <Form.Item
                                label={t('public.remark')}
                                labelCol={{ span: 3 }}
                                name="remark">
                                <Input.TextArea disabled={!store.formEdit} rows={5} autoComplete="off" allowClear
                                    placeholder={`${t('public.input')}${t('public.remark')}`} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>

        </ Modal>)
})