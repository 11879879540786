import {observable,} from 'mobx'

import {getGameInfoList} from '@api'

class GameInfoStore {

    @observable records = []

    // 其他参数，例如排序、过滤等等
    @observable tableOptions = {}

    @observable isFetching = false

    _getTableParams = () => ({pageNum: -1,status:1, ...this.tableOptions})

    fetchRecords = () => {
        const params = this._getTableParams()

        this.isFetching = true

        getGameInfoList(params).then(res => {
            this.records = res.list
        }).finally(() => this.isFetching = false)
    }
}

export default new GameInfoStore()