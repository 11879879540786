import React from 'react'
import { Dropdown } from 'antd'
import { TranslationOutlined } from '@ant-design/icons'
import i18n from 'i18next';
import { set, get } from "@tool"
export default function internation() {
    const items = [
        { label: '中文', key: 'zh' },
        { label: 'English', key: 'en' }
    ]
    const Language = get("Language");
    const handleMenu = (item) => {
        if (Language === null || Language !== item.key) {
            i18n.changeLanguage(item.key); // val入参值为'en'或'zh'
            set('Language', item.key);
            window.location.reload();
        }
    }
    return (
        <div style={{ display: 'flex', alignItems: 'center', height: '100%', marginRight: '10px' }}>
            <Dropdown
                menu={{ items, onClick: handleMenu }}
                placement="bottomRight"
                arrow={{ pointAtCenter: true }}
            >
                <TranslationOutlined style={{ fontSize: '20px' }} />
            </Dropdown>
        </div>
    )
}