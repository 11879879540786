import React from 'react'
import { observer } from 'mobx-react'

import { TableCard } from '@components'
import store from "./playerStore"
import { t, get } from '@tool'

@observer
class ComTable extends React.Component {
    componentDidMount() {
        store.tableOptions = {
            ...store.tableOptions, status: "1"
        }
        store.fetchRecords()
    }
    options = JSON.parse(localStorage.getItem('operatorList'))
    columns = [
        {
            title: t('Player.nickname'),
            width: '5vh',
            key: 'nickName',
            dataIndex: 'nickName',
            ellipsis: true,
        },
        {
            title: t('Player.Account'),
            width: '5vh',
            key: 'playerName',
            dataIndex: 'playerName', ellipsis: true,
        },
        {
            title: t('Player.Owning'),
            width: '5vh',
            key: 'companyId',
            dataIndex: 'companyId',
            ellipsis: true,
            hide: get('userType') == 'PLATFORM',
            render: (text) => {
                const filtered = this.options.filter(item => item.value === text);
                if (filtered.length > 0) {
                    return filtered[0].label;
                } else {
                    return '-'; // 或者返回其他默认值
                }
            }
        },
        {
            title: t('Player.sample'),
            width: '5vh',
            key: 'sample',
            dataIndex: 'sample',
            ellipsis: true,
            render: (text) => {
                return text === 0 ? "否" : "是"
            }
        },
        {
            title: t('Player.amount'),
            width: '5vh',
            key: 'amount',
            dataIndex: 'amount',
            ellipsis: true,
            render: (text) => {
                return (
                    <div>
                        {text ? parseFloat(text.toFixed(2)) : 0}
                    </div>
                )
            }
        },
        {
            title: t('Player.Currency'),
            width: '5vh',
            key: 'currency',
            dataIndex: 'currency',
            ellipsis: true,
        },
        {
            title: t('Player.status'),
            width: '4vh',
            key: 'status',
            dataIndex: 'status',
            ellipsis: true,
            render: (text) => (text === 1) ? t('public.effective') : t('public.invain'),
        },
    ]

    handleTableChange = ({ current, pageSize }, filters, sorter) => {
        store.pageNum = current
        store.pageSize = pageSize
        store.tableOptions = {
            ...store.tableOptions, // 排序：好像只支持单个排序
            sortField: sorter.field, sortOrder: sorter.order, ...filters
        }
        store.fetchRecords()
    }
    render() {
        return (
            <div>
                <TableCard
                    tableLayout={'fixed'}
                    size={'small'}
                    scroll={{
                        x: 1000,
                        y: '38vh'
                    }}
                    rowKey="id"
                    title={t('Player.layer')}
                    loading={store.isFetching}
                    // 后端的数据源
                    dataSource={store.dataSource}
                    onReload={store.fetchRecords}
                    onChange={this.handleTableChange}
                    actions={[]}
                    // 分页器
                    pagination={{
                        showSizeChanger: true,
                        showLessItems: true,
                        showTotal: total => t('public.intotal', total),
                        pageSizeOptions: ['10', '20', '50', '100'], // 如果不传 total，则以后端返回数据条数作为 total 的值
                        total: store.total, // 如果不传，则默认是第一条，如果需要默认显示第3条，则必须传
                        current: store.pageNum,
                        pageSize: store.pageSize,
                    }}
                    columns={this.columns}
                />


            </div>)
    }
}

export default ComTable
