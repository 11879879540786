import React from "react"

import { SearchOutlined } from '@ant-design/icons'

import { Button, Input, Select } from "antd"

import store from "./PlatfromStore"
import ComTable from "./PlatfromTable"
import ComForm from "./PlatfromForm"

import { pulldown, t } from '@tool'

import { MyForm } from '@components'

export default function UserIndex() {

    const onFinish = (params) => {
        // 重置为第一页
        store.setPageNum(1)
        store.tableOptions = params
        store.fetchRecords()
    }

    return (<div>
        <MyForm onFinish={onFinish} initialValues={{ status: '1', ipType: '' }}>
            <MyForm.Item span={6} title={t('WhiteIp.ip')} name={"ip"}>
                <Input allowClear name={'ip'} autoComplete="off"
                    placeholder={`${t('public.input')}${t('WhiteIp.ip')}`} />
            </MyForm.Item>
            <MyForm.Item span={6} title={t('WhiteIp.operatorCompanyName')} name={"operatorCompanyName"}>
                <Input allowClear name={'operatorCompanyName'} autoComplete="off"
                    placeholder={`${t('public.input')}${t('WhiteIp.operatorCompanyName')}`} />
            </MyForm.Item>
            <MyForm.Item span={6} title={t('WhiteIp.ipType')} name={"ipType"}>
                <Select
                    name={'ipType'}
                    placeholder={`${t('public.Select')}${t('WhiteIp.ipType')}`}>
                    {pulldown('ipType').map(item => {
                        return <Select.Option value={item.value} key={item.label}>{item.label}</Select.Option>
                    })}
                </Select>
            </MyForm.Item>
            <MyForm.Item span={6} title={t('BusinessCard.status')} name={"status"}>
                <Select
                    name={'status'}
                    placeholder={`${t('public.Select')}${t('BusinessCard.status')}`}>
                    {pulldown('statusOptionsSon').map(item => {
                        return <Select.Option value={item.value} key={item.label}>{item.label}</Select.Option>
                    })}
                </Select>
            </MyForm.Item>
            <MyForm.Item span={6}>
                <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>{t('public.inquire')}</Button>
            </MyForm.Item>
        </MyForm>
        <ComTable />
        {/* formVisible 控制表单显示 */}
        <ComForm />
    </div>)
}