import React, { useEffect, useRef, useState } from "react"
import * as echarts from 'echarts'
import { t, get } from '@tool'

export default function UserIndex(BetBetweenWeek) {
    const domRef = useRef();
    const [date, setDate] = useState([]);
    const [TotalBet, setTotalBet] = useState([]);
    const [Totalwinloss, setTotalwinloss] = useState([]);
    useEffect(() => {
        if ( BetBetweenWeek.BetBetweenWeek.legend !== 0) {
            const result = BetBetweenWeek.BetBetweenWeek.map((item) => item.dateTime);
            const result1 = BetBetweenWeek.BetBetweenWeek.map((item) => item.betAmount);
            const result2 = BetBetweenWeek.BetBetweenWeek.map((item) => item.profit);
            setDate(result);
            setTotalBet(result1);
            setTotalwinloss(result2);
        }
    }, [BetBetweenWeek.BetBetweenWeek]);

    useEffect(() => {
        chartInit();
    }, [date, TotalBet, Totalwinloss]);
    // 页面变化重新渲染
    const onResize = (chart) => {
        window.addEventListener("resize", () => {
            chart.resize();
        });
        const element = document.querySelector("#card");
        const resizeObserver = new ResizeObserver(() => {
            chart.resize();
        });
        // 开始观察元素
        resizeObserver.observe(element);
    }




    // 图表配置
    const option = {
        backgroundColor: '#fff',
        title: {
            text: '近七日下注情况',
            left: '5%',
            top: '5%'
        },
        tooltip: {
            trigger: 'axis',
        },
        grid: {
            left: '2%',
            right: '3%%',
            bottom: '0',
            top: '20%',
            containLabel: true
        },
        legend: {
            data: ['当日总投注积分', '当日总输赢积分'],
            orient: 'horizontal', // 设置为水平布局
            right: '5%',
            top: '5%',
        },
        xAxis: {
            type: 'category',
            // boundaryGap: false,
            data: date,
            axisLabel: {
                margin: 25,
                interval: 0,
                rotate: -30,
                align: 'center',
                verticalAlign: 'top'
            }
        },
        yAxis: {
            type: 'value'
        },
        series: [
            {
                name: '当日总投注积分',
                type: 'line',
                // smooth: true,
                color: '#E4C779',
                data: TotalBet,

            },
            {
                name: '当日总输赢积分',
                type: 'line',
                // smooth: true,
                color: '#F06F8B',
                data: Totalwinloss

            },



        ]
    }
    //渲染图表
    const chartInit = () => {
        const myChart = echarts.init(domRef.current)
        myChart.setOption(option)
        onResize(myChart);
    }


    return (
        <>
            <div id="card" ref={domRef} style={{ width: '100%', height: '100%' }}></div>
        </>

    )
}