import React from 'react'

import { observer } from 'mobx-react'
import { Popconfirm, Space } from 'antd'
import { DeleteOutlined, EditOutlined, MinusCircleOutlined, PlusOutlined, ExpandAltOutlined, SafetyCertificateOutlined } from '@ant-design/icons'
import { TableCard, AuthButton, } from '@components'

import store from './GameStore'
import authStore from './GameAuthStore'

import { t } from '@tool'

const rowSelection = {
    onChange: (selectedRowKeys) => {
        store.setIds(selectedRowKeys)
    }, getCheckboxProps: (record) => ({
        disabled: record.id === 'Disabled Index', // Column configuration not to be checked
        name: record.id,
    }),
}

@observer
class ComTable extends React.Component {

    componentDidMount() {
        store.tableOptions = {
            ...store.tableOptions, companyType: 'GAME_COMPANY', status: 1
        }
        store.fetchRecords()
    }

    columns = [
        { title: 'ID', fixed: 'left', width: '40px', key: 'id', dataIndex: 'id', ellipsis: true, },
        {
            title: '游戏商类型', width: '80px', key: 'type', dataIndex: 'type', ellipsis: true,
        },
        {
            title: t('Gamebusiness.companyname'),
            width: '80px',
            key: 'companyName',
            dataIndex: 'companyName',
            ellipsis: true,
        },
        {
            title: t('Gamebusiness.status'),
            width: '50px',
            key: 'status',
            dataIndex: 'status',
            ellipsis: true,
            render: (text) => (text === 1) ? t('public.effective') : t('public.invain'),
        },
        {
            title: 'apiKey', width: '150px', key: 'apiKey', dataIndex: 'apiKey', ellipsis: true,
            render: (text) => {
                return (<div className="ellipsis" style={{

                    overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis',
                }} title={t('public.Clickcopy')} onClick={store.copy}>
                    {text}
                </div>)
            }
        },
        {
            title: 'apiSecret', width: '150px', key: 'apiSecret', dataIndex: 'apiSecret', ellipsis: true,
            render: (text) => {
                return (<div className="ellipsis" style={{

                    overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis',
                }} title={t('public.Clickcopy')} onClick={store.copy}>
                    {text}
                </div>)
            }
        },
        {
            title: t('Gamebusiness.apiaddress'), width: '100px', key: 'apiUrl', dataIndex: 'apiUrl', ellipsis: true,
        },
        {
            title: t('public.Controls'), fixed: 'right', width: '300px', key: 'id', render: (_, record) => {
                return (<Space size="middle">
                    <AuthButton
                        type={"primary"}
                        ghost
                        icon={<SafetyCertificateOutlined />}
                        onClick={() => { authStore.showForm(record) }}
                        auth="game.company.edit">
                        {t('Gamebusiness.Auth')}
                    </AuthButton>
                    <AuthButton
                        type={"primary"}
                        ghost
                        icon={<ExpandAltOutlined />}
                        onClick={() => store.syncGameInfo(record)}
                        auth="game.company.edit">
                        {'同步游戏'}
                    </AuthButton>
                    <AuthButton
                        type={"primary"}
                        ghost
                        icon={<ExpandAltOutlined />}
                        onClick={() => store.generationGameEncrypt(record.id)}
                        auth="game.company.edit">
                        {'生成秘钥'}
                    </AuthButton>
                    <AuthButton
                        type={"primary"}
                        ghost
                        icon={<EditOutlined />}
                        onClick={() => store.showForm(record, 1)}
                        auth="game.company.edit">
                        {t('public.edit')}
                    </AuthButton>
                    <Popconfirm
                        title={t('public.sure')}
                        onConfirm={() => store.onDelete(record)}
                        okText={t('public.OK')}
                        cancelText={t('public.Cancel')}
                    >
                        <AuthButton
                            type={"primary"}
                            danger
                            ghost
                            icon={<DeleteOutlined />}
                            auth="game.company.delete">
                            {t('public.delete')}
                        </AuthButton>
                    </Popconfirm>
                </Space>)
            },
        },
    ]

    handleTableChange = ({ current, pageSize }, filters, sorter) => {
        store.pageNum = current
        store.pageSize = pageSize
        store.tableOptions = {
            ...store.tableOptions, // 排序：好像只支持单个排序
            sortField: sorter.field, sortOrder: sorter.order, ...filters
        }
        store.fetchRecords()
    }

    render() {
        return (<TableCard
            tableLayout={'fixed'}
            size={'small'}
            scroll={{
                x: 1900, y: '44vh'
            }}
            handleClickRow={store.handleClickRow}
            rowKey="id"
            rowSelection={{
                type: 'checkbox', ...rowSelection,
            }}
            title={t('Gamebusiness.vendorsList')}
            loading={store.isFetching}
            // 后端的数据源
            dataSource={store.dataSource}
            onReload={store.fetchRecords}
            onChange={this.handleTableChange}
            actions={[<Popconfirm
                title={`${t('public.Are')}${store.ids.length}?`}
                open={store.conDelete}
                onConfirm={() => store.onDelete(-1)}
                onCancel={() => {
                    store.confirmDelete(false)
                }}
                okText={t('public.OK')}
                cancelText={t('public.Cancel')}
            >
                <AuthButton
                    type="primary"
                    icon={<MinusCircleOutlined />}
                    auth="game.company.delete"
                    onClick={() => store.confirmDelete(true)}>
                    {t('public.Batchdeletion')}
                </AuthButton>
            </Popconfirm>, <AuthButton
                type="primary"
                icon={<PlusOutlined />}
                auth="game.company.add"
                onClick={() => store.showForm({}, true)}>
                {t('public.new')}
            </AuthButton>]}
            // 分页器
            pagination={{
                showSizeChanger: true,
                showLessItems: true,
                showTotal: total => t('public.intotal', total),
                pageSizeOptions: ['10', '20', '50', '100'], // 如果不传 total，则以后端返回数据条数作为 total 的值
                total: store.total, // 如果不传，则默认是第一条，如果需要默认显示第3条，则必须传
                current: store.pageNum,
                pageSize: store.pageSize,
            }}
            columns={this.columns} />)
    }
}

export default ComTable
