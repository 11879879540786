import React from 'react'

import { observer } from 'mobx-react'
import { Modal, Tooltip } from 'antd'
import store from './OperateLogStore'
import { t } from '@tool'
export default observer(function () {
    return (// Modal 对话框
        <Modal
            width={'54%'}
            footer={null}
            forceRender={true}
            open={store.formVisible}
            title={store.formTitle}
            onCancel={() => store.formVisible = false}>
            <div style={{ display: "flex", alignItems: 'center', borderBottom: '1px solid #f0f0f0', height: '40px', lineHeight: '40px' }}>
                <p style={{ margin: '0',width: 'fit-content', textAlign: "right", letterSpacing: '2px', fontWeight: "bold" }}>{t('Operationlog.type')}:</p>
                <Tooltip color={'#000'} placement="topLeft" title={store.record.logType} >
                    <p style={{ margin: '0', padding: '0 10px', maxWidth: '895px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {store.record.logType || '--'}
                    </p>
                </Tooltip>
            </div>
            <div style={{ display: "flex", alignItems: 'center', borderBottom: '1px solid #f0f0f0', height: '40px', lineHeight: '40px' }}>
                <p style={{ margin: '0', width: 'fit-content', textAlign: "right", letterSpacing: '2px', fontWeight: "bold" }}>{t('Operationlog.argument')}:</p>
                <Tooltip color={'#000'} placement="topLeft" title={store.record.content} >
                    <p style={{ margin: '0', padding: '0 10px', maxWidth: '895px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {store.record.content || '--'}
                    </p>
                </Tooltip>
            </div>
            <div style={{ display: "flex", alignItems: 'center', borderBottom: '1px solid #f0f0f0', height: '40px', lineHeight: '40px' }}>
                <p style={{ margin: '0', width: 'fit-content', textAlign: "right", letterSpacing: '2px', fontWeight: "bold" }}>{t('Operationlog.Operating')}:</p>
                <Tooltip color={'#000'} placement="topLeft" title={store.record.userName} >
                    <p style={{ margin: '0', padding: '0 10px', maxWidth: '895px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {store.record.userName || '--'}
                    </p>
                </Tooltip>
            </div>
            <div style={{ display: "flex", alignItems: 'center', borderBottom: '1px solid #f0f0f0', height: '40px', lineHeight: '40px' }}>
                <p style={{ margin: '0', width: 'fit-content', textAlign: "right", letterSpacing: '2px', fontWeight: "bold" }}>{t('Operationlog.Request')}:</p>
                <Tooltip color={'#000'} placement="topLeft" title={store.record.addTime} >
                    <p style={{ margin: '0', padding: '0 10px', maxWidth: '895px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {store.record.addTime || '--'}
                    </p>
                </Tooltip>
            </div>


        </Modal>)
})