import React from 'react'

import { observer } from 'mobx-react'
import { Popconfirm, Space, Image } from 'antd'
import { DeleteOutlined, MinusCircleOutlined, PlusOutlined, EditOutlined } from '@ant-design/icons'
import { TableCard, AuthButton, } from '@components'
import store from './WhiteIpStore'
import { t } from '@tool'
const rowSelection = {
    onChange: (selectedRowKeys) => {
        store.setIds(selectedRowKeys)
    }, getCheckboxProps: (record) => ({
        disabled: record.id === 'Disabled Index', // Column configuration not to be checked
        name: record.id,
    }),
}

@observer
class ComTable extends React.Component {

    componentDidMount() {
        store.tableOptions = {
            ...store.tableOptions, status: 1
        }
        store.fetchRecords()
    }
    columns = [
        {
            title: t('WhiteIp.ip'),
            width: '40px',
            key: 'ip', dataIndex: 'ip',
            ellipsis: true,
        },
        {
            title: t('WhiteIp.ipVersion'),
            width: '40px',
            key: 'ipVersion', dataIndex: 'ipVersion',
            ellipsis: true,
        },
        {
            title: t('WhiteIp.ipSetName'),
            width: '60px',
            key: 'ipSetName', dataIndex: 'ipSetName',
            ellipsis: true,
        },
        {
            title: t('WhiteIp.ipType'),
            width: '50px',
            key: 'ipType',
            dataIndex: 'ipType',
            ellipsis: true,
            render: (text) => (text === 1) ? t('WhiteIp.Black') : t('WhiteIp.White'),
        },
        {
            title: t('WhiteIp.operatorCompanyName'),
            width: '40px',
            key: 'operatorCompanyName', dataIndex: 'operatorCompanyName',
            ellipsis: true,
            render: (text) => (text === null) ? '-' : text,
        },
        {
            title: t('BusinessCard.status'),
            width: '50px',
            key: 'status',
            dataIndex: 'status',
            ellipsis: true,
            render: (text) => (text === 1) ? t('public.effective') : t('public.invain'),
        },
        {
            title: t('public.Controls'), fixed: 'right', width: '90px', key: 'id', render: (_, record) => {
                return (<Space size="middle">
                    <AuthButton
                        type={"primary"}
                        ghost
                        icon={<EditOutlined />}
                        onClick={() => store.showForm(record, false)}
                        auth="WhiteIp.Edit">
                        {t('WhiteIp.Edit')}
                    </AuthButton>
                    <Popconfirm
                        title={t('public.sure')}
                        onConfirm={() => store.onDelete(record)}
                        okText={t('public.OK')}
                        cancelText={t('public.Cancel')}
                    >

                        <AuthButton
                            type={"primary"}
                            danger
                            ghost
                            auth="company.delete"
                            icon={<DeleteOutlined />}>
                            {t('public.delete')}
                        </AuthButton>
                    </Popconfirm>
                </Space>)

            },
        },]

    handleTableChange = ({ current, pageSize }, filters, sorter) => {
        store.pageNum = current
        store.pageSize = pageSize
        store.tableOptions = {
            ...store.tableOptions, // 排序：好像只支持单个排序
            sortField: sorter.field, sortOrder: sorter.order, ...filters
        }
        store.fetchRecords()
    }

    render() {
        return (<TableCard
            tableLayout={'fixed'}
            size={'small'}
            handleClickRow={store.handleClickRow}
            scroll={{
                x: 1500,
                y: '44vh'
            }}
            rowKey="id"
            rowSelection={{
                type: 'checkbox', ...rowSelection,
            }}
            title={t('WhiteIp.List')}
            loading={store.isFetching}
            // 后端的数据源
            dataSource={store.dataSource}
            onReload={store.fetchRecords}
            onChange={this.handleTableChange}
            actions={[
                <Popconfirm
                    title={`${t('public.Are')}${store.ids.length}?`}
                    open={store.conDelete}
                    onConfirm={() => store.onDelete(-1)}
                    onCancel={() => { store.confirmDelete(false) }}
                    okText={t('public.OK')}
                    cancelText={t('public.Cancel')}
                >
                    <AuthButton
                        type="primary"
                        icon={<MinusCircleOutlined />}
                        auth="company.delete"
                        onClick={() => store.confirmDelete(true)}>
                        {t('public.Batchdeletion')}
                    </AuthButton>
                </Popconfirm>,
                <AuthButton
                    type="primary"
                    icon={<PlusOutlined />}
                    auth="company.add"
                    onClick={() => store.showForm({}, true)}>
                    {t('public.new')}
                </AuthButton>,
                <AuthButton
                    type="primary"
                    icon={<PlusOutlined />}
                    auth="WhiteIp.Sync"
                    onClick={() => store.onWhiteIpSync()}>
                    {t('WhiteIp.Sync')}
                </AuthButton>
            ]}
            // 分页器
            pagination={{
                showSizeChanger: true,
                showLessItems: true,
                showTotal: total => t('public.intotal', total),
                pageSizeOptions: ['10', '20', '50', '100'], // 如果不传 total，则以后端返回数据条数作为 total 的值
                total: store.total, // 如果不传，则默认是第一条，如果需要默认显示第3条，则必须传
                current: store.pageNum,
                pageSize: store.pageSize,
            }}
            columns={this.columns} />)
    }
}

export default ComTable
