import React from 'react'
import {observer} from 'mobx-react'
import {Popconfirm, Space} from 'antd'
import {
    DeleteOutlined,
    EditOutlined,
    MinusCircleOutlined,
    PlusOutlined,
    SafetyCertificateOutlined
} from '@ant-design/icons'
import {TableCard, AuthButton,} from '@components'

import store from './OperatorStore'

import {history} from '@http'
import {t} from '@tool'

const rowSelection = {
    onChange: (selectedRowKeys) => {
        store.setIds(selectedRowKeys)
    }, getCheckboxProps: (record) => ({
        disabled: record.id === 'Disabled Index', // Column configuration not to be checked
        name: record.id,
    }),
}

@observer
class ComTable extends React.Component {

    componentDidMount() {
        store.tableOptions = {
            ...store.tableOptions,
            companyType: 'OPERATOR_COMPANY',
            status: 1
        }
        store.fetchRecords()
    }

    columns = [
        {title: t('operator.Company'), width: '60px', key: 'companyName', dataIndex: 'companyName',},
        {
            title: t('operator.status'),
            width: '40px',
            key: 'status',
            dataIndex: 'status',
            ellipsis: true,
            render: (text) => (text === 1) ? t('public.effective') : t('public.invain'),
        },
        {
            title: t('operator.mailbox'),
            width: '80px',
            key: 'email',
            ellipsis: true,
            dataIndex: 'email',
            render: (text) => {
                return (
                    <div className="ellipsis" style={{
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                    }} title={t('public.Clickcopy')} onClick={store.copy}>
                        {text}
                    </div>)
            }
        },

        {
            title: t('public.Controls'), fixed: 'right', width: '170px', key: 'id', render: (_, record) => {
                return (<Space size="middle">
                    <AuthButton
                        type={"primary"}
                        ghost
                        icon={<SafetyCertificateOutlined/>}
                        onClick={() => {
                            store.showForm(record)
                        }}
                        auth="operator.price.view">
                        {'查看游戏商点位详情'}
                    </AuthButton>
                </Space>)
            },
        },]

    addGame = (record) => {
        history.push({pathname: `/gamemanager-copy`, state: {params: record.id}})
    }

    handleTableChange = ({current, pageSize}, filters, sorter) => {
        store.pageNum = current
        store.pageSize = pageSize
        store.tableOptions = {
            ...store.tableOptions, // 排序：好像只支持单个排序
            sortField: sorter.field, sortOrder: sorter.order, ...filters
        }
        store.fetchRecords()
    }

    render() {
        return (<TableCard
            tableLayout={'fixed'}
            size={'small'}
            scroll={{
                x: 1500,
                y: '44vh'
            }}
            handleClickRow={store.handleClickRow}
            rowKey="id"
            rowSelection={{
                type: 'checkbox', ...rowSelection,
            }}
            title={t('operator.opeList')}
            loading={store.isFetching}
            // 后端的数据源
            dataSource={store.dataSource}
            onReload={store.fetchRecords}
            onChange={this.handleTableChange}
            actions={[
                <Popconfirm
                    title={`${t('public.Are')}${store.ids.length}?`}
                    open={store.conDelete}
                    onConfirm={() => store.onDelete(-1)}
                    onCancel={() => {
                        store.confirmDelete(false)
                    }}
                    okText={t('public.OK')}
                    cancelText={t('public.Cancel')}
                >
                    <AuthButton
                        type="primary"
                        icon={<MinusCircleOutlined/>}
                        auth="operator.company.delete"
                        onClick={() => store.confirmDelete(true)}>
                        {t('public.Batchdeletion')}
                    </AuthButton>
                </Popconfirm>
                ,
                <AuthButton
                    type="primary"
                    icon={<PlusOutlined/>}
                    auth="operator.company.add"
                    onClick={() => store.showForm({}, 2)}>
                    {t('public.new')}
                </AuthButton>,
            ]
            }
            // 分页器
            pagination={{
                showSizeChanger: true,
                showLessItems: true,
                showTotal: total => t('public.intotal', total),
                pageSizeOptions: ['10', '20', '50', '100'], // 如果不传 total，则以后端返回数据条数作为 total 的值
                total: store.total, // 如果不传，则默认是第一条，如果需要默认显示第3条，则必须传
                current: store.pageNum,
                pageSize: store.pageSize,
            }}
            columns={this.columns}/>)
    }
}

export default ComTable
