import {post} from "@http"

//一键开户
export function oneKeyOperator(params) {
    return post("/manager/openOperator/oneKeyOperator", params)
}

//根据ID获取单个开户信息
export function getOpenOperator(id) {
    return post("/manager/openOperator/" + id, {})
}

//获取列表信息
export function getOpenOperatorList(params) {
    return post("/manager/openOperator/getList", params)
}

//下载授权文件
export function downloadAuthorize(params) {
    return post("/manager/openOperator/downloadAuthorize", params)
}