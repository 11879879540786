import React from "react"

import { SearchOutlined } from '@ant-design/icons'

import { Button, Input, Select } from "antd"

import store from "./gamepackStore"
import ComTable from "./gamepackTable"
import ComForm from "./gamepackForm"
import { pulldown, t } from '@tool'

import { MyForm } from '@components'

export default function UserIndex() {
    const onFinish = (params) => {
        // 重置为第一页
        store.setPageNum(1)
        store.tableOptions = params
        store.fetchRecords()
    }
    return (<div>
        <MyForm onFinish={onFinish} initialValues={{ status: '1', packetName: '' }}>
            <MyForm.Item span={7} title={t('Gamepack.name')} name={"packetName"}>
                <Input allowClear name={'packetName'} autoComplete="off"
                    placeholder={`${t('public.input')} ${t('Gamepack.name')}`} />
            </MyForm.Item>
            <MyForm.Item span={6} title={t('Gamepack.status')} name={"status"}>
                <Select
                    name={'status'}
                    placeholder={`${t('Select.input')} ${t('Gamepack.status')}`}>
                    {pulldown("statusOptionsSon").map(item => {
                        return <Select.Option value={item.value} key={item.value}>{item.label}</Select.Option>
                    })}
                </Select>
            </MyForm.Item>
            <MyForm.Item span={6}>
                <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>{t('public.inquire')}</Button>
            </MyForm.Item>
        </MyForm>
        <ComTable />
        {/* formVisible 控制表单显示 */}
        <ComForm />
    </div>)
}